<template>
  <el-breadcrumb class="app-breadcrumb" :class="[isHome ? 'home-breadcrumb' : '' ]" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-show="!isHome" key="/" :to="{ path: '/' }"><i class="el-icon-self-home-2"></i></el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path" v-if="item.path!='/'">
        <span class="no-redirect">{{ item.meta.title }}</span>
        <!-- <span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{ item.meta.title }}</span> -->
        <!-- <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a> -->
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp'

export default {
  data() {
    return {
      levelList: null,
      isHome: true
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      this.isHome = this.$route.path == '/' ? true : false
      // only show routes with meta.title
      let matched = this.$route.matched.filter((item) => item.meta && item.meta.title)
      const first = matched[0]

      // if (!this.isDashboard(first)) {
      //   matched = [{ path: '/dashboard', meta: { title: 'Dashboard' } }].concat(matched)
      // }

      this.levelList = matched.filter((item) => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    isDashboard(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
    },
    pathCompile(path) {
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: block;
  font-size: 14px;
  line-height: 45px;
  padding-left: 20px;
  background: #f6f7f9;
  margin-left: -24px;

  .no-redirect {
    color: #86909C;
    cursor: text;
  }

  .el-breadcrumb__item:last-child .no-redirect {
    color: #1D2129;
  }
}
.home-breadcrumb.el-breadcrumb{
  height: 25px;
}
</style>
