<template>
    <el-dialog :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" :before-close="handleClose" class="dialog-small">
        <template slot="title">
            <div class="title"></div>
        </template>
        <div class="content">
            <p>欢迎使用灵时云企业结算系统</p>
            <span>为了您更流畅的使用体验，建议阅读一下引导说明。</span>
        </div>
        <span slot="footer">
            <el-button plain @click="handleClose">关 闭</el-button>
            <el-button type="primary" @click="showGuide">流程引导</el-button>
        </span>
    </el-dialog>
</template>
  
<script>
export default {
    data() {
        return {
            show: false,
        }
    },
    methods: {
        // 数据清空
        clear() {

        },
        async open() {
            this.show = true
        },
        showGuide() {
            this.show = false
            this.$emit('showGuideSteps')
        },
        handleClose() {
            this.show = false
            this.$emit('showGuidePop')
        }
    }
}
</script>
  
<style lang="scss" scoped>
::v-deep .el-dialog__body {
    min-height: 116px;
}
.title {
    height: 120px;
    margin: -21px -21px -27px;
    background: url('../../../assets/guide-dialog.png') center no-repeat;
    background-size: 100% 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.content {
    padding: 0 0 0 16px;
    color: #000;
    p {
        line-height: 22px;
        margin: 0 0 8px 0;
        font-size: 16px;
        font-weight: 600;
        color: #000;
    }
    span {
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
    }
}
</style>
  