/* eslint-disable space-before-function-paren */
import { changeFavicon } from '@/utils/setting'
let countdownTimer
export default {
  namespaced: true,
  state: {
    sideFlag: false,
    crumb: '',
    vcCountdown: 0,
    wpVerifyCode: '',
    oemId: '', // oem替换信息
    oemTitle: '' // oem替换信息
  },
  mutations: {
    showSide(state) {
      state.sideFlag = true
    },
    hideSide(state) {
      state.sideFlag = false
    },
    setCrumb(state, t) {
      state.crumb = t
    },
    // 倒计时
    setVcCountdown(state, refresh) {
      if (refresh) {
        state.vcCountdown = 60
      } else {
        state.vcCountdown = state.vcCountdown - 1
      }
      if (state.vcCountdown == 0) {
        clearInterval(countdownTimer)
      }
    },
    clearVcCountdown(state) {
      clearInterval(countdownTimer)
      state.vcCountdown = 0
    },
    setWpVerify(state, code) {
      if (code) {
        state.wpVerifyCode = code
      } else {
        state.wpVerifyCode = ''
      }
    },
    // 设置oem信息
    setOem(state, oem) {
      state.oemId = oem.oemId
      state.oemTitle = oem.oemTitle
      changeFavicon(`./oem/${oem.oemId}/favicon.ico`)
    },
    clearOem(state) {
      state.oemId = ''
      state.oemTitle = ''
    }
  },
  actions: {
    // 倒计时开始
    vcDownStart: ({ commit }) => {
      commit('setVcCountdown', true)
      countdownTimer = setInterval(() => {
        commit('setVcCountdown')
      }, 1000)
    }
  }
}
