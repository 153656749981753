<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetFinanceReturnLogPageList" :searchList="searchList" :initSearch="initSearch">
      <!-- <el-form-item slot="btnline-item-left">
                <el-button plain type="primary" icon="el-icon-download" @click="$refs.Create.open()">导出</el-button>
            </el-form-item> -->

      <el-form-item slot="btnline-item">
        <el-button type="primary" @click="download" :loading="btnloadDownload">
          <i class="main-btn-icon el-icon-self-download-2-line"></i>
          <span>导出</span>
        </el-button>
      </el-form-item>
    </TableView>
  </div>
</template>

<script>
import { downloadExcel } from '@/utils'
const searchList = [
  {
    cmp: 'input',
    label: '流水号',
    prop: 'SearchTxt',
  },
  {
    cmp: 'dtpickers',
    label: '打款时间',
    prop: ['PayTimeBegin', 'PayTimeEnd']
  }
]
const tableLabels = [
  {
    label: '打款时间',
    prop: 'payTime',
    config: { width: 168 }
  },
  {
    label: '流水号',
    prop: 'returnNo'
  },
  { label: '金额(元)', prop: 'returnAmount', cmp: 'money', config: { minWidth: 100 } },
  {
    label: '收款账户名称',
    prop: 'merchantAccountName'
  },
  {
    label: '收款账户号码',
    prop: 'merchantAccountNumber'
  },
  {
    label: '收款开户银行',
    prop: 'merchantAccountBank'
  },
  {
    label: '备注',
    prop: 'remark',
    config: { showOverflowTooltip: true }
  }
]
export default {
  data() {
    return {
      tableLabels,
      searchList,
      btnloadDownload: false
    }
  },
  computed: {
    initSearch() {
      return {
        SearchTxt: this.$route.query.fundNo
      }
    }
  },
  methods: {
    async download() {
      const params = this.$refs.vTable.getParams()
      this.btnloadDownload = true
      const res = await this.$api.ExportFinanceReturnLog(params).finally(() => {
        this.btnloadDownload = false
      })
      if (res.type == 'application/json') {
        // 将blob文件流转换成json
        const reader = new FileReader()
        reader.onload = (event) => {
          const error = JSON.parse(reader.result)
          this.$message.error(error.message || '导出错误')
        }
        reader.readAsText(res)
      } else {
        downloadExcel(res, '退汇记录')
      }
    }
  }
}
</script>
