<template>
  <div>
    <el-dialog :title="handleState ? '上传' : '编辑'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <FormView ref="FormView" :labels="labels" :slotIdxList="[2]" :formConfig="{ labelWidth: '140px' }">
          <template #form-items="{ idx, form }">
            <el-col>
              <template v-if="idx === 2">
                <el-form-item label="任务编号" prop="taskNo" :rules="[{ required: true, message: '必填', trigger: 'blur' }]">
                  <el-select v-model="form.taskNo" @change="changeHandle" :style="{ width: '100%' }">
                    <el-option v-for="item in TaskList" :key="item.taskNo" :label="item.taskNo" :value="item.taskNo"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="任务名称" prop="taskName">
                  <span>{{ taskName || '请选择' }}</span>
                </el-form-item>
              </template>
            </el-col>
          </template>
        </FormView>
      </div>
      <div slot="footer">
        <el-button plain @click="show = !show">取 消</el-button>
        <el-button @click="submit" type="primary" :loading="btnloadSubmit">确 认</el-button>
      </div>
    </el-dialog>
    <WpView ref="Wp" :visible="false" :id="id" @geted="wpGeted" @need="wpNeed" :type="3" />
  </div>
</template>

<script>
import { regulateForm } from '@/configs/forms.js'
import WpView from '@/components/wpView'
export default {
  components: { WpView },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      handleState: false,
      taskName: '',
      id: null,
      labels: regulateForm,
      TaskList: [],
      people: {},
      detail: {}
    }
  },
  methods: {
    clear() {},
    open(row, state) {
      // this.clear()
      if (state) {
        this.handleState = false
        this.id = row.deliverableId
        this.taskName = row.taskName
        this.detail = row
        this.$nextTick(() => {
          this.$refs.Wp.handlerClick()
        })
      } else {
        this.show = true
        this.handleState = true
        this.taskName = ''
        this.id = null
        this.$nextTick(() => {
          this.$refs.FormView.fillData({})
        })
      }
      this.getTaskNoList()
    },
    // 需要验证码
    wpNeed() {
      setTimeout(() => {
        this.$message.warning('输入短信验证码后才能进行编辑')
      }, 10)
    },
    // 确认成功
    wpGeted(res) {
      const { name, mobile, idCard } = res
      this.people = { userName: name, idCard }
      this.show = true
      this.$nextTick(() => {
        let { deliverableList: Deliverable, acceptanceList: Acceptance, ...rest } = this.detail
        this.$refs.FormView.fillData(
          Object.assign(
            {
              deliverableList: Deliverable[0].url || '',
              acceptanceList: Acceptance[0].url || ''
            },
            rest,
            this.people
          )
        )
      })
    },
    // 获取任务编号列表
    getTaskNoList() {
      this.$api.GetTaskManageList().then((res) => {
        this.TaskList = res.list
      })
    },
    // 任务编号选项变动
    changeHandle(val) {
      const selected = this.TaskList.filter((item) => item.taskNo == val)
      this.taskName = selected[0].taskName
    },
    // 获取文件名称
    getName(val) {
      let pos = val.lastIndexOf('\/')
      return val.substring(pos + 1)
    },
    // 提交
    async submit() {
      const result = await this.$refs.FormView.validate()
      this.btnloadSubmit = true
      let { deliverableList: Deliverable, acceptanceList: Acceptance, ...rest } = result
      let params = Object.assign(
        {
          deliverableList: [{ url: Deliverable, name: this.getName(Deliverable) }],
          acceptanceList: [{ url: Acceptance, name: this.getName(Acceptance) }]
        },
        rest,
        {
          taskName: this.taskName
        }
      )
      if (!this.handleState) {
        const data = await this.$api.PutRegulateItem(params).finally(() => {
          this.btnloadSubmit = false
        })
        if (data) this.show = false
      } else {
        const data = await this.$api.PushAddRegulateItem(params).finally(() => {
          this.btnloadSubmit = false
        })
        if (data) this.show = false
      }
      this.$message.success('提交成功')
      this.$emit('updateList')
    }
  }
}
</script>
