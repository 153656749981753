<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetWorkPeoplePageList"
      :searchList="searchList"
      :selection="true"
      @selection-change="selChange"
      :selConfig="{ selectable }"
    >
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('004207')">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain @click="multipleSendSignMsg">批量签约</el-button>
      </el-form-item>
      <el-form-item slot="btnline-item">
        <el-button plain @click="downloadPeople" :loading="btnloadDownloading">
          <i class="main-btn-icon el-icon-self-download-2-line"></i>
          <span>导出</span>
        </el-button>
        <el-button plain v-if="$hasAccess('004204')" @click="add()">
          <i class="main-btn-icon el-icon-self-cengjia"></i>
          <span>添加</span>
        </el-button>
        <div id="guideSteps1" style="display: inline-block; margin-left: 12px">
          <el-button plain v-if="$hasAccess('004205')" @click="download" :loading="btnloadDownload">
            <i class="main-btn-icon el-icon-self-download-2-line"></i>
            <span>下载模板</span>
          </el-button>
          <div slot="btnline-item" v-if="$hasAccess('004206')" style="display: inline-block; margin-left: 12px">
            <el-button v-if="checkAuth" type="primary" @click="$root.$children[0].$refs.AuthDialog.open()">
              <i class="main-btn-icon el-icon-self-upload-2-line"></i>
              <span>导入创客</span>
            </el-button>
            <ComponentFormImport v-else url="WorkPeople/import" button="导入创客" @uploaded="onUploaded" />
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item slot="btnline-item">
        <el-button plain type="primary" @click="download2">导出</el-button>
      </el-form-item> -->
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="185" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('004201')" type="text" @click="$refs.Info.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('004200')" type="text" @click="$refs.Edit.open(row, { contractMode, isSign: row.isSign, isShowWorkerPhoto })"
            >编辑</el-button
          >
          <el-button v-if="$hasAccess('004202') && row.isSign != 1" type="text" @click="sendSignMsg(row)">签约</el-button>
          <!-- <el-button v-if="$hasAccess('004203') && row.isSign != 1" type="text" @click="smallSendSignMsg(row)"
            >小程序签约</el-button
          > -->
          <el-button v-if="row.isSign == 1" type="text" @click="downloadContract(row)">下载合同</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Info ref="Info"></Info>
    <Edit ref="Edit" @submit="asyncRefreshTable()" />
    <DialogImport ref="DialogImport" @submit="asyncRefreshTable()" @confirm="importConfirmShow" @success="importSuccessShow"></DialogImport>
    <DialogImportConfirm ref="DialogImportConfirm" @submit="asyncRefreshTable()" @confirm="importConfirm"></DialogImportConfirm>
    <DialogImportSuccess ref="DialogImportSuccess"></DialogImportSuccess>
  </div>
</template>

<script>
import { tableWorkPeople } from '@/configs/tables'
import { searchWorkPeople } from '@/configs/searches'
import ComponentFormImport from '@/components/formView/import'
import { downloadExcel, downloadUrl, getDay } from '@/utils'
import Info from './info'
import Edit from './edit'
import DialogImport from './import'
import DialogImportConfirm from './importConfirm'
import DialogImportSuccess from './importSuccess'
export default {
  components: { ComponentFormImport, Info, Edit, DialogImport, DialogImportConfirm, DialogImportSuccess },
  props: {
    modelValue: String,
    type: String
  },
  data() {
    return {
      btnloadDownload: false,
      btnloadDownloading: false,
      tableLabels: tableWorkPeople,
      searchList: searchWorkPeople,
      // 多选框
      multipleSelection: [],
      contractMode: 20,
      isShowWorkerPhoto: false
    }
  },
  computed: {
    // 结算单导入，先判断授权
    checkAuth() {
      if (
        this.$store.state.user.auth.signType == 0 ||
        // (!this.$store.state.user.auth.signType == 1 && !this.$store.state.user.auth.isAuth) ||
        this.$store.state.user.supplierSignList.find((item) => {
          return item.isSign != 2
        })
      )
        return true
      return false
    }
  },
  mounted() {
    this.getMerchantInfo()
  },
  methods: {
    // 获取商户信息
    async getMerchantInfo() {
      const res = await this.$api.GetMerchantInfo()
      this.contractMode = res.contractMode
      this.isShowWorkerPhoto = res.isShowWorkerPhoto
    },
    // 签约
    async sendSignMsg(row) {
      const params = {
        id: row.id,
        type: 1,
        supplierPeopleId: row?.supplierPeopleId
      }
      const res = await this.$api.WorkPeopleSendSignMsg(params)
      this.$message.success('签约短信已发送')
    },
    async smallSendSignMsg(row) {
      const params = {
        id: row.id,
        type: 1,
        signMethod: 1,
        supplierPeopleId: row?.supplierPeopleId
      }
      const res = await this.$api.WorkPeopleSendSignMsg(params)
      this.$message.success('小程序签约短信已发送')
    },
    // 选择框事件
    selChange(val) {
      this.multipleSelection = val
    },
    selectable(row, index) {
      if (row.isSign == 1) return false
      return true
    },
    // 下载合同
    downloadContract(row) {
      downloadUrl(row.contractUrl)
      // window.open(row.contractUrl)
    },
    // 批量签约
    async multipleSendSignMsg() {
      if (!this.multipleSelection.length) return this.$message.warning('请先选择需要批量签约的创客')
      const params = this.multipleSelection.map((item) => {
        return {
          id: item.id,
          type: 1,
          supplierPeopleId: item?.supplierPeopleId
        }
      })
      const res = await this.$api.WorkPeopleMultiSendSignMsg(params)
      this.$message.success('签约短信已发送')
    },
    // 下载模板
    async download() {
      this.btnloadDownload = true
      const res = await this.$api.WorkPeopleDownload().finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '创客导入模版')
    },
    async downloadPeople() {
      const params = this.$refs.vTable.getParams()
      this.btnloadDownloading = true
      const res = await this.$api.ExportWorkPeopleList(params).finally(() => {
        this.btnloadDownloading = false
      })
      // 失败处理
      if (res.type == 'application/json') {
        // 将blob文件流转换成json
        const reader = new FileReader()
        reader.onload = (event) => {
          const error = JSON.parse(reader.result)
          this.$message.error(error.message || '导出错误')
        }
        reader.readAsText(res)
      } else {
        downloadExcel(res, `创客列表${getDay(0)}`)
      }
    },
    onUploaded(res) {
      this.$refs.DialogImport.open(res)
    },
    // 导入的数据有通过和不通过，显示二次确认弹窗
    importConfirmShow(data) {
      this.$refs.DialogImportConfirm.open(data)
    },
    // 全部通过导入成功，显示确认弹窗
    importSuccessShow(data) {
      this.$refs.DialogImportSuccess.open(data)
    },
    // 导入成功，关闭所有弹窗
    importConfirm() {
      this.$refs.DialogImport.close()
    },
    // 导出
    async download2() {
      const params = this.$refs.vTable.getParams()
      const res = await this.$api.ExportWorkPeopleList(params)
      downloadExcel(res, '创客列表')
    },
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 100)
    },
    // 添加创客，先判断授权
    add() {
      if (
        this.$store.state.user.auth.signType == 0 ||
        // (!this.$store.state.user.auth.signType == 1 && !this.$store.state.user.auth.isAuth) ||
        this.$store.state.user.supplierSignList.find((item) => {
          return item.isSign != 2
        })
      ) {
        this.$root.$children[0].$refs.AuthDialog.open()
      } else {
        this.$refs.Edit.open()
      }
    }
  }
}
</script>
