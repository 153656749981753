<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView :initSearch="{ SearchTxt: fundNo }" ref="vTable" :labels="tableLabels" apiName="GetWithdrawLogPageList" :searchList="searchList">
      <el-form-item slot="btnline-item" v-if="$hasAccess('006000')">
        <el-button type="primary" @click="download" :loading="btnloadDownload">
            <i class="main-btn-icon el-icon-self-download-2-line"></i>
            <span>导出</span>
        </el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" v-if="$hasAccess('006001')" @click="$refs.Info.open(row, $index)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Info ref="Info"></Info>
  </div>
</template>

<script>
import { tableWithdrawalRecord } from '@/configs/tables'
import { searchWithdrawalRecord } from '@/configs/searches'
import Info from './components/withdrawInfo'
import { downloadExcel } from '@/utils'
export default {
  components: { Info },
  props: {
    modelValue: String,
    type: String
  },
  data() {
    return {
      btnloadDownload: false,
      tableLabels: tableWithdrawalRecord,
      searchList: searchWithdrawalRecord,
      // 多选框
      multipleSelection: [],
      contractMode: 20
    }
  },
  computed: {
    fundNo() {
      return this.$route.query.fundNo
    }
  },
  methods: {
    // 导出
    async download() {
      const params = this.$refs.vTable.getParams()
      this.btnloadDownload = true
      const res = await this.$api.ExportWithdrawData(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '提现记录')
    },
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 100)
    }
  }
}
</script>
