import DetailViewComponents from './detailView.vue'
import DetailCardComponents from './detailCard.vue'
import DetailTabsComponents from './detailTabs.vue'

const detailView = {
  install: function (Vue) {
    Vue.component('DetailView', DetailViewComponents)
    Vue.component('DetailCard', DetailCardComponents)
    Vue.component('DetailTabs', DetailTabsComponents)
  }
}

export default detailView
