<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '导入记录'
    }"
  >
    <div v-if="show">
      <TableView ref="vTable" :hideTitle="true" :labels="tableLabels" apiName="GetWorkPlanImportList" :searchList="searchList">
        <el-table-column slot="table-item" label="操作" width="120" fixed="right">
          <template v-slot="{ row }">
            <el-button type="text" @click="downloadOri(row)">下载源文件</el-button>
            <el-button type="text" @click="downloadRes(row)">下载校验结果</el-button>
          </template>
        </el-table-column>
      </TableView>
    </div>
  </DetailView>
</template>

<script>
import { downloadUrl, downloadUrlName, downloadExcel } from '@/utils'
const tableLabels = [
  { label: '导入时间', prop: 'importTime' },
  { label: '校验通过人数', prop: 'verifyPassCount' },
  { label: '校验通过金额', prop: 'verifyPassAmount' },
  { label: '校验不通过人数', prop: 'verifyNotPassCount' },
  { label: '校验不通过金额', prop: 'verifyNotPassAmount' },
  {
    label: '是否导入系统',
    prop: 'isImport',
    formatter: (row, column, cellValue, index) => {
      return cellValue == 1 ? '已导入' : '未导入'
    }
  }
]
const searchList = []
export default {
  data() {
    return {
      show: false,
      tableLabels,
      searchList
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
    },
    open() {
      this.clear()
      this.show = true
    },
    fileName(url) {
      const fa = url.split('.')
      return '.' + fa[fa.length - 1]
    },
    // 下载源文件
    downloadOri(one) {
      if (one.importFileUrl) {
        const name = '结算单导入源文件_' + one.importTime.replace(/\s/g, '_') + this.fileName(one.importFileUrl)
        downloadUrlName(one.importFileUrl, name)
        // downloadUrl(one.importFileUrl)
      }
    },
    // 下载结果
    async downloadRes(one) {
      const name = '结算单导入校验结果_' + one.importTime.replace(/\s/g, '_')
      const res = await this.$api.ExportImportResult({
        importId: one.id
      })
      downloadExcel(res, name)
    }
  }
}
</script>
