import Layout from '@/layout'
import store from '@/store'

/** meta
 * hideInSide: true 在Side中不展示
 * showInSide: true 当页面访问时才在Side中展示，优先级高于hideInSide
 * icon: 'el-xxx' Side中的icon
 * title: '' 标题
 */

export const currencyRoutes = [
  {
    path: '/login',
    name: 'login',
    hideInSide: true,
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login')
  },
  {
    path: '/error-530',
    name: 'error-530',
    hideInSide: true,
    meta: {
      title: '登录失效'
    },
    component: () => import('@/views/error/530')
  },
  {
    path: '/error-404',
    name: 'error-404',
    hideInSide: true,
    meta: {
      title: '页面不存在'
    },
    component: () => import('@/views/error/404')
  },
  {
    path: '/error-403',
    name: 'error-403',
    hideInSide: true,
    meta: {
      title: '无权访问'
    },
    component: () => import('@/views/error/403')
  },
  {
    path: '/replace',
    name: 'replace',
    component: Layout
  }
]

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        // hideInSide: true,
        meta: {
          title: '首页',
          icon: 'el-icon-self-home-2'
        },
        component: () => import('@/views/home')
      }
    ]
  },
  {
    path: '/notice',
    component: Layout,
    children: [
      {
        path: '',
        name: 'notice',
        hideInSide: true,
        meta: {
          title: '公告',
          ruleName: '004000'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/notice')
      }
    ]
  },
  {
    path: '/msg',
    component: Layout,
    children: [
      {
        path: '',
        name: 'msg',
        hideInSide: true,
        meta: {
          title: '消息',
          ruleName: '004001'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/msg')
      }
    ]
  },
  {
    path: '/discountDetail',
    component: Layout,
    children: [
      {
        path: '',
        name: 'discountDetail',
        hideInSide: true,
        meta: {
          title: '抵扣金明细'
          // ruleName: ''
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/discountDetail')
      }
    ]
  },
  // {
  //   path: '/template',
  //   meta: {
  //     title: '模版管理'
  //     // icon: 'el-icon-house'
  //   },
  //   hideInSide: true,
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/templateTable',
  //       name: 'templateTable',
  //       meta: {
  //         title: '表格模版'
  //         // icon: 'el-icon-house'
  //       },
  //       component: () => import('@/views/template/table')
  //     },
  //     {
  //       path: '/templateForm',
  //       name: 'templateForm',
  //       meta: {
  //         title: '表单模版'
  //         // icon: 'el-icon-house'
  //       },
  //       component: () => import('@/views/template/form')
  //     }
  //   ]
  // },
  {
    path: '/taskManage',
    component: Layout,
    meta: {
      title: '任务管理',
      icon: 'el-icon-self-Sensitive-words-2'
    },
    children: [
      {
        path: '/taskManage/taskList',
        name: 'taskList',
        meta: {
          title: '任务列表',
          ruleName: '310-010'
        },
        component: () => import('@/views/taskManage/taskList')
      },
      {
        path: '/taskManage/deliverablesCheck',
        name: 'deliverablesCheck',
        meta: {
          title: '交付物审核',
          ruleName: '310-020'
        },
        component: () => import('@/views/taskManage/deliverablesCheck')
      },
      {
        path: '/taskManage/orderReceiving',
        name: 'orderReceiving',
        meta: {
          title: '接单列表',
          ruleName: '310-030'
        },
        component: () => import('@/views/taskManage/orderReceiving')
      }
    ]
  },
  {
    path: '/workPeople',
    component: Layout,
    businessType: 1,
    children: [
      {
        path: '',
        name: 'workPeople',
        meta: {
          title: '创客列表',
          ruleName: '320',
          icon: 'el-icon-self-full-name'
        },
        component: () => import('@/views/workPeople')
      }
    ]
  },
  {
    path: '/selfEmployed',
    component: Layout,
    businessType: 2,
    children: [
      {
        path: '',
        name: 'selfEmployed',
        meta: {
          title: '个体户列表',
          ruleName: '430-010',
          icon: 'el-icon-self-full-name'
        },
        component: () => import('@/views/selfEmployed')
      }
    ]
  },
  {
    path: '/workPlan',
    meta: {
      title: '结算管理',
      icon: 'el-icon-self-settle-accounts'
    },
    component: Layout,
    children: [
      {
        path: '/workPlan/Check',
        name: 'workPlanCheck',
        meta: {
          title: '结算单',
          ruleName: '330-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/workPlan/check')
      },
      {
        path: '/workPlan/Detail',
        name: 'workPlanDetail',
        meta: {
          title: '结算明细',
          ruleName: '330-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/workPlan/detail')
      }
    ]
  },
  {
    path: '/account',
    meta: {
      title: '账户管理'
      // icon: 'el-icon-house'
    },
    component: Layout,
    children: [
      {
        path: '/account/mine',
        name: 'myAccount',
        meta: {
          title: '我的账户',
          ruleName: '340',
          icon: 'el-icon-self-wallet-line'
        },
        component: () => import('@/views/account')
      }
    ]
  },
  {
    path: '',
    meta: {
      title: '协议管理'
      // icon: 'el-icon-house'
    },
    component: Layout,
    children: [
      {
        path: 'myAgreement',
        name: 'myAgreement',
        meta: {
          title: '我的协议',
          ruleName: '410',
          icon: 'el-icon-self-agreement'
        },
        component: () => import('@/views/myAgreement')
      }
    ]
  },
  {
    path: '/financeCenter',
    meta: {
      title: '财务中心',
      icon: 'el-icon-self-wallet-2-line'
    },
    component: Layout,
    children: [
      {
        path: '/financeCenter/accountCapital',
        name: 'accountCapital',
        meta: {
          title: '资金流水',
          ruleName: '350-005'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/financeCenter/accountCapital/index')
      },
      {
        path: '/financeCenter/rechargeRecord',
        name: 'rechargeRecord',
        meta: {
          title: '充值记录',
          ruleName: '350-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/financeCenter/rechargeRecord')
      },
      {
        path: '/financeCenter/returnLog',
        name: 'returnLog',
        meta: {
          title: '退汇记录',
          ruleName: '350-040'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/financeCenter/returnLog')
      },
      {
        path: '/financeCenter/withdrawalRecord',
        name: 'withdrawalRecord',
        meta: {
          title: '提现记录',
          ruleName: '350-030'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/financeCenter/withdrawalRecord')
      },
      {
        path: '/financeCenter/disburseRecord',
        name: 'disburseRecord',
        meta: {
          title: '支出流水',
          ruleName: '350-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/financeCenter/disburseRecord')
      },
      {
        path: '/financeCenter/receiptDownload',
        name: 'financeReceiptDownload',
        meta: {
          title: '批量下载回单',
          ruleName: '350-050'
          // ruleName: 'batchDownReceipt'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/financeCenter/receiptDownload')
      }
    ]
  },
  {
    path: '/billApply',
    meta: {
      title: '票税管理',
      icon: 'el-icon-self-coupon-line'
    },
    component: Layout,
    children: [
      {
        path: '/billApplyInfo',
        name: 'billApply',
        meta: {
          title: '发票申请',
          ruleName: '360-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/apply')
      },
      {
        path: '/billInfo',
        name: 'billInfo',
        meta: {
          title: '开票记录',
          ruleName: '360-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/billInfo')
      },
      {
        path: '/taxVoucher',
        name: 'taxVoucher',
        // hideInSide: true,
        meta: {
          title: '完税凭证',
          ruleName: '360-030'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/taxVoucher')
      },
      {
        path: '/addressInfo',
        name: 'addressInfo',
        meta: {
          title: '邮寄地址',
          ruleName: '360-040'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/addressInfo')
      },
      {
        path: '/riseInfo',
        name: 'riseInfo',
        meta: {
          title: '发票抬头',
          ruleName: '360-050'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/riseInfo')
      }
    ]
  },
  {
    path: '/RiskControl',
    meta: {
      title: '风控管理',
      icon: 'el-icon-self-Risk-Management-line'
    },
    component: Layout,
    children: [
      {
        path: '/deliveryRegulate',
        name: 'deliveryRegulate',
        meta: {
          title: '交付物管理',
          ruleName: '370-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/RiskControl/regulate')
      },
      {
        path: '/businessBills',
        name: 'businessBills',
        meta: {
          title: '业务确认单',
          ruleName: '370-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/RiskControl/bills')
      },
      {
        path: '/blacklist',
        name: 'blacklist',
        meta: {
          title: '发放黑名单',
          ruleName: '370-030'
          // icon: 'icon-duoren-renqun'
        },
        component: () => import('@/views/RiskControl/blacklist')
      },
      {
        path: '/whitelist',
        name: 'whitelist',
        meta: {
          title: '签约白名单',
          ruleName: '370-040'
          // icon: 'icon-duoren-renqun'
        },
        component: () => import('@/views/RiskControl/whitelist')
      }
    ]
  },
  {
    path: '/merchant',
    component: Layout,
    children: [
      {
        path: '',
        name: 'merchant',
        hideInSide: true,
        meta: {
          title: '账号设置'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/merchant')
      },
      {
        path: '/merchant/sub',
        name: 'merchant',
        meta: {
          title: '子账号管理',
          ruleName: '380',
          icon: 'el-icon-self-account-number'
        },
        component: () => import('@/views/merchant/sub')
      }
    ]
  }
]
