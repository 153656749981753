<template>
  <el-dialog title="选择发票抬头" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <div class="dialog-wrap" style="height: 600px" v-if="show">
      <div class="flex-home">
        <TableView ref="vTable" :labels="tableLabels" apiName="GetRiseInfo" highlight-current-row @current-change="handleCurrentChange"></TableView>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { tableRiseInfo } from '@/configs/tables'
import { deepClone } from '@/utils'
export default {
  data() {
    return {
      show: false,
      tableLabels: tableRiseInfo
    }
  },
  methods: {
    // 数据清空
    clear() {},
    open(obj) {
      this.clear()
      this.show = true
      // this.$refs.vTable.resetTable()
    },
    handleCurrentChange(val) {
      this.$emit('seled', deepClone(val))
      this.show = false
    }
  }
}
</script>
