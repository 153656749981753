<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView :labels="labels" apiName="GetAgreementList">
      <el-table-column slot="table-item" label="操作">
        <template v-slot="{ row }">
          <el-button type="text" v-if="$hasAccess('006100') && row.effectiveStatus != 0 && row.contractUrl" @click="download(row)">下载</el-button>
          <el-button type="text" v-if="row.signState == 1 && row.signType == 1 && row.effectiveStatus != 2" @click="toLink(row)">签约</el-button>
        </template>
      </el-table-column>
    </TableView>
  </div>
</template>

<script>
import { tableMyAgreement } from '@/configs/tables'
import { downloadUrl } from '@/utils'
export default {
  // 组件名称
  name: 'myAgreement',
  // 组件状态值
  data() {
    return {
      labels: tableMyAgreement
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    download(row) {
      downloadUrl(row.contractUrl)
    },
    toLink(row) {
      window.open(row.signUrl)
    }
  }
}
</script>
<style scoped></style>
