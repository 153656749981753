<template>
  <div>
    <el-dialog :title="isEdit ? '审核' : '查看'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
      <div>
        <el-descriptions
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          :column="2"
          border
          title="接单信息"
        >
          <el-descriptions-item label="申请时间">{{ detail.receiveTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="创客姓名"
            >{{ detail.peopleName || '无' }}
            <WpView v-if="detail.peopleName" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="手机号"
            >{{ detail.peopleMobile || '无' }}
            <WpView v-if="detail.peopleMobile" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="证件号"
            >{{ detail.peopleIDCard || '无' }}
            <WpView v-if="detail.peopleIDCard" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="任务名称">{{ detail.taskName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          v-if="!isEdit"
          :column="2"
          border
          title="审核信息"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <el-descriptions-item label="审核时间">{{ detail.auditTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="审核人">{{ detail.auditer || '无' }}</el-descriptions-item>
          <el-descriptions-item label="审核状态">
            <span>{{ formatStatus(kvAuditState, detail.auditState) }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="审核意见">{{ detail.auditFailreason || '无' }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          v-if="!isEdit"
          :column="2"
          border
          title="验收信息"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <el-descriptions-item label="完成时间">{{ detail.finishTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="操作人">{{ detail.finisher || '无' }}</el-descriptions-item>
          <el-descriptions-item label="成果验收单"
            ><span @click="downLoadResult(detail.acceptanceNoteUrl)" :style="{ color: detail.acceptanceNoteUrl ? 'blue' : '', cursor: 'pointer' }">
              {{ detail.acceptanceNoteUrl ? '下载文件' : '无' }}</span
            ></el-descriptions-item
          >
        </el-descriptions>
      </div>
      <span v-if="isEdit" slot="footer">
        <el-button @click="showReason = true">审核不通过</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">审核通过</el-button>
      </span>
    </el-dialog>
    <el-dialog title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false" class="dialog-small">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button plain @click="showReason = !showReason">取 消</el-button>
        <el-button @click="submitReason" type="primary" :loading="btnloadSubmit">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatStatus, kvAuditState } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import { downloadUrl } from '@/utils'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      showReason: false,
      id: null,
      detail: {
        attachList: []
      },
      reason: '',
      kvAuditState,
      isEdit: false,
      auditState: 0
    }
  },
  methods: {
    formatStatus,
    async open(obj, isEdit) {
      this.isEdit = isEdit
      this.auditState = obj.auditState
      const result = await this.$api.GetTaskManageReceiveInfo({ id: obj.id })
      this.detail = Object.assign({}, result)
      this.show = true
    },
    downLoadResult(e) {
      if (e) {
        downloadUrl(e)
      }
    },
    async submitReason() {
      if (this.reason == '') {
        this.$message.error('请输入审核不通过理由')
        return false
      }
      this.btnloadSubmit = true
      const result = await this.$api
        .UpdateTaskManageReceiveState({
          id: this.detail.id,
          auditState: 2,
          AuditFailreason: this.reason
        })
        .finally(() => {
          this.btnloadSubmit = false
        })
      this.reason = null
      this.$message.success('提交成功')
      this.show = false
      this.showReason = false
      this.$emit('refresh')
    },
    async submit() {
      this.btnloadSubmit = true
      await this.$api.UpdateTaskManageReceiveState({ id: this.detail.id, auditState: 1 }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('审核成功')
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>
