<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="labels" :searchList="searchList" apiName="GetWhitelist">
      <el-form-item slot="btnline-item" v-if="$hasAccess('005501')">
        <el-button type="primary" @click="$refs.AddPeople.open()">
          <i class="main-btn-icon el-icon-self-cengjia"></i>
          <span>添加</span>
        </el-button>
      </el-form-item>
      <el-table-column slot="table-item" label="操作" width="185" fixed="right">
        <template v-slot="{ row }">
          <!-- <el-button v-if="row.auditState == '待提交' && $hasAccess('005505')" type="text" @click="$refs.AddPeople.open(row)">编辑</el-button> -->
          <!-- <el-button v-if="row.auditState == '待提交'" type="text" @click="doSubmit(row)">提交</el-button> -->
          <el-button v-if="$hasAccess('005500')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button v-if="row.auditState == '已撤回' && $hasAccess('005503')" type="text" @click="$refs.AddPeople.open(row)">再次提交</el-button>
          <el-button v-if="row.auditState == '审核不通过' && $hasAccess('005503')" type="text" @click="$refs.AddPeople.open(row)">再次提交</el-button>
          <el-button v-if="row.auditState == '待审核' && $hasAccess('005504')" type="text" @click="doWithdraw(row)">撤回</el-button>
          <el-button type="text" v-if="$hasAccess('005502')" class="el-button-error" @click="delOne(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail"></Detail>
    <AddPeople @submit="refreshTable" ref="AddPeople"></AddPeople>
  </div>
</template>

<script>
import Detail from './detail.vue'
import { searchWhitelist } from '@/configs/searches.js'
import { labelWhitelist } from '@/configs/tables.js'
import AddPeople from './addPeople.vue'
export default {
  components: { Detail, AddPeople },
  data() {
    return {
      searchList: searchWhitelist,
      labels: labelWhitelist
    }
  },
  methods: {
    // 删除
    async delOne(item) {
      await this.$confirm(`确认删除当前数据吗？`, '删除', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.DeleteSystemWhiteInfo({ id: item.id })
      this.$message.success('数据已删除')
      this.refreshTable()
    },
    // 提交
    async doSubmit(item) {
      const res = await this.$api.SubmitSystemWhiteInfo({ id: item.id })

      this.$message.success('提交成功')
      this.refreshTable()
    },
    // 撤回
    async doWithdraw(item) {
      await this.$confirm('确认撤回当前审核信息？', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.RevocationSystemWhiteInfo({ id: item.id })
      this.$message.success('撤回成功')
      this.refreshTable()
    },
    refreshTable() {
      this.$refs.vTable.refreshTable()
    },
  }
}
</script>
