<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '结算明细详情查看'
    }"
  >
    <DetailCard :title="'【订单编号：' + detail.customerOrderNo + '】'">
      <DescView :descLabels="descLabels" :descDatas="detail">
        <template #auditState>
          <span v-html="formatStatusHasColor(kvWorkPlanAuditState, detail.auditState)"></span>
        </template>
        <template #payState>
          <span v-html="formatStatusHasColorHasIcon(kvWorkPlanPayState, detail.payState)"></span>
        </template>
        <template #receiptInfo>
          <el-button v-if="detail.payState == 3 && detail.receiptInfo" type="text" @click="downReceipt()">下载回单</el-button>
          <span v-else>--</span>
        </template>
        <template #userName>{{ detail.userName || '无' }}<WpView v-if="detail.userName" :id="id" :type="4" /></template>
        <template #idCard>{{ detail.idCard || '无' }}<WpView v-if="detail.idCard" :id="id" :type="4" /></template>
        <template #mobile>{{ detail.mobile || '无' }}<WpView v-if="detail.mobile" :id="id" :type="4" /></template>
      </DescView>
    </DetailCard>
  </DetailView>
</template>

<script>
import { formatStatusHasColor, formatStatusHasColorHasIcon, kvWorkPlanAuditState, kvWorkPlanPayState, kvWorkPeopleIsSign } from '@/configs/formatters'
import { downloadUrl } from '@/utils'
import WpView from '@/components/wpView'
const descLabelsHead = [
  { label: '所属任务', prop: 'taskName' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '审核状态', cmp: 'slot', slotName: 'auditState' },
  { label: '打款状态', cmp: 'slot', slotName: 'payState' },
  { label: '电子回单', cmp: 'slot', slotName: 'receiptInfo' },
  { label: '失败原因', prop: 'failCause' },
  { label: '姓名', cmp: 'slot', slotName: 'userName' },
  { label: '证件号', cmp: 'slot', slotName: 'idCard' },
  { label: '手机号', cmp: 'slot', slotName: 'mobile' },
  { label: '签约状态', prop: 'isSign', cmp: 'state', stateOptions: kvWorkPeopleIsSign },
  { label: '下发类型', prop: 'grantType' },
  { label: '账号名', prop: 'accountName' },
  { label: '账号', prop: 'accountNumber' }
]
const descLabelsFoot = [
  { label: '服务费', cmp: 'money', prop: 'serviceFee' },
  { label: '差额服务费', cmp: 'money', prop: 'serviceDiffFee' },
  { label: '订单金额', cmp: 'money', prop: 'orderAmount' },
  { label: '商户流水号', prop: 'shopNo' },
  { label: '创建时间', prop: 'createTime' },
  { label: '确认发放时间', prop: 'grantConfirmTime' },
  { label: '打款成功时间', prop: 'payFinishTime' },
  {
    label: '是否退汇',
    prop: 'isReturn',
    cmp: 'state',
    stateOptions: [
      { key: 0, label: '否' },
      { key: 1, label: '是' }
    ]
  }
]
export default {
  components: { WpView },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvWorkPlanAuditState,
      kvWorkPlanPayState
    }
  },
  computed: {
    businessType() {
      return this.$store.state.user.businessType
    },
    descLabels() {
      const array =
        this.businessType == 2
          ? [
              { label: '个税', cmp: 'money', prop: 'individualTax' },
              { label: '增值税', cmp: 'money', prop: 'addedTax' },
              { label: '税金及附加', cmp: 'money', prop: 'surchargesTax' },
              { label: '结算金额', cmp: 'money', prop: 'finalPayingAmount' }
            ]
          : [{ label: '税后金额', cmp: 'money', prop: 'afterTaxAmount' }]
      return [...descLabelsHead, ...array, ...descLabelsFoot]
    }
  },
  methods: {
    formatStatusHasColor,
    formatStatusHasColorHasIcon,
    receipt() {
      this.$message.error('无效的信息')
    },
    // 数据清空
    clear() {
      this.detail = {}
    },
    open(obj) {
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.detail = obj
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 下载回执
    async downReceipt() {
      if (this.detail.receiptInfo) return downloadUrl(this.detail.receiptInfo)
      // const res = await this.$api.GetReceiptInfo({ DetailId: this.id })
      // if (!res.receiptInfo) return this.$message.warning('回单暂未生成，请稍后重试')
      // this.detail.receiptInfo = res.receiptInfo
      // downloadUrl(res.receiptInfo)
    }
  }
}
</script>
