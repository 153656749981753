<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetTaskManagePageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('004100')" id="guideSteps2">
        <el-button type="primary" @click="add()">
          <i class="main-btn-icon el-icon-self-cengjia"></i>
          <span>发布任务</span>
        </el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="160" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('004101')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <template v-if="$hasAccess('004102')">
            <el-button v-if="row.auditStatus == 1 && row.taskStateEnum == 1" type="text" @click="onSwitch(row)">关闭</el-button>
            <!-- <el-button v-if="row.taskState == '终止'" type="text" @click="$refs.Edit.open(row)">编辑</el-button> -->
            <el-button v-if="row.auditStatus == 1 && row.taskStateEnum == 2" type="text" @click="onSwitch(row)">开启</el-button>
          </template>
          <template v-if="$hasAccess('004103') && row.taskType == 1 && row.auditStatus == 1 && row.taskStateEnum == 1">
            <el-button v-if="row.retrievable == 1" type="text" @click="onChange(row, 0)">隐藏</el-button>
            <el-button v-if="row.retrievable == 0" type="text" @click="onChange(row, 1)">展示</el-button>
          </template>
          <el-button v-if="$hasAccess('004104') && row.auditStatus == 2 && row.taskStateEnum == 0" type="text" @click="add(row)">编辑</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="asyncRefreshTable()" />
    <CreatTask ref="CreatTask" @submit="asyncRefreshTable()" />
    <Detail ref="Detail" />
  </div>
</template>

<script>
import { tableTaskManage } from '@/configs/tables'
import { searchTaskManage } from '@/configs/searches'
import Edit from './edit'
import Detail from './detail'
import CreatTask from './creatTask'
export default {
  components: { Edit, Detail, CreatTask },
  data() {
    return {
      tableLabels: tableTaskManage,
      searchList: searchTaskManage
    }
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    // 切换启动/终止
    async onSwitch(item) {
      const state = item.taskStateEnum == 1 ? '关闭' : '开启'
      const taskState = item.taskStateEnum == 1 ? 2 : 1
      await this.$confirm(`确认要${state}当前任务吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.UpdateTaskManageState({ id: item.id, taskState })
      this.$message.success(`任务已${state}`)
      this.asyncRefreshTable()
      // item.taskState = state == '启动' ? '正常' : state
    },
    async onChange(e, num) {
      const state = num == 0 ? '隐藏' : '展示'
      await this.$confirm(`确认要${state}当前任务吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.TaskManageHideOrShow({ id: e.id, Retrievable: num })
      if (res) {
        this.$message.success(`任务已${state}`)
        e.retrievable = num
        this.asyncRefreshTable()
      }
    },

    // 发布任务，先判断授权
    add(row) {
      if (
        this.$store.state.user.auth.signType == 0 ||
        // (!this.$store.state.user.auth.signType == 1 && !this.$store.state.user.auth.isAuth) ||
        this.$store.state.user.supplierSignList.find((item) => {
          return item.isSign != 2
        })
      ) {
        this.$root.$children[0].$refs.AuthDialog.open()
      } else {
        // this.$refs.Edit.open()
        this.$refs.CreatTask.open(row)
      }
    }
  }
}
</script>
