<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <!-- <el-card shadow="never" style="margin-bottom: 18px; flex-shrink: 0">
      <el-descriptions :column="4" :colon="false" size="medium" style="margin-bottom: -10px">
        <el-descriptions-item label="已开票金额"><b v-format="'#,##0.00'">0</b> 元</el-descriptions-item>
        <el-descriptions-item label="开票中金额"><b v-format="'#,##0.00'">0</b> 元</el-descriptions-item>
        <el-descriptions-item label="可开票金额"><b v-format="'#,##0.00'">0</b> 元</el-descriptions-item>
      </el-descriptions>
    </el-card> -->
    <TableView   :selConfig="{ selectable }" :selection="true" @selection-change="selChange" ref="vTable" :labels="tableLabels" apiName="GetBillInfo" :searchList="searchList">
      <!-- 按钮栏 -->
        <el-form-item slot="btnline-item-left" v-if="$hasAccess('004800') || $hasAccess('004801')">
          <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
          <el-button plain v-if="$hasAccess('004800')" @click="apply">下载发票</el-button>
          <el-button plain v-if="$hasAccess('004801')" @click="applyAll">下载全部发票</el-button>
        </el-form-item>
      <el-form-item slot="btnline-item" v-if="$hasAccess('004901')">
        <el-button type="primary" @click="download" :loading="btnloadDownload">
          <i class="main-btn-icon el-icon-self-download-2-line"></i>
          <span>导出</span>
        </el-button>
      </el-form-item>
      <el-table-column slot="planCount" label="合并笔数" min-width="100">
        <template v-slot="{ row }">
          {{ row.invoicingSource == 2 ? '' : row.planCount }}
        </template>
      </el-table-column>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="116" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('004900')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button v-if="row.verifyState == '待审核' && row.billState == '待开票'" type="text" @click="doWithdraw(row)">撤回</el-button>
          <!-- <el-button v-if="row.verifyState == '待审核'" type="text" @click="cancelOne(row)">取消</el-button> -->
          <!-- <el-button v-if="row.billingState == '已开票'" type="text">下载发票附件</el-button> -->
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail" />
  </div>
</template>

<script>
import { tableBillInfo } from '@/configs/tables'
import { searchBillInfo } from '@/configs/searches'
import { downloadExcel,downloadUrl } from '@/utils'
import Detail from './detail'
export default {
  components: { Detail },
  data() {
    return {
      btnloadDownload: false,
      multipleSelection:[],
      tableLabels: tableBillInfo,
      searchList: searchBillInfo
    }
  },
  methods: {
    // 取消
    async cancelOne(item) {
      await this.$confirm(`确认取消当前开票申请吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.CancelBillInfo({ billId: item.billId })
      this.$message.success('开票申请已取消')
      this.refreshTable()
    },
    async download() {
      const params = this.$refs.vTable.getParams()
      this.btnloadDownload = true
      const res = await this.$api.ExportBillInfoData(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '开票记录')
    },
    async doWithdraw(item) {
      await this.$confirm('撤回后开票记录需要重新申请，确定撤回吗？', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.RevocationInvoice({ id: item.id })
      this.$message.success('撤回成功')
      this.refreshTable()
    },
    refreshTable() {
      this.$refs.vTable.refreshTable()
    },
    selChange(val) {
      this.multipleSelection = val
    },
   async apply() {
      if (!this.multipleSelection.length) return this.$message.warning('请选择要下载发票的项')
      const invoiceIds = this.multipleSelection.map((item) => {
        return item.id
      })
      const res = await this.$api.DownLoadInvoiceById({ invoiceIds: invoiceIds })
      downloadUrl(res.list[0])
      // this.$message.success('撤回成功')
    },
   async applyAll() {
      const params = Object.assign({}, this.$refs.vTable.searchForm)
      const res = await this.$api.DownLoadInvoicePage(params)
      downloadUrl(res.list[0])
    },
    selectable(row, index) {
      if (row.billState != '已开票') return false
      return true
    },
  }
}
</script>
