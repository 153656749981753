<template>
  <el-link
    v-if="path"
    type="primary"
    @click="!apiName ? showView() : false"
    :style="{ 'max-height': '18px', 'font-size': fontSize }"
  >
    {{ name || "查看文件" }}
    <i v-if="isIcon" class="el-icon-view el-icon--right"></i>
    <el-image-viewer
      v-if="showViewer"
      :zIndex="9999"
      :url-list="viewerlList"
      :on-close="
        () => {
          showViewer = false;
          clear();
        }
      "
    />
  </el-link>
  <el-link v-else :style="{ 'font-size': fontSize }">无</el-link>
</template>

<script>
import { isArray } from "@/utils";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    ElImageViewer,
  },
  props: {
    path: String | Array,
    name: String,
    isIcon: true,
    fontSize: {
      type: String,
      default: "14px",
    },
    apiName: String,
  },
  data() {
    return {
      showViewer: false,
      viewerlList: [],
    };
  },
  methods: {
    clear() {
      this.viewerlList = [];
    },

    showView(params) {
      if (this.apiName) {
        this.$api[this.apiName](params).then((res) => {
          this.viewerlList = isArray(res) ? res : [res];
        });
      } else {
        this.viewerlList = isArray(this.path) ? this.path : [this.path];
      }
      this.showViewer = true;
    },
  },
};
</script>
