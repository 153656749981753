<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '完税凭证详情查看'
    }"
  >
    <DetailCard>
      <DescView :descLabels="descLabels" :descDatas="detail">
        <template #taxVoucherAttaches>
          <span v-if="!detail.taxVoucherAttaches || !detail.taxVoucherAttaches.length">无</span>
          <template v-else v-for="(item, index) in detail.taxVoucherAttaches">
            <el-button
              :key="item.voucherId"
              type="text"
              @click="$hasAccess('005001') && downloadUrlName(item.filePath, item.fileName + fileName(item.filePath))"
              >{{ 1 + index + '、' + item.fileName + fileName(item.filePath) }}</el-button
            >
          </template>
        </template>
      </DescView>
    </DetailCard>
  </DetailView>
</template>

<script>
import { downloadUrlName } from '@/utils'
let vm = null
const descLabels = [
  { label: '完税编号', prop: 'taxVoucherNo' },
  { label: '完税月份', prop: 'taxVoucherDateMonth' },
  {
    label: '商户名称',
    prop: 'merchantName',
    formatter: () => {
      return vm.merchant.merchantName
    }
  },
  {
    label: '商户编号',
    prop: 'memberCode',
    formatter: () => {
      return vm.merchant.memberCode
    }
  },
  { label: '税源地名称', prop: 'supplierName', columnConfig: { span: 2 } },
  { label: '完税凭证', cmp: 'slot', slotName: 'taxVoucherAttaches', columnConfig: { span: 2 } }
]
export default {
  data() {
    return {
      id: null,
      show: false,
      descLabels,
      detail: {},
      merchant: {}
    }
  },
  created() {
    vm = this
  },
  methods: {
    downloadUrlName,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        this.id = obj.id
        this.detail = obj
        this.getMerchant()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    fileName(url) {
      const fa = url.split('.')
      return '.' + fa[fa.length - 1]
    },
    async getMerchant() {
      const res = await this.$api.GetMerchantInfo()
      this.merchant = res
    }
  }
}
</script>
