<template>
  <el-dialog :title="!id ? '新增' : '编辑'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 360px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '150px' }" :slotIdxList="[6]" style="height: 100%">
        <template #form-items="{ idx, form }">
          <el-col v-if="idx == 6">
            <el-form-item>
              <el-checkbox v-model="form.setDefault">设为默认发票抬头</el-checkbox>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formRiseInfo } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      fromLabels: formRiseInfo,
      detail: {},
      comeFrom: ''
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj, from) {
      this.clear()
      if (obj && obj.riseId) {
        // 编辑
        const { riseId, riseName, taxpayerNumber, unitAddress, phone, openBank, bankAccount, isDefault } = obj
        this.id = obj.riseId
        this.detail = { riseId, riseName, taxpayerNumber, unitAddress, phone, openBank, bankAccount, isDefault, setDefault: isDefault == '是' ? true : false }
      } else {
        // 添加
        this.detail = {
          isDefault: '否',
          setDefault: false
        }
        if (from) {
          this.comeFrom = from
        }
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
      })
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      
      params.isDefault = params.setDefault ? '是' : '否'
      this.btnloadSubmit = true
      if (this.id) {
        // 编辑
        const res = await this.$api.EditRiseInfo(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddRiseInfo(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('发布成功')
      }
      this.$emit('submit', {refName: 'riseRef', from: this.comeFrom})
      this.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-wrap {
  ::v-deep .el-form {
    height: 100%;
    .el-row {
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>