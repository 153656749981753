<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="tableTemplate" :searchList="searchList" @onSwitch="onSwitch">
      <!-- 附加table -->
      <!-- <el-table slot="ext-table"></el-table> -->
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.DialogTemplate.open()">新增</el-button>
      </el-form-item>
      <el-form-item slot="btnline-item">
        <el-button plain icon="el-icon-download">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" @click="$refs.DialogTemplate.open(row,$index)">编辑</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <DialogTemplate ref="DialogTemplate" @submit="updateDialogTemplate"></DialogTemplate>
  </div>
</template>

<script>
import { tableTemplate } from '@/configs/tables'
import { searchTemplate } from '@/configs/searches'
import DialogTemplate from './dialog'
export default {
  components: { DialogTemplate },
  data() {
    return {
      tableLabels: tableTemplate,
      searchList: searchTemplate
    }
  },
  methods: {
    // 表格内的切换事件
    onSwitch(val, key, row) {
      console.log(val, key, row)
    },
    updateDialogTemplate() {}
  }
}
</script>
