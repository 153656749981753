<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '创客详情查看'
    }"
  >
    <DetailCard>
      <DescView :descLabels="descLabels" :descDatas="detail">
        <template #userName>{{ detail.userName || '无' }}<WpView v-if="detail.userName" :id="workPeopleId" /></template>
        <template #mobile>{{ detail.mobile || '无' }}<WpView v-if="detail.mobile" :id="workPeopleId" /></template>
        <template #idCard>{{ detail.idCard || '无' }}<WpView v-if="detail.idCard" :id="workPeopleId" /></template>
        <template #idFrontPhoto>
          <ImageViewer v-if="detail.idFrontPhoto" :path="detail.idFrontPhoto" />
          <span v-else-if="detail.haveIdFrontPhoto">已采集</span>
          <span v-else>无</span>
        </template>
        <template #idBackPhoto>
          <ImageViewer v-if="detail.idBackPhoto" :path="detail.idBackPhoto" />
          <span v-else-if="detail.haveIdBackPhoto">已采集</span>
          <span v-else>无</span>
        </template>
        <template #livingPhoto>
          <ImageViewer v-if="detail.livingPhoto" :path="detail.livingPhoto" />
          <span v-else-if="detail.haveLivingPhoto">已采集</span>
          <span v-else>无</span>
        </template>
      </DescView>
    </DetailCard>
  </DetailView>
</template>

<script>
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
const descLabels = [
  { label: '人员编号', prop: 'peopleNo' },
  { label: '创客姓名', cmp: 'slot', slotName: 'userName' },
  { label: '手机号', cmp: 'slot', slotName: 'mobile' },
  { label: '身份证号', cmp: 'slot', slotName: 'idCard' },
  { label: '身份证照片正面', cmp: 'slot', slotName: 'idFrontPhoto' },
  { label: '身份证照片反面', cmp: 'slot', slotName: 'idBackPhoto' },
  { label: '采集图像', cmp: 'slot', slotName: 'livingPhoto' },
  { label: '采集时间', prop: 'gatherTime' },
  { label: '电子邮箱', prop: 'email' },
  { label: '地址', prop: 'address' },
  { label: '银行卡号', prop: 'bankNumber' },
  { label: '支付宝', prop: 'alipayNumber' },
  { label: '备注', prop: 'remark' }
]
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      id: null,
      workPeopleId: null,
      show: false,
      descLabels,
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
    },
    open(obj) {
      this.clear()
      if (obj && obj.id && obj.workPeopleId) {
        this.id = obj.id
        this.workPeopleId = obj.workPeopleId
        this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      let res = await this.$api.GetWorkPeopleDetail({
        workPeopleId: this.id
      })
      this.detail = res
    }
  }
}
</script>
