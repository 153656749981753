<template>
  <div class="statisticsPanel">
    <div v-for="(item, index) in list" class="item" :key="index">
      <div class="title">{{ item.label }}</div>
      <div class="money">
        <template v-if="!item.tooltip">
          <span v-format="'#,##0.00'">{{ item.value }}</span>
        </template>
        <el-tooltip v-else placement="right">
          <div slot="content">
            <template v-for="(it, idx) in item.tooltip">
              <br v-if="idx != 0" />{{ it.label }}：<span :key="idx" v-format="'#,##0.00'">{{ it.value }}</span>
            </template>
          </div>
          <span v-format="'#,##0.00'">{{ item.value }}</span>
        </el-tooltip>
        元
      </div>
      <div v-if="item.number || item.number == 0" class="number"><span>笔数：</span>{{ item.number }}笔</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.statisticsPanel {
  flex-shrink: 0;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f2f3f5;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  .item {
    width: 100%;
    border-right: 1px solid #f2f3f5;
    padding: 0 20px;
    &:last-child {
      border: none;
    }
  }
  .title {
    color: #4e5969;
    font-size: 14px;
    line-height: 20px;
  }
  .money {
    color: #4e5969;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    span {
      color: #1d2129;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      margin-right: 4px;
    }
  }
  .number {
    margin-top: 10px;
    color: #86909c;
    font-size: 14px;
    line-height: 20px;
    span {
      margin-right: 5px;
    }
  }
}
</style>
