<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
    <div>
      <el-form ref="vForm" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="选择任务" prop="taskNo">
          <el-select
            v-model="form.taskId"
            placeholder="按任务名称/任务编号查找"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteTask"
            :loading="tLoading"
            @change="taskChange"
            style="width: 400px"
          >
            <el-option v-for="item in taskList" :key="item.id" :value="item.id" :label="`${item.taskName}【${item.taskNumber}】`"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 内容区域 -->
      <div class="top-box">
        <div
          class="top-box-item"
          :class="{ 'top-box-children': i.children && i.children.length }"
          v-for="(i, index) in topBoxData"
          :key="index"
          :style="{ 'background-color': i.bgColor }"
        >
          <div class="item">
            <p class="label" :style="{ color: i.labelColor }">{{ i.label }}</p>
            <p class="value">
              <template v-if="i.isMoney">
                <span class="remark">￥</span>
                <span v-format="'#,##0.00'">{{ i.value }}</span>
              </template>
              <span v-else>{{ i.value }}</span>
            </p>
          </div>
          <div v-if="i.children && i.children.length" class="item children">
            <p v-for="(item, itemIndex) in i.children" :key="itemIndex">
              <span class="label" :style="{ color: item.labelColor }">{{ item.label }}：</span>
              <span class="value" :style="{ color: item.labelColor }">{{ item.value }}</span>
            </p>
          </div>
        </div>
      </div>
      <TableView
        v-if="show"
        ref="vTable"
        :labels="tableLabels"
        apiName="CheckUploadWorkPlanState"
        :searchList="searchList"
        resChildName="checkResultList"
        @geted="onTableGeted"
        :searchExt="{ Mark: mark }"
        title="结算单列表"
      >
        <div v-if="totalData.checkNotPassPeopleNum" slot="ext-table">
          <div class="tip-box">
            <span><i class="icon icon-warning el-icon-self-error-warning-fill"></i>检测到有</span>
            <span class="num">&ensp;{{ totalData.checkNotPassPeopleNum || 0 }}条&ensp;</span>
            <span>数据未通过校验</span>
            <span class="download pointer" @click="exportWorkPeople"
              ><i class="icon icon-download el-icon-self-download-2-line"></i>下载未通过数据</span
            >
          </div>
        </div>
      </TableView>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { tableWorkPlanCheck } from '@/configs/tables'
import { searchWorkPlanCheck } from '@/configs/searches'
import { downloadExcel } from '@/utils'

export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      title: '导入结算单',
      tableLabels: tableWorkPlanCheck,
      searchList: searchWorkPlanCheck,
      mark: '',
      totalData: {},
      repeatIdCardList: [],
      form: {},
      rules: {
        taskNo: [{ required: true, message: '请选择任务', trigger: 'change' }]
      },
      tLoading: false,
      taskList: []
    }
  },
  computed: {
    topBoxData() {
      return [
        {
          label: '校验通过金额',
          value: this.totalData.checkPassAmount || 0.0,
          labelColor: '#00B42A',
          bgColor: '#E8FFEA',
          isMoney: true
        },
        {
          label: '校验不通过金额',
          value: this.totalData.checkNotPassAmount || 0.0,
          labelColor: '#EB5757',
          bgColor: '#FFECE8',
          isMoney: true
        },
        {
          label: '校验人数',
          value: this.totalData.totalPeopleNum || 0,
          labelColor: '#4E5969',
          bgColor: '#E8F3FF',
          children: [
            {
              label: '通过人数',
              value: this.totalData.checkPassPeopleNum || 0,
              labelColor: '#00B42A'
            },
            {
              label: '未通过人数',
              value: this.totalData.checkNotPassPeopleNum || 0,
              labelColor: '#F53F3F'
            }
          ]
        }
      ]
    }
  },
  methods: {
    clear() {
      this.mark = ''
      this.totalData = {}
      this.repeatIdCardList = []
      this.form = {}
      this.tLopading = false
      this.taskList = []
    },
    close() {
      this.show = false
    },
    open(mark) {
      this.clear()
      if (mark) {
        this.mark = mark
        this.show = true
        this.remoteTask()
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 导出
    async exportWorkPeople() {
      const res = await this.$api.ExportCheckUploadWorkPlan({ mark: this.mark, checkResult: 2 })

      downloadExcel(res, '未通过结算单列表')
    },
    onTableGeted(response, params) {
      const { totalData, repeatIdCardList } = response
      if (response.state != 2) {
        setTimeout(() => {
          this.$refs.vTable.refreshTable()
        }, 1000)
      } else {
        this.totalData = totalData || {}
        this.repeatIdCardList = repeatIdCardList || []
      }
    },
    // 内容查询
    async remoteTask(query) {
      this.tLoading = true
      const params = {
        TaskState: 1,
        SearchTxt: query || ''
      }
      const res = await this.$api.GetTaskManageApprovePageList(params)
      this.taskList = res.list
      this.tLoading = false
    },
    taskChange(val) {
      const one = this.taskList.find((item) => {
        return item.id == val
      })
      if (one) {
        this.form.taskName = one.taskName
        this.form.taskNo = one.taskNumber
      } else {
        this.$message.warning('无效的任务')
        this.form = {}
      }
    },
    passingSituation() {
      // 0：如果没有数据，则不操作
      if (!this.totalData || !this.totalData.totalPeopleNum) return 0
      // 3：全部不通过，toast提示：无法导入，没有校验通过的信息
      if (this.totalData.checkNotPassPeopleNum == this.totalData.totalPeopleNum) return 3
      // 1：有通过和不通过数据，显示二次确认弹窗
      if (this.totalData.checkPassPeopleNum && this.totalData.checkNotPassPeopleNum) return 1
      // 4：有重复数据，显示二次确认弹窗
      if (this.totalData.checkPassPeopleNum && this.repeatIdCardList.length) return 4
      // 2：全部通过且无重复，正常导入（逻辑与现在导入不变）；
      if (this.totalData.checkPassPeopleNum == this.totalData.totalPeopleNum && !this.totalData.checkNotPassPeopleNum) return 2
      // 0：其他情况
      return 0
    },
    // 提交
    async submit() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          const passingSituation = this.passingSituation()
          if (passingSituation == 1 || passingSituation == 4) {
            this.$emit('confirm', {
              type: 'workPlan',
              mark: this.mark,
              passPeopleNum: this.totalData.checkPassPeopleNum,
              notPassPeopleNum: this.totalData.checkNotPassPeopleNum,
              repeatIdCardList: this.repeatIdCardList,
              form: this.form
            })
          } else if (passingSituation == 2) {
            this.btnloadSubmit = true
            const res = await this.$api.ConfirmSubmitWorkPlan(Object.assign({ mark: this.mark }, this.form)).finally(() => {
              this.btnloadSubmit = false
            })

            this.$emit('submit')
            this.$emit('success', { type: 'workPlan', passPeopleNum: this.totalData.checkPassPeopleNum })
            this.close()
          } else if (passingSituation == 3) {
            this.$message.warning('无法导入，没有校验通过的信息')
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.top-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  &-item {
    width: 100%;
    height: 88px;
    padding: 16px 24px;
    margin-right: 16px;
    border-radius: 8px;
    &:last-child {
      margin: 0;
    }
    p {
      margin: 0;
    }
    .label {
      margin-bottom: 4px;
      line-height: 20px;
      font-size: 14px;
    }
    .value {
      line-height: 32px;
      font-size: 24px;
      font-weight: 600;
      color: #1d2129;
      font-family: 'Bebas';
    }
    .remark {
      line-height: 24px;
      color: #4e5969;
      font-size: 18px;
    }
  }
  &-children {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .item {
      flex: 1;
    }
    .children {
      p {
        span {
          display: inline-block;
          margin: 0;
        }
        .value {
          line-height: 20px;
          font-size: 14px;
          font-weight: 400;
        }
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }
}
.tip-box {
  padding: 10px 12px;
  margin: -4px 0 12px;
  background-color: #fef4bf;
  .pointer {
    cursor: pointer;
  }
  .icon {
    font-size: 16px;
    &-warning {
      margin-right: 8px;
      color: #f7ba1e;
    }
    &-download {
      margin: 0 4px 0 10px;
    }
  }
  .num {
    color: #cc9213;
  }
  .download {
    color: #165dff;
    font-weight: 600;
  }
}
</style>
