import CryptoJS from 'crypto-js'
const title = process.env.VUE_APP_TITLE
// 加密解密
const key = CryptoJS.enc.Utf8.parse('8JackHaiYangKeLe8JackHaiYangKeLe')
const iv = CryptoJS.enc.Utf8.parse('1234567890123456')
export function Encrypt(word) {
  let srcs = CryptoJS.enc.Utf8.parse(word)
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}
export function Decrypt(ori) {
  const res = CryptoJS.AES.decrypt(ori, key, { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 })
  return res.toString(CryptoJS.enc.Utf8)
}

/**
 * 存储token
 */
export const setToken = (token) => {
  sessionStorage.setItem(`${title}-token`, token)
}
/**
 * 获取token
 */
export const getToken = () => {
  const token = sessionStorage.getItem(`${title}-token`)
  if (token) return Decrypt(token)
  return token
}

/**
 * 存储firstLogin
 */
export const setFirstLogin = (firstLogin, userId) => {
  if (firstLogin) localStorage.setItem(`${title}-firstLogin-${userId}`, firstLogin)
  else localStorage.setItem(`${title}-firstLogin-${userId}`, '')
}
/**
 * 获取firstLogin
 */
export const getFirstLogin = (userId) => {
  return localStorage.getItem(`${title}-firstLogin-${userId}`)
}

/**
 * 存储isMerchantAdmin
 */
export const setIsMerchantAdmin = (isMerchantAdmin) => {
  sessionStorage.setItem(`${title}-isMerchantAdmin`, isMerchantAdmin)
}
/**
 * 获取isMerchantAdmin
 */
export const getIsMerchantAdmin = () => {
  return sessionStorage.getItem(`${title}-isMerchantAdmin`)
}

/**
 * 存储userId
 */
export const setUserId = (userId) => {
  sessionStorage.setItem(`${title}-userId`, userId)
}
/**
 * 获取userId
 */
export const getUserId = () => {
  return sessionStorage.getItem(`${title}-userId`)
}

/**
 * 存储username
 */
export const setUsername = (username) => {
  sessionStorage.setItem(`${title}-username`, username)
}
/**
 * 获取username
 */
export const getUsername = () => {
  return sessionStorage.getItem(`${title}-username`)
}
/**
 * 存储mainName
 */
export const setMainName = (mainName) => {
  sessionStorage.setItem(`${title}-mainName`, mainName)
}
/**
 * 获取mainName
 */
export const getMainName = () => {
  return sessionStorage.getItem(`${title}-mainName`)
}

/**
 * 存储avatar
 */
export const setAvatar = (avatar) => {
  sessionStorage.setItem(`${title}-avatar`, avatar)
}
/**
 * 获取avatar
 */
export const getAvatar = () => {
  return sessionStorage.getItem(`${title}-avatar`)
}

/**
 * 存储businessType
 */
export const setBusinessType = (businessType) => {
  sessionStorage.setItem(`${title}-businessType`, businessType)
}
/**
 * 获取businessType
 */
export const getBusinessType = () => {
  return sessionStorage.getItem(`${title}-businessType`)
}

/**
 * 存储rule
 */
export const setRule = (rule) => {
  sessionStorage.setItem(`${title}-rule`, JSON.stringify(rule))
}
/**
 * 获取rule
 */
export const getRule = () => {
  const rule = sessionStorage.getItem(`${title}-rule`)
  return JSON.parse(rule)
}

/**
 * 存储route
 */
export const setRoute = (route) => {
  sessionStorage.setItem(`${title}-route`, JSON.stringify(route))
}
/**
 * 获取route
 */
export const getRoute = () => {
  const route = sessionStorage.getItem(`${title}-route`)
  return JSON.parse(route)
}

/**
 * 存储oemid
 */
export const setOemId = (oemId) => {
  sessionStorage.setItem(`${title}-oemId`, oemId)
}
/**
 * 获取oem
 */
export const getOemId = () => {
  return sessionStorage.getItem(`${title}-oemId`)
}

/**
 * 存储密码pwd.obj
 */
export const setPwd = (pwd) => {
  if (pwd) localStorage.setItem(`${title}-pwd`, Encrypt(pwd))
  else localStorage.setItem(`${title}-pwd`, '')
}
/**
 * 获取密码pwd.obj
 */
export const getPwd = () => {
  const pwd = localStorage.getItem(`${title}-pwd`)
  if (pwd) return Decrypt(pwd)
  return pwd
}
/**
 * 存储当前弹出提醒
 */
export const setshowOpenInfo = (showOpenInfo) => {
 sessionStorage.setItem(`${title}-showOpenInfo`, showOpenInfo)
}
/**
 * 获取当前弹出提醒
 */
export const getshowOpenInfo = () => {
  return sessionStorage.getItem(`${title}-showOpenInfo`)
}
