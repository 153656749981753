<template>
  <div class="detail-card">
    <div v-if="title" class="detail-card-title">
      <div class="l" :class="{before: hasBefore}">
        <div class="t">{{ title }}</div>
        <div v-if="desc" class="d">{{ desc }}</div>
        <slot name="ext-title"></slot>
      </div>
      <div class="r">
        <slot name="ext"></slot>
        <div v-if="collapse" class="e" @click="changeExpend">
          {{ expend ? '收起' : '展开' }}
          <i class="el-icon-self-arrow-down-double" :class="{ expend }"></i>
        </div>
      </div>
    </div>
    <CollapseTransition>
      <div v-show="expend" class="detail-card-collapse">
        <div class="detail-card-container">
          <slot></slot>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import CollapseTransition from './collapse-transition'
export default {
  components: { CollapseTransition },
  props: {
    title: String,
    desc: String,
    collapse: {
      type: Boolean,
      default: false
    },
    hasBefore: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      expend: true
    }
  },
  methods: {
    changeExpend() {
      this.expend = !this.expend
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-card {
  margin-bottom: 30px;
  &-title {
    padding-top: 6px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f2f3f5;
    display: flex;
    justify-content: space-between;
    .l {
      display: flex;
      align-items: center;
      &.before {
        &::before {
          content: '';
          display: block;
          width: 3px;
          height: 16px;
          border-radius: 0 2px 2px 0;
          opacity: 1;
          background: #30d68cff;
          margin-right: 8px;
        }
      }
    }
    .r {
      display: flex;
      justify-content: flex-end;
    }
    .t {
      color: #000;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin-right: 8px;
    }
    .d {
      color: #4e5969ff;
      font-size: 14px;
      line-height: 20px;
      margin-right: 8px;
    }
    .e {
      color: #30d68c;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      i {
        margin-left: 8px;
        &.expend {
          transform: rotateZ(180deg);
        }
      }
    }
  }
  &-collapse {
    overflow: hidden;
  }
  &-container {
    padding: 16px 0;
    ::v-deep .descview {
      margin: 0 40px;
    }
  }
}
</style>
