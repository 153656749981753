<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView :labels="labels" :searchList="searchList" apiName="GetBlacklist"></TableView>
  </div>
</template>

<script>
import { searchBlacklist } from '@/configs/searches.js'
import { labelBlacklist } from '@/configs/tables.js'
export default {
  data() {
    return {
      searchList: searchBlacklist,
      labels: labelBlacklist
    }
  }
}
</script>
