<template>
  <div class="login">
    <div class="head-wrap">
      <div class="head-logo">
        <div class="logo-wrap">
          <img v-if="$store.state.app.oemId" class="img" :src="require('@/assets/oem/' + $store.state.app.oemId + '/logo-long.png')" />
          <img v-else class="img" src="@/assets/logo-long.png" />
          <!-- <i class="icon iconfont icon-icon_logo"></i>
          <i class="icont iconfont icon-icon_lingshiyun"></i>
          <i class="icont iconfont icon-a-icon_dian_huabandian"></i>
          <i class="icont iconfont icon-icon_qiye"></i> -->
        </div>
        <el-divider direction="vertical"></el-divider>
        <h1 class="title">专注数字化交易合规</h1>
      </div>
    </div>
    <div class="container">
      <div class="box">
        <div class="img-box"></div>
        <div class="login-form">
          <!-- <p class="desc">{{ $title }}</p> -->
          <div class="title">
            <el-image v-if="$store.state.app.oemId" class="logo" :src="require('@/assets/oem/' + $store.state.app.oemId + '/logo.png')"></el-image>
            <el-image v-else class="logo" :src="logoSrc"></el-image>
            <div class="t">登录</div>
          </div>
          <div class="content">
            <el-form :model="loginFormData" :rules="loginRules" ref="loginForm" size="medium">
              <el-form-item prop="username">
                <el-input class="input" clearable placeholder="请输入登录账号" v-model="loginFormData.username">
                  <i slot="prefix" class="el-input__icon el-icon-user icon-input"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  class="input"
                  show-password
                  placeholder="请输入密码"
                  :maxlength="32"
                  v-model="loginFormData.password"
                  @keyup.enter.native="handleLogin('loginForm')"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock icon-input"></i>
                </el-input>
              </el-form-item>
              <el-form-item style="margin-bottom: 14px">
                <el-checkbox v-model="spwd">记住我</el-checkbox>
              </el-form-item>
              <el-alert
                v-if="checked && !canLogo"
                title="系统更新中，暂时无法使用，给您带来不便，敬请理解"
                :closable="false"
                type="warning"
                show-icon
                :description="tips"
              >
              </el-alert>
              <el-form-item>
                <el-button :loading="loading" class="login-btn" type="primary" @click="handleLogin" :disabled="!canLogo"> 登录 </el-button>
              </el-form-item>
              <el-form-item v-if="!$store.state.app.oemId" style="text-align: center; margin-bottom: 40px">
                <el-checkbox v-model="agree">我已阅读并同意</el-checkbox>
                <el-link type="primary" style="display: inline; top: -1px" @click="$refs.DialogAgree.open(1)">《注册协议》</el-link>
                <el-link type="primary" style="display: inline; top: -1px" @click="$refs.DialogAgree.open(2)">《隐私政策》</el-link>
              </el-form-item>
              <div v-else style="height: 30px"></div>
              <template v-if="!$store.state.app.oemId">
                <el-divider content-position="center">第三方登录</el-divider>
                <el-form-item style="text-align: center">
                  <el-link :underline="false" style="display: inline" @click="handleToOpen"
                    ><el-image class="fulu" :src="fuluSrc" />福禄开放平台登录</el-link
                  >
                </el-form-item>
              </template>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright" v-if="!$store.state.app.oemId">
      <ul>
        <li>版权所有 © 灵时云（苏州）信息科技有限公司</li>
        <li><a target="_banck" href="https://beian.miit.gov.cn" style="color: #fff">苏ICP备2022002693号-1</a></li>
        <li>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003897"
            ><img src="../../assets/备案图标.png" />苏公网安备 32059002003897号</a
          >
        </li>
      </ul>
    </div>
    <!-- 弹窗 -->
    <DialogAgree ref="DialogAgree" />
  </div>
</template>

<script>
import logoSrc from '@/assets/logo.png'
import { setPwd, getPwd, getshowOpenInfo } from '@/utils/auth'
import DialogAgree from './agree'
export default {
  name: 'Login',
  components: { DialogAgree },
  data() {
    return {
      loading: false,
      logoSrc,
      fuluSrc: require('@/assets/icon-fulu.png'),
      loginFormData: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, message: '请输入登录账号！', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码！', trigger: 'blur' }]
      },
      spwd: false,
      agree: false,
      tips: '',
      checked: false,
      canLogo: false
    }
  },
  mounted() {
    this.checkLogin()
    const pwd = getPwd()
    if (pwd) {
      const { username, password } = JSON.parse(pwd)
      this.loginFormData = { username, password }
      this.spwd = true
      this.agree = true
    } else {
      this.loginFormData = {
        username: '',
        password: ''
      }
      this.spwd = false
      this.agree = false
    }
    this.$nextTick(() => {
      this.$refs.loginForm.clearValidate()
    })
  },
  methods: {
    async checkLogin() {
      const res = await this.$api.SystemLoginCheck()
      this.canLogo = !res
      this.checked = true
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (!this.$store.state.app.oemId && !this.agree) return this.$message.error('请阅读协议，并勾选同意后登录')
          this.loading = true
          // 登录操作
          const params = {
            username: this.loginFormData.username,
            password: this.loginFormData.password
          }
          this.$store
            .dispatch('user/login', params)
            .then((res) => {
              this.loading = false
              if (this.spwd) setPwd(JSON.stringify(params))
              else setPwd('')
              this.$router.push({ path: this.redirect || '/' })
              let time = getshowOpenInfo()
              if (!time || (time && new Date().getTime() > time)) {
                this.$root.$children[0].$refs.OpenNotice.open()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    handleToOpen() {
      if (!this.agree) return this.$message.error('请阅读协议，并勾选同意后登录')
      window.location.href = process.env.VUE_APP_FULU_CAS
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.head-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: $--border-base;
  .head-logo {
    display: flex;
    align-items: center;
    .logo {
      height: 42px;
      width: 143px;
      flex-shrink: 0;
    }
    .logo-wrap {
      color: #5cc88d;
      display: flex;
      align-items: center;
      margin-right: 20px;
      .img {
        width: 139px;
        height: 24px;
      }
      .icon {
        font-size: 22px;
        margin-right: 20px;
      }
      .icont {
        font-size: 14px;
      }
    }
    .title {
      flex-grow: 1;
      text-align: center;
      font-size: 16px;
      font-weight: normal;
      margin: 0 0 0 20px;
      color: $--color-text-primary;
    }
  }
}

.login {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #f7f7f7;

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
      background: rgba($color: #fff, $alpha: 0.95);
      box-shadow: 0 5px 15px rgba($color: #000000, $alpha: 0.2);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      .img-box {
        width: 650px;
        border-radius: 10px 0 0 10px;
        background-image: url('../../assets/login-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .login-form {
      flex-shrink: 0;
      width: 370px;
      position: relative;
      .desc {
        position: absolute;
        top: 0px;
        right: 15px;
        margin-top: -30px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        text-shadow: 0 2px 5px rgba($color: #000000, $alpha: 1);
      }
      .title {
        text-align: center;
        padding: 20px 40px;
        font-size: 18px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .t {
          position: absolute;
          bottom: -7px;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 14px;
          line-height: 1;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            display: block;
            height: 1px;
            background: $--border-color-base;
            flex: 1;
            margin-right: 20px;
          }
          &::after {
            content: '';
            display: block;
            height: 1px;
            background: $--border-color-base;
            flex: 1;
            margin-left: 20px;
          }
        }
        .logo {
          width: 64px;
          // margin-right: 10px;
        }
      }
      .content {
        padding: 40px 40px 10px;
      }
    }

    .el-form-item {
      margin-bottom: 20px;
    }

    // .input {
    //   background: transparent;
    //   input {
    //     background: $--color-text-primary;
    //     border: none;
    //     color: #fff;
    //   }
    // }

    .icon-input {
      font-size: 20px;
    }

    .login-btn {
      width: 100%;
    }
    .el-divider__text {
      color: $--color-text-secondary;
      font-size: 12px;
    }
  }

  .fulu {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 10px;
    margin-top: -5px;
  }
}

.copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #4e5769;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  ul {
    margin: 0 auto;
    padding: 0;
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
  li {
    // float: left;
    display: inline-block;
    list-style: none;
    &::before {
      content: '|';
      padding: 0 30px;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    img {
      margin-right: 10px;
      vertical-align: middle;
    }
    a {
      color: #fff;
      font-style: normal;
      text-decoration: none;
    }
  }
}
</style>
