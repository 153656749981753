<template>
  <div class="home">
    <el-row class="home-box home-box-titles">
      <el-col :span="16" class="flex flex-a-c">
        <i class="el-icon-self-volume-up-fill" style="font-size: 24px"></i>
        <div v-if="notice && notice.id" class="content-all">
          <span class="title-type">
            {{ formatStatus(kvNoticeType, notice.messageType) }}
          </span>
          <span @click="toNotice" class="content">
            <span v-if="!notice.isRead" class="read"></span>
            {{ notice.messageTitle }}
          </span>
        </div>
        <div v-else class="content-all no-data">暂无通知</div>
      </el-col>
      <el-col :span="8" class="home-notice-r">
        {{ notice.issueTime }}
        <span @click="toNoticeList" class="more-see">
          查看更多<i class="el-icon-d-arrow-right"></i>
        </span>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="8">
        <div class="home-box">
          <div class="home-box-title title-b">今日待办</div>
          <div class="home-box-main flex-wrap">
            <div class="home-sing" @click="$router.push(`/businessBills`)">
              <div class="box">
                <div>业务确认单待上传</div>
                <div class="number green" v-format="'#,##0'">
                  {{ todoData.confirmReceiptCount || 0 }}
                </div>
              </div>
            </div>
            <div class="home-sing" @click="$router.push(`/deliveryRegulate`)">
              <div class="box">
                <div>交付物待上传</div>
                <div class="number yellow" v-format="'#,##0'">
                  {{ todoData.taskDeliverableCount || 0 }}
                </div>
              </div>
            </div>
            <div class="home-sing" @click="$router.push(`/deliveryRegulate`)">
              <div class="box">
                <div>成果验收单</div>
                <div class="number violet" v-format="'#,##0'">
                  {{ todoData.resultAcceptanceCount || 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-box code-img" v-if="!$store.state.app.oemId || $store.state.app.oemId == 'pkwin'">
          <div class="home-box-title flex flex-j-b">
            <span>创客签约码</span>
            <span @click="downloadFileByBase64(qrcodeImg, '创客签约码')">
              <i
                class="el-icon-self-download-2-line"
                style="font-size: 20px"
              ></i>
            </span>
          </div>
          <div class="sub-title">协助创客2分钟完成签约，体验极速业务交付</div>
          <el-image class="image" :src="qrcodeImg">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <p class="name">{{ accountData.merchantName || "" }}</p>
          <p class="tips">请把签约码发送给创客，请用微信扫码</p>
          <!-- <p class="tips">小程序升级改造，暂时关闭小程序签约，有疑问联系运营或管理员</p> -->
        </div>
      </el-col>
      <el-col :span="16">
        <div class="home-box">
          <div class="home-box-title title-b">账户概览</div>
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col
              :span="12"
              class="general-box"
              @click.native="$router.push({ name: 'myAccount' })"
            >
              <div class="txt">可用余额（元）</div>
              <div class="number" v-format="'#,##0.00'">
                {{ accountData.creditBalance || 0 }}
              </div>
              <div class="flex flex-j-b number-b">
                <div>
                  账户余额：
                  <span class="balance" v-format="'#,##0.00'">
                    {{ accountData.accountBalance || 0 }}元
                  </span>
                </div>
                <div class="general-sing">
                  结算中：
                  <span class="yellow" v-format="'#,##0.00'">
                    {{ accountData.blockedBalance || 0 }}元
                  </span>
                </div>
              </div>
            </el-col>
            <el-col
              v-if="accountData.isEnableMonthly"
              :span="12"
              class="general-box"
              @click.native="$router.push({ name: 'discountDetail' })"
            >
              <div class="txt">可用服务费抵扣金（元）</div>
              <div class="number">
                {{ accountData.deductionAvailableAmount || 0 }}
              </div>
              <div class="flex flex-j-b number-b">
                <div>
                  抵扣金总额：
                  <span class="balance" v-format="'#,##0.00'">
                    {{ accountData.deductionAvailableAmount || 0 }}元
                  </span>
                </div>
                <div class="general-sing">
                  冻结中：
                  <span class="yellow" v-format="'#,##0.00'">
                    {{ accountData.deductionFrozenAmount || 0 }}元
                  </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-show="closeBox" class="home-box home-box-image">
          <div class="home-bg">
            <div class="flex">
              <el-image
                class="image"
                fit="contain"
                :src="require('@/assets/home/home-7.png')"
              ></el-image>
              <div class="flex-1">
                <el-row
                  :gutter="12"
                  type="flex"
                  align="middle"
                  class="flow-all"
                >
                  <el-col
                    v-for="(item, keys) in flowList"
                    :key="keys"
                    :span="6"
                  >
                    <div class="flow-sing flex">
                      <div class="flow-num" :class="`flow-num-${item.key}`">
                        {{ item.key }}
                      </div>
                      <div>
                        <div class="flow-title">{{ item.title }}</div>
                        <div>{{ item.cont }}</div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="image-colse" @click="closeBox = false"></div>
            <div class="image-colse-txt"><i class="el-icon-close"></i></div>
          </div>
        </div>
        <div class="home-box">
          <div class="home-box-title title-b">快捷导航</div>
          <div class="home-box-main">
            <div
              class="flex-1 fun-box-sing"
              v-for="item in navList"
              :key="item.name"
            >
              <div
                class="box-sing-n"
                @click="$router.push({ name: item.pathName })"
              >
                <el-image
                  class="image"
                  :src="require(`@/assets/home/home-${item.imageKey}.png`)"
                >
                </el-image>
                <div class="name">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-box" v-if="$hasAccess('004007')">
          <div class="home-position">
            <div class="home-box-title">发票概览</div>
            <div class="title-r" @click="$router.push({ name: 'billApply' })">
              查看<i class="el-icon-d-arrow-right"></i>
            </div>
          </div>
          <div class="home-box-bottom">
            <div  v-for="item in invoiceList" :key="item.title">
              <div v-if="!item.popup" class="bottom-item">
                <div class="home-box-bottom-title">
                  {{ item.title }}
                </div>
                <div class="home-box-bottom-p" v-format="'#,##0.00'">
                  {{ item.sum }}
                </div>
              </div>
              <el-tooltip v-else placement="right">
                <div class="bottom-item">
                  <div class="home-box-bottom-title">
                    {{ item.title }}
                  </div>
                  <div class="home-box-bottom-p" v-format="'#,##0.00'">
                    {{ item.sum }}
                  </div>
                </div>
                <div slot="content">
                  <div v-for="(it, idx) in item.tooltip" :key="it.label">
                    {{ it.label }}：<span :key="idx" v-format="'#,##0.00'">{{ it.value }}</span>
                  </div>
                </div>
             </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <NoticeInfo ref="NoticeInfo" />
  </div>
</template>

<script>
// import * as echarts from 'echarts'
import VueQr from "vue-qr";
import { formatStatus, kvNoticeType } from "@/configs/formatters";
import { downloadUrl } from "@/utils";
import NoticeInfo from "../notice/info";
export default {
  name: "Home",
  components: { NoticeInfo, VueQr },
  data() {
    return {
      navList: [
        {
          name: "任务列表",
          icon: "icon-renwujihua",
          pathName: "taskList",
          imageKey: 1,
        },
        {
          name: "结算管理",
          icon: "icon-jiesuanzhanghubaobiao",
          pathName: "workPlanCheck",
          imageKey: 2,
        },
        {
          name: "我的账户",
          icon: "icon-qianbao",
          pathName: "myAccount",
          imageKey: 3,
        },
        {
          name: "申请开票",
          icon: "icon-kaipiao",
          pathName: "billApply",
          imageKey: 4,
        },
        {
          name: "开票记录",
          icon: "icon-kaipiaojilu",
          pathName: "billInfo",
          imageKey: 5,
        },
        {
          name: "创客列表",
          icon: "icon-duoren-renqun",
          pathName: "workPeople",
          imageKey: 6,
        },
      ],
      invoiceList: [
        {
          title:'实际可开票（元）',
          sum: '0',
          popup: true,
        },
        {
          title:'审核中（元）',
          sum: '0',
          popup: false,
        },
        {
          title:'开票中（元）',
          sum: '0',
          popup: false,
        },
        {
          title:'已开票（元）',
          sum: '0',
          popup: false,
        },
      ],
      flowList: [
        {
          key: "01",
          title: "导入创客",
          cont: "电子签约超省时",
        },
        {
          key: "02",
          title: "创建任务",
          cont: "业务开展安全合规",
        },
        {
          key: "03",
          title: "导入结算单",
          cont: "合规校验有保障",
        },
        {
          key: "04",
          title: "充值发放",
          cont: "高效结算秒到账",
        },
      ],
      closeBox: true,
      logoSrc: require("@/assets/logo.png"),
      merchantId: "",
      accountData: {},
      todoData: {},
      notice: {},
      esQrcodeText: "",
      kvNoticeType,
      qrcodeImg: "",
    };
  },
  computed: {
    // esQrcodeText() {
    //   return 'https:' + process.env.VUE_APP_H5_HOST + 'es/?mid=' + this.merchantId
    // },
    businessType() {
      return this.$store.state.user.businessType;
    },
  },
  mounted() {
    this.getQrSrc();
    this.getInvoice();
    this.dateMonth = new Date();
    this.$nextTick(() => {
      if (this.$hasAccess("004006")) this.getDetail();
      if (this.$hasAccess("004003")) this.getMyToDo();
      if (this.$hasAccess("004000")) this.getNotice();
    });
  },
  methods: {
    formatStatus,
    async getInvoice() {
      const res = await this.$api.GetSummaryInvoiceAmount();
      res.list.map((item) => {
          switch (item.type) {
            case 10:
              this.invoiceList[0].sum = item.amount
              this.invoiceList[0].tooltip = [
                { label: '可开票金额', value: item.canBillAmount },
                { label: '退汇金额', value: item.returnAmount }
              ]
              break
            case 20:
              this.invoiceList[1].sum = item.amount
              break
            case 30:
              this.invoiceList[2].sum = item.amount
              break
            case 40:
              this.invoiceList[3].sum = item.amount
              break
            default:
              break
          }
        })
    },
    async getQrSrc() {
      const res = await this.$api.GetWechatSignUrl();
      console.log(res);
      let dataStr = this.transformArrayBufferToBase64(res);
      this.qrcodeImg = "data:image/png;base64," + dataStr;
      // this.esQrcodeText = res
    },
    async getDetail() {
      const res = await this.$api.GetMerchantInfo();
      this.accountData = res;
      this.merchantId = res.merchantId;
    },
    async getMyToDo() {
      const res = await this.$api.GetMyToDo();
      this.todoData = res;
    },
    async getNotice() {
      const res = await this.$api.GetSystemMessagePageList();
      if (res.list.length) {
        this.notice = res.list[0];
      }
    },
    open(url) {
      downloadUrl(url);
    },
    toNotice() {
      this.$set(this.notice, "isRead", true);
      this.$refs.NoticeInfo.open(this.notice);
    },

    toNoticeList() {
      this.$router.push(`/notice`);
    },
    transformArrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      for (var len = bytes.byteLength, i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    downloadFileByBase64(base64, name) {
      var myBlob = this.dataURLtoBlob(base64);
      var myUrl = URL.createObjectURL(myBlob);
      this.downloadFile(myUrl, name);
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    downloadFile(url, name = "What's the fuvk") {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", name);
      a.setAttribute("target", "_blank");
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.home-notice-r {
  text-align: right;
  line-height: 26px;
  color: #86909c;
  .more-see {
    color: #4e5969;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: #30d68c;
    }
  }
}
.home-box {
  background: #ffffff;
  border: 1px solid #f2f3f5ff;
  border-radius: 8px;
  padding: 20px;
  font-size: 14px;
  color: #4e5969;
  margin-bottom: 20px;
  .content-all {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 26px;
  }
  .no-data {
    margin-left: 12px;
  }
  .title-type {
    color: #000000;
    font-weight: 600;
    margin: 0 12px;
  }
  .content {
    position: relative;
    cursor: pointer;
    .read {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #f56c6c;
      top: -2px;
      left: -8px;
    }
  }
  .home-box-title {
    color: #1d2129ff;
    font-size: 16px;
    font-weight: 600;
  }
  .home-box-main {
    display: flex;
  }
  .title-b {
    margin-bottom: 16px;
  }
  .home-position {
    display: flex;
    justify-content:space-between;
    .title-r {
      cursor: pointer;
      color: #86909c;
    }
  }
}
.home-box-bottom {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  .bottom-item {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    flex:1;
    .home-box-bottom-title {
      font-size: 16px;
      line-height: 20px;
    }
    .home-box-bottom-p {
      font-size: 28px;
      font-weight: bold;
      line-height: 46px;
    }
  }
  .bottom-item:hover {
      background:#f2f3f5;
      transition: all 0.3s;
  }
}
.home-box-titles {
  padding: 14px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-j-b {
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}
.flex-a-c {
  align-items: center;
}

.home-sing {
  width: 50%;
  .box {
    padding: 12px;
    border-radius: 8px;
    transition: all 0.3s;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background: #f2f3f5;
    }
    .number {
      margin-top: 4px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }
    .green {
      color: #30d68c;
    }
    .violet {
      color: #722ed1;
    }
    .yellow {
      color: #ff7d00;
    }
  }
}

.code-img {
  text-align: center;
  .sub-title {
    color: #86909c;
    line-height: 30px;
    text-align: left;
  }
  .image {
    margin-top: 24px;
    height: 200px;
    width: 200px;
    font-size: 90px;
    line-height: 200px;
  }
  .tips {
    color: red;
  }
}

.general-box {
  padding: 20px;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: #f2f3f5;
  }
  .txt {
    line-height: 20px;
  }
  .number {
    margin-top: 4px;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #1d2129;
  }
  .balance {
    color: #1d2129;
  }
  .yellow {
    color: #ff7d00;
  }
  .red {
    color: #f53f3f;
  }
  .general-sing {
    margin-right: 30px;
  }
  .number-b {
    margin-top: 12px;
  }
}

.home-box-image {
  padding: 0px;
  min-height: 100px;
  background: #e8ffea;
  overflow: hidden;
  position: relative;
  .home-bg {
    background: url("~@/assets/home/home-8.png") no-repeat;
    background-position: 100% 0;
  }
  .image {
    width: 180px;
  }
  .image-colse {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: #a7fdbf;
    border-radius: 50%;
    transform: translate(50%, -50%);
    cursor: pointer;
  }
  .image-colse-txt {
    position: absolute;
    top: 4px;
    right: 4px;
    pointer-events: none;
  }
  .flow-all {
    padding: 0 24px;
    height: 100%;
    .flow-sing {
      border: 2px solid #ecf0f0;
      border-radius: 8px;
      padding: 16px 12px;
      background: #ffffffad;

      .flow-num {
        font-size: 20px;
        font-weight: 600;
        position: relative;
        line-height: 0.8;
        margin-right: 4px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: -16px;
          width: 100%;
          height: 4px;
          background: red;
        }
      }
      .flow-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .flow-num-01 {
        color: #13a838;
        &::before {
          background: #13a838;
        }
      }
      .flow-num-02 {
        color: #2364e3;
        &::before {
          background: #2364e3;
        }
      }
      .flow-num-03 {
        color: #9b51e0;
        &::before {
          background: #9b51e0;
        }
      }
      .flow-num-04 {
        color: #ff7c00;
        &::before {
          background: #ff7c00;
        }
      }
    }
  }
}

.fun-box-sing {
  text-align: center;
  color: #1d2129;
  font-weight: 600;
  .name {
    margin-top: 8px;
    cursor: pointer;
  }
  .image {
    width: 60px;
    transition: all 0.3s;
    cursor: pointer;
  }
  .box-sing-n {
    display: inline-block;
    &:hover .image {
      transform: translateY(-6px);
    }
  }
}

::v-deep .el-image img {
  border: none;
}

@media screen and (max-width: 1450px) {
  .home-box-image .flow-all .flow-sing {
    padding: 16px 10px;
    .flow-num {
      font-size: 12px;
    }
    .flow-title {
      font-size: 12px;
    }
  }
}
</style>
