<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView :labels="labels" ref="TableView" apiName="GetRegulateList" :searchList="searchList">
      <!-- <el-form-item slot="btnline-item-left" v-if="$hasAccess('005300')">
        <el-button plain type="primary" @click="$refs.UploadFile.open()"
          >上传</el-button
        >
        <el-button plain type="primary" @click="download">下载模板</el-button>
      </el-form-item> -->
      <template slot="table-item">
        <!-- <el-table-column label="创客成果验收单" v-slot="{ row }" width="120">
          <el-button v-if="$hasAccess('005302') && row.acceptanceUploadStatus == '未上传'" type="text" @click="showUpload(row, 2)">上传</el-button>
          <el-button v-if="$hasAccess('005304') && row.acceptanceUploadStatus == '已上传'" type="text" @click="downloadFile(row, 2)">下载</el-button>
        </el-table-column>
        <el-table-column label="交付物" v-slot="{ row }" width="90">
          <el-button v-if="$hasAccess('005301') && row.deliverableUploadStatus == '未上传'" type="text" @click="showUpload(row, 1)">上传</el-button>
          <el-button v-if="$hasAccess('005303') && row.deliverableUploadStatus == '已上传'" type="text" @click="downloadFile(row, 1)">下载</el-button>
        </el-table-column> -->
        <el-table-column label="操作" width="160" fixed="right" v-slot="{ row }">
          <el-button type="text" v-if="$hasAccess('005305')" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button
            v-if="(row.deliverableUploadStatus == 0 || row.deliverableAuditStatus == 3) && $hasAccess('005301')"
            type="text"
            @click="showUpload(row, 1)"
            >上传交付物</el-button
          >
          <el-button
            v-if="(row.acceptanceUploadStatus == 0 || row.acceptanceAuditStatus == 3) && $hasAccess('005302')"
            type="text"
            @click="showUpload(row, 2)"
            >上传成果验收单</el-button
          >
        </el-table-column>
      </template>
    </TableView>
    <Detail ref="Detail"></Detail>
    <UploadFile ref="UploadFile" @updateList="$refs.TableView.refreshTable()"></UploadFile>
    <UploadPeople ref="UploadPeople" @submit="updataList"></UploadPeople>
    <!-- UI更改 抽离单独文件 -->
    <!-- <el-dialog
      :title="fileType == 1 ? '上传交付物' : '上传成果验收单'"
      width="30%"
      :visible.sync="show"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div class="dialogBox">
        <div v-if="fileType != 1" class="accpent">
          <div class="text">第一步：请下载<span @click="downLoadWd" class="result-text">《成果验收单》</span></div>
          <div class="text">第二步：填写任务完成情况，加盖企业公章后上传</div>
          <div class="text">支持jpg .jpeg .png、pdf，zip，rar，txt大小不超过5M</div>
        </div>
        <el-alert
          v-else
          title="请上传创客接单完成情况截图或者平台佣金结算依据截图"
          show-icon
          type="warning"
          :closable="false"
          style="margin-bottom: 18px; flex-shrink: 0"
        >
        </el-alert>
        <div style="display: flex" class="flex-box">
          <div class="text">上传{{ fileType == 1 ? '交付物' : '成果验收单' }}：</div>
          <MultipleUpload
            :modelValue="fileType == 1 ? detail.deliverableList : detail.acceptanceList"
            :config="config"
            @update="uploadMul"
            :fileSuffix="fileSuffix"
          ></MultipleUpload>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="show = !show">取消</el-button>
        <el-button @click="submit" type="primary" :loading="btnloadSubmit">确认</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import Detail from './detail.vue'
import UploadFile from './uploadFile.vue'
import { searchRegulate } from '@/configs/searches.js'
import { regulateList } from '@/configs/tables.js'
import { downloadDocx, downloadUrl } from '@/utils'
import MultipleUpload from '@/components/formView/multipleUpload.vue'
import UploadPeople from './uploadPeople.vue'
export default {
  components: { Detail, UploadFile, MultipleUpload, UploadPeople },
  data() {
    return {
      btnloadSubmit: false,
      labels: regulateList,
      searchList: searchRegulate,
      show: false,
      fileType: -1,
      detail: {
        deliverableList: [],
        acceptanceList: [],
        deliverableId: '',
        beEnterprise: '',
        taskNo: '',
        taskName: ''
      },
      fileSuffix: ['jpg', 'png', 'jpeg', 'pdf', 'zip', 'rar', 'txt'],
      config: {
        disabled: false
      }
    }
  },
  methods: {
    async downLoadWd() {
      const result = await this.$api.DownloadAcceptanceNoteFile({ id: this.detail.deliverableId, type: 2 })
      downloadDocx(result, '成果验收单')
    },
    uploadMul(val) {
      console.log(val)
      this.fileType == 1 ? (this.detail.deliverableList = val) : (this.detail.acceptanceList = val)
    },
    // 下载模板
    async download() {
      const result = await this.$api.DownloadTaskDeliverableFile()
      downloadDocx(result, '交付物模板下载')
    },
    downloadFile(e, type) {
      if (type == 1 && e.deliverableList.length > 0) {
        e.deliverableList.map((item) => {
          downloadUrl(item.url)
        })
      } else if (type == 2 && e.acceptanceList.length > 0) {
        e.acceptanceList.map((item) => {
          downloadUrl(item.url)
        })
      } else {
        this.$message.error('暂无文件')
      }
    },
    filterType(e, type) {
      //type:1:交付物,2:创客成果验收单
      let v = e.filter((item) => {
        return item.attachType == type
      })
      return v
    },
    showUpload(e, type) {
      // this.detail.deliverableList = []
      // this.detail.acceptanceList = []
      // this.show = true
      // this.fileType = type
      // let { deliverableId, beEnterprise, taskNo, taskName } = e
      // this.detail = Object.assign(this.detail, { deliverableId, beEnterprise, taskNo, taskName })
      
      let { deliverableId, beEnterprise, taskNo, taskName } = e;
      let detail = Object.assign({type, deliverableId, beEnterprise, taskNo, taskName })
      this.$refs.UploadPeople.open(detail)
    },
    updataList(){
      this.$refs.TableView.refreshTable()
    },
    async submit() {
      if (this.detail.deliverableList.length == 0 && this.detail.acceptanceList.length == 0) {
        this.$message.error('请上传文件')
        return false
      }
      this.btnloadSubmit = true
      if (this.fileType == 1) {
        this.detail.deliverableList.map((item) => {
          item.url = item.fileUrl
          item.name = item.fileName
        })
      } else {
        this.detail.acceptanceList.map((item) => {
          item.url = item.fileUrl
          item.name = item.fileName
        })
      }
      const result = await this.$api.UploadTaskDeliverable(this.detail).finally(() => {
        this.btnloadSubmit = false
      })
      if (result) {
        this.$message.success('上传成功')
        this.show = false
        this.$refs.TableView.refreshTable()
      }
    }
  }
}
</script>