<template>
    <el-dialog :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-small import-confirm">
        <div slot="title">
            <i class="el-icon-self-checkbox-circle-fill title-icon"></i>
            <h3 class="title-text">导入成功</h3>
        </div>
      <!-- 内容区域 -->
      <div class="dialog-content">
        <p>本次成功导入<span class="pass">&ensp;{{ passPeopleNum }}条&ensp;</span>{{ success }}</p>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="close">确定</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        show: false,
        type: '',
        passPeopleNum: 0,
      }
    },
    computed: {
        success() {
            return this.type == 'workPlan' ? '结算单' : '信息'
        },
    },
    methods: {
      close() {
        this.show = false
      },
      open({type, passPeopleNum}) {
        this.type = type
        this.passPeopleNum = passPeopleNum
        this.show = true
      }
    }
  }
  </script>
<style lang="scss" scoped>
.import-confirm {
    ::v-deep .el-dialog {
      min-height: 100px;
    }
    .title-icon {
        vertical-align: middle;
        margin-top: 4px;
        color: #30D68C;
        font-size: 24px;
    }
    .title-text {
        display: inline-block;
        vertical-align: middle;
        margin: 4px 0 0 10px;
        line-height: 24px;
        color: #1D2129;
        font-size: 18px;
        font-weight: 600;
    }
    .dialog-content {
        line-height: 20px;
        margin: -32px 44px -16px 36px;
        color: #1D2129;
        font-size: 14px;
        p {
            margin: 0;
            .pass {
                color: #26B786;
            }
        }
    }
}
</style>