<template>
  <div class="home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <el-card>
      <el-descriptions :column="4" :colon="false" size="medium">
        <el-descriptions-item label="余额"
          ><span v-format="'#,##0.00'">{{ detail.accountBalance || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="可用余额"
          ><span v-format="'#,##0.00'">{{ detail.creditBalance || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="结算中"
          ><span v-format="'#,##0.00'">{{ detail.blockedBalance || 0 }}</span> 元
          <!-- <span style="margin-left: 30px"></span>
          <el-tooltip
            class="item"
            effect="dark"
            content="对公转账请在工作日9:00-16:30之间进行汇款，16:30分后提交的充值申请将于第二个工作日到账，具体到账时间以银行到账为准。"
            placement="top"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip> -->
        </el-descriptions-item>
        <el-descriptions-item label="" :contentStyle="{ margin: '0 40px 0 auto' }">
          <el-button style="width: 100px" type="primary" @click="$refs.WithdrawDialog.open()">提现</el-button>
        </el-descriptions-item>
      </el-descriptions>
      <el-alert v-if="hasAutoRecharge" type="warning" :closable="false" style="margin-top: 15px"
        ><p>
          温馨提示：<br />* 充值说明：请根据结算单的不同发放方式充值到对应账户， 打款后一般可实时到账到余额，具体取决于银行的到账时间。
          如需帮助请联系您的专属客服。
        </p></el-alert
      >
      <!-- 账户 -->
      <el-descriptions v-for="(item, index) in accounts" :key="index" direction="vertical" :column="2" border size="medium" style="margin-top: 18px">
        <el-descriptions-item :label="accountType(item.accountType) + '账户'" :labelStyle="{ width: '30%', minWidth: '250px' }">
          <p>
            账户余额：<span v-format="'#,##0.00'">{{ item.accountBalance || 0 }}</span>
          </p>
          <p>
            结算中：<span v-format="'#,##0.00'">{{ item.blockedBalance || 0 }}</span>
          </p>
        </el-descriptions-item>
        <el-descriptions-item label="汇款信息（充值请汇款至以下账户）">
          <!-- <div class="flex-div" v-if="item.id == '2eec280458854a5b9cd40b923a6837c4'" style="color: #f56c6c">账户信息调整中，请勿充值</div> -->
          <div v-if="!detail.supplierIsEnable" class="flex-div">
            <div>
              <p v-if="item.openMode != 4 && item.accountName">
                账户名称：{{ item.accountName }} <i class="copy el-icon-copy-document" :data-clipboard-text="item.accountName" @click="copy"></i>
              </p>
              <p v-if="item.openMode == 4 && item.accountName">
                账户名称：{{ item.accountName }}
                <i class="copy el-icon-copy-document" :data-clipboard-text="item.accountName" @click="copy"></i>
              </p>
              <p v-if="item.openMode != 4 && item.accountBank">
                开户银行：{{ item.accountBank }} <i class="copy el-icon-copy-document" :data-clipboard-text="item.accountBank" @click="copy"></i>
              </p>
              <p v-if="item.openMode != 4 && item.accountNumber">
                账户号码：{{ item.accountNumber }} <i class="copy el-icon-copy-document" :data-clipboard-text="item.accountNumber" @click="copy"></i>
              </p>
              <p v-if="item.openMode == 4 && item.accountNumber">
                付款账号：{{ item.accountNumber }} <i class="copy el-icon-copy-document" :data-clipboard-text="item.accountNumber" @click="copy"></i>
              </p>
              <p v-if="item.accountCode && item.openMode == 3">
                银行行号：{{ item.accountCode }} <i class="copy el-icon-copy-document" :data-clipboard-text="item.accountCode" @click="copy"></i>
              </p>
              <p v-if="item.isRemitAccount && item.openMode == 4" @click="$refs.OrderRecharge.open(item)" style="color: #0493cd; cursor: pointer">
                【汇款充值信息查看】
              </p>
              <p v-if="item.isShowRemark == 1">
                备注信息：{{ detail.memberCode }}
                <i class="copy el-icon-copy-document" :data-clipboard-text="detail.memberCode" @click="copy"></i>
                <el-alert type="warning" :closable="false" style="display: inline; margin-left: 15px"
                  ><i class="el-icon-caret-left"></i> 请将备注信息添加到您的打款备注中， 以保证充值到当前的系统账号</el-alert
                >
              </p>
            </div>
            <div class="flex-div-right" v-if="item.isAutoRecharge">
              <el-button type="primary" plain @click="$refs.RechargeDialog.open(item)" style="width: 100px">充值</el-button>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item
          v-if="detail.isShowProviderBalance && item.accountType == 4 && item.openMode == 3 && item.openState == 4"
          :labelStyle="{ display: 'none' }"
        >
          <p>
            供应商银行账户余额：<span v-format="'#,##0.00'">{{ item.providerBalanceBank || 0 }}</span>
          </p>
          <p>
            供应商支付宝账户余额：<span v-format="'#,##0.00'">{{ item.providerBalanceAli || 0 }}</span>
          </p>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <WithdrawDialog ref="WithdrawDialog" @submit="getDetail"></WithdrawDialog>
    <OrderRecharge ref="OrderRecharge"></OrderRecharge>
    <RechargeDialog ref="RechargeDialog" @submit="getDetail"></RechargeDialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import WithdrawDialog from './withdrawDialog'
import RechargeDialog from './rechargeDialog'
import OrderRecharge from './orderRecharge'
export default {
  components: {
    WithdrawDialog,
    RechargeDialog,
    OrderRecharge
  },
  data() {
    return {
      detail: {},
      accounts: []
    }
  },
  computed: {
    hasAutoRecharge() {
      return this.accounts.length && this.accounts.find((it) => !it.isAutoRecharge)
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const res = await this.$api.GetMerchantInfo()
      this.detail = res
      const accounts = (res.accounts || []).sort((a, b) => {
        if ((a.accountType == 1 || a.accountType == 5) && b.accountType != 1 && b.accountType != 5) return -1
        return 0
      })
      if (res.isShowProviderBalance) {
        // 轮询处理
        for (let i in accounts) {
          const item = accounts[i]
          if (item.accountType == 4 && item.openMode == 3 && item.openState == 4) {
            const bank = await this.$api.GetProviderBalance({ accountNumber: item.accountNumber, payType: 0 })
            item.providerBalanceBank = bank.accountBalance
            const ali = await this.$api.GetProviderBalance({ accountNumber: item.accountNumber, payType: 1 })
            item.providerBalanceAli = ali.accountBalance
          }
        }
      }
      this.accounts = accounts
    },
    copy(e) {
      const clipboard = new Clipboard(e.target)
      clipboard.on('success', (e) => {
        this.$message.success('已复制到剪切板')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message.error('复制失败，请手动复制')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    },
    accountType(val) {
      if (val == 1 || val == 5) return '银行'
      if (val == 2 || val == 6) return '支付宝'
      if (val == 3 || val == 7) return '微信'
      if (val == 4 || val == 8) return '充值'
      return '未知'
    }
  }
}
</script>

<style lang="scss" scoped>
.span50 {
  display: inline-block;
  width: 50%;
}
.copy {
  margin-left: 10px;
  color: #23d38a;
  cursor: pointer;
}
.flex-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-right {
    margin: 0 30px;
  }
}
</style>
