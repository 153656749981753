<template>
  <div class="flex-home">
    <TableView
      ref="TableView"
      :labels="labels"
      :searchList="searchList"
      apiName="getConfirmList"
    >
      <!-- <el-form-item slot="btnline-item-left" v-if="$hasAccess('005400')">
        <el-button plain @click="download" type="primary">下载模板</el-button>
      </el-form-item> -->
      <el-table-column slot="table-item" label="操作" width="110" fixed="right">
        <template v-slot="{ row }">
          <el-button
            v-if="$hasAccess('005405') && row.confirmState == 0"
            type="text"
            @click="$refs.confirmation.open(row, 1)"
          >
            确认
          </el-button>

          <el-button
            v-if="$hasAccess('005406') && row.confirmState == 3"
            type="text"
            @click="$refs.confirmation.open(row, 2)"
          >
            重新确认
          </el-button>

          <el-button
            v-if="$hasAccess('005401') && row.confirmState == 2"
            type="text"
            @click="downloadList(row)"
            >下载</el-button
          >
          <!-- <el-button
            v-if="$hasAccess('005404')"
            type="text"
            @click="$refs.Detail.open(row)"
            >查看</el-button
          > -->
          <!-- <el-button
            v-if="
              $hasAccess('005403') &&
              (row.uploadState == 0 || row.auditState == 3)
            "
            type="text"
            @click="$refs.UploadFile.open(row)"
            >上传</el-button
          > -->
          <!-- <el-button v-if="$hasAccess('005401') && row.uploadState == 1" type="text" @click="contractDownload(row)">下载</el-button> -->
          <!-- <el-divider direction="vertical" v-if="row.uploadState == 1"></el-divider>
          <ImageViewer
            v-if="$hasAccess('005402') && row.uploadState == 1"
            @click.native="preview(row)"
            fontSize="12px"
            :isIcon="false"
            ref="ImageViewer"
            name="预览"
            apiName="GetConfirmReceiptsFileUrl"
            path="default"
          /> -->
        </template>
      </el-table-column>
    </TableView>
    <Confirmation ref="confirmation" @submit="updataList"></Confirmation>
  </div>
</template>

<script>
import { downloadDocx, downloadUrl } from "@/utils";
import UploadFile from "./uploadFile.vue";
import ImageViewer from "@/components/tableView/imageViewer";
import Confirmation from "./confirmation";
import { formatStatus } from "@/configs/formatters";
export default {
  components: { UploadFile, ImageViewer, Confirmation },
  data() {
    return {
      labels: businessBills,
      searchList: searchBusinessBills,
    };
  },
  created() {
    this.$api.GetSupplierInfoCodeName().then((res) => {
      const options = res.list.map((item) => {
        return { label: item.supplierName, key: item.supplierCode };
      });
      const searchList = searchBusinessBills.map((item) => {
        if (item.prop == "SupplierName") {
          return Object.assign({}, item, { options });
        } else {
          return item;
        }
      });
      this.searchList = searchList;
    });
  },
  methods: {
    async downloadList(row) {
      if (row.confirmReceiptsFileUrl) {
        downloadUrl(row.confirmReceiptsFileUrl);
      } else {
        this.$message.error("暂无数据");
      }
      // const result = await this.$api.confirmationOrderDownload({
      //   confirmReceiptsId: row.id,
      // });
      // downloadDocx(result, "业务确认单");
    },
    async download() {
      const result = await this.$api.DownloadConfirmReceiptsFile();
      downloadDocx(result, "业务确认单模板");
    },
    async contractDownload(row) {
      const result = await this.$api.GetConfirmReceiptsFileUrl({
        ConfirmReceiptsId: row.id,
      });
      downloadUrl(result);
    },
    // 预览
    async preview(row) {
      this.$refs.ImageViewer.showView({ ConfirmReceiptsId: row.id });
    },
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable();
      }, 0);
    },
    updataList() {
      this.$refs.TableView.refreshTable();
    },
  },
};

// 业务确认单搜索
const searchBusinessBills = [
  // { prop: "MainName", cmp: "input", label: "企业名称" },
  // {
  //   prop: 'SupplierName',
  //   cmp: 'select',
  //   label: '税源地',
  //   options: [],
  //   config: { style: { width: '300px' } }
  // },
  {
    prop: "Month",
    cmp: "dpicker",
    label: "当前月份",
    config: { type: "month", valueFormat: "yyyy-MM" },
  },
  {
    prop: "ConfirmState",
    cmp: "select",
    label: "状态",
    config: { width: 120 },
    options: [
      { label: "全部", key: "" },
      { label: "待确认", key: "0" },
      { label: "已确认", key: "1" },
      { label: "已通过", key: "2" },
      { label: "已驳回", key: "3" },
    ],
  },
  { prop: "SupplierName", cmp: "input", label: "税源地" },
  // {
  //   prop: "UploadState",
  //   cmp: "select",
  //   label: "上传状态",
  //   options: [
  //     { label: "待上传", key: 0 },
  //     { label: "已上传", key: 1 },
  //   ],
  // },
  // {
  //   prop: "AuditState",
  //   cmp: "select",
  //   label: "审核状态",
  //   options: [
  //     { label: "待审核", key: 0 },
  //     { label: "审核中", key: 1 },
  //     { label: "审核通过", key: 2 },
  //     { label: "退回", key: 3 },
  //   ],
  // },
];

// 业务确认单列表
const businessBills = [
  // { prop: 'mainName', label: '企业名称' },
  { prop: "dateMonth", label: "当前月份" },
  { prop: "paySuccessAfterTaxAmount", label: "总金额", cmp: "money" },
  { prop: "supplierName", label: "税源地" },
  {
    prop: "confirmState",
    label: "状态",
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { label: "待确认", key: 0 },
          { label: "已确认", key: 1 },
          { label: "已通过", key: 2 },
          { label: "已驳回", key: 3 },
        ],
        cellValue
      );
    },
  },
  { prop: "confirmTime", label: "确认时间", config: { width: 120 } },
  { prop: "confirmPerson", label: "确认人" },
  // {
  //   prop: "uploadState",
  //   label: "上传状态",
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(kvAuditStatus, cellValue);
  //   },
  // },
  // { prop: "uploadTime", label: "上传时间", config: { width: 170 } },
  // {
  //   prop: "auditState",
  //   label: "审核状态",
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus(kvSearchAuditStatus, cellValue);
  //   },
  // },
];
</script>
