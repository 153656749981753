<template>
  <el-aside class="side-wrap">
    <div class="scroll-wrap">
      <el-menu class="side-menu" :default-active="activeMenu">
        <template v-for="route in routes">
          <side-item
            v-if="!route.businessType || route.businessType == businessType"
            :key="route.path"
            :item="route"
            :base-path="route.path"
          />
        </template>
      </el-menu>
    </div>
  </el-aside>
</template>

<script>
import SideItem from "./sideItem";

export default {
  name: "Side",
  components: { SideItem },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    businessType() {
      return this.$store.state.user.businessType;
    },
    routes() {
      return this.$router.options.routes;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-aside {
  overflow-y: scroll;
}
.scroll-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 12px;
}
::v-deep .is-active {
  .el-submenu__title {
    color: #23d38a;
    i {
      color: #23d38a;
    }
  }
}
::v-deep .el-submenu__title {
  height: 40px;
  line-height: 40px;
  color: #4E5969;
  &:hover {
    &::before {
      content: "";
      display: block;
      border-radius: 8px;
      width: calc(100% - 8px);
      height: 100%;
      position: absolute;
      left: 8px;
    background-color: #f2f3f5;
    }
    background-color: transparent;
    span{
      position: relative;
    }
    i {
      &:first-child {
        position: relative;
      }
    }
  }
  &:focus {
    background-color: transparent;
  }
}
::v-deep .el-submenu .el-menu-item {
  padding-left: 50px!important;
}
::v-deep .el-menu-item {
  color: #4E5969;
  min-width: 0!important;
  height: 40px!important;
  line-height: 40px!important;
}
</style>
