<template>
  <el-dialog :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <template slot="title">
      <div>{{ detail.messageTitle }}</div>
      <div class="sub">{{ detail.issueTime }} {{ formatStatus(kvNoticeType, detail.messageType) }}</div>
    </template>
    <div class="dialog-wrap" style="height: auto; max-height: 500px">{{ detail.messageContent }}</div>
    <div v-if="attachUrl">
      <el-button type="primary" @click="download(attachUrl)">下载附件</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { formatStatus, kvNoticeType } from '@/configs/formatters'
import { downloadUrl } from '@/utils'
export default {
  data() {
    return {
      id: null,
      show: false,
      attachUrl: '',
      detail: {},
      kvNoticeType
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.attachUrl = ''
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.detail = obj
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetSystemMessageDetail({ id: this.id })
      this.attachUrl = res.attachUrl
    },
    async download(url) {
      if (url) return downloadUrl(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.sub {
  font-size: 12px;
  color: #909399;
  margin-top: 10px;
}
.dialog-wrap {
  line-height: 1.5;
  font-size: 16px;
  text-align: justify;
}
</style>
