<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '提现记录详情查看'
    }"
  >
    <DetailCard title="提现信息">
      <DescView :descLabels="descLabels1" :descDatas="detail"></DescView>
    </DetailCard>
    <DetailCard title="付款信息">
      <DescView :descLabels="descLabels2" :descDatas="detail"></DescView>
    </DetailCard>
  </DetailView>
</template>

<script>
import { kvWithdrawState } from '@/configs/formatters'
const descLabels1 = [
  { label: '提现单号', prop: 'withdrawNo' },
  { label: '申请时间', prop: 'withdrawTime' },
  { label: '商户编号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '提现账户', prop: 'accountNumber' },
  { label: '提现金额', cmp: 'money', prop: 'withdrawAmount' },
  { label: '账户名称', prop: 'withdrawAccountName' },
  { label: '开户行', prop: 'withdrawAccountBank' },
  { label: '收款账号', prop: 'withdrawAccountNumber' },
  { label: '联行号', prop: 'withdrawAccountLines' },
  { label: '创建人', prop: 'createUser' }
]
const descLabels2 = [
  { label: '状态', prop: 'withdrawState', cmp: 'state', stateOptions: kvWithdrawState },
  { label: '关联流水单号', prop: 'withdrawBackNo' },
  { label: '审核时间', prop: 'auditTime' },
  { label: '打款时间', prop: 'withdrawFinishTime' },
  { label: '理由', prop: 'noPassCause' },
  { label: '失败原因', prop: 'withdrawDesc' }
]
export default {
  data() {
    return {
      id: null,
      show: false,
      descLabels1,
      descLabels2,
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        this.id = obj.id
        this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetWithdrawLogInfo({
        id: this.id
      })
      const nameRew = await this.$api.GetMerchantInfo()
      this.detail = res
      let { merchantName, memberCode } = nameRew
      this.detail = Object.assign(this.detail, { merchantName, memberCode })
    }
  }
}
</script>
