<template>
  <el-dialog :title="this.type == 1 ? '提交审核' : '确认发放'" :visible.sync="show" :append-to-body="true"
    :close-on-click-modal="false" class="dialog-large">
    <!-- 内容区域 -->
    <div v-if="show">
      <div style="margin-bottom: 15px">结算单数：{{ list.length }}</div>
      <el-table :data="list" style="width: 100%" height="250">
        <el-table-column prop="planNo" label="结算单号"> </el-table-column>
        <el-table-column prop="totalPeopleNum" label="人数"> </el-table-column>
        <el-table-column prop="paymentChannel" label="付款渠道"> </el-table-column>
        <el-table-column prop="issueAmount" label="提交金额"><span slot-scope="{ row }" v-format="'#,##0.00'">{{
    row.issueAmount || 0
}}</span>
        </el-table-column>
        <el-table-column prop="serviceFee" label="服务费"><span slot-scope="{ row }" v-format="'#,##0.00'">{{
    row.serviceFee || 0
}}</span>
        </el-table-column>
        <el-table-column prop="serviceDiffFee" label="差额服务费"><span slot-scope="{ row }" v-format="'#,##0.00'">{{
    row.serviceDiffFee || 0
}}</span>
        </el-table-column>
        <el-table-column prop="settleAmount" label="合计金额"><span slot-scope="{ row }" v-format="'#,##0.00'">{{
    row.settleAmount || 0
}}</span>
        </el-table-column>
        <template v-if="businessType == 2">
          <el-table-column prop="individualTax" label="个税"><span slot-scope="{ row }" v-format="'#,##0.00'">{{
    row.individualTax || 0
}}</span>
          </el-table-column>
          <el-table-column prop="addedTax" label="增值税"><span slot-scope="{ row }" v-format="'#,##0.00'">{{ row.addedTax
    || 0
}}</span>
          </el-table-column>
          <el-table-column prop="surchargesTax" label="税金及附加"><span slot-scope="{ row }" v-format="'#,##0.00'">{{
    row.surchargesTax || 0
}}</span>
          </el-table-column>
        </template>
      </el-table>

      <div v-if="type == 2" style="margin-top: 10px">
        总金额：<span v-format="'#,##0.00'">{{ summary.total || 0 }}</span>
        <div style="margin-top: 10px" v-if="isEnableMonthly">
          服务费抵扣金：<span v-format="'#,##0.00'">{{ summary.dedAmount || 0 }}</span>
        </div>
        <div style="margin-top: 10px">
          账户支付余额：<span v-format="'#,##0.00'">{{ summary.balAmount || 0 }}</span> 
          <!-- （ 支付宝：<span v-format="'#,##0.00'">{{
    summary.zfb || 0
}}</span>
          银行卡：<span v-format="'#,##0.00'">{{ summary.card || 0 }}</span> 微信：<span v-format="'#,##0.00'">{{ summary.wx ||
    0
}}</span>） -->
        </div>
      </div>
      <div v-if="type == 1" style="margin-top: 10px">
        总金额：<span v-format="'#,##0.00'">{{ summary.total || 0 }}</span>（支付宝：<span v-format="'#,##0.00'">{{ summary.zfb
    || 0
}}</span> 银行卡：<span v-format="'#,##0.00'">{{ summary.card || 0 }}</span>
        微信：<span v-format="'#,##0.00'">{{ summary.wx || 0 }}</span>）
      </div>
      <div v-if="type == 1 && accountBalance" style="font-size: 13px; color: #fe7c40; margin-top: 10px">
        当前服务费抵扣金可用余额<span v-format="'#,##0.00'">{{ accountBalance }}</span>，审批通过后自动抵扣该笔结算单服务费
      </div>
      <el-form style="margin-top: 10px" ref="vForm" :model="form" :rules="rules" :inline="true" v-if="type == 2 && isPayNotify == 1"
        @submit.native.prevent>
        <el-form-item :style="{ width: '80%' }"> 短信验证码将发送至{{ list[0].mobile }} </el-form-item>
        <el-form-item prop="verificationCode" label="短信验证码"><el-input v-model="form.verificationCode"
            placeholder="请输入验证码"></el-input></el-form-item>
        <el-form-item><el-button type="primary" @click="sendVerifyCode" :disabled="vcCountdown != 0"
            style="width: 120px">{{
    vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
}}</el-button></el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submitSH" v-if="type == 1" :loading="btnloadSubmit">确 认</el-button>
      <el-button type="primary" @click="submit" v-if="type == 2" :loading="btnloadSubmit">确认发放</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      type: 1,
      ids: [],
      list: [],
      form: {},
      rules: {
        verificationCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      },
      summary: {},
      accountBalance: 0,
      isEnableMonthly: false,
      isPayNotify: 1
    }
  },
  computed: {
    businessType() {
      return this.$store.state.user.businessType
    },
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.ids = []
      this.list = []
      this.form = {}
    },
    open(list, type, isEnableMonthly, isPayNotify) {
      this.clear()
      this.type = type
      this.isEnableMonthly = isEnableMonthly
      this.isPayNotify = isPayNotify
      // 获取列表planNo数组
      const planNos = list.map((item) => {
        return item.planNo
      })
      this.ids = planNos
      this.getPre(planNos)
    },
    // 获取预发放数据
    async getPre(planNos = []) {
      const amount = await this.$api.GetMerchantInfo()
      this.accountBalance = amount.deductionAvailableAmount
      const res = await this.$api.PreConfirmGrant({ planNos })
      if (res.list && res.list.length) {
        this.list = res.list
        // 处理合计信息
        const summary = res.list.reduce(
          (total, val) => {
            total.total += val.settleAmount
            total.dedAmount += val.deductionAmount
            total.balAmount += val.balanceAmount
            if (val.paymentChannel == '支付宝') total.zfb += val.settleAmount
            if (val.paymentChannel == '银行/银行卡') total.card += val.settleAmount
            if (val.paymentChannel == '微信') total.wx += val.settleAmount
            return total
          },
          { dedAmount: 0, balAmount: 0, zfb: 0, card: 0, wx: 0, total: 0 }
        )
        this.summary = summary
        this.show = true
      } else {
        this.$message.error('结算单提交审核异常，请联系客服处理！')
      }
    },
    // 获取验证吗
    async sendVerifyCode() {
      const res = await this.$api.WorkPlanSendVerifyCode({planNoList: this.ids})
      this.$store.dispatch('app/vcDownStart')
    },
    // 提交审核
    async submitSH() {
      this.btnloadSubmit = true
      const planNoList = this.list.map((item) => {
        return item.planNo
      })
      const res = await this.$api.BatchStartAuditWorkPlan({ planNoList }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('已批量提交审核')
      this.$emit('submit')
      this.show = false
    },
    // 提交发放
    async submit() {
      if (this.isPayNotify == 1) {
        this.$refs.vForm.validate(async (valid) => {
          if (valid) {
            this.btnloadSubmit = true
            const planNoList = this.list.map((item) => {
              return item.planNo
            })
            const res = await this.$api.BatchConfirmGrant(Object.assign({ planNoList }, this.form)).finally(() => {
              this.btnloadSubmit = false
            })
            this.$message.success('批量发放成功')
            this.$emit('submit')
            this.show = false
          }
        })
      } else {
        this.btnloadSubmit = true
            const planNoList = this.list.map((item) => {
              return item.planNo
            })
            const res = await this.$api.BatchConfirmGrant({ planNoList }).finally(() => {
              this.btnloadSubmit = false
            })
            this.$message.success('批量发放成功')
            this.$emit('submit')
            this.show = false
      }
    }
  }
}
</script>
