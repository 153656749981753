<template>
  <el-dialog title="充值" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <el-alert type="warning" :closable="false" style="margin-bottom: 25px">
      <p>说明：<br />1. 请注明真实开票内容，一旦填写不可改动。<br />2. 打款凭证内须填写真实用途。<br />3. 备注可说明打款特殊情况。</p>
    </el-alert>
    <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '150px' }"></FormView>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit()" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const fromLabels = [
  {
    cmp: 'number',
    label: '充值金额',
    prop: 'rechargeAmount',
    itemConfig: { rules: [{ required: true, message: '请输入充值金额', trigger: 'blur' }] },
    config: { style: { width: '100%' }, precision: 2, min: 0, controls: false }
  },
  {
    cmp: 'input',
    label: '打款账户名称',
    prop: 'payAccountName',
    itemConfig: { rules: [{ required: true, message: '请输入打款账户名称', trigger: 'blur' }] }
  },
  {
    cmp: 'input',
    label: '打款账户号码',
    prop: 'payAccountNumber',
    itemConfig: { rules: [{ required: true, message: '请输入打款账户号码', trigger: 'blur' }] }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    config: { placeholder: '请输入备注，不超过20字', maxLength: 20 }
  },
  {
    cmp: 'upload',
    prop: 'bankVoucherUrl',
    label: '上传打款凭证',
    suffixArray: ['jpg', 'png', 'jpeg'],
    itemConfig: {
      rules: [{ required: true, message: '请上传打款凭证', trigger: 'blur' }]
    },
    underSuffix: '图片格式支持jpg、png、jpeg，单张图片大小<5M'
  }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: fromLabels,
      merchantAccountId: ''
    }
  },
  methods: {
    clear() {
      this.merchantAccountId = ''
    },
    open(e) {
      this.merchantAccountId = e.id
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    async submit() {
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      const res = await this.$api.SubmitRechargeAccount(Object.assign({}, params, { merchantAccountId: this.merchantAccountId })).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('充值成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
