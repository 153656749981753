<template>
  <el-dialog :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px" :before-close="handleClose">
    <template slot="title">
      <div>{{ detail.messageTitle }}</div>
      <div class="sub">{{ detail.issueTime }} {{ formatStatus(kvNoticeType, detail.messageType) }}</div>
    </template>
    <div class="dialog-wrap" style="height: auto; max-height: 500px">{{ detail.messageContent }}</div>
    <div v-if="detail.attachUrl">
      <el-button type="primary" @click="download(detail.attachUrl)">下载附件</el-button>
    </div>
    <span slot="footer" v-if="list.length">
      <!-- <el-button :disabled="idx == 0" @click="setDetail(idx - 1)">上一条</el-button> -->
      <el-checkbox style="float: left" v-model="isShowToaday">今日内不再弹出</el-checkbox>
      <el-button v-if="idx >= list.length - 1" type="primary" @click="handleClose">关 闭</el-button>
      <el-button v-else type="primary" @click="setDetail(idx + 1)">下一条</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formatStatus, kvNoticeType } from '@/configs/formatters'
import { downloadUrl } from '@/utils'
import { setshowOpenInfo } from '@/utils/auth'
export default {
  data() {
    return {
      show: false,
      list: [],
      idx: 0,
      detail: {},
      isShowToaday: false,
      kvNoticeType
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.list = []
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      this.getList()
    },
    async getList() {
      const res = await this.$api.GetSystemMessageIsShowPageList()
      if (res.total > 0) {
        this.show = true
        this.list = res.list
        this.setDetail(0)
      }
    },
    setDetail(idx) {
      if (idx < 0 || idx > this.list.length - 1) return
      this.detail = this.list[idx]
      this.idx = idx
      if (!this.detail.isRead) {
        this.$api.GetSystemMessageDetail({ id: this.detail.id })
      }
    },
    async download(url) {
      if (url) return downloadUrl(url)
    },
    handleClose() {
      this.show = false
      if (this.isShowToaday) {
        setshowOpenInfo(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sub {
  font-size: 12px;
  color: #909399;
  margin-top: 10px;
}
.dialog-wrap {
  line-height: 1.5;
  font-size: 16px;
  text-align: justify;
}
</style>
