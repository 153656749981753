<template>
    <el-dialog title="退汇明细" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="80%">
        <!-- 内容区域 -->
        <el-table :data="list" style="width: 100%">
            <el-table-column label="订单号" prop="customerOrderNo" width="200"></el-table-column>
            <el-table-column label="结算单编号" prop="planNo"></el-table-column>
            <el-table-column label="商户名称" prop="merchantName"></el-table-column>
            <el-table-column label="商户号" prop="merchantCode"></el-table-column>
            <el-table-column label="下发类型" prop="grantType"></el-table-column>
            <el-table-column label="税后金额" prop="afterTaxAmount">
                <template slot-scope="{ row, column, $index }">
                    <span v-format="'#,##0.00'">{{ row.afterTaxAmount || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="服务费" prop="serviceFee">
                <template slot-scope="{ row, column, $index }">
                    <span v-format="'#,##0.00'">{{ row.serviceFee || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="差额服务费" prop="serviceDiffFee">
                <template slot-scope="{ row, column, $index }">
                    <span v-format="'#,##0.00'">{{ row.serviceDiffFee || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="抵扣金" prop="deductionAmount">
                <template slot-scope="{ row, column, $index }">
                    <span v-format="'#,##0.00'">{{ row.deductionAmount || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订单金额" prop="orderAmount">
                <template slot-scope="{ row, column, $index }">
                    <span v-format="'#,##0.00'">{{ row.orderAmount || 0 }}</span>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>
  
<script>
export default {
    data() {
        return {
            show: false,
            list: []
        }
    },
    methods: {
        // 数据清空
        clear() {
            this.list = []
        },
        open(list) {
            this.clear()
            if (list) {
                this.list = list
                this.show = true
            } else {
                this.$message.error('无效的信息')
            }
        }
    }
}
</script>
  