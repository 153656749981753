import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css'

import ElementUI from 'element-ui'
import '@/styles/element-theme.scss'

import '@/assets/icon/iconfont.css'
import '@/assets/icon-self/iconfont.css'

import '@/styles/index.scss'

import format from 'vue-text-format'

import tableView from '@/components/tableView'
import formView from '@/components/formView'
import detailView from '@/components/detailView'
import descView from '@/components/descView'

import API from '@/api'

import { hasAccess } from '@/utils'

Vue.use(ElementUI, { size: 'small' })
Vue.use(format)
Vue.use(tableView)
Vue.use(formView)
Vue.use(detailView)
Vue.use(descView)

Vue.prototype.$title = '灵时云 · 企业平台'

Vue.prototype.$api = API

Vue.prototype.$hasAccess = hasAccess

Vue.prototype.$noimg = require('@/assets/noimg.png')

Vue.prototype.$eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')