import {
  formatStatus,
  formatStatusHasColor,
  formatStatusHasColorHasIcon,
  kvTableTemplateStatus,
  kvWorkPeopleIsSign,
  kvWorkPlanPayState,
  kvWorkPlanGrantState,
  kvWorkPlanAuditState,
  kvRechargeState,
  kvRechargeType,
  kvDisburseGrantState,
  kvDisburseGrantType,
  kvTaskAuditStatus,
  kvNoticeType,
  agreementState,
  signState,
  signMethodState,
  effectState,
  kvWithdrawState,
  kvAuditState,
  kvState,
  kvAuditStatus,
  kvSearchAuditStatus,
  kvIsReturn
} from './formatters'

// 表格模版
export const tableTemplate = [
  { label: '标准文本', prop: 'xxa' },
  { label: '长文本缩略', prop: 'xxb', config: { showOverflowTooltip: true } },
  {
    label: 'html',
    prop: 'xxc',
    cmp: 'html'
  },
  {
    label: '状态',
    prop: 'xxd',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvTableTemplateStatus, cellValue)
    }
  },
  {
    label: '带颜色状态',
    prop: 'xxe',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvTableTemplateStatus, cellValue)
    }
  },
  { label: '可切换状态', prop: 'xxf', cmp: 'switch' },
  { label: '时间', prop: 'xxg', config: { width: 168 } },
  {
    label: 'format',
    prop: 'xxh',
    formatter: (row, column, cellValue, index) => {
      return cellValue + row.xxi
    }
  },
  { label: '图片', prop: 'xxi', cmp: 'img' }
  // { label: '长文本', prop: 'xxj', config: { width: '1200' } }
]
// 公告列表
export const tableNotice = [
  {
    label: '',
    prop: 'isRead',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      if (!cellValue) return '<div style="width:8px;height:8px;border-radius:100%;background-color:#5cc88d;margin:0 auto;"></div>'
      return ''
    },
    config: { width: 30 }
  },
  { label: '公告标题', prop: 'messageTitle' },
  {
    label: '公告类型',
    prop: 'messageType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvNoticeType, cellValue)
    },
    config: { width: '130px' }
  },
  { label: '公告时间', prop: 'issueTime', config: { width: 168 } }
]
// 消息列表
export const tableMsg = [
  {
    label: '',
    prop: 'isRead',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      if (!cellValue) return '<div style="width:8px;height:8px;border-radius:100%;background-color:#5cc88d;margin:0 auto;"></div>'
      return ''
    },
    config: { width: 30 }
  },
  { label: '消息标题', prop: 'alertTitle' },
  { label: '消息时间', prop: 'alertTime', config: { width: 168 } }
]
// 创客列表
export const tableWorkPeople = [
  { label: '人员编号', prop: 'peopleNo' },
  { label: '创客姓名', prop: 'userName', config: { width: 170 } },
  { label: '证件号', prop: 'idCard', config: { width: 150 } },
  // {
  //     label: '手机号',
  //     prop: 'mobile'
  // },
  // {
  //     label: '地址',
  //     prop: 'address',
  //     config: { showOverflowTooltip: true }
  // },
  // {
  //     label: '电子邮箱',
  //     prop: 'email'
  // },
  {
    label: '签约状态',
    prop: 'isSign',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPeopleIsSign, cellValue)
    }
  },
  { label: '添加时间', prop: 'createTime', config: { width: 168 } },
  { label: '签约时间', prop: 'signTime', config: { width: 168 } }
]

// 创客校验
export const tableWorkPeopleCheck = [
  { label: '创客姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard', config: { width: 150 } },
  { label: '手机号', prop: 'mobile', config: { width: 120 } },
  { label: '银行卡号', prop: 'accountNumber' },
  { label: '支付宝', prop: 'alipayNumber' },
  { label: '地址', prop: 'address', config: { showOverflowTooltip: true } },
  { label: '电子邮箱', prop: 'email' },
  {
    label: '校验状态',
    prop: 'checkState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus([({ key: 1, label: '未校验' }, { key: 2, label: '已校验' })], cellValue)
    }
  },
  {
    label: '校验结果',
    prop: 'checkResult',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(
        [
          { key: 1, label: '通过' },
          { key: 2, label: '不通过', color: '#f56c6c' }
        ],
        cellValue
      )
    }
  },
  {
    label: '备注',
    prop: 'checkResultRemark',
    config: { showOverflowTooltip: true }
  }
]
// 创客列表任务
export const tableWorkPeopleTask = [
  { label: '任务编号', prop: 'taskNo', config: { width: 200 } },
  { label: '任务名称', prop: 'taskName' },
  { label: '累计结算次数', prop: 'accuSettleCount', config: { width: 120 } },
  { label: '累计获得金额', prop: 'accuSettleAmount', cmp: 'money' }
]
// 创客列表合同
export const tableWorkPeopleContract = [
  { label: '签约企业', prop: 'mainName' },
  { label: '签订日期', prop: 'signTime' },
  {
    label: '签约状态',
    prop: 'isSign',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPeopleIsSign, cellValue)
    }
  }
]
// 任务管理列表
export const tableTaskManage = [
  { label: '创建时间', prop: 'taskCreateTime', config: { width: 168 } },
  { label: '任务编号', prop: 'taskNumber' },
  {
    label: '任务名称',
    prop: 'taskName',
    config: { showOverflowTooltip: true }
  },
  {
    label: '任务类型',
    prop: 'taskType',
    config: { width: 110 },
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '结算任务'
      if (cellValue == 1) return '普通任务'
    }
  },
  { label: '绑定税目', prop: 'taxName' },
  {
    label: '任务内容',
    prop: 'taskDescribe',
    config: { showOverflowTooltip: true }
  },
  {
    label: '审核状态',
    prop: 'auditStatus',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvTaskAuditStatus, cellValue)
    }
  },
  {
    label: '任务状态',
    prop: 'taskState',
    config: { width: 120 }
    // formatter: (row, column, cellValue, index) => {
    //   if (row.auditStatus == 0) return '未开始'
    //   if (row.auditStatus == 2) return '已关闭'
    //   if (cellValue == '终止') return '终止'
    //   if (cellValue == '正常') return '进行中'
    //   return '未知'
    // }
  }
]
// 完税凭证
export const tableTaxVoucherAttach = [
  { label: '编号', prop: 'taxVoucherNo' },
  { label: '税源地名称', prop: 'supplierName' },
  { label: '完税名称', prop: 'taxVoucherName' },
  { label: '月份', prop: 'taxVoucherDateMonth' }
]
// 申请开票列表（按结算单）
export const tableWorkplanInfo = [
  { label: '发放完成日期', prop: 'grantFinishTime', config: { width: 168 } },
  { label: '结算单号', prop: 'planNo', config: { width: 130 } },
  { label: '开票税目', prop: 'taxName' },
  { label: '税源地', prop: 'supplierName' },
  { label: '成功笔数', prop: 'successCount' },
  { label: '可开票金额', prop: 'invoiceAmount', cmp: 'money', config: { minWidth: 105 } },
  {
    label: '结算金额',
    prop: 'settleAmount',
    cmp: 'money'
  },
  { label: '任务编号', prop: 'taskNo' },
  { label: '任务名称', prop: 'taskName' }
]
// 申请开票列表（按结算明细）
export const tableWorkDetailInfo = [
  { label: '打款完成日期', prop: 'payFinishTime', config: { width: 168 } },
  { label: '订单编号', prop: 'customerOrderNo' },
  { label: '结算单号', prop: 'planNo', config: { width: 130 } },
  { label: '开票税目', prop: 'taxName' },
  { label: '税源地', prop: 'supplierName' },
  { label: '可开票金额', prop: 'invoiceAmount', cmp: 'money', config: { minWidth: 105 } },
  {
    label: '结算金额',
    prop: 'settleAmount',
    cmp: 'money'
  },
  { label: '任务编号', prop: 'taskNo' },
  { label: '任务名称', prop: 'taskName' }
]
// 开票记录列表
export const tableBillInfo = [
  { label: '申请编号', prop: 'applyNumber', config: { width: 180 } },
  { label: '申请时间', prop: 'createTime', config: { width: 168 } },
  {
    label: '申请金额',
    prop: 'applyAmount',
    cmp: 'money'
  },
  { label: '合并笔数', slotName: 'planCount', cmp: 'slot' },
  {
    //发票申请类型：1为增值税专用发票、2为增值税普通发票
    label: '发票类型',
    prop: 'invoiceType',
    config: { width: 170 }
  },
  {
    label: '发票税目',
    prop: 'invoiceTax',
    config: { showOverflowTooltip: true }
  },
  {
    label: '税源地',
    prop: 'supplierName'
  },
  {
    label: '开票金额',
    prop: 'invoiceAmount',
    cmp: 'money'
  },
  { label: '备注', prop: 'remark', config: { showOverflowTooltip: true } },
  {
    //发票审核状态：1为待审核、2为审核已通过、3为审核未通过、4为已撤回
    label: '审核状态',
    prop: 'verifyState',
    config: { width: 120 }
  },
  {
    //开票状态：1为未开票、2为待开票、3为开票中、4为已开票、5为已撤回
    label: '开票状态',
    prop: 'billState',
    config: { width: 120 }
  },
  {
    //配送状态：1为未配送、2为已配送
    label: '配送状态',
    prop: 'distributionState',
    config: { width: 120 }
  }
]
// 邮寄地址列表
export const tableAddressInfo = [
  { label: '姓名', prop: 'name', config: { width: 120 } },
  { label: '电话', prop: 'phone', config: { width: 120 } },
  {
    label: '省市区',
    prop: 'province',
    formatter: (row, column, cellValue, index) => {
      return cellValue + ' ' + row.city + ' ' + (row.area || '')
    }
  },
  {
    label: '详细地址',
    prop: 'addressDefail',
    config: { showOverflowTooltip: true }
  },
  { label: '是否默认', prop: 'isDefault', config: { width: 100 } }
]
// 发票抬头列表
export const tableRiseInfo = [
  { label: '抬头名称', prop: 'riseName' },
  { label: '税号', prop: 'taxpayerNumber' },
  {
    label: '注册地址',
    prop: 'unitAddress',
    config: { showOverflowTooltip: true }
  },
  { label: '注册电话', prop: 'phone', config: { width: 120 } },
  { label: '开户银行', prop: 'openBank' },
  { label: '银行账户', prop: 'bankAccount' },
  { label: '是否默认', prop: 'isDefault', config: { width: 100 } }
]
// 结算单列表
export const tableFinalStatementHead = [
  { label: '结算单号', prop: 'planNo', config: { width: 130 } },
  {
    label: '所属任务',
    prop: 'taskName',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return `${cellValue}<br/>（${row.taskNo}）`
    },
    config: { width: 250 }
  },
  { label: '付款渠道', prop: 'paymentChannel', config: { width: 120 } },
  {
    label: '提交金额',
    prop: 'issueAmount',
    cmp: 'money'
  },
  { label: '人数', prop: 'totalPeopleNum' },
  {
    label: '综合服务费',
    prop: 'serviceFee',
    cmp: 'serviceFee',
    config: { minWidth: 120 }
  },
  {
    label: '审核状态',
    prop: 'auditState',
    cmp: 'html',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvWorkPlanAuditState, cellValue)
    }
  },
  {
    label: '发放状态',
    prop: 'grantState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPlanGrantState, cellValue)
    }
  },
  {
    label: '发放进度',
    prop: 'grantFinishCount',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return '<span style="color: #67c23a">' + (cellValue || 0) + '</span>/' + (row.grantRejectCount || 0) + '/' + (row.totalPeopleNum || 0)
    }
  },
  {
    slotName: 'paySuccessCount',
    cmp: 'slot'
  },
  {
    label: '成功金额',
    prop: 'successAmount',
    cmp: 'money'
  },
  {
    label: '结算金额',
    prop: 'settleAmount',
    cmp: 'money'
  }
]
export const tableFinalStatementFoot = [
  { label: '支付金额', prop: 'balanceAmount', cmp: 'money' },
  { label: '创建时间', prop: 'createTime', config: { width: 168 } }
]
// 结算单校验
export const tableWorkPlanCheck = [
  { label: '姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard', config: { width: 180 } },
  { label: '手机号', prop: 'mobile', config: { width: 120 } },
  { label: '下发类型', prop: 'grantType' },
  { label: '账号名', prop: 'accountName' },
  { label: '账号', prop: 'accountNumber', config: { width: 180 } },
  {
    label: '税后金额',
    prop: 'afterTaxAmount',
    cmp: 'money'
  },
  // {
  //   label: '服务费',
  //   prop: 'serviceFee',
  //   cmp: 'money'
  // },
  // {
  //   label: '差额服务费',
  //   prop: 'serviceDiffFee',
  //   cmp: 'money'
  // },
  // {
  //   label: '校验状态',
  //   prop: 'checkState',
  //   formatter: (row, column, cellValue, index) => {
  //     return formatStatus([({ key: 1, label: '未校验' }, { key: 2, label: '已校验' })], cellValue)
  //   }
  // },
  { label: '备注', prop: 'remark', config: { showOverflowTooltip: true } },
  {
    label: '校验结果',
    prop: 'checkResult',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(
        [
          { key: 1, label: '通过' },
          { key: 2, label: '不通过', color: '#f56c6c' }
        ],
        cellValue
      )
    }
  },
  {
    label: '失败原因',
    prop: 'checkResultRemark',
    config: { showOverflowTooltip: true }
  }
]

// 结算审核里面的明细
export const tableWorkPlanWorkDetailHead = [
  { label: '订单编号', prop: 'customerOrderNo', config: { width: 170 } },
  { label: '姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard', config: { width: 150 } },
  { label: '手机号', prop: 'mobile', config: { width: 120 } },
  {
    label: '签约状态',
    prop: 'isSign',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPeopleIsSign, cellValue)
    }
  },
  { label: '下发类型', prop: 'grantType' },
  { label: '账号名', prop: 'accountName' },
  { label: '账号', prop: 'accountNumber', config: { width: 170 } },
  {
    label: '税后金额',
    prop: 'afterTaxAmount',
    cmp: 'money'
  },
  {
    label: '服务费',
    prop: 'serviceFee',
    cmp: 'money'
  },
  {
    label: '差额服务费',
    prop: 'serviceDiffFee',
    cmp: 'money',
    config: { minWidth: 105 }
  },
  {
    label: '订单金额',
    prop: 'orderAmount',
    cmp: 'money'
  }
]
export const tableWorkPlanWorkDetailFoot = [
  {
    label: '支付金额',
    prop: 'balanceAmount',
    cmp: 'money'
  },
  {
    label: '验证状态',
    prop: 'verifyCheck',
    cmp: 'tooltipList',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未验证' },
          { key: 1, label: '已验证' }
        ],
        cellValue
      )
    },
    toolTipFee: [
      {
        label: '验证结果',
        prop: 'verifyResult',
        formatter: (row, column, value, index) => {
          return formatStatus(
            [
              { key: 0, label: '不通过' },
              { key: 1, label: '通过' }
            ],
            value
          )
        }
      },
      { label: '原因', prop: 'verifyDesc' }
    ]
  },
  {
    label: '打款状态',
    prop: 'payState',
    cmp: 'html',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColorHasIcon(kvWorkPlanPayState, cellValue)
    }
  },
  {
    label: '失败原因',
    prop: 'failCause',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return cellValue ? `<span color="#f56c6c">${cellValue}</span>` : '--'
    }
  },
  { label: '备注', prop: 'remark', config: { showOverflowTooltip: true } },
  {
    label: '商户流水号',
    prop: 'shopNo',
    config: { minWidth: 105 },
    formatter: (row, column, cellValue, index) => {
      return cellValue || '--'
    }
  }
]

// 结算明细列表
export const tableBillingDetails = [
  { label: '订单编号', prop: 'customerOrderNo', config: { width: 120 } },
  { label: '结算单编号', prop: 'planNo', config: { width: 130 } },
  {
    label: '所属任务',
    prop: 'taskName',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return `${cellValue}<br/>（${row.taskNo}）`
    },
    config: { width: 250 }
  },
  { label: '姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard', config: { width: 150 } },
  { label: '手机号', prop: 'mobile', config: { width: 120 } },
  {
    label: '审核状态',
    prop: 'auditState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPlanAuditState, cellValue)
    }
  },
  {
    label: '验证状态',
    prop: 'verifyCheck',
    cmp: 'tooltipList',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未验证' },
          { key: 1, label: '已验证' }
        ],
        cellValue
      )
    },
    toolTipFee: [
      {
        label: '验证结果',
        prop: 'verifyResult',
        formatter: (row, column, value, index) => {
          return formatStatus(
            [
              { key: 0, label: '不通过' },
              { key: 1, label: '通过' }
            ],
            value
          )
        }
      },
      { label: '原因', prop: 'verifyDesc' }
    ]
  },
  {
    label: '打款状态',
    prop: 'payState',
    cmp: 'html',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColorHasIcon(kvWorkPlanPayState, cellValue)
    },
    config: { width: 120 }
  },
  {
    label: '签约状态',
    prop: 'isSign',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPeopleIsSign, cellValue)
    }
  },
  { label: '下发类型', prop: 'grantType' },
  {
    label: '税后金额',
    prop: 'afterTaxAmount',
    cmp: 'money'
  },
  {
    label: '服务费',
    prop: 'serviceFee',
    cmp: 'money'
  },
  {
    label: '差额服务费',
    prop: 'serviceDiffFee',
    cmp: 'money',
    config: { width: 120 }
  },
  {
    label: '订单金额',
    prop: 'orderAmount',
    cmp: 'money'
  },

  {
    label: '是否退汇',
    prop: 'isReturn',
    config: { width: 100 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvIsReturn, cellValue)
    }
  }
]
// 结算明细列表
export const tableWithdrawalRecord = [
  { label: '申请时间', prop: 'withdrawTime', config: { width: 168 } },
  { label: '提现单号', prop: 'withdrawNo', config: { width: 190 } },
  {
    label: '提现金额(元)',
    prop: 'withdrawAmount',
    cmp: 'money',
    config: { minWidth: 115 }
  },
  { label: '收款账号', prop: 'withdrawAccountNumber', config: { width: 150 } },
  {
    label: '开户行',
    prop: 'withdrawAccountBank'
  },
  { label: '备注', prop: 'remark' },
  {
    label: '状态',
    prop: 'withdrawState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWithdrawState, cellValue)
    }
  },
  { label: '失败原因', prop: 'withdrawDesc' }
]

// 充值流水列表
export const tableRechargeRecord = [
  { label: '打款时间', prop: 'rechargeTime', config: { width: 168 } },
  { label: '流水号', prop: 'rechargeNo' },
  { label: '打款账号', prop: 'payAccountNumber' },
  { label: '打款户名', prop: 'payAccountName' },
  {
    label: '类型',
    prop: 'rechargeType',
    config: { width: 110 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvRechargeType, cellValue)
    }
  },
  {
    slotName: 'rechargeAmount',
    cmp: 'slot'
  },
  {
    label: '充值状态',
    prop: 'rechargeState',
    cmp: 'html',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor([...kvRechargeState, { key: 5, label: '上账中' }], cellValue)
    }
  },
  { label: '收款账号', prop: 'rechargeAccountNumber' },
  { label: '收款户名', prop: 'rechargeAccountName' },
  // { cmp: 'money', label: '充值后余额', prop: 'totalAfterRecharge' },
  { label: '备注', prop: 'remarks' }
]

// 支出流水列表
export const tableDisburseRecord = [
  { label: '打款时间', prop: 'disburseTime', config: { width: 168 } },
  {
    label: '打款状态',
    prop: 'grantState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvDisburseGrantState, cellValue)
    }
  },
  {
    label: '支付方式',
    prop: 'grantType'
    // formatter: (row, column, cellValue, index) => {
    //   return formatStatus(kvDisburseGrantType, cellValue)
    // }
  },
  {
    slotName: 'afterTaxAmount',
    cmp: 'slot'
  },

  { label: '收款人', prop: 'accountName' },
  { label: '收款账户', prop: 'accountNumber' },
  { label: '所属任务', prop: 'taskName' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '订单号', prop: 'disburseNo' }
]

// 业务确认单列表
export const businessBills = [
  // { prop: 'mainName', label: '企业名称' },
  { prop: 'dateMonth', label: '当前月份' },
  { prop: 'grantAmount', label: '发放金额', cmp: 'money' },
  { prop: 'supplierName', label: '税源地' },
  {
    prop: 'uploadState',
    label: '上传状态',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvAuditStatus, cellValue)
    }
  },
  { prop: 'uploadTime', label: '上传时间', config: { width: 168 } },
  {
    prop: 'auditState',
    label: '审核状态',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvSearchAuditStatus, cellValue)
    }
  }
]

// 交付物管理列表
export const regulateList = [
  { prop: 'userName', config: { width: 100 }, label: '创客姓名' },
  { prop: 'idCard', config: { width: 120 }, label: '身份证号', config: { width: 150 } },
  { prop: 'currentMouth', config: { width: 100 }, label: '当前月份' },
  { label: '发放金额', config: { width: 100 }, prop: 'grantAmount', cmp: 'money' },
  // { prop: 'beEnterprise', label: '所属企业' },
  { prop: 'taskNo', label: '任务编号' },
  { prop: 'taskName', label: '任务名称' },
  {
    label: '任务类型',
    prop: 'taskType',
    config: { width: 110 },
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '结算任务'
      if (cellValue == 1) return '普通任务'
    }
  },
  {
    prop: 'deliverableUploadStatus',
    label: '交付物状态',
    config: { width: 150 },
    formatter: (row, column, cellValue, index) => {
      let state = formatStatus(
        [
          { key: 0, label: '待上传' },
          { key: 1, label: '已上传' }
        ],
        cellValue
      )
      let auditState = formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核中' },
          { key: 2, label: '审核通过' },
          { key: 3, label: '已退回' }
        ],
        row.deliverableAuditStatus
      )
      return state + ' ' + auditState
    }
  },
  {
    prop: 'acceptanceUploadStatus',
    label: '成果验收单状态',
    config: { width: 150 },
    formatter: (row, column, cellValue, index) => {
      let state = formatStatus(
        [
          { key: 0, label: '待上传' },
          { key: 1, label: '已上传' }
        ],
        cellValue
      )
      let auditState = formatStatus(
        [
          { key: 0, label: '待审核' },
          { key: 1, label: '审核中' },
          { key: 2, label: '审核通过' },
          { key: 3, label: '已退回' }
        ],
        row.acceptanceAuditStatus
      )

      return state + ' ' + auditState
    }
  }
]

// 黑名单列表
export const labelBlacklist = [
  { prop: 'userName', label: '姓名', config: { width: 120 } },
  { prop: 'idCard', label: '证件号', config: { width: 150 } },
  { prop: 'mobile', label: '手机号', config: { width: 120 } },
  { prop: 'remark', label: '备注', config: { showOverflowTooltip: true } },
  { prop: 'createTime', label: '创建时间', config: { width: 168 } }
]

// 白名单列表
export const labelWhitelist = [
  { prop: 'userName', label: '姓名' },
  { prop: 'idCard', label: '证件号', config: { width: 150 } },
  { prop: 'mobile', label: '手机号', config: { width: 120 } },
  { prop: 'addPeople', label: '添加人' },
  { prop: 'createTime', label: '添加时间', config: { width: 168 } },
  { prop: 'auditState', label: '状态', config: { width: 120 } },
  { prop: 'refuse', label: '审核意见', config: { showOverflowTooltip: true } }
]

// 子账号列表
export const tableSubMerchant = [
  { prop: 'userName', label: '账号名称' },
  { prop: 'loginName', label: '登录账号' },
  { prop: 'mobile', label: '手机号', config: { width: 120 } },
  { prop: 'roleName', label: '角色' },
  {
    prop: 'isEnable',
    label: '状态',
    config: { width: 120 },
    formatter: (row, column, cellValue) => {
      if (cellValue == 1) {
        return '禁用'
      }
      return '启用'
    }
  }
]
// 我的协议列表
export const tableMyAgreement = [
  {
    prop: 'templateType',
    label: '类型',
    config: { width: 110 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(agreementState, cellValue)
    }
  },
  {
    prop: 'signState',
    label: '签约状态',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(signState, cellValue)
    }
  },
  {
    prop: 'signType',
    label: '签约方式',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(signMethodState, cellValue)
    }
  },
  { prop: 'supplierMainName', label: '签约企业名称', config: { minWidth: 115 } },
  { prop: 'contractTemplateName', label: '协议名称' },
  {
    prop: 'effectiveStatus',
    label: '生效状态',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(effectState, cellValue)
    }
  },
  { prop: 'signTime', label: '签订时间', config: { width: 168 } }
]
// 交付物审核
export const tableDeliverablesCheck = [
  {
    prop: 'userName',
    label: '创客姓名'
  },
  {
    prop: 'mobile',
    label: '手机号',
    config: { width: 120 }
  },
  {
    prop: 'taskName',
    label: '任务名称'
  },
  { prop: 'taskNo', label: '任务编号' },
  { prop: 'createTime', label: '提交时间', config: { width: 168 } },
  {
    prop: 'auditState',
    label: '状态',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvAuditState, cellValue)
    }
  }
]
// 接单列表
export const tableOrderReceiving = [
  {
    prop: 'receiveTime',
    label: '申请时间',
    config: { width: 168 }
  },
  {
    prop: 'peopleName',
    label: '创客姓名'
  },
  {
    prop: 'peopleMobile',
    label: '手机号',
    config: { width: 120 }
  },
  { prop: 'taskNo', label: '任务编号' },
  { prop: 'taskName', label: '任务名称' },
  {
    prop: 'type',
    label: '任务类型',
    config: { width: 110 },
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 0) return '结算任务'
      if (cellValue == 1) return '普通任务'
    }
  },
  {
    prop: 'state',
    label: '状态',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvState, cellValue)
    }
  }
]
// 接单内部列表
export const tableWorkDetailPeople = [
  {
    prop: 'receiveTime',
    label: '申请时间',
    config: { width: 168 }
  },
  {
    prop: 'peopleName',
    label: '创客姓名'
  },
  {
    prop: 'peopleMobile',
    label: '手机号',
    config: { width: 120 }
  }
]
// 抵扣金明细
export const tableDiscountDetail = [
  {
    prop: 'serialNo',
    label: '业务流水号'
  },
  {
    prop: 'tradeTime',
    label: '交易时间',
    config: { width: 168 }
  },
  {
    prop: 'intoAmount',
    label: '转入',
    cmp: 'money'
  },
  {
    prop: 'turnOutAmount',
    label: '转出',
    cmp: 'money'
  },
  {
    prop: 'availableBalance',
    label: '抵扣金余额',
    cmp: 'money'
  },
  {
    prop: 'state',
    label: '状态',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      if (cellValue == 1) return '已入账'
      if (cellValue == 2) return '冻结中'
      if (cellValue == 3) return '已出账'
      if (cellValue == 4) return '已解冻'
    }
  }
]

// agreementState,signTypeState
