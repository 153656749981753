<template>
  <el-dialog title="修改密码" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <div v-if="show">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '130px' }" />
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formPassword } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: formPassword
    }
  },
  methods: {
    // 数据清空
    clear() {},
    open() {
      this.clear()
      this.show = true
    },
    // 提交
    async submit() {
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      const res = await this.$api.UpdateMerchantUserPwd(params).finally(() => {
        this.btnloadSubmit = false
      })
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
