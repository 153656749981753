<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetTaxPaymentReceiptsList" :searchList="searchList">
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('005000')" type="text" @click="$refs.Info.open(row)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Info ref="Info" />
  </div>
</template>

<script>
import { tableTaxVoucherAttach } from '@/configs/tables'
import { searchTaxVoucherAttach } from '@/configs/searches'
import Info from './info'
export default {
  components: { Info },
  data() {
    return {
      tableLabels: tableTaxVoucherAttach,
      searchList: searchTaxVoucherAttach
    }
  }
}
</script>
