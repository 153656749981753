<template>
  <div class="panel-home">
    <!-- 数据统计 -->
    <StatisticsPanel :list="cardItem" ref="StatisticsPanel"></StatisticsPanel>
    <div class="panel-box">
      <div class="pageTitle">{{ $route.meta.title }}</div>
      <TableView @get="getAmount" ref="vTable" :labels="tableLabels" apiName="GetFinanceFundPageList" :searchList="searchList">
        <!-- 按钮栏 -->
        <el-form-item slot="btnline-item" v-if="$hasAccess('007101')">
          <el-button type="primary" @click="download" :loading="btnloadDownload">
            <i class="main-btn-icon el-icon-self-download-2-line"></i>
            <span>导出</span>
          </el-button>
        </el-form-item>
        <template slot="fundAmount">
          <el-table-column label="收支金额(元)" minWidth="115">
            <template v-slot="{ row }">
              <span v-if="row.fundType == 10 || row.fundType == 40 || row.fundType == 60"
                >+<span v-format="'#,##0.00'">
                  {{Number(row.fundAmount) }}
                </span></span
              >
              <span v-else>
                -<span v-format="'#,##0.00'">{{ Number(row.fundAmount) }}</span></span
              >
            </template>
          </el-table-column>
        </template>
        <!-- 操作栏 -->
        <el-table-column slot="table-item" label="操作" width="80" fixed="right">
          <template v-slot="{ row }">
            <el-button v-if="$hasAccess('007100') && (row.fundType < 40 || row.fundType == 60)" type="text" @click="routeLs(row)">查看</el-button>
          </template>
        </el-table-column>
      </TableView>
    </div>
  </div>
</template>

<script>
import { formatStatus, kvFundType } from '@/configs/formatters'
import { searchAccountCapital } from '@/configs/searches'
import { downloadExcel } from '@/utils'
import StatisticsPanel from '@/components/statisticsPanel'
const tableSelfLabels = [
  { label: '入账时间', prop: 'fundTime', config: { width: 168 } },
  {
    label: '业务类型',
    prop: 'fundType',
    config: { width: 110 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvFundType, cellValue)
    }
  },
  {
    label: '业务订单',
    prop: 'fundNo'
  },
  {
    label: '账户号码',
    prop: 'accountNo'
  },
  {
    slotName: 'fundAmount',
    cmp: 'slot'
  },
  { label: '账户余额(元)', prop: 'accountBalance', cmp: 'money', config: { minWidth: 115 } }
]
export default {
  components: { StatisticsPanel },
  data() {
    return {
      btnloadDownload: false,
      searchList: searchAccountCapital,
      tableLabels: tableSelfLabels,
      cardItem: [
        { label: '充值', type: '+', value: 0, num: 0 },
        { label: '交易支出', type: '-', value: 0, num: 0 },
        { label: '提现', type: '-', value: 0, num: 0 }
        // { label: '订单退汇', type: '+', value: 0, num: 0 },
        // { label: '内部划入', type: '+', value: 0, num: 0 },
        // { label: '内部划出', type: '-', value: 0, num: 0 }
      ]
    }
  },
  computed: {},
  methods: {
    async getAmount(e) {
      let res = await this.$api.GetFinanceFundTypeSummary(e)
      if (res.list.length) {
        res.list.map((item) => {
          switch (item.type) {
            case 10:
              this.cardItem[0].value = item.amount || 0
              this.cardItem[0].num = item.count || 0
              break
            case 20:
              this.cardItem[1].value = item.amount || 0
              this.cardItem[1].num = item.count || 0
              break
            case 30:
              this.cardItem[2].value = item.amount || 0
              this.cardItem[2].num = item.count || 0
              break
            // case 40:
            //   this.cardItem[4].value = item.amount || 0
            //   this.cardItem[4].num = item.count || 0
            //   break
            // case 50:
            //   this.cardItem[5].value = item.amount || 0
            //   this.cardItem[5].num = item.count || 0
            //   break
            // case 60:
            //   this.cardItem[3].value = item.amount || 0
            //   this.cardItem[3].num = item.count || 0
            //   break
            default:
              break
          }
        })
      }
    },
    async download() {
      const params = this.$refs.vTable.getParams()
      this.btnloadDownload = true
      const res = await this.$api.ExportFinanceFundData(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, `资金流水`)
    },
    //查看
    routeLs(row) {
      switch (row.fundType) {
        case 10:
          this.$router.push(`/financeCenter/rechargeRecord?fundNo=${row.fundNo}`)
          break
        case 20:
          this.$router.push(`/financeCenter/disburseRecord?fundNo=${row.fundNo}`)
          break
        case 30:
          this.$router.push(`/financeCenter/withdrawalRecord?fundNo=${row.fundNo}`)
          break
        case 60:
          this.$router.push(`/financeCenter/returnLog?fundNo=${row.fundNo}`)
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ext {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  .title-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  .text {
    font-size: 26px;
    color: #333;
    font-weight: 600;
    margin-right: 10px;
  }
}
</style>
