<template>
  <el-dialog
    :title="fileType == 1 ? '上传交付物' : '上传成果验收单'"
    width="30%"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialogBox">
      <div v-if="fileType != 1" class="accpent">
        <div class="text">
          第一步：请下载<span @click="downLoadWd" class="result-text"
            >《成果验收单》</span
          >
        </div>
        <div class="text">第二步：填写任务完成情况，加盖企业公章后上传</div>
        <div class="text">
          支持jpg .jpeg .png、pdf，zip，rar，txt大小不超过5M
        </div>
      </div>
      <el-alert
        v-else
        title="请上传创客接单完成情况截图或者平台佣金结算依据截图"
        show-icon
        type="warning"
        :closable="false"
        style="margin-bottom: 18px; flex-shrink: 0"
      >
      </el-alert>
      <div style="display: flex" class="flex-box">
        <div class="text">
          上传{{ fileType == 1 ? "交付物" : "成果验收单" }}：
        </div>
        <MultipleUpload
          :modelValue="
            fileType == 1 ? detail.deliverableList : detail.acceptanceList
          "
          :config="config"
          @update="uploadMul"
          :fileSuffix="fileSuffix"
        ></MultipleUpload>
      </div>
    </div>
    <div slot="footer">
      <el-button plain @click="show = !show">取 消</el-button>
      <el-button @click="submit" type="primary" :loading="btnloadSubmit">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import MultipleUpload from "@/components/formView/multipleUpload.vue";
import { downloadDocx, downloadUrl } from "@/utils";
export default {
  components: { MultipleUpload },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fileType: -1,
      detail: {
        deliverableList: [],
        acceptanceList: [],
        deliverableId: "",
        beEnterprise: "",
        taskNo: "",
        taskName: "",
      },
      fileSuffix: ["jpg", "png", "jpeg", "pdf", "zip", "rar", "txt"],
      config: {
        disabled: false,
      },
    };
  },
  methods: {
    // 数据清空
    clear() {
      Object.assign(this.$data, this.$options.data());
    },
    // 打开弹窗
    open(obj) {
      this.show = true;
      this.fileType = obj.type;
      this.detail.deliverableId = obj.deliverableId;
      this.detail.beEnterprise = obj.beEnterprise;
      this.detail.taskNo = obj.taskNo;
      this.detail.taskName = obj.taskName;
    },
    uploadMul(val) {
      console.log(val);
      this.fileType == 1
        ? (this.detail.deliverableList = val)
        : (this.detail.acceptanceList = val);
    },
    async downLoadWd() {
      const result = await this.$api.DownloadAcceptanceNoteFile({
        id: this.detail.deliverableId,
        type: 2,
      });
      downloadDocx(result, "成果验收单");
    },
    // 提交操作
    // submit() {
    //   this.$emit("submit");
    //   this.show = false;
    // },
    async submit() {
      if (
        this.detail.deliverableList.length == 0 &&
        this.detail.acceptanceList.length == 0
      ) {
        this.$message.error("请上传文件");
        return false;
      }
      this.btnloadSubmit = true;
      if (this.fileType == 1) {
        this.detail.deliverableList.map((item) => {
          item.url = item.fileUrl;
          item.name = item.fileName;
        });
      } else {
        this.detail.acceptanceList.map((item) => {
          item.url = item.fileUrl;
          item.name = item.fileName;
        });
      }
      const result = await this.$api
        .UploadTaskDeliverable(this.detail)
        .finally(() => {
          this.btnloadSubmit = false;
        });
      if (result) {
        this.$message.success("上传成功");
        this.show = false;
        this.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogBox {
  .text {
    margin-top: 5px;
  }
  .accpent {
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 15px 0;
      color: #303133;
    }
    .result-text {
      color: #409eff;
      cursor: pointer;
    }
    .text {
      margin-bottom: 10px;
    }
  }
}
</style>
