<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '结算单详情查看'
    }"
  >
    <el-steps :active="active" align-center style="margin-bottom: 32px">
      <el-step :status="active >= 0 ? 'success' : ''" title="创建结算单" :description="detail.createTime || ''"></el-step>
      <el-step :status="active > 1 ? 'success' : ''" title="提交结算单" :description="detail.auditStartTime || ''"></el-step>
      <el-step :status="active > 2 ? 'success' : ''" title="审核结算单" :description="detail.auditFinishTime || ''"></el-step>
      <template v-if="detail.grantState != 5">
        <el-step :status="active > 3 ? 'success' : ''" title="确认发放" :description="detail.grantConfirmTime || ''"></el-step>
        <el-step :status="active > 4 ? 'success' : ''" title="发放完成" :description="detail.grantFinishTime || ''"></el-step
      ></template>
      <template v-if="detail.grantState == 5"> <el-step class="reject" icon="el-icon-self-close-line" title="拒绝发放"></el-step></template>
    </el-steps>
    <DetailCard title="结算单信息" :collapse="true" :desc="'【结算单号：' + detail.planNo + '】'">
      <DescView :descLabels="descLabels" :descDatas="detail">
        <template #auditState>
          <span v-html="formatStatusHasColor(kvWorkPlanAuditState, detail.auditState)"></span>
        </template>
        <template #grantState>
          <span v-html="formatStatusHasColor(kvWorkPlanGrantState, detail.grantState)"></span>
        </template>
        <template #grantProcess>
          <span style="color: #67c23a">{{ detail.grantFinishCount || 0 }}</span
          >/{{ detail.grantRejectCount || 0 }}/{{ detail.totalPeopleNum || 0 }}
        </template>
      </DescView>
    </DetailCard>
    <DetailCard title="结算单明细">
      <template #ext>
        <el-button class="btn-table-refresh" type="text" icon="el-icon-self-refresh-line" @click="$refs.vTable.refreshTable()">刷新</el-button>
      </template>
      <TableView
        ref="vTable"
        :hideTitle="true"
        title="结算明细"
        :labels="tableLabels"
        apiName="GetWorkDetailPageList"
        :searchList="searchList"
        :searchExt="{ PlanNo: id }"
      >
        <el-form-item slot="btnline-item" style="margin-top: -66px" v-if="isExistReceipt">
          <el-button plain @click="downZipReceipt">
            <i class="main-btn-icon el-icon-self-download-2-line"></i>
            <span>批量下载回单</span>
          </el-button>
        </el-form-item>
        <el-table-column slot="table-item" label="电子回单" width="100" fixed="right">
          <template v-slot="{ row }">
            <el-button v-if="row.receiptInfo" type="text" @click="downReceipt(row)">下载回单</el-button>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column slot="table-item" label="操作" width="120" fixed="right">
          <template v-slot="{ row }">
            <el-button type="text" @click="$refs.DialogInfo.open(row)">查看</el-button>
            <el-button type="text" class="el-button-error" v-if="row.auditState === 1" @click="delOne(row)">删除</el-button>
            <el-button
              type="text"
              class="el-button-error"
              v-if="detail.auditState === 3 && detail.grantState == 2 && row.payState == 1 && !autoGrant"
              @click="cancel(row)"
              >拒绝发放</el-button
            >
          </template>
        </el-table-column>
      </TableView>
    </DetailCard>
    <!-- 弹窗 -->
    <DialogInfo ref="DialogInfo"></DialogInfo>
  </DetailView>
</template>

<script>
import {
  formatStatus,
  formatStatusHasColor,
  formatStatusHasColorHasIcon,
  kvWorkPeopleIsSign,
  kvWorkPlanPayState,
  kvWorkPlanAuditState,
  kvWorkPlanGrantState
} from '@/configs/formatters'
import { tableWorkPlanWorkDetailHead, tableWorkPlanWorkDetailFoot } from '@/configs/tables'
import { searchWorkPlanWorkDetail } from '@/configs/searches'
import DialogInfo from '../detail/dialog'
import { downloadUrl } from '@/utils'

const descLabels = [
  { label: '审核状态', cmp: 'slot', slotName: 'auditState' },
  { label: '发放状态', cmp: 'slot', slotName: 'grantState' },
  { label: '所属任务', prop: 'taskName' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '提交金额', cmp: 'money', prop: 'issueAmount' },
  { label: '笔数', prop: 'totalPeopleNum', holder: '0' },
  { label: '服务费', cmp: 'money', prop: 'serviceFee' },
  { label: '差额服务费', cmp: 'money', prop: 'serviceDiffFee' },
  { label: '发放进度', cmp: 'slot', slotName: 'grantProcess' },
  { label: '成功金额', cmp: 'money', prop: 'successAmount' },
  { label: '失败金额', cmp: 'money', prop: 'failAmount' }
]
const tableSelfLabelsHead = [
  { label: '订单编号', prop: 'customerOrderNo', config: { width: 170 } },
  { label: '姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard', config: { width: 150 } },
  { label: '手机号', prop: 'mobile', config: { width: 120 } },
  {
    label: '签约状态',
    prop: 'isSign',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPeopleIsSign, cellValue)
    }
  },
  { label: '账号', prop: 'accountNumber', config: { width: 170 } },
  { label: '个税', prop: 'individualTax', cmp: 'money' },
  { label: '增值税', prop: 'addedTax', cmp: 'money' },
  { label: '税金及附加', prop: 'surchargesTax', cmp: 'money', config: { minWidth: 105 } },
  { label: '服务费', prop: 'serviceFee', cmp: 'money' },
  { label: '差额服务费', prop: 'serviceDiffFee', cmp: 'money', config: { minWidth: 105 } },
  { label: '提交金额', prop: 'issueAmount', cmp: 'money' },
  { label: '结算金额', prop: 'settlementAmount', cmp: 'money' },
  { label: '实发金额', prop: 'finalPayingAmount', cmp: 'money' }
]
const tableSelfLabelsFoot = [
  {
    label: '支付金额',
    prop: 'balanceAmount',
    cmp: 'money'
  },
  {
    label: '验证状态',
    prop: 'verifyCheck',
    cmp: 'tooltipList',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未验证' },
          { key: 1, label: '已验证' }
        ],
        cellValue
      )
    },
    toolTipFee: [
      {
        label: '验证结果',
        prop: 'verifyResult',
        formatter: (row, column, value, index) => {
          return formatStatus(
            [
              { key: 0, label: '不通过' },
              { key: 1, label: '通过' }
            ],
            value
          )
        }
      },
      { label: '原因', prop: 'verifyDesc' }
    ]
  },
  {
    label: '打款状态',
    prop: 'payState',
    cmp: 'html',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColorHasIcon(kvWorkPlanPayState, cellValue)
    }
  },
  {
    label: '失败原因',
    prop: 'failCause',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return cellValue ? `<span color="#f56c6c">${cellValue}</span>` : '--'
    }
  },
  { label: '创建时间', prop: 'createTime', config: { width: 168 } }
]
export default {
  components: { DialogInfo },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      searchList: searchWorkPlanWorkDetail,
      kvWorkPlanAuditState,
      kvWorkPlanGrantState,
      active: 0,
      isEnableMonthly: false,
      autoGrant: 0,
      isExistReceipt: false
    }
  },
  computed: {
    businessType() {
      return this.$store.state.user.businessType
    },
    tableLabels() {
      if (this.businessType == 2) {
        if (!this.isEnableMonthly) return [...tableSelfLabelsHead, ...tableSelfLabelsFoot]
        return [...tableSelfLabelsHead, { label: '服务费抵扣金', prop: 'deductionAmount', cmp: 'money' }, ...tableSelfLabelsFoot]
      }
      if (!this.isEnableMonthly) return [...tableWorkPlanWorkDetailHead, ...tableWorkPlanWorkDetailFoot]
      return [...tableWorkPlanWorkDetailHead, { label: '服务费抵扣金', prop: 'deductionAmount', cmp: 'money' }, ...tableWorkPlanWorkDetailFoot]
    },
    descLabels() {
      const aArray =
        this.businessType == 2
          ? [
              { label: '个税', cmp: 'money', prop: 'individualTax' },
              { label: '增值税', cmp: 'money', prop: 'addedTax' },
              { label: '税金及附加', cmp: 'money', prop: 'surchargesTax' }
            ]
          : []
      const bArray = this.isEnableMonthly ? [{ label: '服务费抵扣金', cmp: 'money', prop: 'deductionAmount' }] : []
      return [
        ...descLabels,
        ...aArray,
        { label: '结算金额', cmp: 'money', prop: 'settleAmount' },
        ...bArray,
        { label: '支付金额', cmp: 'money', prop: 'balanceAmount' }
      ]
    }
  },
  methods: {
    formatStatus,
    formatStatusHasColor,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj, isEnableMonthly, autoGrant) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.planNo
        this.isEnableMonthly = isEnableMonthly
        this.autoGrant = autoGrant
        this.detail = obj
        if (this.detail.grantState == 5) {
          this.active = 4
        } else {
          this.active = this.detail.grantFinishTime
            ? 5
            : this.detail.grantConfirmTime
            ? 4
            : this.detail.auditFinishTime
            ? 3
            : this.detail.auditStartTime
            ? 2
            : this.detail.createTime
            ? 1
            : 0
        }
        this.getIsExistReceiptByWorkPlan()
        this.show = true
        this.$nextTick(() => {
          this.$refs.vTable.refreshTable()
        })
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getIsExistReceiptByWorkPlan() {
      const res = await this.$api.IsExistReceiptByWorkPlan({ planNo: this.detail.planNo })
      this.isExistReceipt = res
    },
    // 删除
    async delOne(row) {
      await this.$confirm(`确认删除此条数据吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.WorkDetailDelete({ id: row.id })
      this.$message.success('此条数据已删除')
      this.$refs.vTable.refreshTable()
    },
    // 拒绝发放
    async cancel(row) {
      await this.$confirm(`确认拒绝发放此条数据吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.WorkDetailCancel({ workDetailId: row.id })
      this.$message.success('此条数据已拒绝发放')
      this.$refs.vTable.refreshTable()
    },
    // 下载回执
    async downReceipt(row) {
      downloadUrl(row.receiptInfo)
    },
    // 批量下载回执
    downZipReceipt() {
      this.$api.BuildReceiptZipByWorkPlan({ planNo: this.detail.planNo }).then(() => {
        this.$confirm('回单文件生成中，请在“财务中心-批量下载回单”页面，下载文件', '提示', {
          confirmButtonText: '去下载',
          showCancelButton: false,
          type: 'success'
        }).then(() => {
          this.$router.push(`/financeCenter/receiptDownload`)
        })
      })
      // .catch(() => {
      //   this.$message.error('电子回单数据暂未生成，生成时间需2天左右，请稍后下载！')
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
.reject {
  /deep/ .el-icon-self-close-line {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #f53f3fff;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/ .el-step__title {
  color: #1d2129;
}
/deep/ .el-step__description {
  color: #1d2129;
}
/deep/ .el-step__head.is-wait {
  color: #4e5969;
  border-color: #c0c4cc;
}
</style>
