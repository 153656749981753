import axios from 'axios'
import store from '@/store'
import { MessageBox, Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import { isObject } from '@/utils'

// 请求基础
const base = {
  baseURL: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 15000
}

// 请求头拦截
const requestSuccess = (config) => {
  const token = getToken()

  // 处理token
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  return config
}

// 标准请求
const Request = axios.create(base)
// 请求处理
Request.interceptors.request.use(
  (config) => {
    return requestSuccess(config)
  },
  (error) => {
    console.error('======>err:' + error)
    return Promise.reject(error)
  }
)
// 回调处理
Request.interceptors.response.use(
  (response) => {
    const res = response.data
    if (!isObject(res)) return res
    if (res.code !== '0') {
      if (res.messageType == 3 && res.code == -1) {
        // to re-login
        MessageLogout()
      } else {
        Message({
          message: res.message || '未知错误，请联系开发者',
          type: 'error',
          duration: 5000
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      const data = res.data
      return data
    }
  },
  (error) => {
    if (error.response.status == 401) {
      // to re-login
      MessageLogout()
    } else {
      console.error('======>err:' + error)
      Message({
        message: error.message || '未知错误，请联系开发者',
        type: 'error',
        duration: 5000
      })
    }
    return Promise.reject(error)
  }
)

// 401登出弹窗
const MessageLogout = () => {
  MessageBox.confirm('您的账号已退出，请重新登录', '登出提示', {
    confirmButtonText: '重新登录',
    cancelButtonText: '取 消',
    type: 'warning'
  }).then(() => {
    store.dispatch('user/clearCache').then(() => {
      location.reload()
    })
  })
}

export { Request, MessageLogout }
