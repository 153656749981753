<template>
  <el-dialog title="申请开票" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" fullscreen>
    <div>
      <template v-if="step == 1">
        <div>
          <p>已选择：合并笔数：{{ list.length || 0 }} <span style="margin-right: 15px"></span>累计支付金额：{{ totalMoney.toFixed(2) || 0 }}</p>
          <el-form :inline="true" :model="searchForm" label-suffix="：" style="text-align: right" @submit.native.prevent>
            <el-form-item>
              <el-input v-model="searchForm.searchTxt" placeholder="请输入结算单号、任务编号进行搜索" clearable style="width: 300px"></el-input>
            </el-form-item>
            <!-- 搜索按钮 -->
            <el-form-item>
              <el-button plain icon="el-icon-self-Reset" @click="reset">重置</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-self-xiaosuo" @click="search">搜索</el-button>
            </el-form-item>
          </el-form>
          <div>
              <el-table :data="list" height="100%">
                <el-table-column type="index" width="50" label="#"> </el-table-column>
                <el-table-column v-if="settlementMakeInvoice == 1" prop="customerOrderNo" label="订单编号"> </el-table-column>
                <el-table-column prop="planNo" label="结算单号"> </el-table-column>
                <el-table-column prop="settleAmount" label="支付金额"
                  ><span slot-scope="{ row }" v-format="'#,##0.00'">{{ row.settleAmount || 0 }}</span></el-table-column
                >
                <el-table-column prop="taskNo" label="任务编号"> </el-table-column>
                <el-table-column prop="taskName" label="任务名称"> </el-table-column>
                <el-table-column>
                  <template slot-scope="{ row, $index }">
                    <el-link type="danger" icon="el-icon-close" @click="del(row, $index)"></el-link>
                  </template>
                </el-table-column>
              </el-table>
          </div>
        </div>
      </template>
      <template v-if="step == 2">
        <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }" :slotIdxList="[1, 2]" :initData="{ applyState: 1 }">
          <template #form-items="{ idx, form }">
            <el-col>
              <template v-if="idx === 1">
                <div class="el-divider el-divider--horizontal">
                  <div class="el-divider__text is-left">发票抬头</div>
                  <div class="el-divider__text is-right">
                    <el-link type="primary" @click="selRise">选择</el-link>
                  </div>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="抬头名称" required>
                      <el-input :value="riseForm.riseName" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="纳税人识别号" required>
                      <el-input :value="riseForm.taxpayerNumber" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="单位地址" required>
                      <el-input :value="riseForm.unitAddress" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="电话号码" required>
                      <el-input :value="riseForm.phone" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开户银行" required>
                      <el-input :value="riseForm.openBank" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="银行账户" required>
                      <el-input :value="riseForm.bankAccount" disabled></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider content-position="left">发票内容</el-divider>
                <el-form-item label="开票税目" prop="taxItemsId" :rules="[{ required: true, message: '必填', trigger: 'blur' }]">
                  <span>{{ taxName || '暂无' }}</span>
                  <!-- <el-cascader
                    :props="taxProps"
                    :options="taxItems"
                    v-model="form.taxItemsId"
                    separator="*"
                    disabled
                  ></el-cascader> -->
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="申请金额" required
                      ><span v-format="'#,##0.00'">{{ totalMoney || 0 }}</span
                      >元</el-form-item
                    >
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="合并笔数" required>{{ list.length || 0 }}</el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="退汇金额" required
                      ><span v-format="'#,##0.00'">{{ totalReturn || 0 }}</span
                      >元  <el-button type="text" style="padding:0" @click="$refs.Returns.open(returnList)">明细</el-button></el-form-item
                    >
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开票金额" required
                      ><span v-format="'#,##0.00'">{{ (totalMoney || 0) - (totalReturn || 0) }}</span
                      >元</el-form-item
                    >
                  </el-col>
                </el-row>
              </template>
              <template v-if="idx === 2">
                <div class="el-divider el-divider--horizontal">
                  <div class="el-divider__text is-left">收件人信息</div>
                  <div class="el-divider__text is-right">
                    <el-link type="primary" @click="selAddress">选择</el-link>
                  </div>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="收件人姓名" required>
                      <el-input :value="addressForm.name" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="收件人电话" required>
                      <el-input :value="addressForm.phone" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="选择区域" required>
                      <el-input
                        :value="(addressForm.province || '') + ' ' + (addressForm.city || '') + ' ' + (addressForm.area || '')"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="详细地址" required>
                      <el-input :value="addressForm.addressDefail" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="邮箱"
                      prop="inboxEmail"
                      :rules="form.applyState == 3 || form.applyState == 4 ? [{ required: true, message: '必填', trigger: 'blur' }] : []"
                    >
                      <el-input v-model="form.inboxEmail"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </el-col>
          </template>
        </FormView>
      </template>
    </div>
    <span slot="footer">
      <template v-if="step == 1">
        <el-button plain @click="show = false">取 消</el-button>
        <el-button type="primary" @click="next()">下一步</el-button>
      </template>
      <template v-if="step == 2">
        <el-button plain @click="step = 1">上一步</el-button>
        <el-button type="primary" @click="submit()" :loading="btnloadSubmit">提 交</el-button>
      </template>
    </span>
    <DialogAddress ref="DialogAddress" @seled="addressSeled" />
    <DialogRise ref="DialogRise" @seled="riseSeled" />
    <returns ref="Returns"></returns>
  </el-dialog>
</template>

<script>
import { kvInvoiceType } from '@/configs/formatters'
import { formBillApply } from '@/configs/forms'
import { deepClone } from '@/utils'
import DialogAddress from './address'
import DialogRise from './rise'
import Returns from '../billInfo/returns'
export default {
  components: { DialogAddress, DialogRise, Returns },
  data() {
    return {
      settlementMakeInvoice: null,
      btnloadSubmit: false,
      show: false,
      step: 1,
      oriList: [],
      list: [],
      searchForm: {},
      formLabels: formBillApply,
      baseForm: {},
      riseForm: {},
      addressForm: {},
      taxId: '',
      taxName: '',
      supplierId: '',
      taxProps: {
        value: 'taxId',
        label: 'taxName',
        emitPath: false
        // lazy: true,
        // lazyLoad: async (node, resolve) => {
        //   const { level, value } = node;
        //   if (level == 1) {
        //     const list = await this.getTaxItems(value);
        //     const nodes = list.map((item) => {
        //       return Object.assign({ leaf: true }, item);
        //     });
        //     resolve(nodes);
        //   } else {
        //     resolve();
        //   }
        // },
      },
      taxItems: [],
      returnList: []
    }
  },
  computed: {
    totalMoney() {
      return this.list.reduce((a, b) => {
        return a + parseFloat(b.invoiceAmount)
      }, 0)
    },
    totalReturn() {
      return this.returnList.reduce((a, b) => {
        return a + (parseFloat(b.afterTaxAmount||0) + parseFloat(b.serviceFee||0) + parseFloat(b.serviceDiffFee||0) - parseFloat(b.deductionAmount||0))
      }, 0)
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.settlementMakeInvoice = null
      this.list = []
      this.searchForm = {}
      this.step = 1
      this.baseForm = {}
      this.riseForm = {}
      this.addressForm = {}
      this.returnList = []
    },
    async open(settlementMakeInvoice, list, all, params) {
      this.clear()
      this.settlementMakeInvoice = settlementMakeInvoice
      if (all) {
        let APIOPTS = this.settlementMakeInvoice == 1 ? this.$api.GetInvoiceWorkDetailAllList : this.$api.GetAllWorkplanInfo
        // 全开
        const res = await APIOPTS(params)

        this.oriList = res.list
        let flag = this.oriList.every((item) => {
          return item.supplierName == this.oriList[0].supplierName
        })

        if (!flag) {
          this.$message.error('请选择在同一主体结算单')
          return false
        }
        // 判断税目一致
        const taxList = this.oriList.map((item) => {
          return item.taxId
        })

        const newTaxList = new Set(taxList)

        if (Array.from(newTaxList).length > 1) return this.$message.warning('请选择相同的开票税目合并开票')
        // 判断水源地一致
        const supList = this.oriList.map((item) => {
          return item.supplierId
        })

        const newSupList = new Set(supList)

        if (Array.from(newSupList).length > 1) return this.$message.warning('请选择相同的税源地合并开票')
        this.show = true
        this.search()
        this.taxName = this.oriList[0].taxName
        this.taxId = this.oriList[0].taxId
        this.supplierId = this.oriList[0].supplierId
      } else if (list && list.length) {
        // 选择开
        this.oriList = deepClone(list)
        this.show = true
        this.search()
        this.taxName = this.oriList[0].taxName
        this.taxId = this.oriList[0].taxId
        this.supplierId = this.oriList[0].supplierId
      } else {
        this.$message.error('无效的信息')
      }
    },
    del(item, idx) {
      this.oriList.splice(
        this.oriList.findIndex((it) => it.id == item.id),
        1
      )
      this.list.splice(idx, 1)
    },
    search() {
      if (this.searchForm.searchTxt) {
        const searchTxt = this.searchForm.searchTxt.toLowerCase()
        this.list = this.oriList.filter((item) => {
          return item.planNo.toLowerCase().indexOf(searchTxt) >= 0 || item.taskNo.toLowerCase().indexOf(searchTxt) >= 0
        })
      } else {
        this.list = deepClone(this.oriList)
      }
    },
    reset() {
      this.searchForm = {}
      this.search()
    },
    // 获取商户未开票的退汇订单
    async getReturnList() {
      const res = await this.$api.GetReturnWorkDetailNotBill({supplierId:this.supplierId})
      this.returnList = res.list || []
    },
    async getTaxItems(taxId, label) {
      if (!taxId) {
        const res = await this.$api.GetTaxItems()
        this.taxItems = res.list
      } else {
        const stairOne = await this.$api.GetTaxItems()
        if (stairOne) {
          const stairOneId = stairOne.list.filter((item) => item.taxName == label)[0].taxId
          const result = await this.$api.GetTaxItems({ taxId: stairOneId })
          const children = result.list.map((item) => {
            if (item.taxId == taxId) {
              return Object.assign({ leaf: true }, item)
            } else {
              return item
            }
          })
          this.taxItems = stairOne.list.map((item) => {
            if (item.taxId == stairOneId) {
              return Object.assign({ children }, item)
            } else {
              return item
            }
          })
          this.taxId = taxId
        }
        // return res.list;
      }
    },
    // 获取开票类型列表
    async getSupplierInvoiceTypeList() {
      const res = await this.$api.GetSupplierInvoiceTypeList({ supplierId: this.supplierId })
      const list = kvInvoiceType.filter((item) => {
        return !!res.list.find((it) => it == item.key)
      })
      console.log(list)
      // 处理数据结构并赋值
      this.formLabels.find((item) => item.prop == 'applyState').options = list || []
    },
    next() {
      this.getSupplierInvoiceTypeList()
      if (!this.oriList.length) return this.$message.error('没有申请开票的内容')
      this.step = 2
      this.getReturnList()
      if (!this.riseForm.riseId || !this.baseForm.riseId) {
        this.getDefaultRise()
      }
      if (!this.addressForm.addressId || !this.baseForm.addressId) {
        this.getDefaultAddress()
      }

      if (this.taxId) {
        this.$nextTick(() => {
          this.$refs.vForm.upData({ taxItemsId: this.taxId })
        })
      }
    },
    async getDefaultAddress() {
      const res = await this.$api.GetAddressInfo({ isDefault: true })
      if (!res.list.length) return false
      this.addressForm = res.list[0]
      this.baseForm.addressId = res.list[0].addressId
    },
    async getDefaultRise() {
      const res = await this.$api.GetRiseInfo({ isDefault: true })
      if (!res.list.length) return false
      this.riseForm = res.list[0]
      this.baseForm.riseId = res.list[0].riseId
    },
    selAddress() {
      this.$refs.DialogAddress.open()
    },
    addressSeled(val) {
      this.addressForm = val
      this.baseForm.addressId = val.addressId
    },
    selRise() {
      this.$refs.DialogRise.open()
    },
    riseSeled(val) {
      this.riseForm = val
      this.baseForm.riseId = val.riseId
    },
    // 提交
    async submit() {
      const params = await this.$refs.vForm.getForm()
      if (!this.baseForm.addressId) return this.$message.error('请选择收件人信息')
      if (!this.baseForm.riseId) return this.$message.error('请选择发票抬头')
      const arr = this.oriList.map((item) => {
        return item.id
      })

      let obj = this.settlementMakeInvoice == 1 ? { workDetailIds: arr } : this.settlementMakeInvoice == 0 ? { workplanIds: arr } : {}
      
      this.btnloadSubmit = true
      const res = await this.$api.AddBillInfo(Object.assign(obj, params, this.baseForm)).finally(() => {
        this.btnloadSubmit = false
      })
      this.$emit('submit')
      this.show = false
      this.$message.success('发票申请提交成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.select-btn {
  margin-left: 50px;
}
</style>
