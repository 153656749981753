<template>
  <div v-if="!hidden(item)">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild.path)"
        :noto="onlyOneChild.showInSide"
      >
        <el-menu-item
          v-if="onlyOneChild.meta"
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
          :id="onlyOneChild.name"
        >
          <item
            :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            :title="onlyOneChild.meta.title"
          />
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)">
      <!-- <div class="menu_line"></div> -->
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
        />
      </template>
      <side-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :id="child.name"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from "path";
import AppLink from "./link";
import Item from "./item";
import { isExternal } from "@/utils/validate";
import { hasAccess } from "@/utils";
export default {
  name: "SideItem",
  components: { AppLink, Item },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  computed: {
    activeName() {
      const route = this.$route;
      const { name } = route;
      return name;
    },
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (this.hidden(item)) {
          return false;
        } else {
          this.onlyOneChild = item;
          return true;
        }
      });
      if (showingChildren.length === 1) {
        return true;
      }
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    hidden(item) {
      // 寻找子节点有没有显示的
      if (
        !item.children ||
        item.children.find((it) => {
          return !it.hideInSide && !(it.meta && !hasAccess(it.meta.ruleName));
        })
      ) {
        if (item.meta && !hasAccess(item.meta.ruleName)) return true;
        if (item.showInSide) return item.name != this.activeName;
        return item.hideInSide;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-submenu__title{
  display: flex;
  align-items: center;
  div{
    position: relative;
  }
}
::v-deep .el-menu-item {
  display: flex;
    align-items: center;
  div {
    position: relative;
  }
  &:hover {
    i {
      &:first-child {
        // color: #23D38A;
        position: relative;
      }
    }
  }
  &::before {
    content: "";
    display: block;
    border-radius: 8px;
    width: calc(100% - 8px);
    height: 100%;
    position: absolute;
    left: 8px;
  }
  &:hover {
    background: transparent;
    &::before {
      background-color: #f2f3f5;
    }
  }
  &:focus{
    background-color: transparent;
  }
  &.is-active {
    font-weight: bold;
    color: #30d68cff;
    &::before {
      background-color: #d3feda;
      opacity: 0.5;
    }
    &::after {
      z-index: 1;
      content: "";
      position: absolute;
      left: 8px;
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #30d68cff;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
    i {
      color: #23d38a;
      position: relative;
    }
  }
}
</style>
