<template>
  <el-dialog title="查看秘钥" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <div v-if="show">
      <el-form ref="vForm" :model="form" :rules="rules" :inline="true" @submit.native.prevent>
        <el-form-item prop="verificationCode" label="验证码"
          ><el-input v-model="form.verificationCode" placeholder="请输入验证码"></el-input
        ></el-form-item>
        <el-form-item
          ><el-button type="primary" @click="sendVerifyCode" :disabled="vcCountdown != 0" style="width: 120px">{{
            vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
          }}</el-button></el-form-item
        >
      </el-form>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      form: {},
      rules: {
        verificationCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.form = {}
    },
    open(obj) {
      this.clear()
      this.id = obj
      this.show = true
    },
    // 获取验证吗
    async sendVerifyCode() {
      const res = await this.$api.ViewKeySendSms()
      this.$store.dispatch('app/vcDownStart')
    },
    // 提交
    async submit() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          const res = await this.$api.CheckSecretKey({ memberCode: this.id, verificationCode: this.form.verificationCode }).finally(() => {
            this.btnloadSubmit = false
          })
          this.$message.success('验证成功')
          this.$emit('submit', res)
          this.show = false
        }
      })
    }
  }
}
</script>
