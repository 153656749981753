<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetWorkPlanPageList"
      :searchList="searchList"
      :selection="true"
      @selection-change="selChange"
      :searchExt="{ AuditState: auditStateParm }"
      :selConfig="{ selectable }"
    >
      <!-- 附加table -->
      <div slot="ext-table">
        <div class="radio-all">
          <el-radio-group v-model="auditStatus" size="medium" @input="changeRadio">
            <el-radio-button label="0"> 全部 </el-radio-button>
            <el-radio-button label="1"> 未审核 </el-radio-button>
            <el-radio-button label="2"> 审核中 </el-radio-button>
            <el-radio-button label="3"> 审核通过 </el-radio-button>
            <el-radio-button label="4"> 审核不通过 </el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <!-- <el-card shadow="never" style="margin-bottom: 18px" slot="ext-table">可用余额：无 待发放金额：无</el-card> -->
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('004306') || $hasAccess('004307')" id="guideSteps4">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain v-if="$hasAccess('004306')" @click="multipleGrant(1)">批量提交审核</el-button>
        <el-button plain v-if="$hasAccess('004307') && !autoGrant" @click="multipleGrant(2)">批量确认发放</el-button>
      </el-form-item>
      <el-form-item slot="btnline-item" id="guideSteps3">
        <div v-if="$hasAccess('004304')" style="display: inline-block">
          <el-button plain @click="download" :loading="btnloadDownload">
            <i class="main-btn-icon el-icon-self-download-2-line"></i>
            <span>下载模板</span>
          </el-button>
        </div>
        <div v-if="$hasAccess('004305')" style="display: inline-block; margin-left: 12px">
          <el-button v-if="checkAuth" icon="el-icon-upload" plain type="primary" @click="$root.$children[0].$refs.AuthDialog.open()">
            <i class="main-btn-icon el-icon-self-upload-2-line"></i>
            <span>导入结算单</span>
          </el-button>
          <el-button
            v-else-if="!hasTask"
            type="primary"
            @click="$alert('当前没有正常进行中的任务，请去任务列表中创建任务', '提示', { showConfirmButton: false, type: 'warning' })"
          >
            <i class="main-btn-icon el-icon-self-upload-2-line"></i>
            <span>导入结算单</span>
          </el-button>
          <ComponentFormImport v-else url="WorkPlan/UploadWorkPlan" button="导入结算单" @uploaded="onUploaded" />
        </div>
        <div style="position: absolute; right: -8px; bottom: 50px">
          <el-button type="text" @click="$refs.Record.open()">
            <i class="main-btn-icon el-icon-receiving"></i>
            <span>查看导入记录</span>
          </el-button>
        </div>
      </el-form-item>
      <template slot="paySuccessCount">
        <el-table-column label="成功/失败" minWidth="100">
          <template v-slot="{ row }">
            <el-link style="color: #67c23a" @click="$router.push(`/workPlan/Detail?searchTxt=${row.planNo}&remitStatus=3`)">{{
              row.paySuccessCount || 0
            }}</el-link
            >/<el-link style="color: #f53f3f" @click="$router.push(`/workPlan/Detail?searchTxt=${row.planNo}&remitStatus=4`)">{{
              row.payFailCount || 0
            }}</el-link>
          </template>
        </el-table-column>
      </template>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="145" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" v-if="$hasAccess('004300')" @click="$refs.Info.open(row, isEnableMonthly, autoGrant)">查看</el-button>
          <el-button type="text" v-if="$hasAccess('004302') && row.auditState === 1 && row.grantState != 5" @click="$refs.DialogSHFaF.open(row, 1)"
            >提交审核</el-button
          >
          <el-button type="text" v-if="$hasAccess('004302') && row.auditState === 2" class="el-button-error" @click="backOne(row)"
            >撤销审核</el-button
          >
          <el-button type="text" v-if="$hasAccess('004301') && row.auditState === 1" class="el-button-error" @click="delOne(row)">删除</el-button>
          <el-button type="text" v-if="$hasAccess('004303') && row.auditState === 3 && row.grantState == 2 && !autoGrant" @click="confirmFaf(row)"
            >确认发放</el-button
          >
          <el-button
            type="text"
            v-if="$hasAccess('004303') && row.auditState === 3 && row.grantState == 2 && !autoGrant"
            class="el-button-error"
            @click="cancel(row)"
            >拒绝发放</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <DialogImport ref="DialogImport" @submit="$refs.vTable.refreshTable()" @confirm="importConfirmShow" @success="importSuccessShow"></DialogImport>
    <DialogImportConfirm ref="DialogImportConfirm" @submit="$refs.vTable.refreshTable()" @confirm="importConfirm"></DialogImportConfirm>
    <DialogImportSuccess ref="DialogImportSuccess"></DialogImportSuccess>
    <Info ref="Info" @submit="$refs.vTable.refreshTable()" @close="$refs.vTable.refreshTable()"></Info>
    <Record ref="Record"></Record>
    <DialogSHFaF ref="DialogSHFaF" @submit="$refs.vTable.refreshTable()"></DialogSHFaF>
    <DialogSHFaFList ref="DialogSHFaFList" @submit="$refs.vTable.refreshTable()"></DialogSHFaFList>
  </div>
</template>

<script>
import { formatStatus, formatStatusHasColor, kvWorkPlanAuditState, kvWorkPlanGrantState } from '@/configs/formatters'
import { tableFinalStatementHead, tableFinalStatementFoot } from '@/configs/tables'
import { searchFinalStatement } from '@/configs/searches'
import ComponentFormImport from '@/components/formView/import'
import { downloadExcel } from '@/utils'
import DialogImport from './import'
import DialogImportConfirm from '../../workPeople/importConfirm'
import DialogImportSuccess from '../../workPeople/importSuccess'
import Info from './info'
import Record from './record'
import DialogSHFaF from './dialogSHFaF'
import DialogSHFaFList from './dialogSHFaFList'
const tableSelfLabelsHead = [
  { label: '结算单号', prop: 'planNo' },
  {
    label: '所属任务',
    prop: 'taskName',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return `${cellValue}<br/>（${row.taskNo}）`
    },
    config: { width: 250 }
  },
  { label: '付款渠道', prop: 'paymentChannel' },
  { label: '提交金额', prop: 'issueAmount', cmp: 'money' },
  { label: '人数', prop: 'totalPeopleNum' },
  { label: '综合服务费', prop: 'serviceFee', cmp: 'serviceFee' },
  { label: '个税', prop: 'individualTax', cmp: 'money' },
  { label: '增值税', prop: 'addedTax', cmp: 'money' },
  { label: '税金及附加', prop: 'surchargesTax', cmp: 'money' },
  {
    label: '审核状态',
    prop: 'auditState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvWorkPlanAuditState, cellValue)
    }
  },
  {
    label: '发放状态',
    prop: 'grantState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPlanGrantState, cellValue)
    }
  },
  {
    label: '发放进度',
    prop: 'grantFinishCount',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return '<span style="color: #67c23a">' + (cellValue || 0) + '</span>/' + (row.grantRejectCount || 0) + '/' + (row.totalPeopleNum || 0)
    }
  },
  {
    slotName: 'paySuccessCount',
    cmp: 'slot'
  },
  { label: '成功金额', prop: 'successAmount', cmp: 'money' },
  { label: '结算金额', prop: 'settleAmount', cmp: 'money' },
  { label: '实发金额', prop: 'finishPayingAmount', cmp: 'money' }
]
const tableSelfLabelsFoot = [{ label: '创建时间', prop: 'createTime', config: { width: 140 } }]
export default {
  components: { ComponentFormImport, DialogImport, DialogImportConfirm, DialogImportSuccess, Info, Record, DialogSHFaF, DialogSHFaFList },
  data() {
    return {
      btnloadDownload: false,
      searchList: searchFinalStatement,
      summary: {},
      // 多选框
      multipleSelection: [],
      hasTask: false,
      // 抵扣金显示
      isEnableMonthly: false,
      isPayNotify: 1,
      // 税源地可用
      supplierIsEnable: 1,
      autoGrant: 0,
      auditStatus: '0',
      auditStateParm: ''
    }
  },
  computed: {
    tableLabels() {
      if (this.$store.state.user.businessType == 2) {
        if (!this.isEnableMonthly) return [...tableSelfLabelsHead, ...tableSelfLabelsFoot]
        return [...tableSelfLabelsHead, { label: '服务费抵扣金', prop: 'deductionAmount', cmp: 'money' }, ...tableSelfLabelsFoot]
      }
      if (!this.isEnableMonthly) return [...tableFinalStatementHead, ...tableFinalStatementFoot]
      return [...tableFinalStatementHead, { label: '服务费抵扣金', prop: 'deductionAmount', cmp: 'money' }, ...tableFinalStatementFoot]
    },
    // 结算单导入，先判断授权
    checkAuth() {
      if (
        this.$store.state.user.auth.signType == 0 ||
        // (!this.$store.state.user.auth.signType == 1 && !this.$store.state.user.auth.isAuth) ||
        this.$store.state.user.supplierSignList.find((item) => {
          return item.isSign != 2
        })
      )
        return true
      return false
    }
  },
  mounted() {
    this.$api
      .GetTaskManageApprovePageList({
        taskState: 1
      })
      .then((res) => {
        this.hasTask = !!res.total
      })

    this.getMerchantInfo()
  },
  methods: {
    // 获取商户信息
    async getMerchantInfo() {
      const res = await this.$api.GetMerchantInfo()
      this.isEnableMonthly = res.isEnableMonthly
      this.autoGrant = res.autoGrant
      this.isPayNotify = res.isPayNotify
      this.supplierIsEnable = res.supplierIsEnable
    },
    // 选择框事件
    selChange(val) {
      this.multipleSelection = val
    },
    // 撤销审核
    async backOne(row) {
      await this.$confirm(`确认撤销审核此单吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.StartBackOutWorkPlan({ planNo: row.planNo })
      this.$message.success('此单已撤销审核')
      this.$refs.vTable.refreshTable()
    },
    // 删除
    async delOne(row) {
      await this.$confirm(`确认删除此单吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.WorkPlanDelete({ workPlanId: row.id })
      this.$message.success('此单已删除')
      this.$refs.vTable.refreshTable()
    },
    // 拒绝发放
    async cancel(row) {
      await this.$confirm(`确认拒绝发放此单吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.WorkPlanCancel({ workPlanId: row.id })
      this.$message.success('此单已拒绝发放')
      this.$refs.vTable.refreshTable()
    },
    selectable(row, index) {
      if (row.auditState == 1 || row.grantState == 2) return true
      return false
    },
    // 批量提交
    async multipleGrant(type) {
      if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的数据')
      if (type == 1) {
        // 判断审核勾选
        const one = this.multipleSelection.find((item) => {
          return item.auditState != 1
        })
        if (one) return this.$message.error('已勾选的计划中包含无法发起审核的计划，请确认后再次提交！')
      }
      if (type == 2) {
        // 判断发放勾选
        if (this.supplierIsEnable)
          return this.$alert('很抱歉的提醒您，税源地已下线无法进行结算，请联系您的专属运营处理，感谢您的配合', '提醒', { type: 'warning' })
        const one = this.multipleSelection.find((item) => {
          return item.grantState != 2
        })
        await this.$api.CheckConfirmGrantTime()
        if (one) return this.$message.error('已勾选的计划中包含无法发放款项的计划，请确认后再次提交！')
      }
      this.$refs.DialogSHFaFList.open(this.multipleSelection, type, this.isEnableMonthly, this.isPayNotify)
    },
    async confirmFaf(row) {
      if (this.supplierIsEnable)
        return this.$alert('很抱歉的提醒您，税源地已下线无法进行结算，请联系您的专属运营处理，感谢您的配合', '提醒', { type: 'warning' })
      await this.$api.CheckConfirmGrantTime()
      this.$refs.DialogSHFaF.open(row, 2, this.isEnableMonthly, this.isPayNotify)
    },
    // 下载模板
    async download() {
      this.btnloadDownload = true
      const res = await this.$api.DownloadWorkPlanTemplateFile().finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '结算单模板')
    },
    onUploaded(res) {
      this.$refs.DialogImport.open(res)
    },
    // 导入的数据有通过和不通过，显示二次确认弹窗
    importConfirmShow(data) {
      this.$refs.DialogImportConfirm.open(data)
    },
    // 全部通过导入成功，显示确认弹窗
    importSuccessShow(data) {
      this.$refs.DialogImportSuccess.open(data)
    },
    // 导入成功，关闭所有弹窗
    importConfirm() {
      this.$refs.DialogImport.close()
    },
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 100)
    },
    // 通知formView 更新数据
    changeRadio(e) {
      let val = e
      if (e == '0') val = ''
      this.auditStateParm = val
      this.$nextTick(() => {
        this.$refs.vTable.refreshTable()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-all {
  margin-bottom: 16px;
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: transparent;
    color: #23d38a;
  }
  .radio-number {
    margin-left: 8px;
    padding: 2px 6px;
    background: #f2f3f5;
    color: #86909c;
    border-radius: 16px;
  }
}
</style>
