<template>
    <div v-if="show" class="steps-content">
        <div class="wrap"></div>
        <div v-show="showBox" class="box">
            <div id="guideStepsSide"></div>
            <div id="guideStepsPop">
                <div class="btns">
                    <template v-if="step == 1 || step == 3">
                        <el-button plain>
                            <i class="main-btn-icon el-icon-self-download-2-line"></i>
                            <span>下载模板</span>
                        </el-button>
                        <el-button type="primary">
                            <i class="main-btn-icon el-icon-self-upload-2-line"></i>
                            <span>{{ step == 1 ? '导入创客' : '导入结算单' }}</span>
                        </el-button>
                    </template>
                    <template v-else-if="step == 2">
                        <el-button type="primary">
                            <i class="main-btn-icon el-icon-self-cengjia"></i>
                            <span>发布任务</span>
                        </el-button>
                    </template>
                    <template v-if="step >= 4">
                        <span class="v-sel-info">已选择 0 项</span>
                        <el-button plain>{{ step == 4 ? '批量提交审核' : '申请开票' }}</el-button>
                        <el-button v-if="step == 4 && checkWidth > 300" plain>批量确认发放</el-button>
                    </template>
                </div>
                <div class="guide-steps-pop" :class="{'guide-steps-pop-left' : step >= 4}">
                    <div class="title">{{ details.title }}</div>
                    <div class="con">
                        <div class="text" v-html="details.text"></div>
                        <div class="footer">
                            <span class="num">{{ step + ' / ' + total }}</span>
                            <div class="footer-btns">
                                <div v-if="step != 5" class="exit" @click="hide('exit')">退出指引</div>
                                <el-button v-if="step == 5" type="primary" @click="hide('done')">完成</el-button>
                                <el-button v-else type="primary" @click="nextEvent">下一步</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="step >= 4" id="guideStepsOther"><div></div></div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            show: false,
            total: 0,
            step: 0,
            popDomId: '', // 带有pop的dom
            sideDomId: '',
            showBox: false,
            detailsArr: {
                tit: [
                    '导入创客',
                    '发布任务',
                    '导入结算单',
                    '发放结算单',
                    '申请开票',
                ],
                txt: [
                    '请下载创客模板，按要求填写后再导入创客信息。',
                    '点击发布任务，根据您的实际业务场景发布任务哦。',
                    '请先下载模板，按要求填写后再导入结算单。',
                    '导入结算单后，请选择结算单提交审核，审核通过后可以进行发放。<br/>提交审核前请确认余额充足哦！',
                    '请勾选已完成的结算单提交开票申请。',
                ],
            },
            // 从第二步开始跳转的路径列表
            pathArr: [
                '/taskManage/taskList',
                '/workPlan/Check',
                '/workPlan/Check',
                '/billApplyInfo',
            ],
            // 从第二步开始侧边导航的id列表
            sideDomIdArr: [
                'taskList',
                'workPlanCheck',
                'workPlanCheck',
                'billApply',
            ],
            checkWidth: 0,
        }
    },
    computed: {
        details() {
            let { tit, txt } = this.detailsArr

            let title = tit[this.step - 1], text = txt[this.step - 1]

            return { title, text }
        }
    },
    watch: {
        $route: {
            handler: function(val, oldVal){
                let name = this.sideDomIdArr[this.step - 2]
                
                if (this.step >= 2 && oldVal.name == name || this.step == 1 && oldVal.name == 'workPeople') {
                    this.hide()
                }
            },
            deep: true
        }
    },
    methods: {
        // 数据清空
        clear() {
            Object.assign(this.$data, this.$options.data());
        },
        open({ total, step, popDomId, sideDomId, onlyOpen }) {
            this.clear()
            this.total = total
            this.step = step
            this.popDomId = popDomId
            this.sideDomId = sideDomId
            this.show = true
            this.$nextTick(() => {
                this.highlightEvent(onlyOpen)
            })
        },
        hide(type) {
            this.show = false
            if (type == 'exit') {
                this.$root.$children[0].showGuidePop()
            } else {
                this.$root.$children[0].changeFirstLogin()
            }
        },
        highlightEvent(onlyOpen) {
            let time = this.step == 1 && onlyOpen ? 0 : 1500

            setTimeout(() => {
                this.positioning('pop', this.popDomId)
                this.positioning('side', this.sideDomId)
            }, time)
        },
        // 定位及样式
        positioning(type, domId) {
            if (domId) {
                let dom = type == 'pop' ? document.getElementById('guideStepsPop') : document.getElementById('guideStepsSide'), { top, left, width, height } = document.getElementById(domId).getBoundingClientRect(), t = type == 'pop' ? `${top - 7}px` : `${top - 3}px`, l = type == 'pop' ? `${left - 8}px` : `${left}px`, h = type == 'pop' ? `${height + 25}px` : `${height + 6}px`, w = `${width + 8}px`

                dom.classList.add(`guide-highlight-${type}`)
                dom.style.setProperty('top', t)
                dom.style.setProperty('left', l)
                if (type == 'pop') { // 弹窗定位及样式
                    this.checkWidth = width
                    let popBox = document.getElementsByClassName('guide-steps-pop')[0], lw = this.step == 4 ? width > 300 ? `${width/2 - 30}px` : `${width/2 + 30}px` : this.step == 5 ? `${width/2 + 30}px` : 'unset', rw = this.step < 4 ? `${width/2 - 30}px` : 'unset'
                    
                    popBox.style.setProperty('top', h)
                    popBox.style.setProperty('left', lw)
                    popBox.style.setProperty('right', rw)
                    if (this.step >= 4) {
                        // 表格选中框定位及样式
                        let tableDom = document.getElementsByClassName('table')[0]

                        if (tableDom) {
                            let { top, left } = tableDom.getBoundingClientRect(), t = `${top - 51}px`, l = `${left - 5}px`

                            dom = document.getElementById('guideStepsOther')
                            dom.style.setProperty('top', t)
                            dom.style.setProperty('left', l)
                        }
                    }
                    this.showBox = true
                } else { // 侧边栏定位及样式
                    dom.style.setProperty('height', h)
                    dom.style.setProperty('width', w)
                    dom.style.setProperty('display', 'flex')
                    dom.style.setProperty('align-items', 'center')
                    dom.innerHTML = `<div class="guide-steps-side guide-steps-side-only">${document.getElementById(domId).innerHTML}<div>`
                }
            }
        },
        nextEvent() {
            this.step += 1
            if (this.step == 4) {
                this.popDomId = 'guideSteps4'
                this.positioning('pop', this.popDomId)
            } else {
                this.$router.push(this.pathArr[this.step - 2])
                this.open({total: 5, step: this.step, popDomId: 'guideSteps' + this.step, sideDomId: this.sideDomIdArr[this.step - 2]})
            }
        },
    }
}
</script>
  
<style lang="scss" scoped>
.steps-content {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    .wrap {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
    }
    .box {
        position: relative;
        z-index: 99999;
        #guideStepsSide {
            ::v-deep .guide-steps-side {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-left: 12px;
                border-radius: 8px;
                background-color: rgba(211, 254, 218, .5);
                font-weight: bold;
                font-size: 14px;
                color: #30d68cff;
                &.guide-steps-side-only {
                    position: relative;
                    &::after {
                        content: "";
                        width: 3px;
                        height: 20px;
                        background: #30d68cff;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
                a {
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                    .el-menu-item.is-active {
                        width: 100%;
                        height: 100%;
                        padding-left: 30px !important;
                        &::before, &::after {
                            content: none;
                        }
                    }
                }
                .sub-el-icon {
                    margin-right: 5px;
                    width: 24px;
                    text-align: center;
                    font-size: 18px;
                }
            }
        }
        #guideStepsPop {
            position: absolute;
            ::v-deep .main-btn-icon {
                margin: 0 -1px 0 -4px;
                font-size: 16px;
            }
            .btns {
                .v-sel-info {
                    margin-right: 20px;
                    color: #606266;
                    font-size: 12px;
                }
            }
            .guide-steps-pop {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 360px;
                height: 186px;
                padding: 20px 16px 16px 20px;
                border-radius: 12px;
                background-color: #fff;
                position: absolute;
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 2px;
                    background-color: #fff;
                    position: absolute;
                    top: -3px;
                    right: 30px;
                    transform: rotate(-45deg);
                }
                &.guide-steps-pop-left {
                    &::before {
                        right: unset;
                        left: 30px;
                    }
                }
                .title {
                    line-height: 24px;
                    margin-bottom: 16px;
                    font-size: 18px;
                    color: #1D2129;
                    font-weight: 600;
                }
                .con {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    line-height: 20px;
                    font-size: 14px;
                    color: #4E5969;
                    .text {
                        
                    }
                    .footer {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .num {
                            margin-left: 4px;
                        }
                        .footer-btns {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .exit {
                                width: 80px;
                                height: 32px;
                                line-height: 32px;
                                margin-right: 12px;
                                text-align: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        #guideStepsOther {
            width: 65px;
            height: 62px;
            padding: 5px;
            background-color: #fff;
            border-radius: 12px;
            position: relative;
            div {
                width: 55px;
                height: 52px;
                background-color: #f7f8fa;
                border: 1px solid #ebeef5;
                position: relative;
                &::before {
                    content: '';
                    width: 14px;
                    height: 14px;
                    border: 1px solid #dcdfe6;
                    border-radius: 2px;
                    background-color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
</style>
<style>
.guide-highlight-side, .guide-highlight-pop {
    padding: 3px 8px;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    z-index: 9999;
}
.guide-highlight-pop {
    padding: 7px 8px;
}
</style>