<template>
    <div @click="!apiName ? showView() : false" class="box">
      <div class="img_box">
        <el-image
          style="width: 64px; height: 64px"
          :src="path"
          :fit="cover"></el-image>
      </div>
      <p class="img_title">{{ name || "查看文件" }}</p>
      <el-link
        v-if="path"
        type="primary"
        @click="!apiName ? showView() : false"
        :style="{ 'max-height': '18px', 'font-size': fontSize }"
      >
        <i v-if="isIcon" class="el-icon-view el-icon--right"></i>
        <el-image-viewer
          v-if="showViewer"
          :zIndex="9999"
          :url-list="viewerlList"
          :on-close="
            () => {
              showViewer = false;
              clear();
            }
          "
        />
      </el-link>
      <el-link v-else :style="{ 'font-size': fontSize }">无</el-link>
    </div>
  </template>
  
  <script>
  import { isArray } from "@/utils";
  import ElImageViewer from "element-ui/packages/image/src/image-viewer";
  export default {
    components: {
      ElImageViewer,
    },
    props: {
      path: String | Array,
      name: String,
      isIcon: true,
      fontSize: {
        type: String,
        default: "14px",
      },
      apiName: String,
    },
    data() {
      return {
        showViewer: false,
        viewerlList: [],
      };
    },
    methods: {
      clear() {
        this.viewerlList = [];
      },
  
      showView(params) {
        if (this.apiName) {
          this.$api[this.apiName](params).then((res) => {
            this.viewerlList = isArray(res) ? res : [res];
          });
        } else {
          this.viewerlList = isArray(this.path) ? this.path : [this.path];
        }
        this.showViewer = true;
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .box {
    cursor: pointer;
  }
  .img_title {
    width:64px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    margin: 4px 0 0 0 ;
    margin-left: 10px;
  }
  .img_box {
    width:64px;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    
  }
  </style>
  <style>
  .el-image img {
    border:solid 1px #E5E6EB;
    border-radius: 4px;
  }
  </style>