<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetRiseInfo" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('005202')">
        <el-button type="primary" @click="$refs.Edit.open()">
          <i class="main-btn-icon el-icon-self-cengjia"></i>
          <span>新增</span>
        </el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="185" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" v-if="$hasAccess('005200') && row.isDefault != '是'" @click="defaultOne(row)">设为默认</el-button>
          <el-button type="text" v-if="$hasAccess('005200')" @click="$refs.Edit.open(row)">编辑</el-button>
          <el-button type="text" v-if="$hasAccess('005201')" class="el-button-error" @click="delOne(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="asyncRefreshTable" />
  </div>
</template>

<script>
import { tableRiseInfo } from '@/configs/tables'
import { searchRiseInfo } from '@/configs/searches'
import Edit from './edit'
export default {
  components: { Edit },
  data() {
    return {
      tableLabels: tableRiseInfo,
      searchList: searchRiseInfo
    }
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    // 设置默认
    async defaultOne(item) {
      const res = await this.$api.DefaultRiseInfo({ riseId: item.riseId })
      this.$message.success('已更新默认抬头')
      this.asyncRefreshTable()
    },
    // 删除
    async delOne(item) {
      await this.$confirm(`确认删除当前抬头吗？`, '删除', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.DeleteRiseInfo({ riseId: item.riseId })
      this.$message.success('抬头已删除')
      this.asyncRefreshTable()
    }
  }
}
</script>
