<template>
  <div>
    <el-dialog title="上传" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-small">
      <div class="accpent">
        <div class="text">第一步：请下载<span @click="downLoadWd" class="result-text">《业务确认单》</span></div>
        <div class="text">第二步：填写任务完成情况，加盖企业公章后上传</div>
        <div class="text">支持jpg .jpeg .png、pdf，zip，rar，txt大小不超过5M</div>
      </div>

      <div class="upload">
        <FormView ref="FormView" :labels="labels" :formConfig="{ labelWidth: '140px' }"></FormView>
      </div>
      <div slot="footer">
        <el-button plain @click="show = !show">取 消</el-button>
        <el-button @click="submit" type="primary" :loading="btnloadSubmit">确 认</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog
      :title="fileType == 1 ? '上传交付物' : '上传成果验收单'"
      width="30%"
      :visible.sync="show"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div class="dialogBox">
        <div v-if="fileType != 1" class="accpent">
          <div class="text">第一步：请下载<span @click="downLoadWd" class="result-text">《成果验收单》</span></div>
          <div class="text">第二步：填写任务完成情况，加盖企业公章后上传</div>
          <div class="text">支持.jpg .jpeg .png、pdf，大小不超过5M</div>
        </div>
        <div style="display: flex" class="flex-box">
          <div class="text">上传{{ fileType == 1 ? '交付物' : '成果验收单' }}：</div>
          <MultipleUpload
            :modelValue="fileType == 1 ? detail.deliverableList : detail.acceptanceList"
            :config="config"
            @update="uploadMul"
          ></MultipleUpload>
        </div>
      </div>
      <div slot="footer">
        <el-button plain @click="show = !show">取 消</el-button>
        <el-button @click="submit" type="primary">确 认</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { businessForm } from '@/configs/forms.js'
import { downloadDocx } from '@/utils'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      id: undefined,
      labels: businessForm
    }
  },
  methods: {
    async downLoadWd() {
      await this.$api.ConfirmReceiptsTemplateCheck({ confirmReceiptsId: this.id })
      const result = await this.$api.DownloadConfirmReceiptsFile({ confirmReceiptsId: this.id })
      downloadDocx(result, '业务确认单模板')
    },
    clear() {
      this.$nextTick(() => {
        this.$refs.FormView.fillData({})
      })
    },
    open(row) {
      this.id = row.id
      this.show = true
      this.clear()
    },
    getName(val) {
      let idx = val.lastIndexOf('\/')
      return val.substring(idx + 1)
    },
    // 提交
    async submit() {
      const result = await this.$refs.FormView.validate()
      this.btnloadSubmit = true
      this.$api
        .UploadConfirmReceipts(Object.assign({ confirmReceiptsId: this.id, attachName: this.getName(result.attachUrl) }, result))
        .then((res) => {
          if (res) {
            this.show = false
            this.$message.success('提交成功')
            this.$emit('updateList')
          }
        })
        .finally(() => {
          this.btnloadSubmit = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.accpent {
  margin-bottom: 20px;
  .title {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0;
    color: #303133;
  }
  .result-text {
    color: #409eff;
    cursor: pointer;
  }
  .text {
    margin-bottom: 10px;
  }
}
.upload {
  .el-form-item__label {
    text-align: left;
  }
}
</style>
