<template>
  <el-header class="head-wrap">
    <div class="head-logo">
      <div class="logo-wrap">
        <img v-if="$store.state.app.oemId" class="img" :src="require('@/assets/oem/' + $store.state.app.oemId + '/logo-long.png')" />
        <img v-else class="img" src="@/assets/logo-long.png" />
        <!-- <i class="icon iconfont icon-icon_logo"></i>
        <i class="icont iconfont icon-icon_lingshiyun"></i>
        <i class="icont iconfont icon-a-icon_dian_huabandian"></i>
        <i class="icont iconfont icon-icon_qiye"></i> -->
      </div>
      <!-- <h1 class="title">{{ title }}</h1> -->
    </div>
    <div style="display: flex; align-items: center">
      <!-- <span class="sub-word">客服邮箱：vip@linsyun.com</span> -->
      <span v-if="isMerchantAdmin" class="guide-pop-box">
        <el-popover placement="bottom" popper-class="guide-pop" width="280" trigger="manual" v-model="guidePopVisible">
          <div class="guide-wrap">
            <span class="text">需要查看引导说明的时候记得点我喔～</span>
            <div style="text-align: right">
              <el-button type="primary" @click="guidePopVisible = false">{{ guideCountdown }}s后关闭</el-button>
            </div>
          </div>
          <span slot="reference" class="sub-btn"></span>
        </el-popover>
        <el-tooltip effect="dark" content="结算指引" placement="bottom">
          <span class="sub-btn guide-tips" @click="guideStepsEvent"><i class="el-icon-self-guide-line"></i></span>
        </el-tooltip>
      </span>
      <el-popover v-if="!$store.state.app.oemId" placement="bottom" width="205" trigger="hover">
        <div class="qrcode-wrap">
          <el-image :src="qrcodeSrc" />
          <p>关注灵时云公众号</p>
          <p>客服邮箱：vip@linsyun.com</p>
        </div>

        <span slot="reference" class="sub-btn"><i class="el-icon-self-customer-service-fill"></i></span>
      </el-popover>
      <!-- 帮助 -->
      <el-tooltip v-if="!$store.state.app.oemId" effect="dark" content="操作手册" placement="bottom">
        <!-- <el-button
          class="sub-btn"
          size="mini"
          icon="el-icon-reading"
          circle
          @click="downloadUrl('http://linsyun.gitee.io/docs/')"
        ></el-button> -->
        <span @click="linkOpen('https://honghaiyang1990.github.io/linsyun-docs/')" class="sub-btn"><i class="el-icon-self-Help-Center-2-01"></i></span>
      </el-tooltip>
      <!-- 消息 -->
      <el-popover v-if="$hasAccess('004001')" trigger="click" width="400" v-model="msgVisible" @show="msgShow" @hide="msgHide">
        <div class="msg-wrap">
          <div class="tit">
            <span>消息通知</span><span v-format="'#,##0'">{{ msgSumCount || 0 }}条未读</span>
          </div>
          <div class="list">
            <div class="item" v-for="(item, index) in msgList" :key="item.id" :class="{ over: item.isRead }">
              <i class="el-icon-bell"></i>
              <div class="con">{{ item.alertTitle }}</div>
              <el-button type="text" @click="toMsgDetail(item)">查看</el-button>
            </div>
          </div>
          <div class="more">
            <el-button type="text" @click="toMsgList">更多</el-button>
          </div>
        </div>
        <div style="margin-bottom: 1px" class="sub-btn sub-icon" slot="reference">
          <el-badge is-dot :hidden="!msgIsExistUnRead"><i class="el-icon-self-notification-line"></i></el-badge>
        </div>
      </el-popover>
      <el-divider direction="vertical"></el-divider>
      <!-- 用户 -->
      <el-dropdown class="user-dropdown" trigger="hover">
        <div class="head-user">
          <p class="username">{{ mainName }}<i style="margin-left: 4px" class="el-icon-self-chevron-down"></i></p>
          <!-- <el-image class="avatar" :src="avatar" /> -->
        </div>
        <el-dropdown-menu slot="dropdown" class="menu">
          <div
            style="
              width: 222px;
              border-radius: 8px;
              background: #f7f8faff;
              font-size: 14px;
              text-align: center;
              margin: 3px 8px 8px 8px;
              padding: 18px 10px;
            "
          >
            <div style="color: #000000">{{ username }}</div>
            <div style="color: #86909c; margin-top: 18px">账号</div>
          </div>
          <el-dropdown-item @click.native="$router.push({ name: 'merchant' })">
            <div style="height: 32px; line-height: 32px; font-size: 14px"><i class="el-icon-user"></i>账号设置</div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="$refs.Password.open()">
            <div style="height: 32px; line-height: 32px; font-size: 14px"><i class="el-icon-lock"></i>修改密码</div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <div style="height: 32px; line-height: 32px; font-size: 14px"><i class="el-icon-switch-button"></i>退出登录</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <Password ref="Password" @submit="relogin" />
  </el-header>
</template>

<script>
import { getUsername, getAvatar, getMainName } from '@/utils/auth'
import { downloadUrl } from '@/utils'
import { kvMsgType } from '@/configs/formatters'
import Password from '@/views/merchant/password'
export default {
  name: 'Head',
  components: { Password },
  data() {
    return {
      avatarSrc: require('@/assets/logo.png'),
      qrcodeSrc: require('@/assets/qrcode.png'),
      title: process.env.VUE_APP_TITLE,
      msgVisible: false,
      msgTypeRoutes: kvMsgType,
      msgIsExistUnRead: false,
      msgList: [],
      msgSumCount: 0,
      msgTimeout: undefined,
      queryInterval: 60,

      guidePopVisible: false,
      guideCountdown: 3
    }
  },
  computed: {
    avatar() {
      return getAvatar() || this.avatarSrc
    },
    mainName() {
      return getMainName() || '企业'
    },
    username() {
      return getUsername() || '用户'
    },
    isMerchantAdmin() {
      return this.$store.state.user.isMerchantAdmin
    }
  },
  created() {
    this.$eventBus.$on('doShowGuidePop', (message) => {
      if (message) {
        this.guidePopEvent()
      }
    })
  },
  mounted() {
    this.globalMsg()
  },
  destroyed() {
    clearTimeout(this.msgTimeout)
  },
  methods: {
    downloadUrl,
    // 登出
    async logout() {
      await this.$store.dispatch('user/logOut')
      if (this.$store.state.app.oemId) this.$router.push(`/login?o=${this.$store.state.app.oemId}&redirect=${this.$route.fullPath}`)
      else this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    // 重新登录
    relogin() {
      this.$message.success('密码修改成功，请重新登录~')
      this.logout()
    },
    // 消息查看
    toMsgDetail(item) {
      if (!item.isRead) {
        this.$api.ReadSystemAlert({ id: item.id })
        item.isRead = true
      }
      const msgTypeRoute = this.msgTypeRoutes.find((it) => {
        return it.key == item.alertScene
      })
      if (msgTypeRoute) {
        this.$router.push(msgTypeRoute.route)
      }
      this.msgVisible = false
    },
    // 跳转消息列表
    toMsgList() {
      this.msgVisible = false
      this.$router.push(`/msg`)
    },
    // 全局消息状态
    globalMsg() {
      if (this.$hasAccess('004001')) {
        this.$api
          .GetSystemAlert()
          .then((res) => {
            const { isExistUnRead, queryInterval } = res
            this.msgIsExistUnRead = isExistUnRead
            this.queryInterval = queryInterval || this.queryInterval
            this.setPolling()
          })
          .catch(() => {
            // this.setPolling()
          })
      }
    },
    // 轮询处理
    setPolling() {
      this.msgTimeout = setTimeout(() => {
        this.globalMsg()
      }, this.queryInterval * 1000)
    },
    // 打开消息弹窗
    msgShow() {
      clearTimeout(this.msgTimeout)
      this.$api.GetSystemAlertWithSumCount().then((res) => {
        const { sumCount, systemAlertMessages } = res
        this.msgList = systemAlertMessages || []
        this.msgSumCount = sumCount
      })
    },
    // 关闭消息弹窗
    msgHide() {
      this.setPolling()
    },
    guidePopEvent() {
      this.guidePopVisible = !this.guidePopVisible
      if (this.guidePopVisible) {
        this.guideCountdown = 3

        let timer = setInterval(() => {
          if (this.guideCountdown == 1) {
            this.guidePopVisible = false
            clearInterval(timer)
          }
          this.guideCountdown -= 1
        }, 1000)
      }
    },
    guideStepsEvent() {
      this.$root.$children[0].showGuideSteps()
    },
    linkOpen(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.head-wrap {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: $--border-base;
  .head-logo {
    display: flex;
    align-items: center;
    .logo {
      height: 42px;
      width: 143px;
      flex-shrink: 0;
    }
    .logo-wrap {
      color: #5cc88d;
      display: flex;
      align-items: center;
      .img {
        width: 139px;
        height: 24px;
      }
      .icon {
        font-size: 22px;
        margin-right: 20px;
      }
      .icont {
        font-size: 14px;
      }
    }
    .title {
      flex-grow: 1;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      color: $--color-text-primary;
      padding: 0 8px;
    }
  }

  .user-dropdown {
    cursor: pointer;
  }
  .dropdownBox {
  }
  .head-user {
    display: flex;
    align-items: center;
    .avatar {
      width: 32px;
      border-radius: 100%;
      border: $--border-base;
      flex-shrink: 0;
    }
    .username {
      flex-grow: 1;
      text-align: center;
      font-size: 16px;
      margin: 0;
      color: #1d2129ff;
      font-size: 18px;
      font-weight: 500;
      padding: 0 8px;
    }
  }
}
.sub-word {
  font-size: 12px;
  color: $--color-text-secondary;
  margin-right: 15px;
}
.sub-btn {
  font-size: 20px;
  margin-right: 12px;
  cursor: pointer;
  color: #4e5969;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 23px;
    opacity: 1;
    background: #f2f3f5ff;
  }
}
// .sub-icon {
//   width: 32px;
//   height: 32px;
//   border-radius: 23px;
//   opacity: 1;
//   background: #f2f3f5ff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.qrcode-wrap {
  text-align: center;
  p {
    margin: 0 0 10px;
  }
}
.msg-wrap {
  .tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdfe6;
    padding-bottom: 10px;
  }
  .more {
    text-align: center;
    margin-top: 5px;
  }
  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dcdfe6;
      padding: 5px 0;
      & > * {
        flex-shrink: 0;
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
      }
      i {
        color: #5cc88d;
      }
      .con {
        flex-shrink: 1;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.over {
        color: #dcdfe6;
        i {
          color: #dcdfe6;
        }
      }
    }
  }
}
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f2f3f5;
  color: #606266;
}
.guide-pop-box {
  position: relative;
  .guide-tips {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
<style lang="scss">
.guide-pop {
  .guide-wrap {
    padding: 0 8px 4px 8px;
    .text {
      display: block;
      line-height: 20px;
      margin: 8px 0 20px;
      font-size: 14px;
      color: #1d2129;
    }
  }
}
</style>
