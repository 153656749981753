<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetIndividualInfoPageList" :searchList="searchList">
      <el-form-item slot="btnline-item" v-if="$hasAccess('006500')">
        <el-button @click="download" plain type="primary" :loading="btnloadDownload">下载模板</el-button>
      </el-form-item>
      <el-form-item slot="btnline-item" v-if="$hasAccess('006501')">
        <ComponentFormImport url="IndividualPeople/import" button="导入" @uploaded="onUploaded" />
      </el-form-item>
    </TableView>
  </div>
</template>

<script>
import { formatStatus } from '@/configs/formatters'
// 常量设置
const options = [
  { key: 0, label: '待申请' },
  { key: 1, label: '待审核' },
  { key: 2, label: '注册中' },
  { key: 3, label: '经营中' },
  { key: 4, label: '已拒绝' }
]
const tableLabels = [
  { label: '个体户编号', prop: 'peopleNo', config: { minWidth: 105 } },
  { label: '姓名', prop: 'userName' },
  { label: '身份证', prop: 'idCard' },
  { label: '手机号', prop: 'mobile', config: { width: 120 } },
  {
    label: '个体户状态',
    prop: 'individualState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(options, cellValue)
    }
  },
  { label: '添加时间', prop: 'createTime', config: { width: 168 } },
  { label: '完成申请时间', prop: 'finishTime', config: { width: 168 } },
  { label: '个体户名称', prop: 'companyName', config: { minWidth: 105 } },
  { label: '备注', prop: 'remark' }
]
const searchList = [
  {
    cmp: 'input',
    label: '基础信息',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/身份证/手机号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '个体户状态',
    prop: 'IndividualState',
    options: options
  },
  {
    cmp: 'dtpickers',
    label: '添加时间',
    prop: ['StartCreateTime', 'EndCreateTime']
  },
  {
    cmp: 'dtpickers',
    label: '完成申请时间',
    prop: ['StartFinishTime', 'EndFinishTime']
  }
]
import { downloadExcel } from '@/utils'
import ComponentFormImport from '@/components/formView/import'
export default {
  components: { ComponentFormImport },
  data() {
    return {
      btnloadDownload: false,
      tableLabels: tableLabels,
      searchList: searchList
    }
  },
  methods: {
    async download() {
      this.btnloadDownload = true
      const res = await this.$api.DownloadIndividualPeople().finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '个体户模板')
    },
    onUploaded(res) {
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
