<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetOrdinaryTaskDeliverableList" :searchList="searchList">
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row }">
          <template>
            <el-button v-if="$hasAccess('006800')" type="text" @click="$refs.Detail.open(row, false)">查看</el-button>
            <el-button v-if="row.auditState == 0 && $hasAccess('006801')" type="text" @click="$refs.Detail.open(row, true)">审核</el-button>
          </template>
          <!-- <template v-if="$hasAccess('004102')">
            <el-button v-if="row.auditStatus == 1 && row.taskState == '正常'" type="text" @click="onSwitch(row)">隐藏</el-button>
            <el-button v-if="row.auditStatus == 1 && row.taskState == '终止'" type="text" @click="onSwitch(row)">展示</el-button>
          </template> -->
        </template>
      </el-table-column>
    </TableView>
    <Detail @refresh="asyncRefreshTable" ref="Detail"></Detail>
  </div>
</template>

<script>
import Detail from './newDetail.vue'
import { tableDeliverablesCheck } from '@/configs/tables'
import { searchDeliverablesCheck } from '@/configs/searches'
export default {
  components: {
    Detail
  },
  data() {
    return {
      tableLabels: tableDeliverablesCheck,
      searchList: searchDeliverablesCheck
    }
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    }
  }
}
</script>
