<template>
  <div class="panel-home">
    <!-- 数据统计 -->
    <StatisticsPanel :list="summaryList" ref="StatisticsPanel"></StatisticsPanel>
    <div class="panel-box">
      <div class="pageTitle">{{ $route.meta.title }}</div>
      <TableView
        ref="vTable"
        :labels="tableLabels"
        apiName="GetWorkDetailPageList"
        :searchList="searchList"
        @geted="onTableGeted"
        :searchExt="{ PayState: payStateParm }"
        :initSearch="initSearch"
      >
        <div slot="btnline-item-left">
          <div class="radio-all">
            <el-radio-group v-model="remitStatus" size="medium" @input="changeRadio">
              <el-radio-button label="0"> 全部 </el-radio-button>
              <el-radio-button label="1"> 待打款 </el-radio-button>
              <el-radio-button label="2"> 打款中 </el-radio-button>
              <el-radio-button label="3"> 打款成功 </el-radio-button>
              <el-radio-button label="4"> 打款失败 </el-radio-button>
              <el-radio-button label="5"> 拒绝打款 </el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <!-- 按钮栏 -->
        <el-form-item slot="btnline-item" v-if="$hasAccess('004400')">
          <el-button type="primary" @click="download" :loading="btnloadDownload">
            <i class="main-btn-icon el-icon-self-download-2-line"></i>
            <span>导出</span>
          </el-button>
        </el-form-item>
        <!-- 操作栏 -->
        <el-table-column slot="table-item" label="操作" width="80" fixed="right">
          <template v-slot="{ row }">
            <el-button v-if="$hasAccess('004401')" type="text" @click="$refs.DialogInfo.open(row)">查看</el-button>
          </template>
        </el-table-column>
      </TableView>
    </div>
    <!-- 弹窗 -->
    <DialogInfo ref="DialogInfo"></DialogInfo>
  </div>
</template>

<script>
import {
  formatStatus,
  formatStatusHasColorHasIcon,
  kvWorkPeopleIsSign,
  kvWorkPlanAuditState,
  kvWorkPlanPayState,
  kvIsReturn
} from '@/configs/formatters'
import dayjs from 'dayjs'
import { tableBillingDetails } from '@/configs/tables'
import { searchBillingDetails } from '@/configs/searches'
import { downloadExcel } from '@/utils'
import DialogInfo from './dialog'
import StatisticsPanel from '@/components/statisticsPanel'
const tableSelfLabels = [
  { label: '订单编号', prop: 'customerOrderNo', config: { width: 180 } },
  { label: '结算单编号', prop: 'planNo', config: { minWidth: 105 } },
  {
    label: '所属任务',
    prop: 'taskName',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return `${cellValue}<br/>（${row.taskNo}）`
    },
    config: { width: 250 }
  },
  { label: '姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard', config: { width: 150 } },
  { label: '手机号', prop: 'mobile', config: { width: 120 } },
  {
    label: '签约状态',
    prop: 'isSign',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPeopleIsSign, cellValue)
    }
  },
  { label: '个税', prop: 'individualTax', cmp: 'money' },
  { label: '增值税', prop: 'addedTax', cmp: 'money' },
  { label: '税金及附加', prop: 'surchargesTax', cmp: 'money', config: { minWidth: 105 } },
  { label: '结算金额', prop: 'finalPayingAmount', cmp: 'money' },
  { label: '服务费', prop: 'serviceFee', cmp: 'money' },
  { label: '差额服务费', prop: 'serviceDiffFee', cmp: 'money', config: { minWidth: 105 } },
  { label: '订单金额', prop: 'orderAmount', cmp: 'money' },
  {
    label: '审核状态',
    prop: 'auditState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPlanAuditState, cellValue)
    }
  },
  {
    label: '打款状态',
    prop: 'payState',
    cmp: 'html',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColorHasIcon(kvWorkPlanPayState, cellValue)
    }
  },
  {
    label: '是否退汇',
    prop: 'isReturn',
    config: { width: 100 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvIsReturn, cellValue)
    }
  }
]
export default {
  components: { DialogInfo, StatisticsPanel },
  data() {
    return {
      btnloadDownload: false,
      searchList: searchBillingDetails,
      summaryList: [],
      remitStatus: '0',
      payStateParm: ''
    }
  },
  computed: {
    tableLabels() {
      if (this.$store.state.user.businessType == 2) return tableSelfLabels
      return tableBillingDetails
    },
    initSearch() {
      return {
        PayFinishTimeBegin:
          this.$route.query.searchTxt && this.$route.query.remitStatus ? null : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        PayFinishTimeEnd: this.$route.query.searchTxt && this.$route.query.remitStatus ? null : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        SearchTxt: this.$route.query.searchTxt || ''
      }
    }
  },
  mounted() {
    this.remitStatus = this.$route.query.remitStatus || '0'
  },
  methods: {
    async onTableGeted(respons, params) {
      this.params = params
      const res = await this.$api.GetWorkDetailSumData(params)
      this.summaryList = [
        { label: '待打款金额', value: res.waitPayAmount, number: res.waitPayCount },
        { label: '打款中金额', value: res.payingAmount, number: res.payingCount },
        { label: '打款成功金额', value: res.successPayAmount, number: res.successPayCount },
        { label: '打款成功金额（含服务费）', value: res.successPayAmountServiceFee, number: res.successPayCountServiceFee },
        { label: '打款失败金额', value: res.failPayAmount, number: res.failPayCount },
        { label: '拒绝打款金额', value: res.rejectPayAmount, number: res.rejectPayCount },
        { label: '审核不通过金额', value: res.auditNotPassAmount, number: res.auditNotPassCount }
      ]
    },
    async download() {
      const params = this.$refs.vTable.getParams()
      if (!params.PayFinishTimeBegin && !params.PayFinishTimeEnd && !params.CreateTimeBegin && !params.CreateTimeEnd)
        return this.$message.warning('请选择数据导出时间段')
      this.btnloadDownload = true
      const res = await this.$api.ExportWorkDetailData(params).finally(() => {
        this.btnloadDownload = false
      })
      // 失败处理
      if (res.type == 'application/json') {
        // 将blob文件流转换成json
        const reader = new FileReader()
        reader.onload = (event) => {
          const error = JSON.parse(reader.result)
          this.$message.error(error.message || '导出错误')
        }
        reader.readAsText(res)
      } else {
        if (params.PayFinishTimeBegin && params.PayFinishTimeEnd)
          downloadExcel(res, `结算明细${params.PayFinishTimeBegin}至${params.PayFinishTimeEnd}`)
        else downloadExcel(res, `结算明细${params.CreateTimeBegin}至${params.CreateTimeEnd}`)
      }
    },
    // 通知formView 更新数据
    changeRadio(e) {
      let val = e
      if (e == '0') val = ''
      this.payStateParm = val
      this.$nextTick(() => {
        this.$refs.vTable.refreshTable()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.radio-all {
  margin-bottom: 18px;
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: transparent;
    color: #23d38a;
  }
  .radio-number {
    margin-left: 8px;
    padding: 2px 6px;
    background: #f2f3f5;
    color: #86909c;
    border-radius: 16px;
  }
}
</style>
