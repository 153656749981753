<template>
  <div class="home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <FormView ref="vForm" :labels="fromLabels" :slotIdxList="[1, 3]">
      <template #form-items="{ idx, form }">
        <el-form-item v-if="idx === 1" label="测个slot" prop="ts1" :rules="[{ required: true, message: '必填', trigger: 'blur' }]">
          <el-input v-model="form.ts1"></el-input>
        </el-form-item>
        <el-form-item v-if="idx === 3" label="测个slot2" prop="ts2" :rules="[{ required: true, message: '必填', trigger: 'blur' }]">
          <el-input v-model="form.ts2"></el-input>
        </el-form-item>
      </template>
    </FormView>
    <el-button @click="submit">提交</el-button>
  </div>
</template>

<script>
import { formTemplate } from '@/configs/forms'
export default {
  data() {
    return {
      fromLabels: formTemplate
    }
  },
  methods: {
    async submit() {
      const form = await this.$refs.vForm.getForm()
      console.log(form)
    }
  }
}
</script>
