<template>
  <el-drawer :visible.sync="visibleShow" v-bind="cfg">
    <slot></slot>
  </el-drawer>
</template>

<script>
// 弹窗基础配置项
const DefaultCfg = {
  appendToBody: true,
  direction: 'rtl',
  size: '75%'
}
export default {
  props: {
    show: false,
    config: Object
  },
  computed: {
    visibleShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    // 弹窗配置项格式化
    cfg() {
      return Object.assign({}, DefaultCfg, this.config)
    }
  }
}
</script>