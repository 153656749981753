import { render, staticRenderFns } from "./selected.vue?vue&type=template&id=6ff6c174&scoped=true"
import script from "./selected.vue?vue&type=script&lang=js"
export * from "./selected.vue?vue&type=script&lang=js"
import style0 from "./selected.vue?vue&type=style&index=0&id=6ff6c174&prod&lang=scss&scoped=true"
import style1 from "./selected.vue?vue&type=style&index=1&id=6ff6c174&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff6c174",
  null
  
)

export default component.exports