<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetDisburseLogPageList"
      :searchList="searchList"
      @geted="onTableGeted"
      :initSearch="initSearch"
    >
      <template slot="afterTaxAmount">
        <el-table-column label="金额(元)" minWidth="100">
          <template v-slot="{ row }">
            -<span v-format="'#,##0.00'">{{ Number(row.afterTaxAmount)  }}</span>
          </template>
        </el-table-column>
      </template>
      <!-- 附加table -->
      <!-- <el-card shadow="never" slot="ext-table" style="margin-bottom: 18px"
        >支出总金额（元）:
        <span v-format="'#,##0.00'">{{ amount || 0 }}</span></el-card
      > -->
    </TableView>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { tableDisburseRecord } from '@/configs/tables'
import { searchDisburseRecord } from '@/configs/searches'
export default {
  data() {
    return {
      tableLabels: tableDisburseRecord,
      searchList: searchDisburseRecord,
      amount: 0
    }
  },
  computed: {
    initSearch() {
      return {
        // DisburseTimeBegin: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        // DisburseTimeEnd: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        SearchTxt: this.$route.query.fundNo
      }
    }
  },
  methods: {
    onTableGeted(response, params) {
      const { pageIndex, pageSize, ...rest } = params
      this.$api.GetDisburseLogTotalAmount(rest).then((res) => {
        this.amount = res.data
      })
    }
  }
}
</script>
