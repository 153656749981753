<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '业务确认单详情查看'
    }"
  >
    <DetailCard title="基本信息">
      <DescView :descLabels="descLabels1" :descDatas="detail"> </DescView>
    </DetailCard>
    <DetailCard title="上传概况">
      <DescView :descLabels="descLabels2" :descDatas="detail">
        <template #confirmReceiptAttachs>
          <template v-if="detail.confirmReceiptAttachs && detail.confirmReceiptAttachs.length">
            <div v-for="(item, index) in detail.confirmReceiptAttachs" :key="index">
              <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
              <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-if="item.attachUrl && item.type == 'pdf'">查看文件{{ index + 1 }}</span>
            </div>
          </template>
          <span v-else>无</span>
        </template>
      </DescView>
    </DetailCard>
  </DetailView>
</template>

<script>
import { kvAuditStatus } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import { downloadUrl } from '@/utils'
const descLabels1 = [
  { label: '企业主体', prop: 'mainName' },
  { label: '当前月份', prop: 'currMonth' },
  { label: '税源地', prop: 'supplierName' },
  { label: '发放金额', cmp: 'money', prop: 'grantAmount' }
]
const descLabels2 = [
  { label: '上传状态', prop: 'uploadState', cmp: 'state', stateOptions: kvAuditStatus },
  { label: '退回理由', prop: 'backReason' },
  { label: '上传文件', cmp: 'slot', slotName: 'confirmReceiptAttachs' }
]
export default {
  components: { ImageViewer },
  data() {
    return {
      show: false,
      id: null,
      descLabels1,
      descLabels2,
      detail: {}
    }
  },
  methods: {
    viewPdf(e) {
      downloadUrl(e)
    },
    async open(obj) {
      this.reason = null
      this.id = obj.id
      const result = await this.$api.GetConfirmReceipts({ id: obj.id })
      result.confirmReceiptAttachs.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      this.detail = Object.assign({}, result)
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.otherStyle {
  color: #3d78fa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
</style>
