<template>
  <div>
    <DetailView
      :show.sync="show"
      :config="{
        title: `创客详情${isEdit?'编辑':'审核'}`
      }"
    >
      <!-- 基本信息 -->
      <DetailCard title="基本信息" >
        <DescView :descLabels="descLabels" :descDatas="detail">
          <template #userName>{{ detail.userName || '无' }}<WpView v-if="detail.userName" :id="detail.userId" /></template>
          <template #mobile>{{ detail.mobile || '无' }}<WpView v-if="detail.mobile" :id="detail.userId" /></template>
          <template #idCard>{{ detail.idCard || '无' }}<WpView v-if="detail.idCard" :id="detail.userId" /></template>
          <template #otherDocuments>
            <div v-if="detail.deliverableList && detail.deliverableList.length > 0" style="display:flex;">
              <div v-for="(item, index) in detail.deliverableList" :key="index" class="documents">
                <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
                <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-if="item.attachUrl && item.type == 'pdf'">查看文件{{ index + 1 }}</span>
              </div>
            </div>
            <span v-else>无</span>
          </template>
          <template #status>
            {{ formatStatus(kvAuditState, detail.auditState) }}
          </template>
        </DescView>
      </DetailCard>
      <!-- 审核信息 -->
      <DetailCard title="审核信息" >
        <DescView :descLabels="examineDescLabels" :descDatas="detail">
          <template #status>
            {{ formatStatus(kvAuditState, detail.auditState) }}
          </template>
        </DescView>
      </DetailCard>
      <span v-if="isEdit" class="SBtn" >
        <el-button @click="showReason = true">审核不通过</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">审核通过</el-button>
      </span>
      <el-dialog title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false" class="dialog-small">
        <div>
          <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="reason"> </el-input>
        </div>
        <div slot="footer">
          <el-button @click="showReason = !showReason">取消</el-button>
          <el-button @click="submitReason" type="primary" :loading="btnloadSubmit">确认</el-button>
        </div>
      </el-dialog>
    </DetailView>
   
  </div>
</template>
  
  <script>
  import ImageViewer from '@/components/tableView/imageViewer'
  import WpView from '@/components/wpView'
  import { formatStatus, kvAuditState } from '@/configs/formatters'
  import { downloadUrl } from '@/utils'

  const descLabels = [
    { label: '提交时间', prop: 'createTime' },
    { label: '姓名', cmp: 'slot', slotName: 'userName' },
    { label: '手机号', cmp: 'slot', slotName: 'mobile' },
    { label: '证件号', cmp: 'slot', slotName: 'idCard' },
    { label: '任务名称', prop: 'taskName' },
    { label: '任务编号', prop: 'taskNo' },
    { label: '其他文件', cmp: 'slot', slotName: 'otherDocuments' },
    { label: '备注', prop: 'remark' },
  ]
  const examineDescLabels = [
    { label: '审核时间', prop: 'auditTime' },
    { label: '审核人', prop: 'auditer' },
    { label: '状态', cmp: 'slot', slotName: 'status' },
    { label: '审核意见', prop: 'auditFailreason' },
  ]
  export default {
    components: { ImageViewer, WpView },
    data() {
      return {
        btnloadSubmit: false,
        show: false,
        showReason: false,
        descLabels,
        examineDescLabels,
        detail: {
          attachList: []
        },
        auditState: 0,
        kvAuditState,
        isEdit: false,
        reason: '',
      }
    },
    methods: {
      formatStatus,
      viewPdf(e) {
        downloadUrl(e)
      },
      async open(obj, isEdit) {
        this.isEdit = isEdit
        this.auditState = obj.auditState
        const result = await this.$api.GetTaskDeliverable({ deliverableId: obj.deliverableId })
        result.deliverableList.map((item) => {
          let i = item.attachUrl.lastIndexOf('.')
          item.type = item.attachUrl.substr(i + 1)
        })
        this.detail = Object.assign({}, result)
        this.show = true
      },
      async submitReason() {
        if (this.reason == '') {
          this.$message.error('请输入审核不通过理由')
          return false
        }
        this.btnloadSubmit = true
        const result = await this.$api
          .AuditTaskDeliverable({ deliverableId: this.detail.deliverableId, auditState: 2, auditFailreason: this.reason })
          .finally(() => {
            this.btnloadSubmit = false
          })
        this.reason = null
        this.$message.success('提交成功')
        this.show = false
        this.showReason = false
        this.$emit('refresh')
      },
      async submit() {
        this.btnloadSubmit = true
        await this.$api.AuditTaskDeliverable({ deliverableId: this.detail.deliverableId, auditState: 1 }).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('审核成功')
        this.show = false
        this.$emit('refresh')
      }
    }
  }
  </script>
<style lang="scss" scoped>
.SBtn {
  position: absolute;
  right: 64px;
}
.documents{
  height: 83px;
  width: 96px;
}
</style>