<template>
  <el-dialog
    title="业务确认单"
    class="dialog-medium"
    :visible.sync="show"
    custom-class="confirmation-dialog"
  >
    <div
      class="confirmation-error"
      v-if="
        info.backReason &&
        info.confirmReceiptsMode == 1 &&
        info.confirmState == 3
      "
    >
      {{ info.backReason }}
    </div>
    <el-descriptions
      :column="1"
      border
      :labelStyle="labelStyle"
      class="confirmation-descriptions"
    >
      <el-descriptions-item label="企业主体：">
        {{ info.mainName }}
      </el-descriptions-item>
      <el-descriptions-item label="当前月份：">
        {{ info.dateMonth }}
      </el-descriptions-item>
      <el-descriptions-item label="税源地：">
        {{ info.supplierName }}
      </el-descriptions-item>
      <el-descriptions-item label="总金额：">
        <span v-format="'#,##0.00'">{{ info.payFinishAfterTaxAmount }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <div v-if="info.confirmReceiptsMode == 1">
      <div>
        基于税源地风控规则，需要您下载
        <el-link
          type="primary"
          :underline="false"
          :disabled="downLoadDisabled"
          @click="downLoadWd"
          class="order"
          >《业务确认单》</el-link
        >， 加盖企业公章后上传
      </div>
      <div class="form-view">
        <FormView
          ref="FormView"
          :labels="labels"
          onInputChange="Uploadchange"
          :formConfig="{ labelWidth: '140px' }"
          @fileUploaded="fileUploaded"
        >
        </FormView>
        <div class="files-tips">（支持.jpg/.jpeg/.png/.pdf,大小不超过10M）</div>
      </div>
    </div>
    <div v-else>
      您可以下载
      <el-link
        type="primary"
        :underline="false"
        :disabled="downLoadDisabled"
        @click="downLoadWd"
        class="order"
        >《业务确认单》</el-link
      >
      ，进行核对
    </div>
    <div slot="footer">
      <el-button plain @click="show = !show">取 消</el-button>
      <el-button @click="submit" type="primary" :loading="btnloadSubmit">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { downloadDocx } from "@/utils";
export default {
  data() {
    return {
      show: false,
      labelStyle: {
        "text-align": "right",
        width: "140px",
      },
      labels: businessForm,
      btnloadSubmit: false,
      downLoadDisabled: false,
      info: {},
      status: 0,
      name: "",
    };
  },
  methods: {
    fileUploaded(res, file) {
      this.name = file.name;
    },
    async downLoadWd() {
      this.downLoadDisabled = true;
      const result = await this.$api
        .downloadWordTemplate({
          confirmReceiptsId: this.id,
        })
        .finally(() => {
          this.downLoadDisabled = false;
        });
      downloadDocx(result, "业务确认单模板");
    },
    clear() {
      this.$nextTick(() => {
        this.$refs.FormView.fillData({});
      });
    },
    open(row, status) {
      this.id = row.id;
      this.status = status;
      this.show = true;
      this.getData();
      // this.clear();
    },

    async getData() {
      let res = await this.$api.getConfirmInfo({ ConfirmReceiptsId: this.id });
      if (res) {
        this.info = res;
        this.$nextTick(() => {
          if (this.info.confirmReceiptsMode == 1)
            this.$refs.FormView.fillData({
              attachUrl: res.fileList[0]?.fileUrl,
            });
        });
      }
    },

    getName(val) {
      let idx = val.lastIndexOf("\/");
      return val.substring(idx + 1);
    },
    // 提交
    async submit() {
      this.btnloadSubmit = true;
      let parameter = {
        confirmReceiptId: this.id,
        confirmReceiptFileList: [],
      };
      if (this.info.confirmReceiptsMode == 1) {
        const result = await this.$refs.FormView.validate();
        parameter.confirmReceiptFileList = [
          {
            fileName: this.name,
            fileUrl: result.attachUrl,
          },
        ];
      }
      let res = null;
      if (this.status == 1) {
        res = await this.$api.confirmReceiptsOrder(parameter).finally(() => {
          this.btnloadSubmit = false;
        });
      } else {
        res = await this.$api
          .anewconfirmReceiptsOrder(parameter)
          .finally(() => {
            this.btnloadSubmit = false;
          });
      }
      if (res) {
        this.show = false;
        this.$emit("submit");
      }
    },
  },
};
const businessForm = [
  {
    cmp: "upload",
    prop: "attachUrl",
    label: "确认文件",
    suffixArray: ["jpg", "png", "jpeg", "pdf"],
    type: "service",
    itemConfig: {
      rules: [{ required: true, message: "请上传文件", trigger: "blur" }],
    },
  },
];
</script>

<style lang="scss" scoped>
.confirmation-dialog {
  .confirmation-error {
    background: rgba(236, 128, 141, 0.2);
    color: #d9001b;
    border: 1px solid rgb(236, 128, 141);
    padding: 8px;
    border-radius: 4px;
    width: 90%;
  }
  .title {
    text-align: center;
    font-size: 20px;
    margin: 14px 0;
  }
  .order {
    color: #02a7f0;
  }
  .confirmation-descriptions {
    margin-bottom: 14px;
  }
  .form-view {
    margin-top: 14px;
    position: relative;
    .files-tips {
      position: absolute;
      top: 48px;
      left: 140px;
      color: #aaaaaa;
    }
  }
}
</style>

<style>
.confirmation-dialog .el-dialog__header {
  padding-bottom: 0;
}
</style>
