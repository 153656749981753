<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <el-alert type="warning" :closable="false" style="margin-bottom: 18px; flex-shrink: 0">
      <p>
        1.此操作仅支持下载T+{{
          t0State ? 0 : 2
        }}之前的回单，按日生成；<br />2.已生成的批量回单留存失效为3天（或3个自然日），超过3天（或3个自然日）将会自动删除，请及时下载。
      </p>
    </el-alert>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetReceiptZipPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item">
        <el-button type="primary" @click="$refs.Create.open(t0State)">
          <i class="main-btn-icon el-icon-self-book-3-line"></i>
          <span>生成回单</span>
        </el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="row.state == 1 && !!row.zipUrl" type="text" @click="download(row)">下载</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Create ref="Create" @submit="$refs.vTable.refreshTable()"></Create>
  </div>
</template>

<script>
import Create from './create.vue'
import { formatStatus } from '@/configs/formatters'
import { downloadUrl } from '@/utils'
const searchList = [
  {
    cmp: 'dtpickers',
    label: '生成时间',
    prop: ['StartTime', 'EndTime']
  },
  {
    cmp: 'select',
    label: '文件状态',
    prop: 'State',
    options: [
      { key: '0', label: '生成中' },
      { key: '1', label: '已生成' }
    ],
    config: { placeholder: '全部' }
  }
]
const tableLabels = [
  {
    label: '选择日期',
    prop: 'selectTime',
    formatter: (row, column, cellValue, index) => {
      const arr = cellValue.split('_'),
        time = (cellValue && `${arr[0]} - ${arr[1]}`) || '无',
        numStr = `, 共${row.zipCount || 0}笔`

      return time + numStr
    }
  },
  { label: '结算单编号', prop: 'planNo', config: { width: 130 } },
  {
    label: '状态',
    prop: 'state',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '文件生成中' },
          { key: 1, label: '文件已生成' }
        ],
        cellValue
      )
    }
  },
  {
    label: '生成时间',
    prop: 'finishTime',
    config: { width: 168 },
    formatter: (row, column, cellValue, index) => {
      if (!cellValue || cellValue == '0001-01-01 00:00:00') return '-'
      return cellValue
    }
  }
]
const t0SuplierList = [
  '748a16768fa24d30a8920856c1861bf6',
  '14b6695e752a4d36b1bbe0db045bf975',
  '1a5d97b8edbd427a9a4de31a612f5ff7',
  '4a35afff100d485fb50cf098102ddb0c',
  '70968c2a1d5a40adb485c2720aa5ca2f',
  '85b444ad9296436fa1c8b58eb38ee49d',
  '9f60056f1a7a4a46a3fae053976dbc10',
  'f142d0791a5041279d197428d1080375'
]
export default {
  components: { Create },
  data() {
    return {
      searchList: searchList,
      tableLabels: tableLabels,
      btnloadDownload: false,
      supplierId: null
    }
  },
  computed: {
    t0State() {
      return t0SuplierList.find((item) => item == this.supplierId)
    }
  },
  methods: {
    download(one) {
      if (!one.zipUrl) return this.$message.error('没有可下载的链接')
      downloadUrl(one.zipUrl)
    }
  },
  async mounted() {
    // 获取商户信息
    const res = await this.$api.GetMerchantInfo()
    this.supplierId = res.supplierId
  }
}
</script>
