<template>
  <div class="form-wrap">
    <el-form ref="form" :model="form" v-bind="formCfg" @submit.native.prevent>
      <el-row>
        <template v-for="(item, index) in labels">
          <el-divider :key="item.prop" v-if="item.sessionTitle" content-position="left">{{ item.sessionTitle }}</el-divider>
          <!-- 自定义表单项，数组形式返回idx，需要slot判定idx -->
          <slot name="form-items" v-if="slotIdxList && slotIdxList.includes(index)" :idx="index" :form="form" />
          <el-col :key="index" :span="item.col || 24" v-if="!item.show || stringToFunction(item.show)">
            <el-form-item class="form-line" :prop="isArray(item.prop) ? item.prop[0] : item.prop" :label="item.label" v-bind="item.itemConfig">
              <ComponentFormItem
                :model="form"
                :value="item.prop"
                v-bind="item"
                :disabled="!!(item.disabled && stringToFunction(item.disabled))"
                :itemIndex="itemIndex"
                @input="updateModelData(item.prop, $event)"
                @onInputChange="onInputChange"
                @fileUploaded="fileUploaded"
                v-on="$listeners"
              ></ComponentFormItem>
              <!-- 底部标示 -->
              <p class="under-suffix" v-if="item.underSuffix">注：{{ item.underSuffix }}</p>
              <!-- 侧边栏展示 -->
              <div class="sider-bar" v-if="item.sideBar">{{ item.sideContent }}</div>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// 表单基础配置项
const DefaultFormCfg = {
  labelWidth: '100px',
  labelSuffix: '：'
}
import ComponentFormItem from './item'
import { excludeEmpty, isArray, isNull, deepClone } from '@/utils'
export default {
  name: 'ComponentForm',
  components: { ComponentFormItem },
  props: {
    // 数据头列表
    labels: {
      type: Array,
      required: true
    },
    // 非必填项，有默认值
    // 表单其他配置项
    slotIdxList: Array,
    formConfig: Object,
    initData: Object,
    itemIndex: Number,
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    // 表单配置项格式化
    formCfg() {
      return Object.assign({}, DefaultFormCfg, this.formConfig)
    }
  },
  mounted() {
    if (this.initData) this.form = deepClone(this.initData)
  },
  methods: {
    isArray,
    // 填充表单数据
    fillData(data) {
      this.form = deepClone(data)
      setTimeout(() => {
        this.clearValidate()
      }, 0)
    },
    // 增补表单数据
    upData(data) {
      this.form = Object.assign({}, this.form, data)
      setTimeout(() => {
        this.clearValidate()
      }, 0)
    },
    // 清除validate
    clearValidate() {
      this.$refs.form.clearValidate()
    },
    // validate
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) return resolve(deepClone(this.form)), valid
          reject('请填写完整表单!')
          return valid
        })
      })
    },
    // 获取form
    getForm() {
      return this.validate()
    },
    // 表单项更新
    updateModelData(key, { val, cmp }) {
      if (isArray(key) && isArray(val)) {
        for (let i in key) {
          this.$set(this.form, key[i], val[i] || '')
        }
        // this.$refs.form.validateField(key[0])
      } else {
        this.$set(this.form, key, val)
        if (cmp == 'upload') this.$refs.form.validateField(key)
      }
    },
    // 字符串转function并返回结果
    stringToFunction(str) {
      const s2f = new Function('form', 'return ' + str)
      return s2f(this.form)
    },
    // change事件
    async onInputChange(val, key, index) {
      this.$emit('onInputChange', val, key, index)
    },
    async fileUploaded(val, key){
      this.$emit('fileUploaded', val, key)
    },
  }
}
</script>

<style lang="scss" scoped>
.item-inline {
  .el-form-item__content {
    & > div {
      display: inline-block;
    }
  }
}
.sider-bar {
  margin-left: 20px;
}
.under-suffix {
  font-size: 12px;
  line-height: 1.2;
  margin-top: 14px;
}
</style>
