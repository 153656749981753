<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="labels" :searchList="searchList" apiName="GetMerchantUserPageList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('005600')">
        <el-button type="primary" @click="$refs.Edit.open()">
          <i class="main-btn-icon el-icon-self-cengjia"></i>
          <span>新增子账号</span>
        </el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="160" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('005603')" type="text" @click="onSwitch(row)">{{ row.isEnable ? '启用' : '禁用' }}</el-button>
          <el-button v-if="$hasAccess('005601')" type="text" @click="$refs.Edit.open(row)">编辑</el-button>
          <el-button v-if="$hasAccess('005602')" type="text" class="el-button-error" @click="delOne(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { searchSubMerchant } from '@/configs/searches.js'
import { tableSubMerchant } from '@/configs/tables.js'
import Edit from './edit'
export default {
  components: { Edit },
  data() {
    return {
      searchList: searchSubMerchant,
      labels: tableSubMerchant
    }
  },
  methods: {
    // 切换启用禁用
    async onSwitch(item) {
      const state = item.isEnable ? '启用' : '禁用'
      await this.$confirm(`确认要${state}当前子账号吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = this.$api.DisabledMerchantChildUser({ userId: item.userId })
      this.$message.success(`任务已${state}`)
      item.isEnable = state == '启用' ? 0 : 1
    },
    // 删除
    async delOne(item) {
      await this.$confirm(`确认删除当前子账号吗？`, '删除', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.DeleteMerchantChildUser({ userId: item.userId })
      this.$message.success('子账号已删除')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
