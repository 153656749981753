<template>
  <el-form-item :label="label" class="search-item">
    <template v-if="isTypeOf('rangeInput')">
      <RangeInput v-bind="$attrs" :rangeVal="fakeValue" :cfg="cfg" @otherchange="otherchange" />
    </template>
    <!-- 输入框 -->
    <el-input v-if="isTypeOf('input')" v-model="fakeValue" v-bind="cfg"></el-input>
    <!-- 普通选择器，带options -->
    <el-select v-if="isTypeOf('select')" v-model="fakeValue" v-bind="cfg">
      <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.key"> </el-option>
    </el-select>
    <!-- 带搜索的级联选择器 -->
    <el-cascader v-if="isTypeOf('cascader')" v-model="fakeValue" v-bind="cfg"></el-cascader>
    <!-- 日期选择器 -->
    <el-date-picker v-if="isTypeOf('dpicker')" v-model="fakeValue" v-bind="cfg"> </el-date-picker>
    <!-- 日期时间范围选择器，prop是数组 -->
    <el-date-picker v-if="isTypeOf('dtpickers')" v-model="fakeValue" v-bind="cfg"> </el-date-picker>
  </el-form-item>
</template>

<script>
const dtPickersShortcuts = [{
        text: '今日',
        onClick(picker) {
          const end = new Date(), start = new Date(), today = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`

          start.setTime(new Date(`${today} 00: 00: 00`).getTime())
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '昨日',
        onClick(picker) {
          const end = new Date(), start = new Date(), yesterdayTime = new Date(start.getTime() - 3600 * 1000 * 24), yesterday = `${yesterdayTime.getFullYear()}-${yesterdayTime.getMonth() + 1}-${yesterdayTime.getDate()}`

          end.setTime(new Date(`${yesterday} 23: 59: 59`).getTime())
          start.setTime(new Date(`${yesterday} 00: 00: 00`).getTime())
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '7日',
        onClick(picker) {
          const end = new Date(), start = new Date()

          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '30日',
        onClick(picker) {
          const end = new Date(), start = new Date()

          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      }]
// 基础配置项
const DefaultCfg = {
  clearable: true
}
const DtpickersCfg = {
  defaultTime: ['00:00:00', '23:59:59'],
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期',
  rangeSeparator: '至',
  valueFormat: 'yyyy-MM-dd HH:mm:ss',
  type: 'datetimerange',
  pickerOptions: {
    shortcuts: dtPickersShortcuts
  },
}

import { isNull, isArray, deepClone } from '@/utils'
import RangeInput from './rangeInput.vue'
export default {
  name: 'ComponentTableColumn',
  components: {
    RangeInput
  },
  props: {
    model: Object,
    // 表单数据项
    value: String | Number | Array,
    // 表单项标示
    cmp: String,
    // 表单域model字段
    prop: String | Array,
    // 标签文本
    label: String,
    // 其他配置项
    config: Object,
    // other
    // select的options
    options: Array
  },
  computed: {
    // 配置项格式化
    cfg() {
      if (this.cmp == 'dtpickers') return Object.assign({}, DefaultCfg, DtpickersCfg, this.config)
      const placeholder = (this.cmp == 'select' || this.cmp == 'cascader' ? '请选择' : '请输入') + this.label
      const style = (this.cmp == 'select' || this.cmp == 'input') ? { width: '160px' } : {}
      return Object.assign({ placeholder, style }, DefaultCfg, this.config)
    },
    fakeValue: {
      get() {
        if (isArray(this.value))
          return this.value.reduce((next, it) => {
            next.push(this.model[it] || '')
            return next
          }, [])
        return this.model[this.value]
      },
      set(val) {
        if (isArray(this.value) && isNull(val)) {
          this.$emit('input', [])
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  methods: {
    otherchange(e) {
      this.fakeValue = e
    },
    // 特殊项匹配判定
    isTypeOf(name) {
      return name === this.cmp
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  width: 70px;
  height: 70px;
  display: block;
  margin: 0 auto;
}
</style>

<style lang="scss">
.el-form--inline {
  .search-item {
    margin-right: 30px;
    .el-form-item__label {
      padding-right: 2px;
    }
  }
}
</style>
