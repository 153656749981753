<template>
  <div class="tabs-wrap">
    <div class="tab-active-bar" :style="{ width: tabBar.w + 'px', transform: 'translateX(' + tabBar.l + 'px)' }"></div>
    <div
      v-for="(item, index) in tabs"
      :key="item.id"
      :ref="'tabItem_' + item.id"
      class="tab-item"
      :class="{ active: item.id == tabActive }"
      @click="switchTab(item, index)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    active: null
  },
  data() {
    return {
      tabBar: { w: 0, l: 5 }
    }
  },
  computed: {
    tabActive: {
      get() {
        return this.active || (this.tabs[0] && this.tabs[0].id)
      },
      set(val) {
        this.$emit('update:active', val)
      }
    }
  },
  watch: {
    tabActive(val, old) {
      if (val != old && val) this.setTabActiveBar()
    },
    tabs(val, old) {
      if (val != old && val) this.setTabActiveBar()
    }
  },
  mounted() {
    this.setTabActiveBar(this.tabActive)
  },
  methods: {
    switchTab(it, idx) {
      if (it.id == this.tabActive) return false
      this.tabActive = it.id
    },
    setTabActiveBar() {
      this.$nextTick(() => {
        if (!this.tabActive || !this.$refs['tabItem_' + this.tabActive]) return false
        const one = this.$refs['tabItem_' + this.tabActive][0]
        this.tabBar = {
          w: one.offsetWidth,
          l: one.offsetLeft
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-wrap {
  position: relative;
  margin: 0 0 16px;
  padding: 0 5px 10px;
  display: flex;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e4e7ed;
    z-index: 1;
  }

  .tab-item {
    position: relative;
    font-size: 16px;
    color: #86909c;
    line-height: 1;
    padding: 6px;
    cursor: pointer;
    margin-right: 20px;

    &:hover,
    &.active {
      color: #1d2129;
    }
  }

  .tab-active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: #30d68c;
    z-index: 2;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
}
</style>
