<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetAddressInfo" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('005101')">
        <el-button type="primary" @click="$refs.Edit.open()">
          <i class="main-btn-icon el-icon-self-cengjia"></i>
          <span>新增</span>
        </el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="185" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" v-if="$hasAccess('005100') && row.isDefault != '是'" @click="defaultOne(row)">设为默认</el-button>
          <el-button type="text" v-if="$hasAccess('005100')" @click="$refs.Edit.open(row)">编辑</el-button>
          <el-button type="text" v-if="$hasAccess('005101')" class="el-button-error" @click="delOne(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableAddressInfo } from '@/configs/tables'
import { searchAddressInfo } from '@/configs/searches'
import Edit from './edit'
export default {
  components: { Edit },
  data() {
    return {
      tableLabels: tableAddressInfo,
      searchList: searchAddressInfo
    }
  },
  methods: {
    // 设置默认
    async defaultOne(item) {
      const res = await this.$api.DefaultAddressInfo({ addressId: item.addressId })
      this.$message.success('已更新默认地址')
      this.$refs.vTable.refreshTable()
    },
    // 删除
    async delOne(item) {
      await this.$confirm(`确认删除当前地址吗？`, '删除', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.DeleteAddressInfo({ addressId: item.addressId })
      this.$message.success('地址已删除')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
