<template>
  <div>
    <el-dialog :title="!id ? '添加' : '编辑' + title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
      <!-- 内容区域 -->
      <div v-if="show">
        <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '150px' }" :slotIdxList="[10]" @onInputChange="onInputChange">
          <!-- <template #form-items="{ idx, form }">
            <el-col v-if="id && idx === 10" :span="22">
              <el-form-item label="签约状态">
                <span style="margin-right: 50px">{{ other.contractMode == 20 && other.isSign != 1 ? '未签约' : '已签约' }}</span>
                <el-checkbox v-if="other.contractMode == 20 && other.isSign != 1" v-model="form.updateSign">更新签约信息</el-checkbox>
              </el-form-item>
            </el-col>
          </template> -->
        </FormView>
      </div>
      <span slot="footer">
        <el-button plain @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <WpView ref="Wp" :visible="false" :id="workPeopleId" @geted="wpGeted" @need="wpNeed" />
  </div>
</template>

<script>
import { formWorkPeople } from '@/configs/forms'
import WpView from '@/components/wpView'
export default {
  components: { WpView },
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      workPeopleId: null,
      show: false,
      title: '创客',
      fromLabels: formWorkPeople,
      people: {},
      detail: {},
      other: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.people = {}
    },
    open(obj, other) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        // const { id, taskName, taskDescribe, taskNumber } = obj
        this.id = obj.id
        this.workPeopleId = obj.workPeopleId
        this.other = other
        // 处理是否显示图片
        formWorkPeople.find((item) => item.prop == 'idFrontPhoto').config.noPreview = !other.isShowWorkerPhoto
        formWorkPeople.find((item) => item.prop == 'idBackPhoto').config.noPreview = !other.isShowWorkerPhoto
        // 需要先获取授权用户信息
        this.$nextTick(() => {
          this.$refs.Wp.handlerClick()
        })
      } else {
        // 添加
        // 处理是否显示图片
        formWorkPeople.find((item) => item.prop == 'idFrontPhoto').config.noPreview = false
        formWorkPeople.find((item) => item.prop == 'idBackPhoto').config.noPreview = false
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData({})
        })
      }
    },
    // 需要验证码
    wpNeed() {
      setTimeout(() => {
        this.$message.warning('输入短信验证码后才能进行编辑')
      }, 10)
    },
    // 确认成功
    wpGeted(res) {
      const { name, mobile, idCard } = res
      this.people = { userName: name, mobile, idCard }
      this.getDetail(this.id)
    },
    // 获取创客详情
    async getDetail(id) {
      const res = await this.$api.GetWorkPeopleDetail({
        workPeopleId: id
      })
      this.detail = res
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(Object.assign({ id: this.id, isSign: this.other.isSign }, this.detail, this.people))
      })
    },
    // 验证输入空格并替换
    onInputChange(event, label) {
      const list = ['userName', 'mobile', 'idCard']
      if (list.includes(label)) {
        let exp = /[^\S\n\r\t]/
        if (exp.test(event)) {
          let val = event.replace(/[^\S\n\r\t]/g, '')
          this.$refs.vForm.upData({ [label]: val })
        }
      }
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      if (this.id) {
        // 编辑
        const res = await this.$api.UpdateWorkPeople(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddWorkPeople(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
