<template>
  <el-upload
    :action="uploadUrl"
    :show-file-list="false"
    v-loading="loading"
    class="file-uploader"
    :headers="{
      Authorization: 'Bearer ' + $store.state.user.token
    }"
    :before-upload="onBeforeUpload"
    :on-error="onUploadError"
    :on-success="onUpload"
  >
    <el-button slot="trigger" type="primary">
      <i class="el-icon-self-upload-2-line" style="margin: 0 -1px 0 -4px; font-size: 16px"></i>
      <span>{{ button || '导入' }}</span>
    </el-button>
  </el-upload>
</template>

<script>
import { MessageLogout } from '@/utils/request'
export default {
  name: 'ComponentFormImport',
  props: {
    url: String,
    button: String
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    uploadUrl() {
      return process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + this.url
    }
  },
  methods: {
    onBeforeUpload(file, fileList) {
      // 文件大小限制
      if (file.size / (1024 * 1024) > 10) {
        this.$message.warning(`当前限制文件大小不能大于10M`)
        return false
      }
      // 格式限制
      // let suffix = this.getFileType(file.name)
      // let suffixArray = ['jpg', 'pdf']
      // if (suffixArray.indexOf(suffix) === -1) {
      //   this.$message.warning(`只能上传jpg或pdf文件`)
      //   return false
      // }
      this.loading = true
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    onUploadError(err) {
      this.loading = false
      // 401判断
      const e401 = /^Error:\sfail\sto\spost(.|\n)*401$/
      if (e401.test(err)) return MessageLogout()
      this.$message.error(err.message || '上传失败')
    },
    onUpload(res) {
      if (!res || res.code !== '0' || !res.message) return this.onUploadError(res)
      this.loading = false
      this.$emit('uploaded', res.message)
    }
  }
}
</script>
