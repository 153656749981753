<template>
  <el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body width="800px">
    <div slot="title" class="t-wrap">
      <div class="t-alert">
        <el-alert title="您需要完成以下操作" show-icon type="warning" :closable="false"> </el-alert>
      </div>
      <el-button v-if="$store.state.user.auth.signType == 0 && show" circle icon="el-icon-refresh" @click="refresh"></el-button>
      <div class="refresh-text" v-if="$store.state.user.auth.signType != 0 && show">
        如您已认证或签约成功，请点此 <el-button @click="refresh" size="mini" type="warning">刷新</el-button>
      </div>
      <el-button circle size="mini" icon="el-icon-close" @click="show = false"></el-button>
    </div>
    <template v-if="$store.state.user.auth.signType != 0 && show">
      <el-row style="margin-bottom: 18px; line-height: 32px" v-if="showAuth">
        <el-col :span="16">您需要完成企业认证</el-col>
        <el-col :span="8">
          <el-tag v-if="$store.state.user.auth.isAuth" type="success">已认证</el-tag>
          <el-button v-else-if="$store.state.user.auth.authUrl" type="text" @click="linkto($store.state.user.auth.authUrl)">去认证</el-button>
          <span v-else>请联系运营人员协助您完成认证，联系电话{{ phone }}</span>
        </el-col>
      </el-row>
      <template v-for="(item, index) in $store.state.user.supplierSignList">
        <el-row style="line-height: 32px" v-if="item.isSign != 2" :key="index">
          <el-col :span="16"
            ><span v-if="!$store.state.user.auth.isAuth || (item.signType == 1 && !item.signUrl)">待平台为您生成</span>《{{
              item.contractName
            }}》</el-col
          >
          <el-col :span="8">
            <span v-if="item.signType == 2 && item.isSign != 2">请联系运营人员协助您完成签约，联系电话{{ phone }}</span>
            <span v-else-if="!$store.state.user.auth.isAuth || !item.isSign || !item.signUrl">如有疑问，请您联系{{ phone }}</span>
            <el-button v-else-if="item.isSign == 1" type="text" @click="linkto(item.signUrl)">去签约</el-button>
            <el-tag v-else type="success">已签约</el-tag>
          </el-col>
        </el-row>
      </template>
    </template>
    <p v-if="$store.state.user.auth.signType == 0 && show" style="text-align: center">
      需签约后才能继续使用系统，请联系运营人员协助您完成签约，联系电话{{ phone }}
    </p>
    <span slot="footer"> </span>
  </el-dialog>
</template>

<script>
import { downloadUrl } from '@/utils'
export default {
  data() {
    return {
      show: false,
      phone: '027-87002787'
    }
  },
  computed: {
    showAuth() {
      const has = this.$store.state.user.supplierSignList.find((item) => {
        return item.signType == 1
      })
      return !!has
    }
  },
  methods: {
    open() {
      this.show = true
    },
    linkto(url) {
      if (!url) return this.$message.warning('链接未生成，请稍后刷新重试')
      downloadUrl(url)
    },
    refresh() {
      this.$store.dispatch('user/getAuth').then((res) => {
        if (
          res.signType == 0 ||
          // (res.signType == 1 && !res.isAuth) ||
          res.supplierSignList.find((item) => {
            return item.isSign != 2
          })
        ) {
          this.$message.info('状态已刷新')
        } else {
          this.$message.success('已完成签约，请继续使用~')
          this.show = false
        }
        this.$root.$children[0].showGuideDialog(res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.t-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .t-alert {
    flex-grow: 1;
    margin-right: 15px;
    .el-alert {
      background-color: transparent;
    }
  }
  .refresh-text {
    font-size: 13px;
    line-height: 18px;
    color: #e6a23c;
    margin-right: 15px;
  }
}
</style>
