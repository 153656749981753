<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="labels" :seq="false" :tableConfig="{ border: false, size: 'medium' }" apiName="GetSystemMessagePageList">
      <el-form-item slot="btnline-item">
        <el-button type="text" @click="allRead">全部标记为已读</el-button>
      </el-form-item>
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="toDetail(row)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Info ref="Info" />
  </div>
</template>

<script>
import { tableNotice } from '@/configs/tables'
import Info from './info'
export default {
  components: { Info },
  data() {
    return {
      labels: tableNotice
    }
  },
  methods: {
    toDetail(item) {
      item.isRead = true
      this.$refs.Info.open(item)
    },
    async allRead() {
      await this.$api.SetAllSystemMessageRead()
      this.$message.success('已经全部标记为已读')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
