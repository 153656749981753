import {
  kvTableTemplateStatus,
  kvWorkPeopleIsSign,
  kvWorkPlanAuditState,
  kvWorkPlanGrantState,
  kvWorkPlanPayState,
  kvRechargeState,
  kvRechargeType,
  kvWithdrawSearchState,
  kvWhiteState,
  kvState,
  kvDelState,
  kvSearchAuditStatus,
  kvFundType,
  kvBussinessType,
  kvIsReturn
} from './formatters'

import API from '@/api'

// 搜索模版
export const searchTemplate = [
  { cmp: 'input', label: '输入框1', prop: 'xxa' },
  {
    cmp: 'input',
    label: '输入框2',
    prop: 'xxb',
    config: { placeholder: '请输入自定义' }
  },
  {
    cmp: 'select',
    label: '选择框1',
    prop: 'xxc',
    options: kvTableTemplateStatus
  },
  { cmp: 'dtpickers', label: '时选框1', prop: ['xxd1', 'xxd2'] }
]
// 任务列表
export const searchTaskManage = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入任务名称/任务编号',
      style: { width: '200px' }
    }
  },
  {
    cmp: 'select',
    label: '任务类型',
    prop: 'TaskType',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '结算任务' },
      { key: 1, label: '普通任务' }
    ]
  },
  {
    cmp: 'select',
    label: '审核状态',
    prop: 'AuditState',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '待审核' },
      { key: 1, label: '审核通过' },
      { key: 2, label: '审核不通过' }
    ]
  },
  {
    cmp: 'select',
    label: '任务状态',
    prop: 'TaskState',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '未开始' },
      { key: 1, label: '进行中' },
      { key: 2, label: '已关闭' },
      { key: 3, label: '已终止' }
    ]
  }
]
// 申请开票（按结算单）
export const searchWorkplanInfo = [
  { cmp: 'input', label: '结算单号', prop: 'planNo' },
  {
    cmp: 'dtpickers',
    label: '发放日期',
    prop: ['grantFinishTimeBegin', 'grantFinishTimeEnd']
  },
  {
    cmp: 'cascader',
    label: '开票税目',
    prop: 'InvoiceTaxId',
    config: {
      filterable: true,
      props: {
        lazy: true,
        value: 'taxId',
        label: 'taxName',
        emitPath: false,
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node
          if (level == 0) {
            const res = await API.GetTaxItems()
            const nodes = res.list
            resolve(nodes)
          }
          if (level == 1) {
            const res = await API.GetTaxItems({ taxId: value })
            const list = res.list
            const nodes = list.map((item) => {
              return Object.assign({ leaf: true }, item)
            })
            resolve(nodes)
          } else {
            resolve()
          }
        }
      }
    }
  },
  {
    prop: 'SupplierName',
    cmp: 'input',
    label: '税源地',
  },
  { cmp: 'input', label: '任务编号', prop: 'taskNo' },
  { cmp: 'input', label: '任务名称', prop: 'taskName' }
]
// 申请开票（按结算明细）
export const searchWorkDetailInfo = [
  { cmp: 'input', label: '结算单号', prop: 'planNo' },
  {
    cmp: 'dtpickers',
    label: '打款完成日期',
    prop: ['payFinishTimeBegin', 'payFinishTimeEnd']
  },
  {
    cmp: 'cascader',
    label: '开票税目',
    prop: 'InvoiceTaxId',
    config: {
      filterable: true,
      props: {
        lazy: true,
        value: 'taxId',
        label: 'taxName',
        emitPath: false,
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node
          if (level == 0) {
            const res = await API.GetTaxItems()
            const nodes = res.list
            resolve(nodes)
          }
          if (level == 1) {
            const res = await API.GetTaxItems({ taxId: value })
            const list = res.list
            const nodes = list.map((item) => {
              return Object.assign({ leaf: true }, item)
            })
            resolve(nodes)
          } else {
            resolve()
          }
        }
      }
    }
  },
  {
    prop: 'SupplierName',
    cmp: 'input',
    label: '税源地',
  },
  { cmp: 'input', label: '任务编号', prop: 'taskNo' },
  { cmp: 'input', label: '任务名称', prop: 'taskName' }
]
// 开票记录
export const searchBillInfo = [
  { cmp: 'input', label: '申请编号', prop: 'applyNumber' },
  {
    cmp: 'select',
    label: '审核状态',
    prop: 'verifyState',
    options: [
      { key: 1, label: '待审核' },
      { key: 2, label: '审核已通过' },
      { key: 3, label: '审核不通过' },
      { key: 4, label: '已撤回' }
    ],
    config: { placeholder: '全部' }
  },
  {
    cmp: 'select',
    label: '开票状态',
    prop: 'billState',
    options: [
      { key: 1, label: '未开票' },
      { key: 2, label: '待开票' },
      { key: 3, label: '开票中' },
      { key: 4, label: '已开票' },
      { key: 5, label: '已撤回' }
    ],
    config: { placeholder: '全部' }
  },
  {
    prop: 'SupplierName',
    cmp: 'input',
    label: '税源地',
  },
  {
    cmp: 'select',
    label: '配送状态',
    prop: 'distributionState',
    options: [
      { key: 1, label: '未配送' },
      { key: 2, label: '已配送' }
    ],
    config: { placeholder: '全部' }
  },
  {
    cmp: 'dtpickers',
    label: '申请日期',
    prop: ['createTimeBegin', 'createTimeEnd']
  }
]
// 完税凭证
export const searchTaxVoucherAttach = [
  {
    cmp: 'dpicker',
    label: '完税月份',
    prop: 'TaxTime',
    config: {
      valueFormat: 'yyyy-MM-dd',
      type: 'month'
    }
  }
]
// 邮寄地址列表
export const searchAddressInfo = [
  { cmp: 'input', label: '姓名', prop: 'name', config: {placeholder: '请输入收件人姓名'} },
  { cmp: 'input', label: '电话', prop: 'phone', config: {placeholder: '请输入收件人手机号/座机号', style: { width: '220px' }} }
]
// 发票抬头列表
export const searchRiseInfo = [
  { cmp: 'input', label: '抬头名称', prop: 'riseName', config: {placeholder: '请输入企业名称'} },
  { cmp: 'input', label: '注册电话', prop: 'phone' }
]
// 创客列表
export const searchWorkPeople = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入创客姓名/手机号/证件号',
      style: { width: '230px' }
    }
  },
  {
    cmp: 'select',
    label: '签约状态',
    prop: 'isSign',
    options: kvWorkPeopleIsSign
  },
  {
    cmp: 'dtpickers',
    label: '添加时间',
    prop: ['StartCreateTime', 'EndCreateTime']
  },
  {
    cmp: 'dtpickers',
    label: '签约时间',
    prop: ['StartSignTime', 'EndSignTime']
  }
]
// 创客列表任务
export const searchWorkPeopleTask = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入任务名称/任务编号',
      style: { width: '300px' }
    }
  }
]
// 创客校验
export const searchWorkPeopleCheck = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入创客姓名/证件号/手机号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '校验状态',
    prop: 'CheckResult',
    options: [
      { key: 1, label: '通过' },
      { key: 2, label: '不通过' }
    ],
    config: {
      placeholder: '全部',
    }
  }
]
// 结算单搜索
export const searchFinalStatement = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入结算单编号/任务编号/任务名称',
      style: { width: '280px' }
    }
  },
  // {
  //   cmp: 'select',
  //   label: '审核状态',
  //   prop: 'AuditState',
  //   more: true,
  //   options: kvWorkPlanAuditState
  // },
  {
    cmp: 'select',
    label: '发放状态',
    prop: 'GrantState',
    options: kvWorkPlanGrantState
  },
  {
    cmp: 'dtpickers',
    label: '创建时间',
    prop: ['CreateTimeBegin', 'CreateTimeEnd']
  },
  {
    cmp: 'select',
    label: '付款渠道',
    prop: 'PaymentChannel',
    options: [
      { key: '银行/银行卡', label: '银行/银行卡' },
      { key: '支付宝', label: '支付宝' },
      { key: '微信', label: '微信' }
    ]
  }
]
// 结算单校验
export const searchWorkPlanCheck = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/证件号/手机号',
      style: { width: '200px' }
    }
  },
  {
    cmp: 'select',
    label: '下发类型',
    prop: 'GrantType',
    options: [
      { key: '银行卡', label: '银行卡' },
      { key: '支付宝', label: '支付宝' },
      { key: '微信', label: '微信' }
    ],
    config: {
      placeholder: '全部',
    }
  },
  {
    cmp: 'select',
    label: '校验结果',
    prop: 'checkResult',
    options: [
      { key: 1, label: '通过' },
      { key: 2, label: '不通过' }
    ],
    config: {
      placeholder: '全部',
    }
  }
]

// 结算审核里面的明细
export const searchWorkPlanWorkDetail = [
  {
    cmp: 'input',
    label: '',
    prop: 'PeopleSearchTxt',
    config: {
      placeholder: '请输入姓名/证件号/手机号进行搜索',
      style: { width: '300px' }
    }
  }
]
// 结算明细搜索
export const searchBillingDetails = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入订单编号/结算单号/任务编号/任务名称',
      style: { width: '320px' }
    }
  },
  // { cmp: 'select', label: '审核状态', prop: 'AuditState', options: kvWorkPlanAuditState },
  // { cmp: 'select', label: '发放状态', prop: 'GrantState', options: kvWorkPlanGrantState },
  // {
  //   cmp: 'select',
  //   label: '打款状态',
  //   prop: 'PayState',
  //   more: true,
  //   options: kvWorkPlanPayState
  // },
  {
    cmp: 'dtpickers',
    label: '打款完成时间',
    prop: ['PayFinishTimeBegin', 'PayFinishTimeEnd']
  },
  {
    cmp: 'select',
    label: '下发类型',
    prop: 'GrantType',
    options: [
      { key: '银行/银行卡', label: '银行/银行卡' },
      { key: '支付宝', label: '支付宝' },
      { key: '微信', label: '微信' }
    ]
  },
  { cmp: 'input', label: '商户流水号', prop: 'ShopNo' },
  { cmp: 'input', label: '姓名', prop: 'UserName' },
  { cmp: 'input', label: '证件号', prop: 'IdCard' },
  {
    cmp: 'dtpickers',
    label: '创建时间',
    prop: ['CreateTimeBegin', 'CreateTimeEnd'],
  },
  {
    cmp: 'select',
    label: '是否退汇',
    prop: 'IsReturn',
    options: kvIsReturn,
  }
]

// 充值流水搜索
export const searchRechargeRecord = [
  {
    cmp: 'input',
    label: '流水号',
    prop: 'SearchTxt',
  },
  {
    cmp: 'dtpickers',
    label: '打款时间',
    prop: ['RechargeTimeBegin', 'RechargeTimeEnd']
  },
  {
    cmp: 'select',
    label: '类型',
    prop: 'RechargeType',
    options: kvRechargeType
  },
  {
    cmp: 'select',
    label: '充值状态',
    prop: 'RechargeState',
    options: kvRechargeState
  }
]
// 支出流水搜索
export const searchDisburseRecord = [
  {
    cmp: 'input',
    label: '订单号',
    prop: 'SearchTxt',
  },
  {
    cmp: 'dtpickers',
    label: '打款时间',
    prop: ['DisburseTimeBegin', 'DisburseTimeEnd']
  }
]
// 提现记录搜索
export const searchWithdrawalRecord = [
  {
    cmp: 'input',
    label: '提现单号',
    prop: 'SearchTxt',
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['WithdrawTimeBegin', 'WithdrawTimeEnd']
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'WithdrawState',
    options: kvWithdrawSearchState
  }
]

// 交付物搜索搜索
export const searchRegulate = [
  { prop: 'UserName', cmp: 'input', label: '创客姓名' },
  {
    prop: 'CurrentMonth',
    cmp: 'dpicker',
    config: { type: 'month', valueFormat: 'yyyy-MM' },
    label: '当前月份'
  },
  // {
  //   cmp: 'input',
  //   label: '所属企业',
  //   prop: 'BeEnterprise',
  //   config: {
  //     placeholder: '请输入企业名称',
  //     style: { width: '250px' }
  //   }
  // },
  {
    prop: 'DeliverableUploadStatus',
    cmp: 'select',
    label: '交付物状态',
    options: [
      { label: '全部', key: null },
      { label: '待上传', key: 0 },
      { label: '已上传', key: 1 }
      // { label: '已退回', key: 2 }
    ]
  },
  {
    prop: 'AcceptanceUploadStatus',
    cmp: 'select',
    label: '验收单状态',
    options: [
      { label: '全部', key: null },
      { label: '待上传', key: 0 },
      { label: '已上传', key: 1 }
      // { label: '已退回', key: 2 }
    ]
  },

  {
    cmp: 'select',
    label: '交付物审核状态',
    prop: 'DeliverableAuditStatus',
    options: kvSearchAuditStatus,
    config: {
      style: { width: '190px' }
    }
  },
  {
    cmp: 'select',
    label: '验收单审核状态',
    prop: 'AcceptanceAuditStatus',
    options: kvSearchAuditStatus,
    config: {
      style: { width: '190px' }
    }
  }
]

// 业务确认单搜索
export const searchBusinessBills = [
  { prop: 'MainName', cmp: 'input', label: '企业名称' },
  // {
  //   prop: 'SupplierName',
  //   cmp: 'select',
  //   label: '税源地',
  //   options: [],
  //   config: { style: { width: '300px' } }
  // },
  {
    prop: 'Month',
    cmp: 'dpicker',
    label: '当前月份',
    config: { type: 'month', valueFormat: 'yyyy-MM' }
  },
  {
    prop: 'UploadState',
    cmp: 'select',
    label: '上传状态',
    options: [
      { label: '待上传', key: 0 },
      { label: '已上传', key: 1 }
    ]
  },
  {
    prop: 'AuditState',
    cmp: 'select',
    label: '审核状态',
    options: [
      { label: '待审核', key: 0 },
      { label: '审核中', key: 1 },
      { label: '审核通过', key: 2 },
      { label: '退回', key: 3 }
    ]
  }
]

// 黑名单搜索
export const searchBlacklist = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/身份证号码/手机号',
      style: { width: '230px' }
    }
  }
]

// 白名单搜索
export const searchWhitelist = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/身份证号码/手机号',
      style: { width: '230px' }
    }
  },
  {
    prop: 'AuditState',
    cmp: 'select',
    label: '状态',
    options: kvWhiteState
  },
  {
    cmp: 'dtpickers',
    label: '添加时间',
    prop: ['StartTime', 'EndTime']
  }
]

// 子账户搜索
export const searchSubMerchant = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入登录账号/账号名称/手机号',
      style: { width: '250px' }
    }
  }
]
// 交付物审核
export const searchDeliverablesCheck = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/手机号/身份证号',
      style: { width: '220px' }
    }
  },
  {
    cmp: 'input',
    prop: 'TaskName',
    label: '任务名称',
  },
  {
    cmp: 'input',
    label: '任务编号',
    prop: 'TaskNo',
  },
  {
    prop: 'AuditState',
    cmp: 'select',
    label: '状态',
    options: kvDelState
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['StartTime', 'EndTime']
  }
]
// 接单审核
export const searchOrderReceiving = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/手机号/身份证号',
      style: { width: '220px' }
    }
  },
  {
    cmp: 'input',
    prop: 'TaskName',
    label: '任务名称',
  },
  {
    cmp: 'input',
    label: '任务编号',
    prop: 'TaskNo',
  },
  {
    prop: 'State',
    cmp: 'select',
    label: '状态',
    options: kvState
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['ApplyStartTime', 'ApplyEndTime']
  }
]
// 结算明细搜索
export const searchAccountCapital = [
  {
    cmp: 'dtpickers',
    label: '查询时间',
    prop: ['FundTimeBegin', 'FundTimeEnd']
  },
  {
    cmp: 'select',
    label: '业务类型',
    prop: 'FundType',
    options: kvFundType
  },
  { cmp: 'input', label: '业务订单号', prop: 'FundNo' },
  { cmp: 'rangeInput', label: '收支金额', prop: ['MinAmount', 'MaxAmount'], config: { controls: false, precision: 2 } },
  { cmp: 'input', label: '账户号码', prop: 'AccountNumber' }
]
// 抵扣金明细搜索
export const searchDiscountDetail = [
  { cmp: 'input', label: '业务流水号', prop: 'SerialNo' },
  {
    cmp: 'dtpickers',
    label: '交易时间',
    prop: ['StartTime', 'EndTime']
  },
  {
    cmp: 'select',
    label: '收支类别',
    prop: 'Type',
    options: [
      { key: 0, label: '全部' },
      { key: 1, label: '转入' },
      { key: 2, label: '转出' }
    ]
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'State',
    options: [
      { key: 0, label: '全部' },
      { key: 1, label: '已入账' },
      { key: 2, label: '冻结中' },
      { key: 3, label: '已出账' },
      { key: 4, label: '已解冻' }
    ]
  }
]
