<template>
  <el-dialog title="生成回单" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- <el-alert type="warning" :closable="false" style="margin-bottom: 18px;">
            <p>温馨提示：<br />1.系统根据您选择的日期，对该日期下打款成功订单的回单进行批量下载<br />2.回单文件名为订单编号</p>
        </el-alert> -->
    <div v-if="show">
      <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }"> </FormView>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { regular } from '@/configs/validate'
const receiptCreate = [
  {
    cmp: 'dtpickers',
    label: '选择日期',
    prop: ['startTime', 'endTime'],
    itemConfig: {
      rules: [{ required: true, message: '请选择结算单发放完成日期', trigger: 'change' }]
    },
    config: {
      placeholder: '请选择结算单发放完成日期',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > dayjs().endOf('day').subtract(2, 'day').valueOf()
        }
      },
      style: { width: '500px' }
    }
  },
  {
    cmp: 'input',
    label: '姓名',
    prop: 'userName',
    config: { style: { width: '500px' } }
  },
  {
    cmp: 'input',
    label: '身份证号',
    prop: 'idCard',
    col: 11,
    itemConfig: {
      rules: [{ pattern: regular.IDCard, message: regular.IDCardMsg, trigger: 'blur' }]
    },
    config: { style: { width: '500px' } }
  },
  {
    cmp: 'input',
    label: '收款账号',
    prop: 'receivingAccount',
    config: { style: { width: '500px' } }
  }
]

export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      formLabels: receiptCreate
    }
  },
  methods: {
    // 数据清空
    clear() {},
    async open(t0State = false) {
      this.clear()
      if (t0State) {
        this.formLabels[0].config.pickerOptions = {
          disabledDate(time) {
            return time.getTime() > dayjs().endOf('day').subtract(0, 'day').valueOf()
          }
        }
      } else {
        this.formLabels[0].config.pickerOptions = {
          disabledDate(time) {
            return time.getTime() > dayjs().endOf('day').subtract(2, 'day').valueOf()
          }
        }
      }
      this.show = true
    },
    // submit
    async submit() {
      const params = await this.$refs.vForm.getForm()

      params.startTime = dayjs(params.startTime).format()
      params.endTime = dayjs(params.endTime).format()
      this.btnloadSubmit = true
      const res = await this.$api.BuildReceiptZip(params).finally(() => {
        this.btnloadSubmit = false
      })
      if (res) {
        this.$message.success('生成回单中')
        this.$emit('submit')
        this.show = false
      }
    }
  }
}
</script>
