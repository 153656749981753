<template>
  <div>
    <!-- 输入框 -->
    <el-input v-if="isTypeOf('input')" v-model.trim="fakeValue" v-bind="cfg" @change="onInputChange($event, prop, itemIndex)"></el-input>
    <!-- 数字输入框 -->
    <template v-if="isTypeOf('number')">
      <div v-if="cfg.unitText">
        <el-input-number v-model="fakeValue" v-bind="cfg" @change="onInputChange($event, prop, itemIndex)"/>
        <span class="unit-text">{{ cfg.unitText }}</span>
      </div>
      <el-input-number v-else v-model="fakeValue" v-bind="cfg" @change="onInputChange($event, prop, itemIndex)"/>
    </template>
    <!-- 普通选择器，带options -->
    <el-select v-if="isTypeOf('select')" v-model="fakeValue" v-bind="cfg" @change="onInputChange($event, prop, itemIndex)">
      <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.key"> </el-option>
    </el-select>
    <!-- 单选，带options -->
    <el-radio-group v-if="isTypeOf('radio')" v-model="fakeValue" v-bind="cfg">
      <el-radio :label="item.key" v-for="item in options" :key="item.key">{{ item.label }} </el-radio>
    </el-radio-group>
    <!-- 省市区级联 -->
    <el-cascader v-if="isTypeOf('pca')" v-model="fakeValue" v-bind="cfg" :options="pcaOptions" :props="{ value: 'label' }" style="width: 100%"></el-cascader>
    <!-- 多选，带options -->
    <el-checkbox-group v-if="isTypeOf('checkbox')" v-model="fakeValue" v-bind="cfg">
      <el-checkbox v-for="item in options" :label="item.key" :key="item.key">{{ item.label }}</el-checkbox>
    </el-checkbox-group>
    <!-- 日期选择器 -->
    <el-date-picker v-if="isTypeOf('dpicker')" v-model="fakeValue" v-bind="cfg"> </el-date-picker>
    <!-- 日期时间范围选择器，prop是数组 -->
    <el-date-picker v-if="isTypeOf('dtpickers')" v-model="fakeValue" type="datetimerange" v-bind="cfg"> </el-date-picker>
    <!-- 文件上传 -->
    <Upload
      v-if="isTypeOf('upload')"
      :modelValue="fakeValue"
      :config="cfg"
      :suffixArray="suffixArray"
      :type="type"
      @update="fileUpdate($event)"
      @uploaded="fileUploaded"
    ></Upload>
    <multipleUpload v-if="isTypeOf('multipleUpload')" :modelValue="fakeValue" :config="cfg" @update="fileUpdate($event)"></multipleUpload>
  </div>
</template>

<script>
// 表单item配置项
const DefaultItemCfg = {}
// 表单内容配置项
const DefaultCfg = {
  minWidth: '100px',
  align: 'center'
}
const DtpickersCfg = {
  defaultTime: ['00:00:00', '23:59:59'],
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期',
  rangeSeparator: '至',
  valueFormat: 'yyyy-MM-dd HH:mm:ss'
}
import multipleUpload from './multipleUpload'
import { isNull, isArray, deepClone } from '@/utils'
import Upload from './upload'
import { regionData } from 'element-china-area-data'
export default {
  name: 'ComponentFormItem',
  components: { Upload, multipleUpload },
  props: {
    model: Object,
    // 表单数据
    value: String | Number | Array,
    // 表单项标示
    cmp: String,
    // 表单域model字段
    prop: String | Array,
    // 标签文本
    label: String,
    // 其他配置项
    itemConfig: Object,
    config: Object,
    // other
    // select的options
    options: Array,
    // 可控
    disabled: Boolean,
    // 上传类型
    type: String,
    suffixArray: {
      type: Array,
      default: () => ['jpg', 'png', 'docx']
    },
    itemIndex: Number
  },
  data() {
    return {
      pcaOptions: regionData
    }
  },
  computed: {
    // 表单item配置项格式化
    itemCfg() {
      return Object.assign({}, DefaultItemCfg, this.itemConfig)
    },
    // 表单内容配置项格式化
    cfg() {
      if (this.cmp == 'dtpickers') return Object.assign({}, DefaultCfg, DtpickersCfg, this.config)
      const placeholder = (this.cmp == 'input' ? '请输入' : '请选择') + this.label
      return Object.assign({ placeholder, disabled: this.disabled }, DefaultCfg, this.config)
    },
    fakeValue: {
      get() {
        if (isArray(this.value)) {
          return this.value.reduce((next, it) => {
            if (this.cmp == 'pca' && !this.model[it]) return next
            next.push(this.model[it] || '')
            return next
          }, [])
        }
        return this.model[this.value]
      },
      set(val) {
        if (isArray(this.value) && isNull(val)) {
          this.$emit('input', { val: [], cmp: this.cmp })
        } else {
          this.$emit('input', { val, cmp: this.cmp })
        }
      }
    }
  },
  methods: {
    // 特殊项匹配判定
    isTypeOf(name) {
      return name === this.cmp
    },
    // 上传回调
    fileUpdate(val) {
      this.fakeValue = val
    },
    fileUploaded(val, file){
      this.$emit('fileUploaded', val, file)
    },
    // change事件
    async onInputChange(val, key, index) {
      this.$emit('onInputChange', val, key, index)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
</style>
