<template>
  <div>
    <el-button class="detail-tabs-btn btn-table-refresh" type="text" icon="el-icon-self-refresh-line" @click="$refs.vTable.refreshTable()"
      >刷新</el-button
    >
    <TableView
      ref="vTable"
      :hideTitle="true"
      :title="tabName == 'two' ? '创客列表' : '领取列表'"
      :labels="tableLabels"
      :apiName="tabName == 'two' ? 'GetWorkPeoplePageList' : 'GetTaskManageReceiveList'"
      :searchList="searchList"
      :searchExt="{ TaskNo: tabName == 'three' ? null : taskNo, TaskId: tabName == 'three' ? taskId : null }"
    >
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="tabName == 'two'" type="text" @click="$refs.WorkPeopleInfo.open(row)">查看</el-button>
          <el-button v-if="row.IsContract == 0 && tabName == 'two'" type="text" @click="sendSignMsg(row)">签约</el-button>
          <el-button v-if="tabName == 'three'" type="text" @click="$refs.Detail.open(row, false)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
    <WorkPeopleInfo ref="WorkPeopleInfo"></WorkPeopleInfo>
    <Detail ref="Detail"></Detail>
  </div>
</template>

<script>
import { tableWorkPeople, tableWorkDetailPeople } from '@/configs/tables'
import { searchWorkPeople } from '@/configs/searches'
import WorkPeopleInfo from '../../workPeople/info'
import Detail from '../orderReceiving/detail'
export default {
  components: { WorkPeopleInfo, Detail },
  props: {
    taskNo: String,
    tabName: String,
    taskId: String
  },
  data() {
    return {}
  },
  computed: {
    tableLabels() {
      return this.tabName == 'three' ? tableWorkDetailPeople : tableWorkPeople
    },
    searchList() {
      return this.tabName == 'three' ? [] : searchWorkPeople
    }
  },
  watch: {
    tabName(e) {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    }
  },
  methods: {
    // 签约
    async sendSignMsg(row) {
      let params = {
        id: row.id,
        type: 1,
        supplierPeopleId: row?.supplierPeopleId
      }
      const res = await this.$api.WorkPeopleSendSignMsg(params)
    }
  }
}
</script>
