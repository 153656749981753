import store from '@/store'
const title = process.env.VUE_APP_TITLE

/**
 * 设置title
 */
export const setTitle = (name, hasConfig = false) => {
  let resTitle = name
  if (hasConfig) {
    resTitle = (store.state.app.oemTitle || title) + '-' + name
  }
  document.title = resTitle
}

/**
 * 获取title
 */
export const getTitle = (disConfig = false) => {
  if (disConfig) {
    return document.title
  }
  return document.title
}

/**
 * 修改ico
 */
export const changeFavicon = (src) => {
  const link = document.createElement('link');
  const oldLink = document.querySelector('link[rel="icon"]');
  link.rel = 'icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}