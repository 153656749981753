<template>
  <el-dialog title="汇款充值" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <p>
      户名：{{ detail.alipayAccountName || '无' }}
      <i v-if="detail.alipayAccountName" class="copy el-icon-copy-document" :data-clipboard-text="detail.alipayAccountName" @click="copy"></i>
    </p>
    <p>
      账号：{{ detail.alipayAccountNumber || '无' }}
      <i v-if="detail.alipayAccountNumber" class="copy el-icon-copy-document" :data-clipboard-text="detail.alipayAccountNumber" @click="copy"></i>
    </p>
    <p>
      银行：{{ detail.alipayAccountBank || '无' }}
      <i v-if="detail.alipayAccountBank" class="copy el-icon-copy-document" :data-clipboard-text="detail.alipayAccountBank" @click="copy"></i>
    </p>
    <p>
      支行：{{ detail.alipayAccountBranch || '无' }}
      <i v-if="detail.alipayAccountBranch" class="copy el-icon-copy-document" :data-clipboard-text="detail.alipayAccountBranch" @click="copy"></i>
    </p>
    <p>
      开户地：{{ detail.alipayAccountAddresses || '无' }}
      <i
        v-if="detail.alipayAccountAddresses"
        class="copy el-icon-copy-document"
        :data-clipboard-text="detail.alipayAccountAddresses"
        @click="copy"
      ></i>
    </p>
    <p>
      联行号：{{ detail.alipayAccountLines || '无' }}
      <i v-if="detail.alipayAccountLines" class="copy el-icon-copy-document" :data-clipboard-text="detail.alipayAccountLines" @click="copy"></i>
    </p>
    <p style="margin-top: 30px">1.登录你的网上银行（任意银行）必须使用本公司同名的银行账户进行汇款转账，首次使用建议小金额转入测试。</p>
    <p>2.转账时，填写以上专属账户，请梳已收款账户支行选择：支付宝-备付金账户、开户地选择：上海市-上海市，否则支付宝无法收到资金。</p>
    <p>3.汇款请在工作日09:00-17:00进行，17:00之后提交的充值根据央行大小额通道规则可能申请第二天到账。具体时间根据短信为准。</p>
    <span slot="footer">
      <el-button @click="show = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      detail: {}
    }
  },
  methods: {
    clear() {
      this.detail = {}
    },
    open(e) {
      console.log(e)
      this.clear()
      this.detail = e
      this.show = true
    },
    copy(e) {
      const clipboard = new Clipboard(e.target)
      clipboard.on('success', (e) => {
        this.$message.success('已复制到剪切板')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message.error('复制失败，请手动复制')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.copy {
  margin-left: 10px;
  color: #78c390;
  cursor: pointer;
}
</style>
