<template>
  <el-upload
    :action="isUploadType"
    :show-file-list="false"
    v-loading="loading"
    class="file-uploader"
    :headers="extraData"
    :before-upload="onBeforeUpload"
    :on-error="onUploadError"
    :on-success="onUpload"
    :on-preview="handlePreview"
    v-bind="config"
    style="margin-right: 15px; display: inline-block"
  >
    <el-button slot="trigger" icon="el-icon-upload" style="margin-right: 15px" type="primary" size="mini" :disabled="config.disabled">{{
      fakeValue ? '重传' : '上传'
    }}</el-button>
    <template v-if="fakeValue">
      <el-link v-if="!config.noPreview" type="primary" @click="lookFile(fakeValue)" style="max-height: 24px"
        >查看文件 <i class="el-icon-view el-icon--right"></i>
      </el-link>
      <span v-else>已上传</span>
    </template>
    <el-image-viewer
      v-if="showViewer"
      :zIndex="9999"
      :url-list="viewerlList"
      :on-close="
        () => {
          showViewer = false
        }
      "
    />
  </el-upload>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { downloadUrl } from '@/utils'
export default {
  name: 'ComponentFormUpload',
  components: {
    ElImageViewer
  },
  props: {
    modelValue: String,
    type: String,
    config: Object,
    suffixArray: {
      type: Array,
      default: () => ['jpg', 'png', 'docx']
    }
  },
  data() {
    return {
      loading: false,
      uploadUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + 'Upload/UploadFile',
      uploadExcelUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + 'Upload/UploadExcelFile',
      uploadPictureUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + 'Upload/UploadPictureFile',
      uploadServeUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + 'Upload/UploadConfirmReceiptsFile',
      showViewer: false,
      viewerlList: []
    }
  },
  computed: {
    // 判断上传文件类型
    isUploadType() {
      if (this.type == 'excel') {
        return this.uploadExcelUrl
      } else if (this.type == 'picture') {
        return this.uploadPictureUrl
      } else if (this.type == 'service') {
        return this.uploadServeUrl
      } else {
        return this.uploadUrl
      }
    },
    extraData() {
      return {
        Authorization: 'Bearer ' + this.$store.state.user.token
      }
    },
    fakeValue: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update', val)
      }
    }
  },
  methods: {
    handlePreview() {},
    onBeforeUpload(file, fileList) {
      // 文件大小限制
      if (file.size / (1024 * 1024) > 10) {
        this.$message.warning(`当前限制文件大小不能大于10M`)
        return false
      }
      // 格式限制
      let suffix = this.getFileType(file.name)
      if (this.suffixArray.indexOf(suffix) === -1) {
        console.log(this.suffixArray)
        this.$message.warning(`只能上传${this.suffixArray.toString()}`)
        return false
      }
      this.loading = true
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    onUploadError(err) {
      this.loading = false
      this.$message.error(err.message || '上传失败')
    },
    lookFile(e) {
      console.log(e)
      const regImg = /\.(jpg|jpeg|png|JPG)$/
      const regpdf = /\.(pdf)$/
      const regzip = /\.(zip)$/
      const regrar = /\.(rar)$/
      if (regImg.test(e)) {
        this.viewerlList = [e]
        this.showViewer = true
      } else if (regpdf.test(e)) {
        downloadUrl(e)
      } else if (regzip.test(e)) {
        downloadUrl(e)
      } else if (regrar.test(e)) {
        downloadUrl(e)
      } else {
        downloadUrl(e)
      }
    },
    onUpload(res, file) {
      if (!res || res.code !== '0' || !res.data) return this.onUploadError(res)
      this.loading = false
      this.fakeValue = res.data
      this.$message.success('上传成功')
      this.$emit('uploaded', res.data, file)
    },
    //word\pdf\excel\预览方法
    wordPreview(url) {
      // var xurl = "https://view.xdocin.com/xdoc?_xdoc=";
      var xurl = 'https://view.officeapps.live.com/op/view.aspx?src='
      xurl += encodeURIComponent(url)
      var ops = {
        _pdf: true //word/excel文档尝试以pdf方式显示，默认false
        // "_watermark": "XDOC文档预览", //水印文本，显示水印
        // "_saveable": false, //是否允许保存PDF，默认true
        // "_printable": false, //是否允许打印PDF，默认true
        // "_copyable": false, //是否允许选择复制内容，默认true
        // "_toolbar": false, //是否显示底部工具条，默认true
        // "_title": "文档预览", //自定义标题
        // "_expire": 30, //预览链接有效期，单位分钟，默认永久有效
        // "_limit": "1,3", //限制页数，如：“5”表示只显示前5页，“2,5”表示从第2页开始的5页，对pdf/doc/docx/ppt/pptx有效
      } //传入预览参数
      for (var a in ops) {
        xurl += '&' + encodeURIComponent(a) + '=' + encodeURIComponent(ops[a])
      }
      window.open(xurl)
    }
  }
}
</script>
