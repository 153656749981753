<template>
  <el-dialog :title="type == 1 ? '提交审核' : '确认发放'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-medium">
    <!-- 内容区域 -->
    <div v-if="show">
      <el-descriptions :column="2" size="medium" :labelStyle="{ width: '90px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="结算单号">{{ detail.planNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="人数">{{ detail.totalPeopleNum || '无' }}</el-descriptions-item>
        <el-descriptions-item label="提交金额">{{ (detail.issueAmount || 0).toFixed(2) }}元</el-descriptions-item>
        <el-descriptions-item label="服务费">{{ (detail.serviceFee || 0).toFixed(2) }}元</el-descriptions-item>
        <el-descriptions-item label="差额服务费">{{ (detail.serviceDiffFee || 0).toFixed(2) }}元</el-descriptions-item>
        <el-descriptions-item label="合计金额">{{ (detail.settleAmount || 0).toFixed(2) }}元</el-descriptions-item>
        <el-descriptions-item v-if="type == 2 && isEnableMonthly" label="服务费抵扣金"
          >{{ (detail.deductionAmount || 0).toFixed(2) }}元</el-descriptions-item
        >
        <el-descriptions-item v-if="type == 2" label="账户余额支付">{{ (detail.balanceAmount || 0).toFixed(2) }}元</el-descriptions-item>
        <!-- <el-descriptions-item style="flex-basis: 100%" label="合计金额">{{ (detail.settleAmount || 0).toFixed(2) }}元</el-descriptions-item> -->
        <template v-if="businessType == 2">
          <el-descriptions-item label="个税"
            ><span v-format="'#,##0.00'">{{ detail.individualTax || 0 }}</span></el-descriptions-item
          >
          <el-descriptions-item label="增值税"
            ><span v-format="'#,##0.00'">{{ detail.addedTax || 0 }}</span></el-descriptions-item
          >
          <el-descriptions-item label="税金及附加"
            ><span v-format="'#,##0.00'">{{ detail.surchargesTax || 0 }}</span></el-descriptions-item
          >
        </template>
        <el-descriptions-item
          v-if="type != 1 && isPayNotify == 1"
          label="短信验证码将发送至"
          :span="2"
          :labelStyle="{
            width: '130px',
            textAlign: 'right',
            flexShrink: 0
          }"
          >{{ detail.mobile }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="type == 2 && isPayNotify == 1"
          label="短信验证码"
          :span="2"
          :labelStyle="{
            width: '75px',
            textAlign: 'right',
            flexShrink: 0,
            lineHeight: '32px'
          }"
        >
          <el-form ref="vForm" :model="form" :rules="rules" :inline="true" @submit.native.prevent>
            <el-form-item prop="verificationCode"><el-input v-model="form.verificationCode" placeholder="请输入验证码"></el-input></el-form-item>
            <el-form-item
              ><el-button type="primary" @click="sendVerifyCode" :disabled="vcCountdown != 0" style="width: 120px">{{
                vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
              }}</el-button></el-form-item
            >
          </el-form>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <template slot="footer">
      <div v-if="type == 1 && accountBalance" style="font-size: 13px; color: #fe7c40;text-align: left;margin-top: -20px;margin-bottom: 20px;">
        当前服务费抵扣金可用余额<span v-format="'#,##0.00'">{{ accountBalance }}</span
        >，审批通过后自动抵扣该笔结算单服务费
      </div>
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submitSH" v-if="type == 1" :loading="btnloadSubmit">确 认</el-button>
      <el-button type="primary" @click="submit" v-if="type == 2" :loading="btnloadSubmit">确认发放</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      type: 1,
      detail: {},
      form: {},
      accountBalance: 0,
      rules: {
        verificationCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      },
      isEnableMonthly: false,
      isPayNotify: 1
    }
  },
  computed: {
    businessType() {
      return this.$store.state.user.businessType
    },
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.form = {}
      this.btnDisabled = false
    },
    open(obj, type, isEnableMonthly = false, isPayNotify = 1) {
      this.clear()
      this.type = type
      this.id = obj.planNo
      this.isEnableMonthly = isEnableMonthly
      this.isPayNotify = isPayNotify
      this.getPre()
    },
    // 获取预发放数据
    async getPre() {
      const amount = await this.$api.GetMerchantInfo()
      this.accountBalance = amount.deductionAvailableAmount
      const res = await this.$api.PreConfirmGrant({ planNos: [this.id] })
      if (res.list && res.list.length) {
        this.detail = res.list[0] || {}
        this.show = true
      } else {
        this.$message.error('结算单提交审核异常，请联系客服处理！')
      }
    },
    // 获取验证吗
    async sendVerifyCode() {
      const res = await this.$api.WorkPlanSendVerifyCode({planNoList: [this.id]})
      this.$store.dispatch('app/vcDownStart')
    },
    // 提交审核
    async submitSH() {
      this.btnloadSubmit = true
      let params = {
        planNo: this.detail.planNo
      }
      const res = await this.$api.StartAuditWorkPlan(params).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('已提交审核')
      this.$emit('submit')
      this.show = false
    },
    // 提交发放
    async submit() {
      if (this.isPayNotify == 1) {
        this.$refs.vForm.validate(async (valid) => {
          if (valid) {
            this.btnloadSubmit = true
            const res = await this.$api.ConfirmGrant(Object.assign({ planNo: this.detail.planNo }, this.form)).finally(() => {
              this.btnloadSubmit = false
            })
            this.$message.success('发放成功')
            this.$emit('submit')
            this.show = false
          }
        })
      } else {
        this.btnloadSubmit = true
        const res = await this.$api.ConfirmGrant({ planNo: this.detail.planNo }).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('发放成功')
        this.$emit('submit')
        this.show = false
      }
    }
  }
}
</script>
