<template>
  <el-descriptions class="descview" v-bind="cfg">
    <template v-for="(item, idx) in descLabels">
      <el-descriptions-item :label="item.label" v-bind="columnCfg(item.columnConfig)" :key="idx">
        <!-- slot项 -->
        <slot v-if="item.cmp == 'slot'" :name="item.slotName" :datas="descDatas"></slot>
        <!-- 状态机字典项 -->
        <template v-if="item.cmp == 'state'">
          <!-- <div v-if="item.stateDot" v-html="formatStatusHasDot(item.stateOptions, descDatas[item.prop])"></div> -->
          <template>{{ formatStatus(item.stateOptions, descDatas[item.prop]) }}</template>
        </template>
        <!-- 金钱格式 -->
        <template v-if="item.cmp == 'money'">{{ textFormat(descDatas[item.prop] || 0, '#,##0.00') || item.holder || holder }}元</template>
        <!-- 普通项 -->
        <template v-if="!item.cmp">{{
          (item.formatter ? item.formatter(descDatas[item.prop], descDatas) : descDatas[item.prop]) || item.holder || holder
        }}</template>
      </el-descriptions-item>
    </template>
  </el-descriptions>
</template>

<script>
import { formatStatus } from '@/configs/formatters.js'
const DefaultCfg = {
  border: true,
  column: 2,
  labelStyle: { textAlign: 'right', width: '120px' },
  contentStyle: { width: '250px' }
}
const DefaultColumnConfig = {}
export default {
  props: {
    config: Object,
    holder: {
      type: String,
      default: '-'
    },
    // 列数据
    descLabels: {
      type: Array,
      required: true
    },
    descDatas: {
      type: Object
    }
  },
  computed: {
    cfg() {
      return Object.assign({}, DefaultCfg, this.config)
    }
  },
  methods: {
    formatStatus,
    columnCfg(columnConfig = {}) {
      return Object.assign({}, DefaultColumnConfig, columnConfig)
    }
  }
}
</script>
