<template>
  <el-dialog :title="title + (type == 1 ? '注册协议' : '隐私政策')" :visible.sync="show" :append-to-body="true" width="800px">
    <!-- 内容区域 -->
    <div class="dialog-wrap">
      <div class="content" v-if="type == 1">
        <h1>注册协议</h1>
        <h2><b>提示条款</b></h2>
        <p><b>欢迎您（下称"用户"或"您"）与灵时云综合服务平台经营者签订本《注册协议》(下称“本协议”)并使用灵时云综合服务平台服务。</b></p>
        <p style="text-indent: 0">
          <b
            >【审慎阅读】您在申请注册流程或使用灵时云综合服务平台服务之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的条款、法律适用和争议解决条款。免除或限制责任的条款将以粗体标识，您应重点阅读。若您对协议有任何疑问，可向共享平台客服咨询。</b
          >
        </p>
        <p style="text-indent: 0">
          <b
            >【签约】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后或您以其他任何明示或者默示方式表示接受本协议的，即表示您已充分阅读、理解并接受本协议的全部内容，并与灵时云综合服务平台经营者达成一致，成为灵时云综合服务平台用户。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序或立即停止使用灵时云综合服务平台服务。</b
          >
        </p>
        <h2><b>一、定义</b></h2>
        <p>1、共享平台：灵时云综合服务平台（域名为www.linsyun.com）。</p>
        <p>
          2、灵时云综合服务平台经营者：下称“我们”，是指灵时云（苏州）信息科技有限公司及其关联公司和/或可能通过共享平台向您提供相关服务的运营关联单位。
        </p>
        <p>3、用户：下称“您”或“用户”，注册、登录、使用、浏览、获取本协议项下服务的个人、企业或其他组织。</p>
        <p>4、共享平台服务：灵时云综合服务平台经营者通过共享平台为平台内用户提供的各类综合服务。</p>
        <h2>二、协议主体</h2>
        <p><b>1、签约主体</b></p>
        <p><b>本协议是您与我们之间关于注册、登录共享平台以及使用共享平台服务所订立的协议。本协议对您与我们均具有合同效力。</b></p>
        <p>
          <b
            >本协议项下，共享平台经营者可能根据共享平台的业务调整而发生变更，变更后的共享平台经营者与您共同履行本协议并向您提供服务，共享平台经营者的变更不会影响您本协议项下的权益。共享平台经营者还有可能因为提供新的共享平台服务而新增，如您使用新增的共享平台服务的，视为您同意新增的共享平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</b
          >
        </p>
        <p><b>2、补充协议 </b></p>
        <p>
          <b
            >由于互联网高速发展，您与我们签署的本协议列明的条款并不能完整罗列并覆盖您与我们所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，共享平台公示的相关声明及政策、共享平台规则和协议均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用共享平台服务，视为您同意上述补充协议。</b
          >
        </p>
        <h2>三、账号注册与使用</h2>
        <p><b>1、用户资格</b></p>
        <p>
          <b
            >您确认，在您申请注册成为共享平台用户时，您应当具备中华人民共和国法律规定的及您与我们达成的其他与共享平台服务相关联的合作协议所约定的与您行为相适应的民事行为能力、资质或专业能力；您不属于中华人民共和国法律规定的及您与我们达成的其他与共享平台服务相关联的合作协议所约定的禁止性使用共享平台服务的主体。否则您应立即停止注册程序，并由您承担因此产生的一切责任。</b
          >
        </p>
        <p>2、账号说明</p>
        <p>
          当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得共享平台账号并成为共享平台用户。<b
            >您的共享平台账号仅限您本人使用。未经我们同意，您直接或间接授权第三方使用您共享平台账号或获取您账号项下信息的行为所导致的一切责任后果由您自行承担，我们对此不承担任何责任。</b
          >
        </p>
        <p>
          <b
            >共享平台账号的所有权归我们所有。仅当有法律明文规定、司法裁定或经我们同意，并符合共享平台规则规定的用户账号转让流程的情况下，您可进行账号的转让。您的账号一经转让，该账号项下权利义务一并转移。除此外，您的账号不得以任何方式转让，否则由此产生的一切责任均由您承担。
          </b>
        </p>
        <p>3、真实身份</p>
        <p>
          您应遵循诚实信用、合法善意的原则，提交注册资料，相关注册资料应当遵守法律法规、公序良俗、信息真实等原则，不应提交任何违法或不良信息。相关资料如有变动，您应及时更新。<b
            >如果因您所提供的注册资料不合法、不真实、不准确或未及时更新，从而导致相关法律责任或不利后果的，您应自行承担。</b
          >
        </p>
        <p>
          <b
            >作为共享平台经营者，为使您更好地使用共享平台的各项服务，保障您的账号安全，我们可要求您按共享平台要求及我国法律规定完成实名认证。未经验证通过的，我们有权拒绝向您提供任何服务，因此而导致的相关法律责任或不利后果，应您由自行承担。</b
          >
        </p>
        <p><b>4、账号使用规范 </b></p>
        <p>
          <b
            >您发现账号存在安全问题，您应立即联系我们予以调查处理，否则我们对潜在损失的产生或扩大不承担任何责任。您知悉并同意，如您在账号信息的保管上、相关上网设备的网络安全维护上存在任何过失导致账号丢失、泄露，您应对此所产生的任何后果负责，我们对此不负任何责任。</b
          >
        </p>
        <p>
          <b
            >您应对您账号项下的所有行为（包括但不限于在线签署任何协议，浏览、支付、提现、上传、发布、输入任何内容）所产生的一切后果负责，通过您的账号所发生的上述各项行为将视为您本人的真实意思表示。</b
          >
        </p>
        <p>
          <b
            >您通过账号在接受和使用共享平台服务中所上传、发送的任何内容都应真实、合法、有效且具有合法来源，如相关内容涉及任何第三方的合法权益，应事先获得相应的许可。如收到涉及您的相关举报或投诉，我们可采取相关法律法规所要求或允许的方式，向相关争议方、相关部门提供包括账号在内的必要的信息，以便解决纠纷争议，保护正当权利人的合法权益。</b
          >
        </p>
        <p><b>5、个人信息保护</b></p>
        <p><b>我们将对您所提交的注册信息中涉及个人信息的内容予以保护，不会将其转移或披露给任</b></p>
        <p><b>何非关联的第三方。但发生以下情形的，您事先已对我们进行授权，同意我们进行转移或披露：</b></p>
        <p><b>（1）相关法律法规或司法机关、行政机关等国家机关要求的；</b></p>
        <p><b>（2）为您提供相关服务所必须的；</b></p>
        <p><b>（3）为完成合并、分立、收购或资产转让而转移的；</b></p>
        <p><b>（4）以维护我们合法权益之目的；</b></p>
        <p><b>（5）经过您的同意，或者依据您与我们达成的其他相关协议规则可以转移或披露给第三方</b></p>
        <p><b>的情形。</b></p>
        <p>
          <b
            >我们将尽可能运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。您认可非因我们故意或重大过失原因造成您的个人信息</b
          >
        </p>
        <p><b>被泄露时，我们不承担任何责任。</b></p>
        <p><b>6、注册信息授权</b></p>
        <p><b>您理解并同意，我们在如下情形出现时，可向第三方提供您的注册信息：</b></p>
        <p><b>（1）为了使您达成使用共享平台服务目的，我们需要向您的交易相对方提供；</b></p>
        <p><b>（2）您需要的服务由第三方提供或由我们与第三方共同提供；</b></p>
        <p><b>（3）在法律法规允许且不违背公序良俗的范围内，为改进共享平台质量，我们将会向第三</b></p>
        <p><b>方提供；</b></p>
        <p><b>（4）其他您理解并同意授权的情形。</b></p>
        <p><b>7、账号停用</b></p>
        <p><b>您理解并同意，我们在如下情形出现时，有权视情节采取包括但不限于后述一种或多种措</b></p>
        <p><b>施：拒绝提供共享平台服务；限制、中止、冻结或注销注册账号；解除终止本协议；要求您承担因下述事宜造成的全部损失：</b></p>
        <p><b>（1）您不具有共享平台用户资格的；</b></p>
        <p><b>（2）您不满足主体认证条件的；</b></p>
        <p><b>（3）您提供虚假资料、信息的；</b></p>
        <p><b>（4）您违反您与我们达成的其他与共享平台服务相关联的合作协议或您与我们达成的其他</b></p>
        <p><b>（5）与共享平台服务相关联的合作协议解除终止的；</b></p>
        <p><b>（6）您违反本协议（含补充协议）其他约定；</b></p>
        <p><b>（7）其他我们有正当理由的。</b></p>
        <p>8、账号自主注销</p>
        <p>
          您在符合共享平台规则规定的账号自主注销流程的情况下，可通过共享平台人工服务注销您的账号。<b
            >账号一旦注销，您将无法再使用，但该账号注销行为并不影响您对您账号注销前的行为所应承担的责任。</b
          >
        </p>
        <h2><b>四、数据保护</b></h2>
        <p>
          <b
            >对于您在使用共享平台服务过程中产生的数据，我们尊重并依法保护您的数据权利。在此前提下，我们收集、记录的您在使用共享平台服务过程中所产生的数据的相关权利归属于我们，且属于我们商业秘密，未经我们事先书面同意，您不得为本协议约定之外的目的使用前述数据或以任何形式提供给他人。</b
          >
        </p>
        <h2><b>五、用户行为</b></h2>
        <p>
          <b
            >1、您在注册账号或使用共享平台服务时，须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：</b
          >
        </p>
        <p><b>（1）反对宪法所确定的基本原则的；</b></p>
        <p><b>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</b></p>
        <p><b>（3）损害国家荣誉和利益的；</b></p>
        <p><b>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</b></p>
        <p><b>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</b></p>
        <p><b>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</b></p>
        <p><b>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</b></p>
        <p><b>（8）侮辱或者诽谤他人，侵害他人合法权益的；</b></p>
        <p><b>（9）假冒任何人或实体或以其它方式歪曲您与任何人或实体之关联性的内容；</b></p>
        <p><b>（10）任何第三方的信息，包括但不限于姓名或名称、地址、电话号码、电子邮件地址、身份证号以及信用卡卡号；</b></p>
        <p><b>（11）病毒、不可靠数据或其它有害的、破坏性的或危害性的文件；</b></p>
        <p>
          <b>（12）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的要求的；</b>
        </p>
        <p><b>（13）侵害他人名誉、隐私权、商业秘密、商标权、著作权、专利权及其他权利；</b></p>
        <p><b>（14）出租、出借、出售、购买银行账号或者支付账号，假冒他人身份或者虚构代理关系开立账号；</b></p>
        <p><b>（15）为破坏或滥用目的注册多个账号，或恶意上传重复的、无效的大容量数据和信息； </b></p>
        <p><b>（16）进行任何破坏共享平台服务公平性或者其他影响共享平台正常秩序的行为；</b></p>
        <p><b>（17）相关法律法规或本协议（含补充协议）、您与我们达成的其他与共享平台服务相关联的合作协议、规则等禁止的。 </b></p>
        <p>
          <b
            >2、如果您在注册账号或使用共享平台服务过程中违反了相关法律法规或本协议约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求我们给予协助。因此给您或者他人造成损害的，您应自行承担全部责任，我们不承担任何责任。</b
          >
        </p>
        <p>
          <b
            >3、如果您违反本协议约定，我们有权进行独立判断并采取相应措施，包括但不限于通过技术手段删除、屏蔽相关内容或断开链接等。同时，我们有权视您的行为性质，采取包括但不限于暂停或终止向您提供服务；限制、中止、冻结或终止您对账号的使用；追究法律责任等措施。</b
          >
        </p>
        <p><b>4、您违反本协议约定，导致任何主体损失的，您应当独立承担责任；我们因此遭受损失的，您也应当一并赔偿。</b></p>
        <h2>六、遵守当地法律监管</h2>
        <p>
          <b
            >1、您在注册账号或使用共享平台服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。</b
          >
        </p>
        <p><b>2、您应避免因注册账号或使用共享平台服务而使我们违反法律法规或被政治和公共事件牵连，否则我们有权暂停或终止对您的服务。</b></p>
        <h2><b>七、按现状提供服务</b></h2>
        <p><b>您理解并同意：</b></p>
        <p>
          <b
            >1、共享平台服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力保障服务的连贯性和安全性，但我们不能随时预见和防范法律、技术以及其他风险，我们对此类风险在法律允许的范围内免责，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</b
          >
        </p>
        <p><b>2、因经营策略安排或调整等原因,不同用户可使用的有关本服务的内容可能会存在差异，具体以我们实际提供的为准。</b></p>
        <h2>八、知识产权保护</h2>
        <p>
          1、共享平台包含的或者提供的任何内容，包括但不限于文字、图片、音频、视频、档案、资讯、网站架构、界面设计、数据、文档、计算机软件等，均由我们或我们的关联方依法拥有知识产权，包括但不限于商标权、专利权、著作权、商业秘密等，但第三方产品及服务或者相关权利人依照法律规定应享有的权利除外。
        </p>
        <p>
          2、共享平台包含的或者提供的图标、按钮、服务名称等均为我们或我们的关联公司方在中国或其他国家依法拥有的商标或商业标识，相关权利人依照法律规定应享有的权利除外。未经我们、我们的关联方或相关权利人事先书面同意，您不得复制、模仿、使用任何上述商标或商业标识，或实施任何其他可能引起相关公众和/或消费者混淆的行为。
        </p>
        <p>
          3、未经我们、我们的关联方或相关权利人事先书面同意，您不得擅自使用、修改、反向工程、反向编译或反汇编、转载、链接、复制、公开传播、散布、发行或公开发表共享平台和本服务的任何程序或内容，或将共享平台（或共享平台的任何部分）和本服务的任何程序或内容嵌入其他平台或服务，或将共享平台（或共享平台的任何部分）和本服务的任何程序或内容进行汇编或建立镜像站点等。
        </p>
        <p>
          4、尊重知识产权是您应尽的义务。未经我们、我们的关联方或相关权利人事先书面同意，您不得为任何营利性或非营利性目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权。如有违反，您需要承担所有的责任。
        </p>
        <h2><b>九、服务的变更、中断、终止</b></h2>
        <p>
          <b
            >1、您理解并同意，根据国家法律法规变化、运营需要或为提升服务质量的目的，我们将不时优化、调整共享平台服务（包括但不限于中断、中止或终止部分或全部服务），本协议的内容也可能随之更新，我们将通过在共享平台上发出公告、站内信等合理、醒目的方式向您进行通知，前述变更内容一经通知即生效（另有说明除外），而无需再另行获得您的同意。若您不同意变更后的协议内容，您应停止使用共享平台服务。若您在上述变更内容实施后继续使用共享平台服务，将视为您已同意各项变更后的内容。</b
          >
        </p>
        <p>
          <b
            >2、您理解并同意，我们为履行本协议之目的，无需再另行获得您的同意，即可将本协议下部分或全部服务及相应的权利义务转交由第三方运营或履行。具体受让主体以我们通知的为准。</b
          >
        </p>
        <p>
          <b
            >3、您理解并同意，无需再另行获得您的同意，在我们发生合并、分立、收购、资产转让时，我们可向第三方转让本服务下相关资产。具体受让主体以我们通知的为准。</b
          >
        </p>
        <p>
          <b
            >3、您有责任自行备份存储在本服务中的数据。如果服务终止，我们有权从服务器上永久地删除您的信息和数据,法律法规另有规定的除外。服务中止或终止后，我们没有义务向您提供或返还信息或数据。</b
          >
        </p>
        <p><b>4、协议期限</b></p>
        <p>
          <b
            >本协议自签订生效之日起一年内有效。协议期满，双方应另行签署新的协议；若双方未签署新的协议，而以实际行动继续合作的，例如您继续使用共享平台服务的，视为双方同意协议期限顺延，直至本协议以其他约定或法定事由终止。若您满足自主注销条件并注销完毕您账号的或我们依据本协议约定注销您账号的，自账号注销之日起，本协议自动终止。</b
          >
        </p>
        <h2><b>十、违约责任</b></h2>
        <p>
          <b
            >1、若您违反本协议约定，我们有权要求您向我们承担违约责任，赔偿我们承受或遭致的所有损失。您赔偿我们损失的范围包括因您的违约行为导致我们对第三方支付的违约金或损害赔偿金、所遭受的政府部门罚款、合理的律师费、诉讼费、公证费、差旅费等。</b
          >
        </p>
        <p><b>2、若您违反本协议约定，我们有权要求解除终止本协议。但无论我们是否解除终止本协议，均不影响我们要求您承担违约责任的权利。</b></p>
        <p><b>3、若我们违反本协议约定，则我们承担责任的范围以可以合理预见的您实际遭受的直接经济损失为限。</b></p>
        <h2>十一、不可抗力或其他免责事由</h2>
        <p><b>1、不可抗力</b></p>
        <p>
          <b
            >您理解并同意，在我们提供本协议项下服务的过程中，可能会遇到不可抗力等风险因素，使导致服务发生中断或终止。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于信息网络设备维护、信息网络连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、疫情、政府行为、法律法规变动、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的不能服务或延迟服务等行为。出现上述情况时，我们将努力在第一时间与相关部门配合，及时进行修复，但是由此给您造成的损失，我们在法律允许的范围内免责。</b
          >
        </p>
        <p><b>2、其他免责事由</b></p>
        <p><b> 您理解并同意，在法律允许的范围内，我们对以下事由所导致的服务中断或终止不承担责任：</b></p>
        <p><b>（1）共享平台维护或升级，或受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</b></p>
        <p><b>（2）您或我们的电脑软件、服务器、系统、硬件、通信线路、供电线路出现故障；</b></p>
        <p><b>（3）您操作不当或通过非经我们授权或认可的方式使用共享平台服务；</b></p>
        <p><b>（4）您通过非法的方式使用本协议项下服务；</b></p>
        <p><b>（5）政府行为（包括不限于因中国政府机关不授予或取消我们的相应经营资质或权利；</b></p>
        <p><b>税务主管机关调整代征税率等）导致我们无法履行部分条款或全部条款义务的；</b></p>
        <p><b>（6）其他我们无法控制或合理预见的情形。</b></p>
        <p><b>尽管有前款约定，我们将采取合理行动积极促使服务恢复正常。</b></p>
        <p><b>3、特殊免责事由</b></p>
        <p>
          <b
            >您理解并同意，我们不对因您出现违约行为或依据本协议约定，我们有权采取的包括但不限于中断或终止向您提供共享平台服务；限制、中止、冻结或终止您对账号的使用等措施，所导致的任何损害承担任何责任。</b
          >
        </p>
        <p>4、信息真实性</p>
        <p>
          我们提示您，在使用共享平台服务的过程中，您可能会遇到网络信息或其他用户行为带来的风险，该等风险包含他人匿名或冒名的含有威胁、诽谤、令人反感、非法、误导、欺骗等任何信息或行为所造成任何心理、生理上的伤害或经济损失。请您仔细甄别，并在遇到上述相关不法行为时及时向我们或有关机关举报或投诉。我们将对相关内容开展日常检查并在收到举报或投诉时进行相应处理，但请您注意，我们不对因第三方侵权行为给您造成的损害负责。
        </p>
        <p><b>5、责任限制</b></p>
        <p>
          <b
            >我们可能同时为您及您的（交易）对手方提供服务，您同意对我们可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张我们在提供服务时存在法律上的瑕疵。</b
          >
        </p>
        <p>
          <b
            >您知悉并确认，我们仅系共享平台的技术提供方，并不参与您与交易相对方之间具体的商品或服务交易。您与交易相对方或其他任何第三方在本协议履行过程中所产生的纠纷或责任应由您与交易相对方或其他任何第三方自行解决或承担，而不应向我们主张，我们对此免责。</b
          >
        </p>
        <p><b>共享平台服务是在现有技术水平下提供的，我们将尽力为您提供服务，但无法保证该服务完全符合您的预期。</b></p>
        <h2>十二、通知与送达</h2>
        <p>1、您知悉并认可，我们可视情况通过下列任意一种或几种方式向您通知重要信息：</p>
        <p>（1）向您注册账号时所提交的电子邮箱地址发送电子信息；</p>
        <p>（2）向您注册账号时所提交的手机号码发送电子信息；</p>
        <p>（3）向您提供的实际地址寄送纸质载体信息；</p>
        <p>（4）在共享平台显著位置刊登电子信息；</p>
        <p>（5）向您在注册账号发送站内信或以其他电子通信方式发送信息。</p>
        <p>
          上述电子信息在发送成功或刊登完成后即视为送达。相关纸质载体的发送，无论是否签收，均应以相关邮寄凭证上的邮寄日期后的第三个自然日视为有效送达。
        </p>
        <p>上述送达方式同样可适用于相关诉讼、仲裁等司法程序（包括但不限于仲裁、一审、二审、再审、执行及督促程序等）。</p>
        <p>
          2、您应当保证所提供的联系方式是准确、有效的，并进行实时更新，如因您提供的联系方式不准确或怠于更新、您拒绝签收等不可归责于我们的原因，导致相关通知、文件、文书无法实际送达，您将自行承担由此可能产生的法律后果。
        </p>
        <h2>十三、管辖与法律适用</h2>
        <p><b>1、本协议的成立、生效、履行、解释及纠纷解决等相关事宜，均适用中华人民共和国大陆地区法律（不包括冲突法）。</b></p>
        <p><b>2、本协议签订地为中华人民共和国湖北省武汉市东湖新技术开发区。</b></p>
        <p><b>3、若您和我们之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</b></p>
        <p>4、本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
        <p>5、本协议的任何条款被中止、终止、撤销、判定无效或不可执行，不影响其他条款的效力或执行。</p>
        <p><b>6、本协议最终解释权归我们方所有，在法律法规允许的范围内，我们保留一切解释和修改的权利。</b></p>
      </div>
      <div class="content" v-if="type == 2">
        <h1>隐私政策</h1>
        <h2>引言</h2>
        <p>
          欢迎您使用灵时云综合服务平台（下称“共享平台”）。灵时云综合服务平台经营者（下称“我们”）深知个人信息对您的重要性，我们会尽全力依法保护您的个人信息安全可靠。同时，我们承诺，将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        </p>
        <p>
          <b
            >本《灵时云综合服务平台隐私政策》（下称“本政策”）与您所使用我们提供的共享平台服务息息相关，您在浏览、注册、登录、使用、退出共享平台时，我们将按照本政策的约定处理和保护您的个人信息，因此我们希望您能够仔细阅读、充分理解本政策的全文，并在需要时，按照本政策的指引，作出您认为适当的选择。重点内容及敏感个人信息我们已将字体加粗请您特别关注。一旦您点击同意、使用或继续使用共享平台服务时，即意味着您同意我们按照本政策处理您的相关信息。如对本政策有任何疑问，您可以通过本政策“如何联系我们”中提供的方式与我们联系。</b
          >
        </p>
        <p>请在使用共享平台服务前，仔细阅读并了解本政策。</p>
        <p>为了您更好的理解本政策的内容，我们根据相关法律法规的定义将其中涉及的专业术语整理如下：</p>
        <p>
          <b>1. 个人信息：</b
          >指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息（出自于《中华人民共和国个人信息保护法》）。我们实际具体收集的个人信息种类以下文描述为准。
        </p>
        <p>
          <b>2. 敏感个人信息：</b
          >指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息（出自于《中华人民共和国个人信息保护法》）。我们实际具体收集的敏感个人信息种类以下文描述为准。相比其他个人信息，敏感个人信息可能对您的个人权益影响更大。我们在此善意的提醒您需在谨慎考虑后再向我们提供您的敏感个人信息。涉及到处理敏感个人信息的，我们会在文中以粗体明显标注。
        </p>
        <p>
          <b>3. 授权同意：</b
          >是指您对您的个人信息进行特定处理做出明确授权的行为，包括通过积极的行为作出授权（即明示同意），也包括通过消极的不作为而作出授权（出自于GB/T35273-2020
          《信息安全技术个人信息安全规范》）。请您知悉，“授权同意”在本政策中有时亦表述为“授权”、“同意”。
        </p>
        <p>
          <b>4. 删除：</b>指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态（出自于 GB/T
          35273-2020《信息安全技术个人信息安全规范》）。
        </p>
        <p>
          <b>5. 去标识化：</b>指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程（出自于《中华人民共和国个人信息保护法》）。
        </p>
        <p><b>6. 匿名化：</b>指个人信息经过处理无法识别特定自然人且不能复原的过程（出自于《中华人民共和国个人信息保护法》）。</p>
        <p>
          <b>7. 自动化决策：</b
          >指通过计算机程序自动分析、评估个人的行为习惯、兴趣爱好或者经济、健康、信用状况等，并进行决策的活动（出自于《中华人民共和国个人信息保护法》）。
        </p>
        <p>
          <b>8. 未成年人：</b
          >不满十八周岁的自然人为未成年人。十六周岁以上的未成年人，以自己的劳动收入为主要生活来源的，视为完全民事行为能力人（出自于《中华人民共和国民法典》）。
        </p>
        <p>除另有约定外，本政策所用定义与《灵时云综合服务平台注册协议》中的定义具有相同的涵义。</p>
        <h2><b>一、 不同业务功能项下个人信息收集使用规则</b></h2>
        <p>在您使用共享平台服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
        <p>1. 为实现向您提供共享平台服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用共享平台服务；</p>
        <p>
          2.
          为实现向您提供共享平台服务的附加功能，您可选择是否授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果。
        </p>
        <p>我们主要通过以下途径收集您的个人信息：</p>
        <p>1. 您在使用共享平台服务时，根据业务需要您主动提供的信息，例如：注册账号时提供的手机号码。</p>
        <p>2. 您在使用共享平台服务时，我们收集、记录、汇总的信息，例如：您的交易信息、浏览记录。</p>
        <p>3. 您在使用共享平台服务时，我们从第三方间接获取的您的信息，例如：授权登录时通过第三方平台提供的手机号码。</p>
        <p>我们在此特别提醒您：</p>
        <h3><b>(一) 业务功能一：服务内容展示</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>
          (1) 设备信息：为了保障共享平台的安全运行，共享平台会收集您设备的 MAC
          地址、IP地址、WLAN接入点、软件版本号、网络接入方式、类型、状态；为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限并根据您的授权获取相关信息。
        </p>
        <p>共计 1 类个人信息。</p>
        <p>
          上述业务功能依赖必要信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息。您在未注册登录状态下我们不会主动要求您再主动提供额外的个人信息。
        </p>
        <p>2. 必要信息对应之服务</p>
        <p>
          用于服务内容的展示，如无需注册或者登录即可查看最新资讯内容、了解我们等。除此之外，我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。
        </p>
        <h3><b>(二) 业务功能二：登录注册</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>(1) 手机号码（包括通过第三方平台（如微信）授权获取的手机号码）；</p>
        <p>(2) 验证码。</p>
        <p>共计 2类个人信息。</p>
        <p>
          我们收集该类信息是为了满足相关法律法规的网络实名制要求，上述业务功能依赖此类信息才得以运行。如您拒绝提供可能导致您注册/登录失败，请您谨慎考虑后提供。
        </p>
        <p>2. 必要信息对应之服务</p>
        <p>用于登录注册共享平台。</p>
        <p>除此之外，我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。</p>
        <h3><b>(三) 业务功能三：实名认证及签约</b></h3>
        <p>1. 必要个人信息收集</p>
        <p><b>(1) 姓名；</b></p>
        <p><b>(2) 身份证号码；</b></p>
        <p><b>(3) 身份证照片；</b></p>
        <p><b>(4) 银行卡信息。</b></p>
        <p>共计 4 类个人信息。</p>
        <p>
          您应知悉，<b>身份证号码、身份证照片、银行卡信息属于您的敏感个人信息。</b>我们收集该类信息是为了满足相关法律法规的要求，上述业务功能依赖此类信息才得以运行。如您拒绝提供可能导致您无法使用我们的此功能，请您谨慎考虑后提供。<b
            >此外，为了避免盗用身份信息（身份证号码、身份证照片）的情况，保障安全性，共享平台可能将通过第三方公司提供的认证服务、识别服务收集并使用您的身份认证信息（包括姓名、身份证号、银行卡）。同时，为协助您快速完成身份认证、风控核验等，对于您曾经在我们的共享平台服务中提供的相关身份认证信息（包括姓名、身份证相关信息、手机号码），您同意我们可以将前述信息的全部或部分自动预填于其他需要您身份认证的页面，我们仅协助填写，只有在您点击下一步操作后才由您自行提供至对应服务系统。</b
          >
        </p>
        <p>2. 系统权限获取</p>
        <p>
          当您上传身份证照片时，我们将需要获取您的设备<b>相机或相册权限</b>，并收集您提供的包含个人信息的图片，并自动扫描身份证照片上的信息，减少您手动输入的时间以及避免手动输入的错误。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。
        </p>
        <p>3. 必要信息对应之服务</p>
        <p>(1) 用于验证您的身份，确保线上签约方为您本人；</p>
        <p>(2) 与相关方签署电子合同；</p>
        <p>(3) 满足相关法律规定及监管要求、确保用户身份真实性、实现反欺诈等风险控制、保障系统和服务安全或提供服务。</p>
        <h3><b>(四) 业务功能四：搜索功能</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>(1) 搜索的字词（包括语音信息）。</p>
        <p>共计 1 类个人信息</p>
        <p>2. 必要个人信息对应之服务。</p>
        <p>用于您在共享平台内搜索感兴趣的内容。</p>
        <p>3. 可自主选择提供的个人信息：</p>
        <p><b>(1) 浏览记录（包括浏览内容、浏览时间）；</b></p>
        <p>(2) 搜索的时间。</p>
        <p>共计 2 类个人信息。</p>
        <p>如您拒绝提供，我们将无法为您推荐您感兴趣的服务，但不会导致您无法使用搜索功能。</p>
        <p>4. 可自主选择提供的信息对应之服务</p>
        <p>为了向您提供您所需要的内容和可能更感兴趣的服务，同时亦可以改进我们的服务。</p>
        <h3><b>(五) 业务功能五：平台内接单及交付</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>(1) 姓名；</p>
        <p>(2) 手机号码；</p>
        <p><b>(3) 身份证号码；</b></p>
        <p><b>(4) 订单信息（包括但不限于交易项目名称、交易时间）；</b></p>
        <p>(5) 交付服务所涉第三方平台（包括但不限于快手、抖音、微博等平台）账号名及对应主页链接（依据您承接的项目及平台提示对应提供）；</p>
        <p>(6) 交付物对应所涉第三方平台作品链接（依据您承接的项目及平台提示对应提供）。</p>
        <p>共计 6 类个人信息（其中5与6合称交付信息）。</p>
        <p>
          <b
            >为便于您了解、查询于平台内接单产生的订单信息以及就订单项下项目对应服务进行交付，我们会收集您在使用共享平台服务过程中产生的订单信息（包含个人信息）用于向您展示及便于您对订单进行管理。</b
          >
        </p>
        <p>您可以通过共享平台查看和管理您的订单信息。</p>
        <p>
          您应知悉，<b>身份证号码、身份信息、订单信息属于您的敏感个人信息</b>。上述业务功能依赖必要信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息。
        </p>
        <p>2. 必要信息对应之服务</p>
        <p>在平台内接单及交付；</p>
        <p>除此之外，我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。</p>
        <h3><b>(六) 业务功能六：收款功能</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>(1) 姓名；</p>
        <p>(2) 手机号码；</p>
        <p><b>(3) 身份证号码；</b></p>
        <p><b>(4) 银行卡账号/支付宝收款账号/微信收款账号；</b></p>
        <p><b>(5) 订单信息（包括但不限于交易项目名称、交易时间）；</b></p>
        <p><b>(6) 交易金额（包括税前交易金额、税后交易金额）。</b></p>
        <p>共计 6 类个人信息。</p>
        <p>
          您应知悉，<b>身份证号码、银行卡信息/支付宝收款账号/微信收款账号、订单信息及交易金额属于您的敏感个人信息</b>。上述业务功能依赖必要信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息。
        </p>
        <p>2. 必要信息对应之服务</p>
        <p>为您提供结算功能，便于您基于共享平台内接单项目向交易相对方收取对应的服务收入。</p>
        <p>3. 当您提供银行卡照片时，请阅一（三）2.<b>设备相机/相册使用权限</b>相关约定。您主动提供的信息对应之服务。</p>
        <p>
          您提供银行卡照片时，我们将自动扫描银行卡上的信息，减少您手动输入的时间以及避免手动输入的错误。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。
        </p>
        <h3><b>(七) 业务功能七：申请注册个体工商户（如适用）</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>
          若您委托共享平台内其他主体为您注册个体工商户，为保证您委托的服务能够顺利交易，我们会收集并向您选择的其他主体披露下列个人信息。您知晓并同意该其他主体不可避免地获知及使用您的前述个人信息，用于完成您委托其为您注册个体工商户之目的。您需要向我们提供以下信息：
        </p>
        <p>(1) 姓名；</p>
        <p>(2) 手机号码；</p>
        <p><b>(3) 身份证号码；</b></p>
        <p><b>(4) 身份证照片。</b></p>
        <p>共计 4 类个人信息。</p>
        <p>
          您应知悉，<b>身份证号码、身份证照片属于您的敏感个人信息</b>。上述业务功能依赖必要信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息。
        </p>
        <p>2. 系统权限获取</p>
        <p>请阅一（三）2.<b>设备相机/相册使用权限</b>相关约定。</p>
        <p>3. 必要信息对应之服务</p>
        <p>
          我们将依据您于共享平台达成的委托事宜向您委托的为您代为注册个体工商户的第三方共享您的必要个人信息，个人信息共享方式详见“二、我们如何共享、转让、公开披露您的个人信息”。
        </p>
        <p>除此之外，我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。</p>
        <h3>(八) 业务功能八：申请临时税务登记（如适用）</h3>
        <p>1. 必要个人信息收集</p>
        <p>
          若您委托共享平台内其他主体为您办理临时税务登记，为保证您委托的服务能够顺利交易，我们会收集并向您选择的其他主体披露下列个人信息。您知晓并同意该其他主体不可避免地获知及使用您的前述个人信息，用于完成您委托其为您办理临时税务登记之目的。您需要向我们提供以下信息：
        </p>
        <p>(1) 姓名；</p>
        <p>(2) 手机号码；</p>
        <p><b>(3) 身份证号码；</b></p>
        <p><b>(4) 身份证照片。</b></p>
        <p>共计 4 类个人信息。</p>
        <p>
          您应知悉，<b>身份证号码、身份证照片属于您的敏感个人信息</b>。上述业务功能依赖必要信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息。
        </p>
        <p>2. 系统权限获取</p>
        <p>请阅一（三）2.<b>设备相机/相册使用权限</b>相关约定。</p>
        <p>3. 必要信息对应之服务</p>
        <p>
          我们将依据您于共享平台达成的委托事宜向您委托的为您代为办理临时税务登记的第三方共享您的必要个人信息，个人信息共享方式详见“二、我们如何共享、转让、公开披露您的个人信息”。
        </p>
        <p>除此之外，我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。</p>
        <h3><b>(九) 业务功能九：代办涉税事宜</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>
          若您委托共享平台内其他主体为您（或您通过共享平台注册的个体工商户）办理申报纳税、代开发票、代缴税款等涉税相关的事宜的，为保证您委托的服务能够顺利交易，我们会收集并向您选择的其他主体披露下列个人信息。您知晓并同意该其他主体不可避免地获知及使用您的前述个人信息，用于完成您委托其代办涉税事宜之目的。您需要向我们提供以下信息：
        </p>
        <p>(1) 姓名；</p>
        <p>(2) 手机号码；</p>
        <p><b>(3) 身份证号码；</b></p>
        <p><b>(4) 身份证照片；</b></p>
        <p><b>(5) 纳税所属期内的全部订单信息；</b></p>
        <p><b>(6) 纳税所属期内的全部交易结算流水金额。</b></p>
        <p>共计 6 类个人信息。</p>
        <p>2. 系统权限获取</p>
        <p>请阅一（三）2.<b>设备相机/相册使用权限</b>相关约定。</p>
        <p>3. 必要信息对应之服务</p>
        <p>
          用于您委托共享平台内其他主体为您提供代办涉税服务事宜。<b
            >为便于您了解、查询您委托共享平台内其他主体代办涉税事宜的履行情况，我们会收集您在使用共享平台服务过程中产生的动态订单信息、交易及结算金额以及您委托的共享平台内其他主体为您代办涉税事宜后提交的相关纳税（包括增值税、增值税附加税、个人所得税）信息（包含敏感个人信息）用于向您展示及便于您对委托事项及纳税信息进行管理。</b
          >
        </p>
        <p>您可以通过共享平台查看和管理您的纳税信息。</p>
        <p>
          您应知悉，<b>身份证号码、身份证照片、订单信息、交易结算流水信息、纳税信息属于您的敏感个人信息</b>。上述业务功能依赖必要信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息。
        </p>
        <p>除此之外，我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。</p>
        <h3><b>(十) 业务功能十：使用客服功能或者其他用户响应功能</b></h3>
        <p>1. 必要个人信息收集</p>
        <p>
          当您使用客服功能或者其他用户响应功能，该功能包括在线提交意见反馈、与在线/人工客服沟通、行使您的相关个人信息控制权、其他客户投诉和需求，您需要向我们提供以下信息：
        </p>
        <p>(1) 姓名；</p>
        <p><b>(2) 联系方式；</b></p>
        <p><b>(3) 您与我们的沟通信息（包括文字/图片/音视频/通话记录形式，包括订单信息、您为了证明相关事实提供的其他信息）；</b></p>
        <p>(4) 与您需求相关联的其他必要信息。</p>
        <p>共计 4 类个人信息。</p>
        <p>您应知悉，<b>沟通信息可能包含您的敏感个人信息</b>。如您拒绝提供可能导致您无法使用我们的客服等用户响应机制。</p>
        <p>2. 系统权限获取</p>
        <p>
          <b>当您通过图片、音视频、通话方式与我们联系并且沟通相关事宜时，我们将获取您的相机权限或者相册权限、麦克风、存储文件权限。</b
          >上述权限是您设备上的一项设置，您可以通过设备设置页面进行管理。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。
        </p>
        <p>3. 必要信息对应之服务</p>
        <p>(1) 您的账号与系统安全；</p>
        <p>(2) 我们收集这些信息是为了调查事实与帮助您解决问题。</p>
        <p>除此之外，我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。</p>
        <h3>(十一) 运营及服务安全需求</h3>
        <p>1. 必要个人信息收集</p>
        <p>(1) 设备信息；</p>
        <p>(2) 日志信息；</p>
        <p>(3) 交易信息；</p>
        <p>(4) 实名认证信息；</p>
        <p>(5) 账户信息。</p>
        <p>共计5类个人信息。</p>
        <p>2. 必要信息对应之服务</p>
        <p>
          用于保障您使用共享平台服务时的账号与系统安全，防止恶意程序及反作弊、反欺诈，并协助提升共享平台服务的安全性和可靠性，以防产生任何危害用户、共享平台及社会的行为。
        </p>
        <p>
          您应知悉，<b>交易信息、实名认证信息、账户信息属于您的敏感个人信息</b>。我们会根据上述信息来综合判断您账号、账户及交易风险、进行身份验证、客户服务、检测及防范安全事件、诈骗监测、存档和备份用途，并依法采取必要的记录、分析、处置措施。同时，当发生账号或系统安全问题时，我们会使用相关信息进行安全验证与风险排除，确保我们向您提供的服务的安全性，以用来保障您的权益不受侵害，并优化我们的服务。
        </p>
        <h3><b>(十二) 其他</b></h3>
        <p>存在以下情形之一的，我们将于事前进行个人信息保护影响评估，并对处理情况进行记录：</p>
        <p>（1）处理敏感个人信息；</p>
        <p>（2）利用个人信息进行自动化决策；</p>
        <p>（3）委托处理个人信息、向其他个人信息处理者提供个人信息、公开个人信息；</p>
        <p>（4）向境外提供个人信息；</p>
        <p>（5）其他对您个人权益有重大影响的个人信息处理活动。</p>
        <p>
          如果某一需要收集您的个人信息的功能或服务未能在本政策中予以说明的，或者我们超出了与收集您的个人信息时所声称的目的及具有直接或合理关联范围的，或者我们推出新的服务或更新迭代已有的服务需要收集新的个人信息或者变更个人信息使用目的或方式的，<b
            >我们将在收集和使用您的个人信息前，通过更新本政策、页面提示、弹窗、消息通知、网站公告或其他便于您获知的方式另行向您说明，并为您提供自主选择同意的方式，且在征得您明示同意后收集和使用。</b
          >
        </p>
        <p>
          <b
            >请您知悉，如信息无法单独或结合其他信息识别或关联到您的个人身份的，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时、我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本政策处理与保护。</b
          >
        </p>
        <h3><b>(十三) 例外情形</b></h3>
        <p>另外，您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意：</p>
        <p>(1) 与国家安全、国防安全直接相关的；</p>
        <p>(2) 与公共安全、公共卫生、重大公共利益直接相关的：</p>
        <p>(3) 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>(4) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>
          (5)
          除您明确拒绝外，我们在合理的范围内处理的您的个人信息是您自行向社会公众公开的，或从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道。若我们处理您已公开的个人信息对您权益有重大影响的，我们将受限于获得您的明确同意为前提；
        </p>
        <p>(6) 根据您的要求签订或履行协议所必需的；</p>
        <p>(7) 用于维护系统及相关服务的安全稳定运行所必需的，例如发现、处置系统及相关服务的故障；</p>
        <p>(8) 为合法的新闻报道所必需的；</p>
        <p>(9) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>(10) 法律法规规定的其他情形。</p>
        <h2><b>二、 我们如何共享、转让、公开披露您的个人信息</b></h2>
        <h3><b>（一） 共享</b></h3>
        <p><b>我们不会与共享平台经营者以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</b></p>
        <p>1. 与关联方共享</p>
        <p>
          为便于我们向您提供共享平台服务，推荐您可能感兴趣的信息，识别账户异常，保护共享平台经营者关联公司或其他用户或公众的人身财产安全免遭侵害，我们可能会将您的个人信息与我们的关联方共享，我们只会共享必要的个人信息，且受本政策中所声明目的的约束。
        </p>
        <p>2. 与授权合作伙伴共享</p>
        <p>
          我们可能会向授权合作伙伴等第三方共享您的信息，以保障为您提供的服务顺利完成。授权合作伙伴声明除了其自身隐私政策之外还受到本政策的约束，您的个人信息将受到不低于本政策同等的保护。授权合作伙伴受到的本政策管理机制的约束。我们的授权合作伙伴包括：
        </p>
        <p>
          (1)
          身份核验供应商。为准确地为您提供共享平台服务，我们需要核查您的身份信息，您同意并授权共享平台或其关联公司将<b>您的姓名、身份证号码、手机号码、银行账号、身份证照片共享至身份核验供应商进行核验</b>；
        </p>
        <p>
          (2)
          数字证书认证机构<b>（如适用）</b>。为确保您在共享平台线上签约的效力，您同意并授权共享平台或其关联公司将<b>您的姓名、身份证号码或其他有效证件号码共享给认证机构用于生成您的数字证书，您同意共享平台及其关联公司调用您的数字证书</b>。
        </p>
        <p>3. 与企业客户共享：</p>
        <p>
          为给您筛选适当的生产经营活动、了解您的生产经营情况、提供共享平台服务，便于您委托共享平台内其他主体为您提供代办涉税服务，协助您解决、处理相关涉税争议或纠纷，您授权并同意将<b>您的姓名、身份证号码、身份证照片、订单信息、交易结算流水信息、纳税信息共享给相应有业务需求的企业客户</b>。
        </p>
        <p>
          <b
            >基于企业客户的需要，为解决您的需求，实现业务办理，企业客户需要向为您提供的服务的相关主管部门报备审核（包括主管税务部门、企业客户落地园区政府部门），您授权并同意企业客户将您的前述信息及交付信息共享给前述主管部门。</b
          >
        </p>
        <p>4. 共享您个人信息的其他情形</p>
        <p>
          (1)
          事先获得您明确的同意或授权；未经您的同意，我们不会共享您的个人信息，除非共享的个人信息已去标识化处理，且被共享的第三方无法重新识别此类信息所属的自然人主体。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息；
        </p>
        <p>
          (2) 根据《电子商务法》《网络交易监督管理办法》等法律法规规定、诉讼争议解决需要或者行政、司法等有权机关依法提出的要求对外共享您的个人信息；
        </p>
        <p>(3) 只有共享您的个人信息，才能实现共享平台服务的核心功能或提供您需要的服务；</p>
        <p>(4) 应您需求为您处理您及您通过平台注册的企业的相关业务时，提供给相关政策部门或者企业；</p>
        <p>(5) 符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供。</p>
        <p>
          对我们与之共享个人信息的公司、组织和个人，会与其签署严格的保密协定或保密条款，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。
        </p>
        <h3><b>（二） 转让</b></h3>
        <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：</p>
        <p>1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>
          2.
          在涉及合并、分立、解散、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，若接收方变更本政策约定的个人信息处理目的、处理方式的，我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>
          在上述情形之下，我们将与受让方签订个人信息转让协议，声明除了受让方自身隐私政策之外还受到本政策的约束，您的个人信息将受到不低于本政策同等的保护。
        </p>
        <h3><b>（三） 公开披露</b></h3>
        <p>我们仅会在以下情形下，公开披露您的个人信息：</p>
        <p>(1) 获得您明确同意或基于您的主动选择，共享平台可能会公开披露您的个人信息；</p>
        <p>(2) 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，且不再另行通知您；</p>
        <p>
          (3)
          基于其他平台用户或者任何第三人的申请、并提供初步证据的披露：当您和平台其他主体发生法律纠纷，或任何第三人认为您的行为侵犯其合法权益，意欲提起法律诉讼时，在申请人提供基本证据后，我们可能会公开披露您的个人信息，同时我们会保留申请人披露记录，以备您随时查询您个人信息的披露情况。
        </p>
        <h3><b>（四） 共享、转让、公开披露个人信息时事先征得授权同意的例外</b></h3>
        <p>1. 与国家安全、国防安全有关的；</p>
        <p>2. 与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>3. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>4. 您自行向社会公众公开的个人信息；</p>
        <p>5. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>6. 进行个人信息安全审计；</p>
        <p>7. 保护个人信息主体避免遭受欺诈和严重人身伤害等；</p>
        <p>8. 有权机关的要求、法律法规等规定的其他情形。</p>
        <p>
          根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>
        <h3><b>（五） 上述信息的存储地点以及保留期限</b></h3>
        <p>1. 存储地点：</p>
        <p>
          我们依照法律法规的规定，将您的个人信息存储于中华人民共和国境内。目前我们不存在跨境存储您的个人信息或向境外提供个人信息的场景。如需跨境存储或向境外提供个人信息的，我们会单独向您明确告知（包括出境的目的、接收方、使用方式与范围、使用内容、安全保障措施、安全风险等）并再次征得您的授权同意，并严格要求接收方按照本政策以及法律法规相关要求来处理您的个人信息。
        </p>
        <p>2. 存储期限：</p>
        <p>
          (1)
          我们在为提供共享平台服务之目的所必需且最短的期间内保留您的个人信息，例如：当您使用我们的注册登录时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们需要持续为您保留您的手机号码，以向您正常提供该功能、保障您的账号和系统安全。在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。但您行使删除权、注销账号或法律法规另有规定的除外（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。
        </p>
        <p>
          (2)
          我们将持续为您提供优质服务，若因特殊原因导致我们的部分或全部共享平台服务被迫停止运营，我们将提前在显著位置或向您发送推送消息或以其他方式通知您，并将停止对您个人信息的收集，同时在超出法律法规规定的必需且最短期限后，我们将会对所持有的您的个人信息进行删除或匿名化处理。
        </p>
        <h2><b>三、 我们如何保护您的个人信息</b></h2>
        <h3><b>（一）安全防护措施</b></h3>
        <p>
          我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如：
        </p>
        <p>1. 个人信息完整性保护措施：数据安全传输方面，使用安全通道传输个人信息，确保数据传输过程中个人信息的秘密性和完整性；</p>
        <p>
          2.
          个人信息传输、存储和备份过程的加密措施：数据安全存储方面，敏感个人信息需加密存储，确保数据安全使用规范能够落实到位；定期对个人信息进行备份与恢复，确保个人信息在存储使用过程中的完整性；
        </p>
        <p>
          <b
            >请您谅解，由于技术的限制以及风险防范的局限性，即便我们已经尽量加强安全防护措施，也无法始终保证信息绝对的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题；您一旦离开共享平台及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护你在共享平台及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于共享平台的链接或引导。</b
          >
        </p>
        <p>
          4.
          个人信息的保留和删除机制：数据安全销毁方面，根据法律法规要求和业务实际需求设定个人信息存储的最小可用期限，对到期的数据通过安全删除技术进行处理，技术手段包括但不限于数据彻底清除方案、磁盘销毁、物理销毁等。
        </p>
        <h3><b>（二）数据收集制度</b></h3>
        <p>
          我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <h3><b>（三）个人信息安全事件的响应</b></h3>
        <p>
          在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况（包括泄露、篡改、丢失的个人信息种类、原因）和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </p>
        <p>您应理解，根据法律法规的要求，若我们采取措施能够有效避免个人信息泄露、篡改、丢失造成危害的，我们可以不通知您。</p>
        <h2><b>四、 您的权利</b></h2>
        <p>按照中国相关的法律、法规、强制性标准，我们保障您对自己的个人信息行使以下权利：</p>
        <h3><b>（一）查阅、复制您的个人信息</b></h3>
        <p>您有权查阅、复制您的个人信息，法律法规规定的例外情况除外。</p>
        <p>若您请求将您的个人信息转移至您指定的其他个人信息处理者，我们将在符合国家网信部门规定条件的前提下，为您提供转移的路径。</p>
        <h3><b>（二）更正、补充您的个人信息</b></h3>
        <p>当您发现我们处理的关于您的个人信息不准确或者不完整时，您有权要求我们作出更正、补充。</p>
        <h3><b>（三）删除您的个人信息</b></h3>
        <p>有以下情形之一的，我们将有权删除您的个人信息；如果我们未删除的，您可以向我们提出删除个人信息的请求：</p>
        <p>1. 处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
        <p>2. 停止提供服务，或者保存期限已届满；</p>
        <p>3. 您撤回同意；</p>
        <p>4. 我们违反法律、行政法规或者违反约定处理您的个人信息；</p>
        <p>5. 法律、行政法规规定的其他情形。</p>
        <p>
          当法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </p>
        <h3><b>（四）改变您授权同意的范围</b></h3>
        <p>
          每个业务功能需要一些基本的个人信息才能得以完成。对于我们收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。收回授权同意可能影响您使用某些产品或服务，我们会在您收回授权同意时向您说明。
        </p>
        <h3><b>（五）您如何注销您的账户</b></h3>
        <p>
          您可申请注销此前注册的账户。在注销账户之后，我们将停止为您提供服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。<b
            >您将无法再以此账户登录和使用我们的共享平台及使用该账号进行登录的第三方产品和服务；该账户在您使用共享平台期间已产生的但未消耗完毕的权益及未来的预期利益等全部权益将被清除；该账户下的内容、信息、数据、交易记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外）；同时，您的账号一旦注销完成，将无法恢复。</b
          >
        </p>
        <h3><b>（六）您如何针对我们信息系统自动化决策提出异议 （如适用）</b></h3>
        <p>
          在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动化决策机制作出决定。我们保证该等决策的透明度和结果公平、公正，并不得对您在交易价格等交易条件上实行不合理的差别待遇。若我们通过自动化决策方式向您进行信息推送、商业营销，我们将向您提供不针对您个人特征的选项供您选择。
        </p>
        <p>如果这些决定显著影响您的合法权益，您有权要求我们作出解释，并有权拒绝我们仅通过自动化决策的方式作出决定。</p>
        <h3><b>（七）您如何请求我们就个人信息处理规则进行解释说明</b></h3>
        <p>您有权请求我们就个人信息处理规则进行解释说明。</p>
        <h3><b>（八）响应您的上述请求</b></h3>
        <p>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。在身份验证过程中，我们会向您说明验证身份的原因，并采取适当的控制措施，避免您在验证身份的过程中造成个人信息泄露。
        </p>
        <p>
          我们将在十五天内作出答复。如您不满意，还可以通过以下途径投诉：【官方网站www.linsyun.com
          和官方客户服务邮箱vip@linsyun.com】，若我们因为人员调整、岗位更替、系统问题暂时无法响应，将明确说明响应的时间以及无法短时间内响应的原因。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情形收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        </p>
        <p>在以下情形中，我们将无法响应您的请求：</p>
        <p>1. 与国家安全、国防安全直接相关的；</p>
        <p>2. 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3. 与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
        <p>4. 有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p>5. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>6. 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
        <p>7. 涉及商业秘密的。</p>
        <h2><b>五、 我们将如何处理儿童的个人信息</b></h2>
        <h3><b>（一）我们的目标用户</b></h3>
        <p>
          我们的产品、网站和服务主要面向【18】周岁以上或未满18周岁但已满16周岁主要以自有收入作为生活来源的完全民事行为能力人。如果没有监护人的同意，未成年人（包括儿童）不应创建自己的个人信息主体账户。
        </p>
        <h3><b>（二）使用或公开信息的条件</b></h3>
        <p>对于经监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</p>
        <h3><b>（三）儿童的定义</b></h3>
        <p>尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。</p>
        <h3><b>（四）删除数据</b></h3>
        <p>如果我们发现自己在未事先获得可证实的监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</p>
        <h2><b>六、 您的个人信息如何在全球范围内转移</b></h2>
        <p>
          原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。若因业务需求、政府和司法监管要求存在跨境信息传输情况，我们会依照《个人信息保护法》等相关法律法规的规定进行，并详细向您告知境外接收方的名称或者姓名、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使本政策项下权利的方式和程序等事项，并取得您的单独同意。
        </p>
        <h2><b>七、 第三方服务</b></h2>
        <p>我们的服务可能包括链接至第三方提供的其他服务。该等第三方服务可能由相关的第三方运营。</p>
        <p><b>请您注意，本政策不适用于：通过我们的服务而接入的第三方服务收集的信息；通过在我们服务中提供其他服务的第三方所收集的信息。</b></p>
        <p>
          <b
            >您使用该等第三方的服务（包括您向该等第三方提供任何个人信息)，须受第三方自己的服务条款及隐私政策约束，您需要仔细阅读其条款。本政策仅适用于共享平台所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而我们对任何第三方使用由您提供的信息不承担任何责任。</b
          >
        </p>
        <h2><b>八、 我们如何使用Cookies和同类型技术</b></h2>
        <h3><b>（一）关于 Cookies 和同类技术</b></h3>
        <p>
          Cookies 是包含字符串的小文件，在您登入和使用网站或其他网络内容时发送存放在您的计算机、移动设备或其他装置内（通常经过加密）。Cookies
          同类技术是可用于与 Cookies 类似用途的其他技术，例如：Web Beacon、Proxy、嵌入式脚本等。
        </p>
        <p>
          目前，我们主要使用 Cookies 收集您的个人信息。您知悉并同意，随着技术的发展和我们产品和服务的进一步完善，我们也可能会使用 Cookies 同类技术。
        </p>
        <h3><b>（二）我们如何使用 Cookies 和同类技术</b></h3>
        <p>在您使用共享平台服务时，我们可能会使用 Cookies 和同类技术收集您的一些个人信息，包括：<b>您的登录信息、您的验证信息</b>。</p>
        <p>1. 保障产品与服务的安全、高效运转</p>
        <p>
          我们可能会设置认证与保障安全性的Cookies或匿名标识符，使我们确认您是否安全登录服务，或者是否遭受盗用、欺诈等不法行为。这些技术还会帮助共享平台改进服务效率，提升登录和响应速度。
        </p>
        <p>2. 帮助您获得更轻松的访问体验</p>
        <p>使用Cookies和同类型技术可以帮助您省去重复填写个人信息、判断您的登录状态以及账号或数据安全等。</p>
        <p>3. 为您推荐、展示、推送您可能感兴趣的内容</p>
        <p>我们可能会利用 Cookies和同类技术了解您的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验。</p>
        <p>
          我们承诺仅在本政策所述目的范围内使用 Cookies 和同类技术。如果您的浏览器允许，您可以通过您的浏览器的设置以管理、（部分/全部）拒绝 Cookies
          与/或同类技术；或删除已经储存在您的计算机、移动设备或其他装置内的 Cookies
          与/或同类技术，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改浏览器设置，请具体查看您使用的浏览器的相关设置页面。请您知悉：如您拒绝使用或删除的，您可能将无法获得最佳的服务体验，但不影响我们提供的核心服务。
        </p>
        <h3><b>（三）SDK的使用（如适用）</b></h3>
        <p>为保障共享平台的稳定运行、功能实现，使您能够享受和使用更多的服务及功能，我们会嵌入授权合作伙伴的SDK或其他类似的应用程序。</p>
        <p>
          我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
        </p>
        <h2><b>九、 本政策如何更新</b></h2>
        <p>我们的隐私政策可能变更。</p>
        <p>未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。</p>
        <p>
          对于重大变更，我们还会提供更为显著的通知，通常情况下采取的通知方式如：您登录信息系统时、使用时弹出窗口、直接向您推送通知、向您发送邮件、短信等。<b
            >该变更一经通知即生效（另有说明除外）。若您点击同意或您在本政策变更后继续访问共享平台，则表示您已充分阅读、理解并接受修改后的本政策并受之约束。</b
          >
        </p>
        <p>本政策所指的重大变更包括但不限于：</p>
        <p>1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
        <p>2. 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
        <p>3. 个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>4. 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p>6. 个人信息安全影响评估报告表明存在高风险时。</p>
        <h2><b>十、 如何联系我们</b></h2>
        <p>如果您对本个人信息保护政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
        <p>电子邮件地址：【vip@linsyun.com】</p>
        <p>一般情况下，我们将在十五天内回复。</p>
        <h2><b>十一、 其他</b></h2>
        <h3>
          <b
            >（一）您应认真阅读并充分理解本政策所有内容（特别是加粗字体内容）的意义以及由此产生的法律效力，自愿同意本政策。一旦您在线点击“同意”本隐私保护政策或使用共享平台服务即视为该等行为是您本人真实的意思表示，您同意本政策并愿意承担由此带来的一切法律后果。
          </b>
        </h3>
        <h3>
          <b
            >（二）本政策的解释及争议解决均应适用中华人民共和国大陆地区法律。如就本政策的签订、履行等发生任何争议的，双方应尽量友好协商解决；协商不成时，任何一方均可向本政策签订地湖北省武汉市东湖新技术开发区有管辖权的人民法院诉讼解决。
          </b>
        </h3>
        <h3>（三） 本政策的标题仅为方便及阅读而设，不影响本政策任何条款的含义或解释。</h3>
        <h3>（四） 本政策任一条款被废止、无效或不可执行，该条应视为可分的且并不影响本政策其余条款的有效性及可执行性。</h3>
      </div>
    </div>
    <span slot="footer">
      <!-- <el-button plain @click="show = false">取 消</el-button> -->
      <el-button type="primary" @click="show = false">我知道了</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      show: false,
      type: 1,
      title: '灵时云综合服务平台-'
    }
  },
  methods: {
    open(type = 1) {
      this.type = type
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 40px;
  h1 {
    text-align: center;
    font-size: 20px;
  }
  h2 {
    font-weight: normal;
    font-size: 16px;
  }
  h3 {
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
  }
  p {
    text-indent: 2em;
    margin: 0;
    line-height: 2;
  }
}
</style>
