<template>
  <!-- 特殊项 -->
  <el-table-column v-if="cmp" :prop="prop" :label="label" v-bind="cfg">
    <template slot-scope="{ row, column, $index }">
      <div v-if="isTypeOf('html')" v-html="formatter ? formatter(row, column, row[prop], $index) : row[prop]"></div>
      <div v-if="isTypeOf('tooltip')">
        <el-tooltip placement="top">
          <div slot="content" v-html="formatter(row, column, row[prop], $index)"></div>
          <span>{{ row[prop] }}</span>
        </el-tooltip>
      </div>
      <div v-if="isTypeOf('html-tooltip')">
        <el-tooltip placement="top">
          <div slot="content" v-html="formatter2(row, column, row[prop], $index)"></div>
          <span v-html="formatter ? formatter(row, column, row[prop], $index) : row[prop]"></span>
        </el-tooltip>
      </div>
      <div v-if="isTypeOf('img')">
        <el-image class="img" :src="imgUrl(row[prop])" :preview-src-list="[imgUrl(row[prop])]" v-bind="imgCfg"></el-image>
      </div>
      <div v-if="isTypeOf('switch')">
        <el-switch :value="row[prop]" @change="onSwitch($event, prop, row)" v-bind="switchCfg"> </el-switch>
      </div>
      <div v-if="isTypeOf('money')">
        <span v-format="'#,##0.00'">{{ row[prop] || 0 }}</span>
      </div>
      <div v-if="isTypeOf('serviceFee')">
        <el-tooltip placement="top">
          <div slot="content">
            服务费：<span v-format="'#,##0.00'">{{ row.serviceFee }}</span
            >元<br />差额服务费：<span v-format="'#,##0.00'">{{ row.serviceDiffFee }}</span
            >元
          </div>
          <span v-format="'#,##0.00'">{{ 0 + (row.serviceFee || 0) + (row.serviceDiffFee || 0) }}</span>
        </el-tooltip>
      </div>
      <!--悬浮窗列表展示 -->
      <div v-if="isTypeOf('tooltipList')">
        <el-tooltip placement="top">
          <div slot="content">
            <template v-for="item in toolTipFee">
              <div :key="item.prop" v-if="row[item.prop] !== null">
                <span>{{ item.label }}:</span>
                <span>{{ item.formatter ? item.formatter(row, column, row[item.prop], $index) : row[item.prop] }}</span>
              </div>
            </template>
          </div>
          <span>{{ formatter ? formatter(row, column, row[prop], $index) : row[prop] }}</span>
        </el-tooltip>
      </div>
    </template>
  </el-table-column>
  <!-- 普通项 -->
  <el-table-column v-else :prop="prop" :label="label" :formatter="formatter" v-bind="cfg"> </el-table-column>
</template>

<script>
// 基础配置项
const DefaultCfg = {
  minWidth: '100px'
  // align: 'center'
}
// img基础配置项
const imgDefaultCfg = {
  fit: 'contain',
  style: { width: '45px', height: '45px' }
}
// switch基础配置项
const switchDefaultCfg = {}
export default {
  name: 'ComponentTableColumn',
  props: {
    // 特殊项标示
    cmp: String,
    // 列字段名
    prop: String,
    // 列头标题
    label: String,
    // 内容格式化
    formatter: Function,
    formatter2: Function,
    // item配置项
    config: Object,
    // img配置项
    imgConfig: Object,
    // switch配置项
    switchConfig: Object,
    //悬浮金额展示 格式//[{prop: 'serviceFee', label: '服务费'}, {prop: 'serviceDiffFee', label: '差额服务费'}]
    toolTipFee: Array
  },
  computed: {
    // 配置项格式化
    cfg() {
      return Object.assign({}, DefaultCfg, this.config)
    },
    imgCfg() {
      return Object.assign({}, imgDefaultCfg, this.imgConfig)
    },
    switchCfg() {
      return Object.assign({}, switchDefaultCfg, this.switchConfig)
    }
  },
  methods: {
    // 特殊项匹配判定
    isTypeOf(name) {
      return name === this.cmp
    },
    // 图片地址转换
    imgUrl(val) {
      // 网络地址
      const regHttp = /^http/
      const expHttp = new RegExp(regHttp)
      if (expHttp.test(val)) return val
      // 本地地址
      const regLocal = /^@\//
      const expLocal = new RegExp(regLocal)
      if (expLocal.test(val)) return require('@/' + val.replace(/^@\//, ''))
      // 其他
      return ''
      // return this.attachUrl + val
    },
    // switch事件
    async onSwitch(val, key, row) {
      await this.$confirm('确认执行此操作吗？', { type: 'warning' })
      // row[key] = val
      this.$emit('onSwitch', val, key, row, () => (row[key] = val))
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  display: block;
  margin: 0 auto;
}
</style>
