<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <AuthDialog ref="AuthDialog" />
    <OpenNotice ref="OpenNotice" />
    <GuideDialog ref="GuideDialog" @showGuidePop="showGuidePop" @showGuideSteps="showGuideSteps"/>
    <GuideSteps ref="GuideSteps"/>
  </div>
</template>

<script>
import { Decrypt, getFirstLogin, setFirstLogin, setOemId, getOemId } from '@/utils/auth'
import AuthDialog from '@/layout/authDialog'
import OpenNotice from '@/views/notice/openInfo'
import GuideDialog from '@/views/notice/guide/guideDialog'
import GuideSteps from '@/views/notice/guide/steps'
import { getUrlParams } from '@/utils'
import oemList from '@/configs/oem.js'
export default {
  name: 'App',
  components: { AuthDialog, OpenNotice, GuideDialog, GuideSteps },
  data() {
    return {}
  },
  computed: {
    token() {
      return this.$store.state.user.token
    },
    isMerchantAdmin() {
      return this.$store.state.user.isMerchantAdmin
    },
    userId() {
      return this.$store.state.user.userId
    },
  },
  watch: {
    token(val) {
      if (val) this.checkAuth()
    }
  },
  mounted() {
    this.checkOem()
    // 判断code
    const urlParams = getUrlParams(window.location.href)
    if (urlParams.MerchantId) {
      // 有商户ID，跳转认证服务器
      window.location.href =
        process.env.VUE_APP_FULU_PASSPORT +
        encodeURIComponent(window.location.origin) +
        '&response_type=code&scope=get_user_info&state=' +
        urlParams.MerchantId
    }
    if (urlParams.code) {
      const loading = this.$loading({
        lock: true,
        text: '授权登录中',
        spinner: 'el-icon-loading'
      })

      // 已授权
      this.$store
        .dispatch('user/loginFulu', {
          code: urlParams.code,
          state: urlParams.state
        })
        .then((res) => {
          loading.close()
          this.$router.push({ path: this.redirect || '/' })
        })
        .catch((err) => {
          loading.close()
        })
    } else if (this.token) this.checkAuth()

    // 监听页面缩放，并实时清零
    window.addEventListener('resize', () => {
      const tableList = window.document.getElementsByClassName('el-table__fixed-body-wrapper')
      for (let item of tableList) {
        item.style.height = ''
      }
    })
  },
  methods: {
    // 判断oem来源并变更oem信息host
    checkOem() {
      // 先判断domain，再判断query
      const domain = window.location.host
      const domainOne = oemList.find(it => it.oemDomain == domain && it.oemType == 'domain')
      if (domainOne) {
        setOemId(domainOne.oemId)
        this.$store.commit('app/setOem', domainOne)
      } else if (window.location.pathname == '/login') {
        const urlParams = getUrlParams(window.location.href)
        if (urlParams&&urlParams.o) {
          this.checkOemQuery(urlParams.o)
        } else {
          this.clearOem()
        }
      } else {
        const o = getOemId()
        this.checkOemQuery(o)
      }
    },
    // 在oemList中查找符合标准的urlParams.o
    checkOemQuery(o) {
      const one = oemList.find(it => it.oemId == o && it.oemType == 'query')
      if ( one ) {
        setOemId(one.oemId)
        this.$store.commit('app/setOem', one)
      } else {
        this.clearOem()
      }
    },
    clearOem() {
      setOemId('')
      this.$store.commit('app/clearOem')
    },
    checkAuth() {
      // 判断auth&sign
      this.$store.dispatch('user/getAuth').then((res) => {
        // 线上签约判断认证且签约，线下签约只判断签约
        if (
          res.signType == 0 ||
          // (res.signType == 1 && !res.isAuth) ||
          res.supplierSignList.find((item) => {
            return item.isSign != 2
          })
        ) {
          this.$refs.AuthDialog.open()
        }
        this.showGuideDialog(res)
      })
    },
    // 显示新手引导弹窗
    showGuideDialog(res) {
      let { isAllSign, isAuth } = res

      if (isAllSign && isAuth == 1 && this.isMerchantAdmin && getFirstLogin(this.userId) == 1) { // 全部签约认证完成，且为超级管理员，且浏览器第一次登录
        this.$refs.GuideDialog.open()
      }
    },
    showGuidePop() {
      this.$eventBus.$emit('doShowGuidePop', true)
      this.changeFirstLogin()
    },
    // 关闭、退出指引、完成时，如果是首次登录，改成非首次
    changeFirstLogin() {
      if (getFirstLogin(this.userId) == 1) {
        setFirstLogin(2, this.userId)
      }
    },
    // 新手引导开始
    showGuideSteps() {
      let onlyOpen = false

      if (this.$route.name == 'workPeople') {
        onlyOpen = true
      } else {
        this.$router.push(`/workPeople`)
      }
      this.$refs.GuideSteps.open({total: 5, step: 1, popDomId: 'guideSteps1', sideDomId: 'workPeople', onlyOpen})
    },
  }
}
</script>
<style>
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: rgba(201, 205, 212, 0.4);
  min-height: 28px;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:vertical {
  border-right: 2px solid transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  border-bottom: 2px solid transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(201, 205, 212, 0.8);
  border-color: #fff;
}
::-webkit-scrollbar-track {
  background: #fff;
}
</style>
