import { kvTableTemplateStatus } from './formatters'
import { regular } from './validate'
import dayjs from 'dayjs'

// 表单模版
export const formTemplate = [
  { cmp: 'input', label: '输入框1', prop: 'xxa', itemConfig: { rules: [{ required: true, message: '必填', trigger: 'blur' }] }, config: {} },
  { cmp: 'select', label: '选择框1', prop: 'xxc', options: kvTableTemplateStatus },
  { cmp: 'dtpickers', label: '时选框1', prop: ['xxd1', 'xxd2'] },
  { cmp: 'upload', label: '上传', prop: 'xxe', itemConfig: { rules: [{ required: true, message: '必填', trigger: 'blur' }] } }
]

// 任务管理-编辑
export const formTaskManage = [
  { cmp: 'input', label: '任务编号', prop: 'taskNumber', config: { disabled: true, placeholder: '系统生成' } },
  { cmp: 'input', label: '任务名称', prop: 'taskName', itemConfig: { rules: [{ required: true, message: '请输入任务名称', trigger: 'blur' }] } },
  {
    cmp: 'input',
    label: '任务内容',
    prop: 'taskDescribe',
    itemConfig: { rules: [{ required: true, message: '请输入任务内容', trigger: 'blur' }] },
    config: { type: 'textarea', rows: 3 }
  }
]

// 修改密码
export const formPassword = [
  {
    cmp: 'input',
    label: '原密码',
    prop: 'oldPwd',
    config: { showPassword: true },
    itemConfig: { rules: [{ required: true, message: '请输入原密码', trigger: 'blur' }] }
  },
  {
    cmp: 'input',
    label: '新密码',
    prop: 'userPwd',
    config: { showPassword: true },
    itemConfig: {
      rules: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { pattern: /^[a-z0-9A-Z_]{6,16}$/, message: '请输入6-16位的数字、字母（区分大小写）、下划线', trigger: 'blur' }
      ]
    }
  },
  {
    cmp: 'input',
    label: '确认新密码',
    prop: 'confirmPwd',
    config: { showPassword: true, placeholder: '请输入新密码' },
    itemConfig: {
      rules: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { pattern: /^[a-z0-9A-Z_]{6,16}$/, message: '请输入6-16位的数字、字母（区分大小写）、下划线', trigger: 'blur' }
      ]
    }
  }
]

// 邮寄地址-编辑
export const formAddressInfo = [
  {
    cmp: 'input',
    label: '姓名',
    prop: 'name',
    itemConfig: { rules: [{ required: true, message: '必填', trigger: 'blur' }] },
    config: { placeholder: '请输入收件人姓名' }
  },
  {
    cmp: 'input',
    label: '电话',
    prop: 'phone',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        { pattern: regular.telemobile, message: regular.telemobileMsg, trigger: 'blur' }
      ]
    },
    config: { placeholder: '请输入收件人手机号/座机号' }
  },
  {
    cmp: 'pca',
    label: '省市区',
    prop: ['province', 'city', 'area'],
    itemConfig: { rules: [{ required: true, message: '必填', trigger: ['blur', 'change'] }] },
    config: { placeholder: '请选择省市区' }
  },
  {
    cmp: 'input',
    label: '详细地址',
    prop: 'addressDefail',
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    },
    config: { type: 'textarea', rows: 2, placeholder: '请输入详细地址（如，**街**号**）' }
  },
  {}
]

// 发票抬头-编辑
export const formRiseInfo = [
  {
    cmp: 'input',
    label: '抬头名称',
    prop: 'riseName',
    itemConfig: { rules: [{ required: true, message: '必填', trigger: 'blur' }] },
    config: { placeholder: '请输入企业名称' }
  },
  {
    cmp: 'input',
    label: '税号',
    prop: 'taxpayerNumber',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        { pattern: regular.trn, message: regular.trnMsg, trigger: 'blur' }
      ]
    },
    config: { placeholder: '请输入企业税号' }
  },
  { cmp: 'input', label: '注册地址', prop: 'unitAddress', itemConfig: { rules: [{ required: true, message: '必填', trigger: 'blur' }] } },
  {
    cmp: 'input',
    label: '注册电话',
    prop: 'phone',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        { pattern: regular.telemobile, message: '请输入正确的电话', trigger: 'blur' }
      ]
    }
  },
  { cmp: 'input', label: '开户银行', prop: 'openBank', itemConfig: { rules: [{ required: true, message: '必填', trigger: 'blur' }] } },
  {
    cmp: 'input',
    label: '银行账号',
    prop: 'bankAccount',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        { pattern: regular.naturalNumber, message: '请输入正确的银行账号', trigger: 'blur' }
      ]
    }
  },
  {}
]

// 申请开票
export const formBillApply = [
  {
    cmp: 'radio',
    label: '发票类型',
    prop: 'applyState',
    options: [],
    sessionTitle: '发票类型',
    itemConfig: { rules: [{ required: true, message: '必选', trigger: 'change' }] }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remarks',
    config: { type: 'textarea', rows: 2, maxLength: 500 }
  },
  {}
]

// 交付物表单上传
export const regulateForm = [
  {
    cmp: 'input',
    prop: 'userName',
    label: '创客姓名',
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    prop: 'idCard',
    label: '身份证号',
    itemConfig: {
      rules: [
        { required: true, message: '必填', trigger: 'blur' },
        { pattern: regular.IDCard, message: regular.IDCardMsg, trigger: 'blur' }
      ]
    }
  },
  {
    cmp: 'upload',
    prop: 'deliverableList',
    label: '上传交付物',
    suffixArray: ['jpg', 'png', 'jpeg', 'pdf', 'zip', 'rar', 'txt'],
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  },
  {
    cmp: 'upload',
    prop: 'acceptanceList',
    label: '上传成果验收单',
    suffixArray: ['jpg', 'png', 'jpeg', 'pdf', 'zip', 'rar', 'txt'],
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  }
]
// 业务确认单弹窗上传
export const businessForm = [
  {
    cmp: 'upload',
    prop: 'attachUrl',
    label: '业务确认单文件',
    suffixArray: ['jpg', 'png', 'jpeg', 'pdf', 'zip', 'rar', 'txt'],
    itemConfig: {
      rules: [{ required: true, message: '必填', trigger: 'blur' }]
    }
  }
]
// 创客添加、编辑
export const formWorkPeople = [
  {
    cmp: 'input',
    label: '人员编号',
    prop: 'peopleNo',
    col: 11,
    config: { disabled: true, placeholder: '系统生成' }
  },
  {
    cmp: 'input',
    prop: 'userName',
    label: '创客姓名',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入创客姓名', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'mobile',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { pattern: regular.mobile, message: regular.mobileMsg, trigger: 'blur' }
      ]
    },
    config: { maxLength: 11 }
  },
  {
    cmp: 'input',
    prop: 'idCard',
    label: '身份证号',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入身份证号', trigger: 'blur' },
        { pattern: regular.IDCard, message: regular.IDCardMsg, trigger: 'blur' }
      ]
    },
    disabled: `form.id`
  },
  {
    cmp: 'upload',
    label: '身份证正面照片',
    prop: 'idFrontPhoto',
    col: 11,
    config: { noPreview: true },
    disabled: `form.id&&form.isSign==1`
  },
  {
    cmp: 'upload',
    label: '身份证反面照片',
    prop: 'idBackPhoto',
    col: 11,
    config: { noPreview: true },
    disabled: `form.id&&form.isSign==1`
  },
  {
    cmp: 'input',
    prop: 'email',
    label: '电子邮箱',
    col: 11,
    itemConfig: {
      rules: [{ pattern: regular.email, message: regular.emailMsg, trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    prop: 'address',
    label: '地址',
    col: 11,
    config: { maxLength: 50 }
  },
  {
    cmp: 'input',
    label: '银行卡号',
    prop: 'bankNumber',
    col: 11,
    itemConfig: {
      rules: [{ pattern: regular.bankCard, message: regular.bankCardMsg, trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '支付宝',
    prop: 'alipayNumber',
    col: 11
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    col: 22,
    config: { type: 'textarea', placeholder: '请输入备注，不超过200字', maxLength: 200 }
  }
]
// 子账号
export const formSubMerchant = [
  {
    cmp: 'input',
    label: '子账号名称',
    prop: 'userName',
    itemConfig: {
      rules: [{ required: true, message: '请输入子账号名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '绑定手机号',
    prop: 'mobile',
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { pattern: regular.mobile, message: regular.mobileMsg, trigger: 'blur' }
      ]
    }
  },
  {
    cmp: 'select',
    label: '关联角色',
    prop: 'roleId',
    itemConfig: { rules: [{ required: true, message: '请选择关联角色', trigger: ['blur', 'change'] }] }
  }
]
// 提现表单
export const withdrawForm = [
  {
    cmp: 'select',
    label: '提现账户',
    prop: 'accountId',
    itemConfig: { rules: [{ required: true, message: '请选择提现账户', trigger: ['blur', 'change'] }], class: 'item-inline' },
    config: { style: { width: '400px' } },
    sideContent: `可提现金额：0.00元`,
    sideBar: true,
    options: []
  },
  {
    cmp: 'number',
    label: '提现金额',
    prop: 'withdrawAmount',
    itemConfig: { rules: [{ required: true, message: '请输入提现金额', trigger: 'blur' }] },
    config: { style: { width: '400px' }, precision: 2, min: 0, controls: false }
  },
  {
    cmp: 'input',
    label: '账户名称',
    prop: 'withdrawAccountName',
    itemConfig: { rules: [{ required: true, message: '请输入当前账号认证主体名称', trigger: 'blur' }] },
    disabled: true,
    config: { style: { width: '400px' }, placeholder: '当前账号认证主体名称' }
  },
  {
    cmp: 'input',
    label: '收款账号',
    prop: 'withdrawAccountNumber',
    itemConfig: { rules: [{ required: true, message: '请输入收款账户', trigger: 'blur' }] },
    config: { style: { width: '400px' } }
  },
  {
    cmp: 'input',
    label: '开户行',
    prop: 'withdrawAccountBank',
    itemConfig: { rules: [{ required: true, message: '请输入开户行', trigger: 'blur' }] },
    config: { style: { width: '400px' } }
  },
  {
    cmp: 'input',
    label: '联行号',
    prop: 'withdrawAccountLines',
    itemConfig: { rules: [{ required: true, message: '请输入联行号', trigger: 'blur' }] },
    config: { style: { width: '400px' } }
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    config: { type: 'textarea', placeholder: '请输入备注，不超过200字', maxLength: 200 }
  }
]
// 新建商户
export const formPeolple = [
  {
    cmp: 'input',
    label: '创客姓名',
    prop: 'name',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入创客姓名', trigger: 'blur' }]
    },
    config: { placeholder: '请输入创客姓名' }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'phone',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入手机号', maxlength: 11 }
  },
  {
    cmp: 'input',
    label: '证件号',
    prop: 'idCard',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入身份证号', trigger: 'blur' },
        {
          pattern: regular.IDCard,
          message: regular.IDCardMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入身份证号' }
  },
  {
    cmp: 'input',
    label: '银行卡号',
    prop: 'bankCard',
    col: 11,
    itemConfig: {
      rules: [{ pattern: regular.bankCard, message: regular.bankCardMsg, trigger: 'blur' }]
    },
    config: { placeholder: '请输入银行卡号' }
  },
  {
    cmp: 'input',
    label: '支付宝',
    prop: 'alipay',
    col: 11,
    config: { placeholder: '请输入支付宝号' }
  },
  // {
  //   cmp: '',
  //   label: '',
  //   prop: '',
  //   col: 11
  // },
  {
    cmp: 'upload',
    label: '身份证正面照片',
    prop: 'idFontPhoto',
    col: 11
  },
  {
    cmp: 'upload',
    label: '身份证反面照片',
    prop: 'idBackPhoto',
    col: 11
  },
  {
    cmp: 'multipleUpload',
    label: '其他文件',
    prop: 'whiteAttach',
    col: 11
  },
  // other
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    col: 22,
    config: { type: 'textarea', maxlength: 200 }
  }
]
