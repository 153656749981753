<template>
  <el-dialog title="申请开票" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
    <div class="content-box">
      <DetailCard title="基本信息" :hasBefore="false">
        <DescView :descLabels="descLabels" :descDatas="detail" class="basic-info">
          <template #taxName>
            <span>{{ taxName }}</span>
          </template>
          <template #invoicingMoney>
            <template slot="label">
              <span>开票金额</span>
              <el-tooltip effect="dark" content="开票金额 = 申请金额 - 退汇金额" placement="top">
                <i class="icon el-icon-self-question-line"></i>
              </el-tooltip>
            </template>
            <span v-format="'#,##0.00'">{{ detail.invoicingMoney }}</span
            >元
          </template>
          <template #totalMoney>
            <template slot="label">
              <span>申请金额</span>
              <el-tooltip effect="dark" content="申请金额是在发票申请页面中，选择的结算信息可开票金额总和" placement="top">
                <i class="icon el-icon-self-question-line"></i>
              </el-tooltip>
            </template>
            <span v-format="'#,##0.00'">{{ detail.totalMoney }}</span
            >元
          </template>
          <template #totalReturn>
            <template slot="label">
              <span>退汇金额</span>
              <el-tooltip
                effect="dark"
                content="因银行退汇操作前视为打款成功，平台为支持商户正常业务开票，预先支持所有打款成功的结算信息进行开票。若在开票后产生的退汇金额，平台将在下次开票申请额度中扣除，以保证正常冲销"
                placement="top"
              >
                <i class="icon el-icon-self-question-line"></i>
              </el-tooltip>
            </template>
            <span v-format="'#,##0.00'">{{ detail.totalReturn }}</span
            >元
          </template>
        </DescView>
        <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }" :slotIdxList="[1]" @onInputChange="onInputChange">
          <template #form-items="{ idx, form }">
            <el-col v-if="idx == 1" :span="20">
              <el-form-item label="发票抬头" prop="riseId" :rules="[{ required: true, message: '请选择公司抬头', trigger: ['blur', 'change'] }]">
                <el-select
                  ref="riseRef"
                  filterable
                  v-model="form.riseId"
                  placeholder="请选择公司抬头"
                  @visible-change="(v) => addBtnByVisibleChange(v, 'riseRef')"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, idx) in riseList"
                    :key="idx"
                    :label="item.riseName + '（' + item.taxpayerNumber + '）'"
                    :value="item.riseId"
                  >
                    <template>
                      <span>{{ item.riseName }}</span>
                      <el-tooltip effect="dark" placement="top" popper-class="tipsinfo">
                        <div slot="content" class="rise-tipsinfo">
                          <div class="item">
                            <span>抬头名称：</span>
                            <span>{{ item.riseName }}</span>
                          </div>
                          <div class="item">
                            <span>税号：</span>
                            <span>{{ item.taxpayerNumber }}</span>
                          </div>
                          <div class="item">
                            <span>注册地址：</span>
                            <span>{{ item.unitAddress }}</span>
                          </div>
                          <div class="item">
                            <span>注册电话：</span>
                            <span>{{ item.phone }}</span>
                          </div>
                          <div class="item">
                            <span>开户银行：</span>
                            <span>{{ item.openBank }}</span>
                          </div>
                          <div class="item">
                            <span>银行账号：</span>
                            <span>{{ item.bankAccount }}</span>
                          </div>
                        </div>
                        <i class="el-icon-self-information-line" style="margin-left: 2px; font-size: 16px; cursor: pointer"></i>
                      </el-tooltip>
                    </template>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="邮寄地址"
                prop="addressId"
                :rules="
                  form.applyState == 1 || form.applyState == 2 ? [{ required: true, message: '请选择邮寄地址', trigger: ['blur', 'change'] }] : []
                "
              >
                <el-select
                  ref="addressRef"
                  filterable
                  v-model="form.addressId"
                  placeholder="请选择邮寄地址"
                  @visible-change="(v) => addBtnByVisibleChange(v, 'addressRef')"
                  style="width: 100%"
                  popper-class="popper-address"
                >
                  <el-option
                    v-for="(item, idx) in addressList"
                    :key="idx"
                    :label="item.name + ' ' + item.phone + ' ' + item.province + ' ' + item.city + ' ' + item.area + ' ' + item.addressDefail"
                    :value="item.addressId"
                  >
                    <template>
                      <span>{{
                        item.name + ' ' + item.phone + ' ' + item.province + ' ' + item.city + ' ' + item.area + ' ' + item.addressDefail
                      }}</span>
                    </template>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="邮箱"
                prop="inboxEmail"
                :rules="form.applyState == 3 || form.applyState == 4 ? [{ required: true, validator: emailValidator, trigger: 'blur' }] : []"
              >
                <el-input v-model="form.inboxEmail" placeholder="请输入邮箱" @change="emailChange"></el-input>
              </el-form-item>
            </el-col>
          </template>
        </FormView>
      </DetailCard>
      <DetailCard title="结算信息" :hasBefore="false">
        <TableView
          ref="vTable"
          v-loading="loading"
          :labels="tableLabels"
          :hideTitle="true"
          :disInitLoad="true"
          :isFrontEndApi="true"
          :allListData="oriList"
          :searchExt="searchExt"
        >
          <!-- 操作栏 -->
          <el-table-column slot="table-item" label="操作" width="80" fixed="right">
            <template v-slot="{ row, $index }">
              <el-button type="text" class="el-button-error" @click="deleteEvent(row)">删除</el-button>
            </template>
          </el-table-column>
        </TableView>
      </DetailCard>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit()" :loading="btnloadSubmit">提 交</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { kvInvoiceType } from '@/configs/formatters'
import { deepClone } from '@/utils'
import { regular } from '@/configs/validate'

export default {
  props: {
    totalReturn: 0,
    returnList: []
  },
  data() {
    return {
      settlementMakeInvoice: null,
      btnloadSubmit: false,
      show: false,

      loading: false,
      searchExt: {},
      oriList: [],
      list: [],

      baseForm: {},
      riseList: [], // 发票抬头选项列表
      addressList: [], // 邮寄地址选项列表
      taxId: '',
      taxName: '',
      supplierId: '',
      // returnList: [],

      descLabels: [
        { label: '开票税目', slotName: 'taxName', cmp: 'slot' },
        { label: '开票金额', slotName: 'invoicingMoney', cmp: 'slot' },
        { label: '申请金额', slotName: 'totalMoney', cmp: 'slot' },
        { label: '退汇金额', slotName: 'totalReturn', cmp: 'slot' }
      ],
      formLabels: [
        {
          cmp: 'select',
          label: '发票类型',
          prop: 'applyState',
          col: 8,
          itemConfig: { rules: [{ required: true, message: '请选择发票类型', trigger: ['blur', 'change'] }], class: 'item-inline' },
          config: { style: { width: '400px' } },
          options: []
        },
        {
          cmp: 'input',
          label: '备注',
          prop: 'remarks',
          col: 20,
          config: { type: 'textarea', rows: 2, maxLength: 500, placeholder: '请输入备注说明' }
        }
      ]
    }
  },
  computed: {
    tableLabels() {
      if (this.settlementMakeInvoice == 1) {
        return [
          { label: '订单编号', prop: 'customerOrderNo' },
          { label: '结算单号', prop: 'planNo' },
          {
            label: '支付金额',
            prop: 'settleAmount',
            cmp: 'money'
          },
          { label: '任务编号', prop: 'taskNo' },
          { label: '任务名称', prop: 'taskName' }
        ]
      } else {
        return [
          { label: '结算单号', prop: 'planNo' },
          {
            label: '支付金额',
            prop: 'settleAmount',
            cmp: 'money'
          },
          { label: '任务编号', prop: 'taskNo' },
          { label: '任务名称', prop: 'taskName' }
        ]
      }
    },
    totalMoney() {
      return this.oriList.reduce((a, b) => {
        return a + parseFloat(b.invoiceAmount)
      }, 0)
    },
    // totalReturn() {
    //     return this.returnList.reduce((a, b) => {
    //         return a + (parseFloat(b.afterTaxAmount||0) + parseFloat(b.serviceFee||0) + parseFloat(b.serviceDiffFee||0) - parseFloat(b.deductionAmount||0))
    //     }, 0)
    // },
    detail() {
      return {
        invoicingMoney: this.totalMoney - this.totalReturn,
        totalMoney: this.totalMoney,
        totalReturn: this.totalReturn
      }
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.settlementMakeInvoice = null
      this.oriList = []
      this.baseForm = {}
      this.riseList = []
      this.addressList = []
      // this.returnList = []
    },
    async open(settlementMakeInvoice, list, all, params) {
      this.clear()
      this.settlementMakeInvoice = settlementMakeInvoice
      if (all) {
        this.searchExt = params
        this.init(list)
      } else if (list && list.length) {
        // 选择开
        this.init(list)
      } else {
        this.$message.error('无效的信息')
      }
    },
    async init(list) {
      this.show = true
      this.oriList = deepClone(list)
      this.$nextTick(() => {
        this.$refs.vTable.getTableList()
      })
      this.taxName = this.oriList[0].taxName
      this.taxId = this.oriList[0].taxId
      this.supplierId = this.oriList[0].supplierId
      // this.getReturnList()
      await this.getSupplierInvoiceTypeList()
      await this.getRiseInfo()
      await this.getAddressInfo()
      this.$nextTick(() => {
        this.$refs.vForm.fillData({ ...this.baseForm, taxItemsId: this.taxId })
      })
    },
    // 删除（需校验申请金额是否大于退汇金额）
    deleteEvent(item) {
      let list = JSON.parse(JSON.stringify(this.oriList))

      list.splice(
        list.findIndex((it) => it.id == item.id),
        1
      )
      let totalMoney = list.reduce((a, b) => {
        return a + parseFloat(b.invoiceAmount)
      }, 0)

      if (totalMoney > this.totalReturn) {
        this.oriList.splice(
          this.oriList.findIndex((it) => it.id == item.id),
          1
        )
        this.$refs.vTable.getTableList()
        this.$message.success('删除成功')
      } else {
        this.$message.warning('删除失败，申请金额需要大于退汇金额')
      }
    },
    // 获取商户未开票的退汇订单
    // async getReturnList() {
    //     const res = await this.$api.GetReturnWorkDetailNotBill({supplierId: this.supplierId, taxId: this.taxId})
    //     this.returnList = res.list || []
    // },
    // 获取开票类型列表
    async getSupplierInvoiceTypeList() {
      const res = await this.$api.GetSupplierInvoiceTypeList({ supplierId: this.supplierId })
      const list = kvInvoiceType.filter((item) => {
        return !!res.list.find((it) => it == item.key)
      })
      console.log(list)
      // 处理数据结构并赋值
      this.formLabels.find((item) => item.prop == 'applyState').options = list || []
      this.baseForm.applyState = (list[0] && list[0].key) || ''
    },
    // 获取邮寄地址选项列表
    async getAddressInfo() {
      const res = await this.$api.GetAddressInfo()

      if (!res.list.length) return false
      this.addressList = res.list
      this.getDefault('addressId', this.addressList)
      this.$refs.vForm.upData({ addressId: this.baseForm.addressId })
    },
    // 获取发票抬头选项列表
    async getRiseInfo() {
      const res = await this.$api.GetRiseInfo()

      if (!res.list.length) return false
      this.riseList = res.list
      this.getDefault('riseId', this.riseList)
      this.$refs.vForm.upData({ riseId: this.baseForm.riseId })
    },
    // 有默认取默认值，没有默认就取第一个值
    getDefault(name, list) {
      if (list && list.length) {
        const hasDefault = list.some((item) => item.isDefault == '是')

        if (hasDefault) {
          let defaultData = list.filter((item) => item.isDefault == '是')[0]

          this.baseForm[name] = (defaultData && defaultData[name]) || ''
        } else {
          this.baseForm[name] = (list[0] && list[0][name]) || ''
        }
      } else {
        this.baseForm[name] = ''
      }
    },
    // 动态新增新增按钮
    addBtnByVisibleChange(visible, refName) {
      if (visible) {
        const ref = this.$refs[refName]
        let popper = ref.$refs.popper,
          btnName = refName == 'riseRef' ? '新增发票抬头' : '新增邮寄地址'

        if (popper.$el) popper = popper.$el
        if (!Array.from(popper.children).some((v) => v.className === 'el-template-menu__list')) {
          const el = document.createElement('div')

          el.className = 'el-template-menu__list'
          el.style = 'padding: 14px 0; border-top: 1px solid #E5E6EB; color: #30D68C; font-size: 14px'
          el.innerHTML = `<div style="line-height: 20px; text-align: center; cursor: pointer">${btnName}</div>`
          popper.appendChild(el)
          el.onclick = () => {
            this.$emit('addEvent', refName, 'selected')
          }
        }
      }
    },
    emailChange(v) {
      if (v) {
        this.$refs.vForm.$refs.form.validate('inboxEmail')
      }
    },
    emailValidator(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else if (!regular.email.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    },
    // change事件
    async onInputChange(val, key) {
      if (key == 'applyState') {
        if (val == 1 || val == 2) {
          this.$refs.vForm.$refs.form.clearValidate('inboxEmail')
        } else if (val == 3 || val == 4) {
          this.$refs.vForm.$refs.form.clearValidate('addressId')
        }
      }
    },
    // 提交
    async submit() {
      const params = await this.$refs.vForm.getForm()
      const arr = this.oriList.map((item) => {
        return item.id
      })

      if (!(arr && arr.length)) {
        return this.$message.warning('没有申请开票的内容')
      }

      let obj = this.settlementMakeInvoice == 1 ? { workDetailIds: arr } : this.settlementMakeInvoice == 0 ? { workplanIds: arr } : {}

      this.btnloadSubmit = true
      const res = await this.$api.AddBillInfo(Object.assign({ invoicingSource: this.settlementMakeInvoice }, obj, params)).finally(() => {
        this.btnloadSubmit = false
      })
      this.$emit('submit')
      this.show = false
      this.$message.success('发票申请提交成功')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 4px 20px 30px;
}
.content-box {
  .basic-info {
    margin: 0 0 24px 0;
    .icon {
      margin-left: 2px;
      font-size: 16px;
      cursor: pointer;
    }
  }
  ::v-deep .detail-card {
    margin: 0;
    .detail-card-container {
      padding: 16px 0 6px;
    }
  }
}
.el-select-dropdown__item {
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
<style lang="scss">
.tipsinfo {
  .rise-tipsinfo {
    .item {
      margin: 5px 0;
    }
  }
}
.popper-address {
  .el-select-dropdown__item {
    max-width: 580px;
    height: auto;
    line-height: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    word-break: break-all;
    white-space: normal;
    text-overflow: unset;
  }
}
</style>
