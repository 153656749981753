<template>
  <div class="upload">
    <el-upload
      :action="this.type == 'excel' ? uploadExcelUrl : uploadUrl"
      :multiple="true"
      :show-file-list="false"
      v-loading="loading"
      class="file-uploader"
      :headers="extraData"
      :before-upload="onBeforeUpload"
      :on-error="onUploadError"
      :on-success="onUpload"
      :on-preview="handlePreview"
      :on-exceed="handleExceed"
      v-bind="config"
      :limit="number"
      :file-list="fakeValue"
      style="margin-right: 15px; display: inline-block"
    >
      <el-button v-if="config.disabled" disabled slot="trigger" icon="el-icon-upload" style="margin-right: 15px" type="primary" size="mini">
        上传</el-button
      >
      <el-button v-else slot="trigger" icon="el-icon-upload" style="margin-right: 15px" type="primary" size="mini"> 上传</el-button>
    </el-upload>
    <div v-if="fakeValue" class="imageList">
      <div v-for="(item, index) in fakeValue" :key="index" class="file-item">
        <span @click="lookFile(item)" class="text"
          >{{ item.fileName || '查看文件' }}
          <i class="el-icon-view el-icon--right lookIcon"></i>
        </span>
        <i v-if="isEdit" @click="deleteFile(item)" class="el-icon-close el-icon--right otherIcon"></i>
      </div>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :zIndex="9999"
      :url-list="viewerlList"
      :on-close="
        () => {
          showViewer = false
        }
      "
    />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { downloadUrl } from '@/utils'
export default {
  name: 'ComponentFormUpload',
  components: {
    ElImageViewer
  },
  props: {
    modelValue: Array,
    type: String,
    config: Object,
    number: {
      type: Number,
      default: 5
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    //文件大小
    fileSize: {
      type: Number,
      default: 5
    },
    //文件格式
    fileSuffix: {
      type: Array,
      default: () => ['jpg', 'png', 'jpeg', 'pdf']
    },
    uploadOutUrl: {
      type: String,
      default: 'Upload/UploadFile'
    }
  },
  data() {
    return {
      loading: false,
      uploadUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + this.uploadOutUrl,
      uploadExcelUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + 'Upload/UploadExcelFile',
      showViewer: false,
      viewerlList: []
    }
  },
  computed: {
    extraData() {
      return {
        Authorization: 'Bearer ' + this.$store.state.user.token
      }
    },
    fakeValue: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update', val)
      }
    }
  },
  methods: {
    handlePreview() {},
    handleExceed(files, fileListe) {
      this.$message.warning('最多上传' + this.number + '个文件')
    },
    onBeforeUpload(file, fileList) {
      // 文件大小限制
      if (file.size / (1024 * 1024) > this.fileSize) {
        this.$message.warning(`当前限制文件大小不能大于${fileSize}M`)
        return false
      }
      // 格式限制
      let suffix = this.getFileType(file.name)
      if (this.fileSuffix.indexOf(suffix) === -1) {
        this.$message.warning(`只能上传${this.fileSuffix.toString()}`)
        return false
      }
      this.loading = true
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    onUploadError(err) {
      this.loading = false
      this.$message.error(err.message || '上传失败')
    },
    onUpload(res) {
      if (!res || res.code !== '0' || !res.data) return this.onUploadError(res)
      const fileList = this.fakeValue || []
      fileList.push({
        fileName: this.getName(res.data),
        fileUrl: res.data
      })
      this.fakeValue = fileList
      this.loading = false
      this.$message.success('上传成功')
      this.$emit('uploaded', this.fakeValue)
    },
    deleteFile(e) {
      this.fakeValue.splice(this.fakeValue.indexOf(e.fileUrl), 1)
      this.$forceUpdate()
      this.$emit('uploaded', this.fakeValue)
    },
    lookFile(e) {
      const regImg = /\.(jpg|jpeg|png|JPG)$/
      const regpdf = /\.(pdf)$/
      const regzip = /\.(zip)$/
      const regrar = /\.(rar)$/
      if (regImg.test(e.fileUrl)) {
        this.viewerlList = [e.fileUrl]
        this.showViewer = true
      } else if (regpdf.test(e.fileUrl)) {
        downloadUrl(e.fileUrl)
      } else if (regzip.test(e.fileUrl)) {
        downloadUrl(e.fileUrl)
      } else if (regrar.test(e.fileUrl)) {
        downloadUrl(e.fileUrl)
      } else {
        downloadUrl(e.fileUrl)
      }
    },
    getName(val) {
      const pos = val.lastIndexOf('/')
      return val.substring(pos + 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.upload {
  .file-uploader {
  }
  .imageList {
    display: flex;
    flex-direction: column;
    max-height: 78px;
    overflow: auto;
    // padding-left: 48px;
    .file-item {
      .text {
        color: #236df4;
        cursor: pointer;
      }
      .lookIcon {
        margin-right: 70px;
        color: #236df4;
      }
      .otherIcon {
        &:hover {
          color: #f00;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
