<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="labels" :seq="false" :tableConfig="{ border: false, size: 'medium' }" apiName="GetSystemAlertPageList">
      <el-form-item slot="btnline-item">
        <el-button type="text" @click="allRead">全部标记为已读</el-button>
      </el-form-item>
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="toDetail(row)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
  </div>
</template>

<script>
import { tableMsg } from '@/configs/tables.js'
import { kvMsgType } from '@/configs/formatters'
export default {
  data() {
    return {
      labels: tableMsg,
      msgTypeRoutes: kvMsgType
    }
  },
  methods: {
    toDetail(item) {
      if (!item.isRead) {
        this.$api.ReadSystemAlert({ id: item.id })
        item.isRead = true
      }
      const msgTypeRoute = this.msgTypeRoutes.find((it) => {
        return it.key == item.alertScene
      })
      if (msgTypeRoute) {
        this.$router.push(msgTypeRoute.route)
      }
    },
    async allRead() {
      await this.$api.SetAllReadSystemAlert()
      this.$message.success('已经全部标记为已读')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
