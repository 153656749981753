<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '交付物详情查看'
    }"
  >
    <DetailCard title="基本信息">
      <DescView :descLabels="descLabels1" :descDatas="detail">
        <template #userName>{{ detail.userName || '无' }}<WpView v-if="detail.userName" :id="detail.id" :type="1" /></template>
        <template #idCard>{{ detail.idCard || '无' }}<WpView v-if="detail.idCard" :id="detail.id" :type="1" /></template>
      </DescView>
    </DetailCard>
    <DetailCard title="交付物">
      <DescView :descLabels="descLabels2" :descDatas="detail">
        <template #deliverableList>
          <template v-if="detail.deliverableList && detail.deliverableList.length">
            <div v-for="(item, index) in detail.deliverableList" :key="index">
              <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
              <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-if="item.attachUrl && item.type == 'pdf'">查看文件{{ index + 1 }}</span>
            </div>
          </template>
          <span v-else>无</span>
        </template>
      </DescView>
    </DetailCard>
    <DetailCard title="成果验收单">
      <DescView :descLabels="descLabels3" :descDatas="detail">
        <template #acceptanceList>
          <template v-if="detail.acceptanceList && detail.acceptanceList.length > 0">
            <div v-for="(item, index) in detail.acceptanceList" :key="index">
              <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
              <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-if="item.attachUrl && item.type == 'pdf'">查看文件{{ index + 1 }}</span>
            </div>
          </template>
          <span v-else>无</span>
        </template>
      </DescView>
    </DetailCard>
  </DetailView>
</template>

<script>
import { kvAuditStatus } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import { downloadUrl } from '@/utils'
const descLabels1 = [
  { label: '创客姓名', cmp: 'slot', slotName: 'userName' },
  { label: '身份证号', cmp: 'slot', slotName: 'idCard' },
  { label: '当前月份', prop: 'currMonth' },
  { label: '发放金额', cmp: 'money', prop: 'grantAmount' },
  { label: '任务编号', prop: 'taskNo' },
  { label: '任务名称', prop: 'taskName' }
]
const descLabels2 = [
  { label: '交付物状态', prop: 'uploadState', cmp: 'state', stateOptions: kvAuditStatus },
  { label: '退回理由', prop: 'delBackReason' },
  { label: '上传文件', cmp: 'slot', slotName: 'deliverableList' }
]
const descLabels3 = [
  { label: '上传时间', prop: 'apcUploadDateTime' },
  { label: '上传人', prop: 'apcUploader' },
  { label: '成果验收单状态', prop: 'apcUploadState', cmp: 'state', stateOptions: kvAuditStatus },
  { label: '退回理由', prop: 'apcBackReason' },
  { label: '上传文件', cmp: 'slot', slotName: 'acceptanceList' }
]
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      show: false,
      id: null,
      descLabels1,
      descLabels2,
      descLabels3,
      detail: {},
      isEdit: false
    }
  },
  methods: {
    viewPdf(e) {
      downloadUrl(e)
    },
    async open(obj) {
      const result = await this.$api.GetTaskDeliverable({ deliverableId: obj.deliverableId })
      result.deliverableList.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      result.acceptanceList.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      this.detail = Object.assign({}, result)
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.otherStyle {
  color: #3d78fa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
</style>
