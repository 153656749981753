<template>
  <el-dialog title="添加" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="8vh" class="dialog-large">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }"></FormView>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formPeolple } from '@/configs/forms'

export default {
  props: {},
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: formPeolple,
      form: {},
      id: '',
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = ''
      this.form = {}
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      let params = {}

      if (obj && obj.id) {
        // 编辑白名单（再次提交，相当于新添加）, 审核不通过的调用新接口
        this.id = obj.id
        this.detail = obj
        await this.getSystemWhiteInfoByEdit()
        let {
          userName: name,
          mobile: phone,
          idCard,
          bankNumber: bankCard,
          alipayNumber: alipay,
          idFontPhoto,
          idBackPhoto,
          attachList: whiteAttach,
          remark
        } = this.form

        params = { name, phone, idCard, bankCard, alipay, idFontPhoto, idBackPhoto, whiteAttach, remark }
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(params)
      })
    },
    // 获取白名单详情
    async getSystemWhiteInfoByEdit() {
      const result = await this.$api.GetSystemWhiteInfoByEdit({ id: this.id })

      this.form = Object.assign({}, result)
    },
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.map((item) => {
        if (item.prop === 'mermchantId') {
          item.options = v
        }
      })
    },
    // 提交
    async submit() {
      const result = await this.$refs.vForm.validate()

      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        let res = undefined
        if (this.detail.auditState == '审核不通过') {
          res = await this.$api.ReSubmitSystemWhiteInfo({ ...this.form, id: this.id }).finally(() => {
            this.btnloadSubmit = false
          })
        } else {
          res = await this.$api.AddSystemWhiteInfo(this.form).finally(() => {
            this.btnloadSubmit = false
          })
        }

        if (res) {
          if (this.id) this.$message.success('再次提交成功')
          else this.$message.success('添加成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}
</script>
