<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
    <!-- 内容区域 -->
    <div>
      <div class="top-box">
        <div class="top-box-item" v-for="(i, index) in topBoxData" :key="index" :style="{ 'background-color': i.bgColor }">
          <p class="label" :style="{ color: i.labelColor }">{{ i.label }}</p>
          <p class="value">{{ i.value }}</p>
        </div>
      </div>
      <TableView
        v-if="show"
        ref="vTable"
        :labels="tableLabels"
        apiName="GetWorkPeopleCheck"
        :searchList="searchList"
        resChildName="checkResultList"
        @geted="onTableGeted"
        :searchExt="{ Mark: mark }"
        tableMinHeight="350px"
        :handLoading="true"
      >
        <div v-if="totalData.checkNotPassPeopleNum" slot="ext-table">
          <div class="tip-box">
            <span><i class="icon icon-warning el-icon-self-error-warning-fill"></i>检测到有</span>
            <span class="num">&ensp;{{ totalData.checkNotPassPeopleNum || 0 }}条&ensp;</span>
            <span>数据未通过校验</span>
            <span class="download pointer" @click="exportWorkPeople"
              ><i class="icon icon-download el-icon-self-download-2-line"></i>下载未通过数据</span
            >
          </div>
        </div>
      </TableView>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { downloadExcel } from '@/utils'
import { tableWorkPeopleCheck } from '@/configs/tables'
import { searchWorkPeopleCheck } from '@/configs/searches'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      title: '导入创客',
      tableLabels: tableWorkPeopleCheck,
      searchList: searchWorkPeopleCheck,
      mark: '',
      totalData: {}
    }
  },
  computed: {
    topBoxData() {
      return [
        {
          label: '校验通过人数',
          value: this.totalData.checkPassPeopleNum || 0,
          labelColor: '#00B42A',
          bgColor: '#E8FFEA'
        },
        {
          label: '校验不通过人数',
          value: this.totalData.checkNotPassPeopleNum || 0,
          labelColor: '#EB5757',
          bgColor: '#FFECE8'
        }
      ]
    }
  },
  methods: {
    close() {
      this.show = false
    },
    open(mark) {
      if (mark) {
        this.mark = mark
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    //导出未通过
    async exportWorkPeople() {
      const res = await this.$api.exportWorkPeople({ mark: this.mark, checkResult: 2 })
      downloadExcel(res, '未通过创客列表')
    },
    onTableGeted(response, params) {
      const { totalData } = response
      this.totalData = totalData || {}
      if (response.state != 2) {
        setTimeout(() => {
          this.$refs.vTable.refreshTable()
        }, 1000)
      } else {
        this.$refs.vTable.closeLoading()
      }
    },
    passingSituation() {
      // 1：有通过和不通过数据，显示二次确认弹窗；2：全部通过，正常导入（逻辑与现在导入不变）；3：全部不通过，toast提示：无法导入，没有校验通过的信息
      if (this.totalData && this.totalData.totalPeopleNum && (this.totalData.checkPassPeopleNum || this.totalData.checkNotPassPeopleNum)) {
        return this.totalData.checkNotPassPeopleNum == this.totalData.totalPeopleNum
          ? 3
          : this.totalData.checkPassPeopleNum == this.totalData.totalPeopleNum
          ? 2
          : 1
      }
      return 0
    },
    // 提交
    async submit() {
      if (this.passingSituation() == 1) {
        this.$emit('confirm', {
          type: 'workPeople',
          mark: this.mark,
          passPeopleNum: this.totalData.checkPassPeopleNum,
          notPassPeopleNum: this.totalData.checkNotPassPeopleNum
        })
      } else if (this.passingSituation() == 2) {
        this.btnloadSubmit = true
        const res = await this.$api.ConfirmWorkPeopleCheck({ mark: this.mark }).finally(() => {
          this.btnloadSubmit = false
        })

        this.$emit('submit')
        this.$emit('success', { type: 'workPeople', passPeopleNum: this.totalData.checkPassPeopleNum })
        this.close()
      } else if (this.passingSituation() == 3) {
        this.$message.warning('无法导入，没有校验通过的信息')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.top-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  &-item {
    width: 100%;
    height: 88px;
    padding: 16px 24px;
    margin-right: 16px;
    border-radius: 8px;
    &:last-child {
      margin: 0;
    }
    p {
      margin: 0;
    }
    .label {
      margin-bottom: 4px;
      line-height: 20px;
      font-size: 14px;
    }
    .value {
      line-height: 32px;
      font-size: 24px;
      font-weight: 600;
      color: #1d2129;
      font-family: 'Bebas';
    }
  }
}
.tip-box {
  padding: 10px 12px;
  margin: -4px 0 12px;
  background-color: #fef4bf;
  .pointer {
    cursor: pointer;
  }
  .icon {
    font-size: 16px;
    &-warning {
      margin-right: 8px;
      color: #f7ba1e;
    }
    &-download {
      margin: 0 4px 0 10px;
    }
  }
  .num {
    color: #cc9213;
  }
  .download {
    color: #165dff;
    font-weight: 600;
  }
}
</style>
