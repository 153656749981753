<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '签约白名单详情查看'
    }"
  >
    <DetailCard title="基本信息">
      <DescView :descLabels="descLabels1" :descDatas="detail">
        <template #userName>{{ detail.userName || '无' }}<WpView v-if="detail.userName" :id="id" :type="1" /></template>
        <template #mobile>{{ detail.mobile || '无' }}<WpView v-if="detail.mobile" :id="id" :type="1" /></template>
        <template #idCard>{{ detail.idCard || '无' }}<WpView v-if="detail.idCard" :id="id" :type="1" /></template>
        <template #idFontPhoto><ImageViewer v-if="detail.idFontPhoto" :path="detail.idFontPhoto" /> <span v-else>无</span></template>
        <template #idBackPhoto><ImageViewer v-if="detail.idBackPhoto" :path="detail.idBackPhoto" /> <span v-else>无</span></template>
        <template #attachList>
          <template v-if="detail.attachList && detail.attachList.length" >
            <div v-for="(item, index) in detail.attachList" :key="index">
              <ImageViewer v-if="item.fileUrl" :path="item.fileUrl" :name="'查看文件' + (index + 1)" />
            </div>
          </template>
          <span v-else>无</span>
        </template>
      </DescView>
    </DetailCard>
    <DetailCard title="审核信息">
      <DescView :descLabels="descLabels2" :descDatas="detail"></DescView>
    </DetailCard>
  </DetailView>
</template>
  </div>
</template>

<script>
import { formatStatus, kvWhiteState } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
const descLabels1 = [
  { label: '添加时间', prop: 'createTime' },
  { label: '添加人', prop: 'addPeople' },
  { label: '创客姓名', cmp: 'slot', slotName: 'userName' },
  { label: '手机号', cmp: 'slot', slotName: 'mobile' },
  { label: '证件号', cmp: 'slot', slotName: 'idCard' },
  { label: '银行卡号', prop: 'bankNumber' },
  { label: '支付宝', prop: 'alipayNumber' },
  { label: '身份证正面', cmp: 'slot', slotName: 'idFontPhoto' },
  { label: '身份证反面', cmp: 'slot', slotName: 'idBackPhoto' },
  { label: '其他文件', cmp: 'slot', slotName: 'attachList' },
  { label: '备注', prop: 'remark' }
]
const descLabels2 = [
  { label: '审核时间', prop: 'auditTime' },
  { label: '审核人', prop: 'auditPeople' },
  { label: '状态', prop: 'auditState', cmp: 'state', stateOptions: kvWhiteState },
  { label: '审核意见', prop: 'refuse' }
]
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      show: false,
      id: null,
      descLabels1,
      descLabels2,
      detail: {},
      kvWhiteState
    }
  },
  methods: {
    formatStatus,
    async open(obj) {
      this.id = obj.id
      const result = await this.$api.GetWhitelistDetail({ id: obj.id })
      this.detail = Object.assign({}, result)
      this.show = true
    }
  }
}
</script>
