// 通用formatStatus
export function formatStatus(kvs, value, not = '未知') {
  const result = kvs.find((item) => {
    return item.key == parseInt(value)
  })
  if (result) return result.label
  return not
}
// 带颜色的formatStatus
export function formatStatusHasColor(kvs, value, not = '未知') {
  const result = kvs.find((item) => {
    return item.key == parseInt(value)
  })
  if (result) return `<span${result.color ? ' style="color:' + result.color + ';"' : ''}>${result.label}</span>`
  return not
}
// 带颜色带icon的formatStatus
export function formatStatusHasColorHasIcon(kvs, value, not = '未知') {
  const result = kvs.find((item) => {
    return item.key == parseInt(value)
  })
  if (result) return `<span${result.color ? ' style="color:' + result.color + ';"' : ''}>${result.label} <i class="${result.elIcon}"></i></span>`
  return not
}

// TableTemplate状态
export const kvTableTemplateStatus = [
  { key: 0, label: '状态0', color: '#F53F3F' },
  { key: 1, label: '状态1' }
]

// 创客列表签约状态
export const kvWorkPeopleIsSign = [
  { key: 0, label: '未签约' },
  { key: 1, label: '已签约' },
  { key: 2, label: '签约失败' }
]

// 是否退汇状态
export const kvIsReturn = [
  { key: 0, label: '否' },
  { key: 1, label: '是' }
]

// 结算单列表审核状态
export const kvWorkPlanAuditState = [
  { key: 1, label: '未审核' },
  { key: 2, label: '审核中' },
  { key: 3, label: '审核通过', color: '#00B42A' },
  { key: 4, label: '审核不通过', color: '#F53F3F' }
]
// 结算单列表发放状态
export const kvWorkPlanGrantState = [
  { key: 1, label: '未发放' },
  { key: 2, label: '待发放' },
  { key: 3, label: '发放中' },
  { key: 4, label: '发放完成' },
  { key: 5, label: '拒绝发放' }
]
// 结算单打款状态
export const kvWorkPlanPayState = [
  { key: 1, label: "待打款", color: "#909399", elIcon: 'el-icon-time' },
  { key: 2, label: "打款中", color: "#606266", elIcon: 'el-icon-loading' },
  { key: 3, label: "打款成功", color: "#00B42A", elIcon: 'el-icon-success' },
  { key: 4, label: "打款失败", color: "#F53F3F", elIcon: 'el-icon-error' },
  { key: 5, label: "拒绝打款", color: "#FF7D00", elIcon: 'el-icon-warning' },
];
// 充值状态
export const kvRechargeState = [
  { key: 1, label: '待上账', color: '#00B42A' },
  { key: 2, label: '失败', color: '#F53F3F' },
  { key: 3, label: '上账中' },
  { key: 4, label: '已上账' }
]
// 充值类型
export const kvRechargeType = [
  { key: 1, label: '充值' },
  { key: 2, label: '内部划入' },
]
// 支出流水列表打款状态
export const kvDisburseGrantState = [
  { key: 1, label: '打款成功' },
  { key: 2, label: '打款失败' },
  { key: 3, label: '校验中' }
]
// 支出流水列表支付方式状态
export const kvDisburseGrantType = [
  { key: 1, label: '银行卡' },
  { key: 2, label: '支付宝' },
  { key: 3, label: '微信' }
]
// 业务类型
export const kvFundType = [
  { key: 10, label: '充值' },
  { key: 20, label: '交易支出' },
  { key: 30, label: '提现' },
  { key: 40, label: '内部划入' },
  { key: 50, label: '内部划出' },
  { key: 60, label: '订单退汇' }
]
// 任务审核状态
export const kvTaskAuditStatus = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核通过', color: '#00B42A' },
  { key: 2, label: '审核不通过', color: '#F53F3F' }
]
// 消息类型
export const kvMsgType = [
  { key: 10, label: '任务审核', route: '/taskManage/taskList' },
  { key: 20, label: '结算单审核', route: '/workPlan/Check' },
  { key: 30, label: '发票申请', route: '/billApplyInfo' },
  { key: 40, label: '开票完成', route: '/billInfo' },
  { key: 50, label: '添加黑名单', route: '/blacklist' },
  { key: 60, label: '添加白名单', route: '/whitelist' },
  { key: 70, label: '提现审核通过', route: '/financeCenter/withdrawalRecord' },
  { key: 80, label: '提现完成', route: '/financeCenter/withdrawalRecord' },
  { key: 90, label: '上传完税凭证后', route: '/taxVoucher' },
  { key: 100, label: '退回业务确认单', route: '/businessBills' },
  { key: 110, label: '退回交付物', route: '/deliveryRegulate' },
  { key: 120, label: '每月生成交付物', route: '/deliveryRegulate' },
  { key: 130, label: '每月生成业务确认单', route: '/businessBills' }
]

// 公告类型
export const kvNoticeType = [
  { key: 1, label: '系统更新' },
  { key: 2, label: '放假通知' },
  { key: 3, label: '打款时间通知' },
  { key: 4, label: '系统通知' },
  { key: 99, label: '其他通知' }
]
// 我的协议 - 协议类型
export const agreementState = [
  { key: 1, label: '主协议' },
  { key: 2, label: '补充协议' },
  { key: 3, label: '创客协议' }
]
// 我的协议 - 签约方式
export const signMethodState = [
  { key: 1, label: '线上签约' },
  { key: 2, label: '线下签约' }
]
// 我的协议 - 签约状态
export const signState = [
  { key: 0, label: '未生成' },
  { key: 1, label: '未签约' },
  { key: 2, label: '已签约' }
]
// 我的协议 - 生效状态
export const effectState = [
  { key: 0, label: '未生效' },
  { key: 1, label: '已生效' },
  { key: 2, label: '已作废' }
]
// 提现记录状态
export const kvWithdrawState = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核通过' },
  { key: 2, label: '审核不通过' },
  { key: 3, label: '未提现' },
  { key: 4, label: '提现中' },
  { key: 5, label: '提现成功' },
  { key: 6, label: '提现失败' }
]
// 提现记录搜索
export const kvWithdrawSearchState = [
  { key: 0, label: '待审核' },
  { key: 2, label: '审核不通过' },
  { key: 4, label: '提现中' },
  { key: 5, label: '提现成功' },
  { key: 6, label: '提现失败' }
]
// 白名单审核状态
export const kvWhiteState = [
  { key: 1, label: '待审核' },
  { key: 2, label: '审核通过' },
  { key: 3, label: '审核不通过' },
  { key: 0, label: '已撤回' },
]
// 审核状态
export const kvDelState = [
  { key: null, label: '全部' },
  { key: 0, label: '待审核' },
  { key: 1, label: '审核通过' },
  { key: 2, label: '审核不通过' }
]
//交付物审核
export const kvAuditState = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核通过' },
  { key: 2, label: '审核不通过' }
]
//接单状态
export const kvState = [
  { key: 0, label: '待确认' },
  { key: 1, label: '进行中' },
  { key: 2, label: '已完成' },
  { key: 3, label: '已拒绝' }
]
//交付物上传状态
export const kvAuditStatus = [
  { key: 0, label: '待上传' },
  { key: 1, label: '已上传' },
  { key: 2, label: '已退回' }
]
//交付物/验收单审核状态
export const kvSearchAuditStatus = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核中' },
  { key: 2, label: '审核通过' },
  { key: 3, label: '已退回' }
]
//业务类型
export const kvBussinessType = [
  { key: null, label: '全部' },
  { key: 0, label: '充值' },
  { key: 1, label: '交易发放' },
  { key: 2, label: '提现发放' }
]
// 开票类型
export const kvInvoiceType = [
  { key: 1, label: '增值税专用发票' },
  { key: 2, label: '增值税普通发票' },
  { key: 3, label: '增值税专用电子发票' },
  { key: 4, label: '增值税普通电子发票' }
]