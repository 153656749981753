<template>
  <div class="home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <el-card style="margin-bottom: 20px" shadow="never">
      <el-descriptions
        title="企业认证"
        :column="1"
        :labelStyle="{ 'line-height': '32px', width: '120px', textAlign: 'right', marginRight: '50px' }"
        :contentStyle="{ 'line-height': '32px' }"
      >
        <el-descriptions-item label="认证状态" contentClassName="el-des-content-flex">
          <el-tag v-if="detail.isAuth && detail.isAuth != '0'" type="success">已认证</el-tag>
          <template v-else>
            <el-tag type="info">未认证</el-tag>
            <el-button v-if="detail.authUrl" type="text" @click="toAuth">去认证</el-button>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="企业名称">{{ detail.mainName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="统一社会信用代码">{{ detail.creditCode || '--' }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card style="margin-bottom: 20px" shadow="never">
      <el-descriptions
        title="账号信息"
        :column="1"
        :labelStyle="{ 'line-height': '32px', width: '120px', textAlign: 'right', marginRight: '50px' }"
        :contentStyle="{ 'line-height': '32px' }"
      >
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="手机号" contentClassName="el-des-content-flex">
          <div>{{ detail.userTel || '--' }}</div>
          <el-button type="text" @click="$refs.ChangePhone.open(detail.userTel)">更换手机号</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="注册时间">{{ detail.createTime || '--' }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card style="margin-bottom: 20px" shadow="never">
      <el-descriptions
        title="对接参数"
        :column="1"
        :labelStyle="{ 'line-height': '32px', width: '120px', textAlign: 'right', marginRight: '50px' }"
        :contentStyle="{ 'line-height': '32px' }"
      >
        <el-descriptions-item label="商户号">{{ detail.memberCode || '--' }}</el-descriptions-item>
        <el-descriptions-item label="接口秘钥" contentClassName="el-des-content-flex">
          <div>
            <span>{{ detail.secretKey || '************' }} </span>
            <i v-if="detail.secretKey" class="copy el-icon-copy-document" :data-clipboard-text="detail.secretKey" @click="copy"></i>
          </div>
          <el-button v-if="!detail.secretKey" type="text" @click="$refs.CheckKey.open(detail.memberCode)">查看秘钥</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="订单回调地址" contentClassName="el-des-content-flex">
          <div>{{ detail.apiUrl || '--' }}</div>
          <el-button type="text" @click="changeApiUrl">编辑</el-button>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card shadow="never">
      <el-descriptions
        :column="1"
        :labelStyle="{ 'line-height': '32px', width: '120px', textAlign: 'right', marginRight: '50px' }"
        :contentStyle="{ 'line-height': '32px' }"
      >
        <template slot="title">
          提醒设置 <span style="font-size: 12px; color: #909399; font-weight: normal">（余额提醒时间段为每日09:00-18:00）</span>
        </template>
        <el-descriptions-item label="余额不足提醒" contentClassName="el-des-content-flex">
          <div>
            <div>{{ balanceWarn.isWarn ? '开启' : '关闭' }}</div>
            <div v-if="balanceWarn.isWarn">
              余额低于{{ balanceWarn.warnAmount || 0 }}元，进行余额提醒，提醒手机号{{ balanceWarn.mobile.filter((it) => !!it).join('、') }}
            </div>
          </div>
          <div>
            <el-button type="text" v-if="!balanceWarn.isWarn" @click="$refs.OpenBalanceWarn.open(balanceWarn)">开启</el-button>
            <el-button type="text" v-else @click="closeBalanceWarn">关闭</el-button>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <CheckKey ref="CheckKey" @submit="onCheckKey" />
    <ChangePhone ref="ChangePhone" :phone="detail.userTel" @submit="onChangePhone" />
    <OpenBalanceWarn ref="OpenBalanceWarn" @submit="getBalanceWarn" />
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import CheckKey from './checkKey'
import ChangePhone from './changePhone'
import OpenBalanceWarn from './openBalanceWarn'
import { downloadUrl } from '@/utils'
export default {
  components: { CheckKey, ChangePhone, OpenBalanceWarn },
  data() {
    return {
      detail: {},
      balanceWarn: {}
    }
  },
  mounted() {
    this.getDetail()
    this.getBalanceWarn()
  },
  methods: {
    async getBalanceWarn() {
      const res = await this.$api.GetMerchantBalanceWarnSetting()
      this.balanceWarn = res
      console.log(this.balanceWarn)
    },
    async getDetail() {
      const res = await this.$api.GetMerchantInfo()
      this.detail = res
      this.getMApi()
    },
    async getMApi() {
      const res = await this.$api.GetMerchantApi({ MerchantCode: this.detail.memberCode })
      this.$set(this.detail, 'apiUrl', res)
    },
    toAuth() {
      downloadUrl(this.detail.authUrl)
    },
    changeAvater() {},
    onChangePhone(val) {
      if (val) {
        this.$set(this.detail, 'userTel', val)
      }
    },
    // 查看密钥
    onCheckKey(val) {
      this.$set(this.detail, 'secretKey', val)
    },
    // 编辑回调地址
    async changeApiUrl() {
      const param = await this.$prompt('请输入订单回调地址', '编辑', {
        confirmButtonText: '保 存',
        cancelButtonText: '取 消',
        inputPattern: /^(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/,
        inputErrorMessage: '回调地址格式不正确'
      })
      const res = await this.$api.EditMerchantApi({ MerchantCode: this.detail.memberCode, ApiUrl: param.value })
      this.$message.success('回调地址编辑成功')
      this.getMApi()
    },
    // 关闭余额不足提醒
    async closeBalanceWarn() {
      await this.$confirm(`确定关闭余额提醒吗？`, '关闭余额提醒', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.SaveMerchantBalanceWarnSetting({ id: this.balanceWarn.id, isWarn: 0 })
      this.$message.success(`余额提醒已关闭`)
      this.getBalanceWarn()
    },
    copy(e) {
      const clipboard = new Clipboard(e.target)
      clipboard.on('success', (e) => {
        this.$message.success('已复制到剪切板')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message.error('复制失败，请手动复制')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.copy {
  margin-left: 48px;
  color: #23d38a;
  cursor: pointer;
}
</style>
