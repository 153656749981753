<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetTaskManageReceiveList" :searchList="searchList">
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row }">
          <template>
            <el-button v-if="$hasAccess('006900')" type="text" @click="$refs.Detail.open(row, false)">查看</el-button>
            <el-button v-if="row.state == 0 && $hasAccess('006901')" type="text" @click="$refs.Detail.open(row, true)">审核</el-button>
            <el-button v-if="row.state == 1 && $hasAccess('006902')" type="text" @click="$refs.Finish.open(row)">确认完成</el-button>
          </template>
        </template>
      </el-table-column>
    </TableView>
    <Detail @refresh="asyncRefreshTable" ref="Detail"></Detail>
    <Finish @refresh="asyncRefreshTable" ref="Finish"></Finish>
  </div>
</template>

<script>
import Detail from './detail.vue'
import Finish from './finish.vue'
import { tableOrderReceiving } from '@/configs/tables'
import { searchOrderReceiving } from '@/configs/searches'
export default {
  components: {
    Detail,
    Finish
  },
  data() {
    return {
      tableLabels: tableOrderReceiving,
      searchList: searchOrderReceiving
    }
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    }
  }
}
</script>
