<template>
  <div>
    <el-dialog title="确认完成" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
      <div>
        <el-descriptions
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          :column="2"
          border
          title="接单信息"
        >
          <el-descriptions-item label="申请时间">{{ detail.receiveTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="创客姓名"
            >{{ detail.peopleName || '无' }}
            <WpView v-if="detail.peopleName" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="手机号"
            >{{ detail.peopleMobile || '无' }}
            <WpView v-if="detail.peopleMobile" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="证件号"
            >{{ detail.peopleIDCard || '无' }}
            <WpView v-if="detail.peopleIDCard" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="任务名称">{{ detail.taskName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
        </el-descriptions>
        <div class="title">成果验收单</div>
        <div class="text">第一步：请下载<span @click="downLoadWd" class="result-text">《成果验收单》</span></div>
        <div class="text">第二步：填写任务完成情况，加盖企业公章后上传</div>
        <div class="text">支持.jpg .jpeg .png、pdf，大小不超过5M</div>
        <Upload
          :suffixArray="['jpg', 'jpeg', 'png', 'pdf']"
          :config="{ disabled: false }"
          :modelValue="url || null"
          @update="fileUpdate($event)"
        ></Upload>
      </div>
      <span slot="footer">
        <el-button plain @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { downloadDocx } from '@/utils'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import Upload from '@/components/formView/upload'
export default {
  components: { ImageViewer, WpView, Upload },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      id: null,
      url: null,
      detail: {
        attachList: []
      }
    }
  },
  methods: {
    async open(obj) {
      this.url = null
      this.auditState = obj.auditState
      const result = await this.$api.GetTaskManageReceiveInfo({ id: obj.id })
      this.detail = Object.assign({}, result)
      this.show = true
    },
    async submit() {
      this.btnloadSubmit = true
      await this.$api.FinishTaskManageReceive({ id: this.detail.id, acceptanceNoteUrl: this.url }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('确认完成')
      this.show = false
      this.$emit('refresh')
    },
    fileUpdate(e) {
      this.url = e
    },
    async downLoadWd() {
      const result = await this.$api.DownloadAcceptanceNoteFile({ id: this.detail.id, type: 1 })
      downloadDocx(result, '成果验收单')
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0;
  color: #303133;
}
.result-text {
  color: #409eff;
  cursor: pointer;
}
.text {
  margin-bottom: 10px;
}
</style>
