<template>
  <div class="accountForm">
    <el-dialog title="提现" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="2vh" width="1000px">
      <el-steps  :active="step" align-center style="margin-bottom: 32px">
        <el-step :status="step >=1  ? 'success' : ''" title="填写提现信息"></el-step>
        <el-step :status="step >2 ? 'success' : ''" title="输入验证码"></el-step>
      </el-steps>
      <div class="title"></div>
      <!-- 内容区域 -->
      <div class="dialog-wrap" style="height: 400px">
        <FormView v-show="step == 0" ref="vForm" @onInputChange="changeNumber" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }"></FormView>
        <div v-show="step == 1" class="step2">
          <div class="step2-content">
            <div class="text">短信验证码将发送至{{ userTel }}</div>
            <div class="code">
              <span>验证码：</span>
              <el-input v-model="verifyCode" placeholder="请输入验证码" style="width: 200px; margin-right: 20px"></el-input>
              <el-button :disabled="vcCountdown != 0" type="primary" @click="getCode">{{
                vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <template v-if="step == 0">
          <el-button type="primary" @click="next">下一步</el-button>
        </template>
        <template v-if="step == 1">
          <el-button @click="step = 0">上一步</el-button>
          <el-button type="primary" @click="submit()" :loading="btnloadSubmit">提交</el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatStatus } from '@/configs/formatters'
import { withdrawForm } from '@/configs/forms'

export default {
  data() {
    return {
      btnloadSubmit: false,
      fromLabels: withdrawForm,
      title: '商户',
      step: 0,
      show: false,
      form: {},
      accountForm: {},
      verifyCode: '',
      accountList: [],
      userTel: ''
    }
  },
  computed: {
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    formatStatus,

    open() {
      // this.clear()
      this.fromLabels.map((item) => {
        if (item.prop == 'accountId') {
          item.sideContent = `可提现金额：0.00元`
        }
      })
      this.show = true
      this.step = 0
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
        this.verifyCode = ''
      })
      this.getAllAccountBalance()
    },
    async getAllAccountBalance() {
      let res = await this.$api.GetAllAccountBalance()
      this.accountList = res.list
      let accountList = res.list.map((item) => {
        return {
          key: item.accountId,
          label: item.accountNumber
        }
      })
      let nameRew = await this.$api.GetMerchantInfo()
      this.$nextTick(() => {
        this.$refs.vForm.upData({ withdrawAccountName: nameRew.mainName })
        this.userTel = nameRew.userTel
      })
      this.fromLabels.map((item) => {
        if (item.prop == 'accountId') {
          item.options = accountList
        }
      })
    },
    changeNumber(val, key) {
      let money = 0
      if (key == 'accountId') {
        this.accountList.map((item) => {
          if (item.accountId == val) {
            money = item.creditBalance
            this.$refs.vForm.upData({ money })
          }
        })
        this.fromLabels.map((item) => {
          if (item.prop == 'accountId') {
            item.sideContent = `可提现金额：${money.toFixed(2)}元`
          }
          if (item.prop == 'withdrawAmount') {
            this.$set(item.itemConfig.rules, 1, {
              validator: (rule, value, callback) => {
                if (value > money) {
                  callback(new Error('提现金额不能大于可提现金额'))
                } else {
                  callback()
                }
              }
            })
          }
        })
      }
    },
    async next() {
      // step1Form更新
      this.form = await this.$refs.vForm.getForm()
      const result = await this.$refs.vForm.validate()
      if (result) {
        this.step = 1
      }
    },

    // 校验规则
    validateBankChannel(rule, value, callback) {
      if (this.bankChannelDisabled && !value) {
        callback(new Error('请选择银行卡的发放通道'))
      } else {
        callback()
      }
    },
    //获取验证码
    async getCode() {
      this.loading = true
      await this.$api.WithdrawSendSms()
      this.loading = false
      this.$store.dispatch('app/vcDownStart', true)
    },
    async submit() {
      if (this.verifyCode == '') {
        this.$message.error('请输入验证码')
        return false
      }
      this.form.verifyCode = this.verifyCode
      this.btnloadSubmit = true
      const res = await this.$api.MerchantAccountWithdrawApply(this.form).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('已提交申请')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-wrap {
  .step2 {
    width: 100%;
    height: 100%;
    position: relative;
    .step2-content {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      .text {
        margin-bottom: 20px;
      }
    }
  }
}
/deep/ .el-step__title {
  color: #1d2129;
}
/deep/ .el-step__description {
  color: #1d2129;
}
/deep/ .el-step__head.is-wait {
    color: #4E5969;
    border-color: #c0c4cc;
}
</style>
