<template>
  <div>
    <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
      <div>
        <el-descriptions
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          :column="2"
          border
          title="基本信息"
        >
          <el-descriptions-item label="提交时间">{{ detail.createTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="姓名"
            >{{ detail.userName || '无' }}
            <WpView v-if="detail.userName" :id="detail.userId" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="手机号"
            >{{ detail.mobile || '无' }}
            <WpView v-if="detail.mobile" :id="detail.userId" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="证件号"
            >{{ detail.idCard || '无' }}
            <WpView v-if="detail.idCard" :id="detail.userId" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="任务名称">{{ detail.taskName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
          <el-descriptions-item label="其他文件">
            <template v-if="detail.deliverableList && detail.deliverableList.length > 0">
              <div v-for="(item, index) in detail.deliverableList" :key="index">
                <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
                <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-if="item.attachUrl && item.type == 'pdf'">查看文件{{ index + 1 }}</span>
              </div>
            </template>
            <span v-else>无</span>
          </el-descriptions-item>
          <el-descriptions-item label="备注">{{ detail.remark || '无' }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          v-if="auditState != 0"
          :column="2"
          border
          title="审核信息"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <el-descriptions-item label="审核时间">{{ detail.auditTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="审核人">{{ detail.auditer || '无' }}</el-descriptions-item>
          <el-descriptions-item label="状态">{{ formatStatus(kvAuditState, detail.auditState) }}</el-descriptions-item>
          <el-descriptions-item label="审核意见">{{ detail.auditFailreason || '无' }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <span v-if="isEdit" slot="footer">
        <el-button @click="showReason = true">审核不通过</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">审核通过</el-button>
      </span>
    </el-dialog>
    <el-dialog title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false" class="dialog-small">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button plain @click="showReason = !showReason">取 消</el-button>
        <el-button @click="submitReason" type="primary" :loading="btnloadSubmit">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatStatus, kvAuditState } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import { downloadUrl } from '@/utils'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      showReason: false,
      id: null,
      detail: {
        attachList: []
      },
      reason: '',
      kvAuditState,
      isEdit: false,
      auditState: 0
    }
  },
  methods: {
    formatStatus,
    viewPdf(e) {
      downloadUrl(e)
    },
    async open(obj, isEdit) {
      this.isEdit = isEdit
      this.auditState = obj.auditState
      const result = await this.$api.GetTaskDeliverable({ deliverableId: obj.deliverableId })
      result.deliverableList.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      this.detail = Object.assign({}, result)
      this.show = true
    },
    async submitReason() {
      if (this.reason == '') {
        this.$message.error('请输入审核不通过理由')
        return false
      }
      this.btnloadSubmit = true
      const result = await this.$api
        .AuditTaskDeliverable({ deliverableId: this.detail.deliverableId, auditState: 2, auditFailreason: this.reason })
        .finally(() => {
          this.btnloadSubmit = false
        })
      this.reason = null
      this.$message.success('提交成功')
      this.show = false
      this.showReason = false
      this.$emit('refresh')
    },
    async submit() {
      this.btnloadSubmit = true
      await this.$api.AuditTaskDeliverable({ deliverableId: this.detail.deliverableId, auditState: 1 }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('审核成功')
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.otherStyle {
  color: #78c390;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
</style>
