<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetRechargeLogPageList"
      :searchList="searchList"
      @geted="onTableGeted"
      :initSearch="initSearch"
    >
      <template slot="rechargeAmount">
        <el-table-column label="打款金额(元)" minWidth="115">
          <template v-slot="{ row }">
            +<span v-format="'#,##0.00'">{{ Number(row.rechargeAmount) }}</span>
          </template>
        </el-table-column>
      </template>
      <!-- 附加table -->
      <!-- <el-card shadow="never" slot="ext-table" style="margin-bottom: 18px"
        >充值总金额（元）：<span v-format="'#,##0.00'">{{
          amount || 0
        }}</span></el-card
      > -->
    </TableView>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { tableRechargeRecord } from '@/configs/tables'
import { searchRechargeRecord } from '@/configs/searches'
export default {
  data() {
    return {
      tableLabels: tableRechargeRecord,
      searchList: searchRechargeRecord,
      amount: 0
    }
  },
  computed: {
    initSearch() {
      return {
        SearchTxt: this.$route.query.fundNo
        // RechargeTimeBegin: dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        // RechargeTimeEnd: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
    }
  },
  methods: {
    onTableGeted(response, params) {
      const { pageIndex, pageSize, ...rest } = params
      this.$api.GetGetRechargeLogTotalAmount(rest).then((res) => {
        this.amount = res.data
      })
    }
  }
}
</script>
