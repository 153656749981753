<template>
  <div class="panel-home">
    <!-- 数据统计 -->
    <StatisticsPanel :list="cardItem" ref="StatisticsPanel"></StatisticsPanel>
    <div class="panel-box">
      <div class="pageTitle">{{ $route.meta.title }}</div>
      <el-alert type="warning" :closable="false" style="margin-bottom: 18px; flex-shrink: 0">
        <template #title>
          <span v-html="alertTitle"></span>
        </template>
      </el-alert>
      <TableView
        ref="vTable"
        v-loading="loading"
        :labels="tableLabels"
        disInitLoad="true"
        :apiName="apiName"
        :searchList="searchList"
        :selection="true"
        @selection-change="selChange"
        :selConfig="{ selectable }"
        @get="getInvoiceAmount"
      >
        <!-- 按钮栏 -->
        <el-form-item slot="btnline-item-left" v-if="$hasAccess('004800')" id="guideSteps5">
          <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
          <el-button plain v-if="$hasAccess('004800')" @click="apply">申请开票</el-button>
        </el-form-item>
        <el-form-item slot="btnline-item" v-if="$hasAccess('004801')">
          <el-button
            type="primary"
            v-if="(this.settlementMakeInvoice == 0 || this.settlementMakeInvoice == 1) && $hasAccess('004801')"
            @click="apply('all')"
          >
            <i class="main-btn-icon el-icon-self-file-list-2-line"></i>
            <span>申请全部开票</span>
          </el-button>
        </el-form-item>
      </TableView>
    </div>
    <DialogSelected ref="DialogSelected" :returnList="returnList" :totalReturn="totalReturn" @submit="asyncRefreshTable()" @addEvent="addEvent" />
    <DialogApplyByMoney ref="DialogApplyByMoney" @submit="asyncRefreshTable()" @addEvent="addEvent" />
    <DialogRise ref="DialogRise" @submit="submitEvent" />
    <DialogAddress ref="DialogAddress" @submit="submitEvent" />
  </div>
</template>

<script>
import { tableWorkplanInfo, tableWorkDetailInfo } from '@/configs/tables'
import { searchWorkplanInfo, searchWorkDetailInfo } from '@/configs/searches'
import DialogSelected from './selected'
import DialogApplyByMoney from './applyByMoney'
import StatisticsPanel from '@/components/statisticsPanel'
import DialogRise from '../riseInfo/edit'
import DialogAddress from '../addressInfo/edit'

export default {
  components: { StatisticsPanel, DialogSelected, DialogApplyByMoney, DialogRise, DialogAddress },
  data() {
    return {
      settlementMakeInvoice: null, // 0：按结算单；1：按结算明细；2：按金额开票
      supplierId: '',
      apiName: '',
      tableLabels: [],
      searchList: [],
      multipleSelection: [],
      loading: false,
      cardItem: [
        { label: '实际可开票金额', value: 0 },
        { label: '审核中金额', value: 0 },
        { label: '开票中金额', value: 0 },
        { label: '已开票金额', value: 0 },
        { label: '总支付金额', value: 0 }
      ],
      // 获取商户未开票的退汇订单信息
      returnList: [],
      totalReturn: 0
    }
  },
  computed: {
    alertTitle() {
      return this.settlementMakeInvoice == 2
        ? '申请开票时间：每月1号至25号，超过25号之后提交的开票申请，将根据综合情况确认是否可以开票<p style="margin: 0;">因商户已配置按税目自定义发放额度开票，再次开票无需勾选结算单</p>'
        : '申请开票时间：每月1号至25号，超过25号之后提交的开票申请，将根据综合情况确认是否可以开票'
    }
  },
  async created() {
    await this.getMerchantInfo()
    if (this.apiName) {
      this.$refs.vTable.getTableList()
    }
  },
  methods: {
    // 获取商户信息
    async getMerchantInfo() {
      const res = await this.$api.GetMerchantInfo()

      let { settlementMakeInvoice, supplierId } = res
      this.settlementMakeInvoice = settlementMakeInvoice
      this.supplierId = supplierId
      this.apiName =
        this.settlementMakeInvoice == 0 || this.settlementMakeInvoice == 2
          ? 'GetWorkplanInfo'
          : this.settlementMakeInvoice == 1
          ? 'GetInvoiceWorkDetailPageList'
          : ''
      this.tableLabels =
        this.settlementMakeInvoice == 0 || this.settlementMakeInvoice == 2
          ? tableWorkplanInfo
          : this.settlementMakeInvoice == 1
          ? tableWorkDetailInfo
          : []
      this.searchList =
        this.settlementMakeInvoice == 0 || this.settlementMakeInvoice == 2
          ? searchWorkplanInfo
          : this.settlementMakeInvoice == 1
          ? searchWorkDetailInfo
          : []
    },
    selectable(row, index) {
      if (this.settlementMakeInvoice == 2) return false
      return true
    },
    // 表格延迟刷新
    asyncRefreshTable() {
      // setTimeout(() => {
      this.$refs.vTable.refreshTable()
      // }, 200)
    },
    async getInvoiceAmount(e) {
      let res = await this.$api.GetSummaryInvoiceAmount(e)
      if (res) {
        res.list.map((item) => {
          switch (item.type) {
            case 10:
              this.cardItem[0].value = item.amount
              this.cardItem[0].canBillAmount = item.canBillAmount
              this.cardItem[0].returnAmount = item.returnAmount
              this.cardItem[0].tooltip = [
                { label: '可开票金额', value: item.canBillAmount },
                { label: '退汇金额', value: item.returnAmount }
              ]
              break
            case 20:
              this.cardItem[1].value = item.amount
              break
            case 30:
              this.cardItem[2].value = item.amount
              break
            case 40:
              this.cardItem[3].value = item.amount
              break
            case 50:
              this.cardItem[4].value = item.amount
              break
            default:
              break
          }
        })
      }
    },
    selChange(val) {
      this.multipleSelection = val
    },
    async apply(type) {
      if (this.settlementMakeInvoice == 2) {
        this.$refs.DialogApplyByMoney.open({ supplierId: this.supplierId })
      } else {
        let applyList = this.multipleSelection,
          noTips = '请选择要申请开票的项',
          params = Object.assign({}, this.$refs.vTable.searchForm)

        if (type == 'all') {
          // 全开或按金额开票
          this.$refs.vTable.getTableList()
          const APIOPTS = this.settlementMakeInvoice == 1 ? this.$api.GetInvoiceWorkDetailAllList : this.$api.GetAllWorkplanInfo
          const res = await APIOPTS(params)

          applyList = res.list
          noTips = '没有申请开票的内容'
        }
        if (!applyList.length) return this.$message.warning(noTips)
        let flag = applyList.every((item) => {
          return item.supplierName == applyList[0].supplierName
        })

        if (!flag) {
          this.$message.error('请选择在同一主体结算单')
          return false
        }
        // 判断税目一致
        const taxList = applyList.map((item) => {
          return item.taxId
        })
        const newTaxList = new Set(taxList)

        if (Array.from(newTaxList).length > 1) return this.$message.warning('请选择相同的开票税目合并开票')
        // 判断税源地一致
        const supList = applyList.map((item) => {
          return item.supplierId
        })
        const newSupList = new Set(supList)

        if (Array.from(newSupList).length > 1) return this.$message.warning('请选择相同的税源地合并开票')
        // 判断金额总和 与 退汇金额的差值
        const totalMoney = applyList.reduce((a, b) => {
          return a + parseFloat(b.invoiceAmount)
        }, 0)

        // 获取商户未开票的退汇订单
        const returnRes = await this.$api.GetReturnWorkDetailNotBill({ supplierId: applyList[0].supplierId, taxId: applyList[0].taxId })
        this.returnList = returnRes.list || []
        this.totalReturn = returnRes.list.reduce((a, b) => {
          return (
            a +
            (parseFloat(b.afterTaxAmount || 0) +
              parseFloat(b.serviceFee || 0) +
              parseFloat(b.serviceDiffFee || 0) -
              parseFloat(b.deductionAmount || 0))
          )
        }, 0)

        if (totalMoney < this.totalReturn)
          return this.$message.warning(
            `您当前税目有${this.totalReturn}元的退汇金额，申请开票时，您选中的结算单金额需要大于当前退汇金额，请重新选择！`
          )

        // if (type != 'all' && totalMoney < this.cardItem[0].returnAmount) return this.$message.warning(`您当前有${this.cardItem[0].returnAmount}元的退汇金额，申请开票时，您选中的结算单金额需要大于当前退汇金额，请重新选择！`)
        if (type == 'all') {
          this.$refs.DialogSelected.open(this.settlementMakeInvoice, applyList, true, params)
        } else {
          this.$refs.DialogSelected.open(this.settlementMakeInvoice, applyList)
        }
      }
    },
    addEvent(refName, from) {
      if (refName == 'riseRef') {
        // 新增发票抬头
        this.$refs.DialogRise.open({}, from)
      } else {
        // 新增邮寄地址
        this.$refs.DialogAddress.open({}, from)
      }
    },
    submitEvent({ refName, from }) {
      if (refName == 'riseRef') {
        // 发票抬头
        from == 'applyByMoney' ? this.$refs.DialogApplyByMoney.getRiseInfo() : this.$refs.DialogSelected.getRiseInfo()
      } else {
        // 邮寄地址
        from == 'applyByMoney' ? this.$refs.DialogApplyByMoney.getAddressInfo() : this.$refs.DialogSelected.getAddressInfo()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ext {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  .title-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  .text {
    font-size: 26px;
    color: #333;
    font-weight: 600;
    margin-right: 10px;
  }
}
</style>
