// oemType: domain看w域名，并带有oemDomain参数标注域名；query看地址栏参数，?o=oemId
export default [
  {
    oemId: 'pkwin',
    oemTitle: '普鲸PKwin',
    oemType: 'query'
  },
  {
    oemId: 'pkwin',
    oemTitle: '普鲸PKwin',
    oemType: 'domain',
    oemDomain: 'pj.linsyun.com'
  },
  {
    oemId: 'dcy',
    oemTitle: '大财云服Dcy',
    oemType: 'query'
  },
  {
    oemId: 'dcy',
    oemTitle: '大财云服Dcy',
    oemType: 'domain',
    oemDomain: 'dcyf.linsyun.com'
  }
]
