<template>
  <el-dialog title="申请开票" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-large">
    <div class="content-box">
      <DetailCard title="基本信息" :hasBefore="false">
        <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }" :slotIdxList="[0]">
          <template #form-items="{ idx, form }">
            <el-col v-if="idx == 0" :span="20">
              <el-form-item label="发票抬头" prop="riseId" :rules="[{ required: true, message: '请选择公司抬头', trigger: ['blur', 'change'] }]">
                <el-select
                  ref="riseRef"
                  filterable
                  v-model="form.riseId"
                  placeholder="请选择公司抬头"
                  @visible-change="(v) => addBtnByVisibleChange(v, 'riseRef')"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, idx) in riseList"
                    :key="idx"
                    :label="item.riseName + '（' + item.taxpayerNumber + '）'"
                    :value="item.riseId"
                  >
                    <template>
                      <span>{{ item.riseName }}</span>
                      <el-tooltip effect="dark" placement="top" popper-class="tipsinfo">
                        <div slot="content" class="rise-tipsinfo">
                          <div class="item">
                            <span>抬头名称：</span>
                            <span>{{ item.riseName }}</span>
                          </div>
                          <div class="item">
                            <span>税号：</span>
                            <span>{{ item.taxpayerNumber }}</span>
                          </div>
                          <div class="item">
                            <span>注册地址：</span>
                            <span>{{ item.unitAddress }}</span>
                          </div>
                          <div class="item">
                            <span>注册电话：</span>
                            <span>{{ item.phone }}</span>
                          </div>
                          <div class="item">
                            <span>开户银行：</span>
                            <span>{{ item.openBank }}</span>
                          </div>
                          <div class="item">
                            <span>银行账号：</span>
                            <span>{{ item.bankAccount }}</span>
                          </div>
                        </div>
                        <i class="el-icon-self-information-line" style="margin-left: 2px; font-size: 16px; cursor: pointer"></i>
                      </el-tooltip>
                    </template>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="邮寄地址" prop="addressId" :rules="addressRules">
                <el-select
                  ref="addressRef"
                  filterable
                  v-model="form.addressId"
                  placeholder="请选择邮寄地址"
                  @visible-change="(v) => addBtnByVisibleChange(v, 'addressRef')"
                  style="width: 100%"
                  popper-class="popper-address"
                >
                  <el-option
                    v-for="(item, idx) in addressList"
                    :key="idx"
                    :label="item.name + ' ' + item.phone + ' ' + item.province + ' ' + item.city + ' ' + item.area + ' ' + item.addressDefail"
                    :value="item.addressId"
                  >
                    <template>
                      <span>{{
                        item.name + ' ' + item.phone + ' ' + item.province + ' ' + item.city + ' ' + item.area + ' ' + item.addressDefail
                      }}</span>
                    </template>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="邮箱" prop="inboxEmail" :rules="emailRules">
                <el-input v-model="form.inboxEmail" placeholder="请输入邮箱" @change="emailChange"></el-input>
              </el-form-item>
            </el-col>
          </template>
        </FormView>
      </DetailCard>
      <DetailCard title="开票信息" :hasBefore="false" v-loading="loading">
        <el-alert type="warning" :closable="false" style="margin-bottom: 20px; flex-shrink: 0">
          <template #title>提示：开票金额大于0的税目才可以申请开票，请知悉</template>
        </el-alert>
        <template v-if="oriList && oriList.length">
          <div class="apply-info-box" v-for="(i, index) in oriList" :key="i.taxItemsId">
            <DescView class="apply-info" :descLabels="descLabels" :config="{ border: false, labelStyle: { textAlign: 'right', width: '130px' } }">
              <template #taxItemsName>
                <span>{{ i.taxItemsName }}</span>
              </template>
              <template #maxCanBillAmount>
                <span v-format="'#,##0.00'">{{ i.maxCanBillAmount }}</span
                >元
              </template>
            </DescView>
            <FormView
              ref="vFormApply"
              :labels="applyFormLabels(i.maxCanBillAmount, i.returnAmount)"
              :formConfig="{ labelWidth: '150px' }"
              :itemIndex="index"
              @onInputChange="onInputChange"
            ></FormView>
          </div>
        </template>
        <div v-else style="text-align: center">暂无可开票税目，快去结算发放吧~</div>
      </DetailCard>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit()" :loading="btnloadSubmit">提 交</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { kvInvoiceType } from '@/configs/formatters'
import { deepClone } from '@/utils'
import { regular } from '@/configs/validate'

export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      oriList: [], // 开票信息列表
      baseForm: {}, //基本信息表单
      riseList: [], // 发票抬头选项列表
      addressList: [], // 邮寄地址选项列表
      supplierId: '', // 税源地ID
      inboxEmailRequired: false, // 邮箱是否必填
      addressIdRequired: true, // 邮寄地址是否必填
      applyStateOptions: [],
      invoiceAmountRepeat: false, // 不同税目下输入的最大申请金额是否一样，一样的话就取最近的一条减去退汇金额

      descLabels: [
        { label: '开票税目', slotName: 'taxItemsName', cmp: 'slot' },
        { label: '当前最大可开票金额', slotName: 'maxCanBillAmount', cmp: 'slot' }
      ],
      formLabels: [
        {
          cmp: 'input',
          label: '备注',
          prop: 'remarks',
          col: 20,
          config: { type: 'textarea', rows: 2, maxLength: 500, placeholder: '请输入备注说明' }
        }
      ]
    }
  },
  computed: {
    emailRules() {
      return this.inboxEmailRequired ? [{ required: true, validator: this.emailValidator, trigger: 'blur' }] : []
    },
    addressRules() {
      return this.addressIdRequired ? [{ required: true, validator: this.addressValidator, trigger: ['blur', 'change'] }] : []
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.oriList = []
      this.baseForm = {}
      this.riseList = []
      this.addressList = []
    },
    async open({ supplierId }) {
      this.clear()
      this.supplierId = supplierId
      this.init()
    },
    async init() {
      this.show = true
      await this.getSupplierInvoiceTypeList()
      await this.getTaxCanBillList()
      this.getRiseInfo()
      this.getAddressInfo()
    },
    // 获取开票类型列表
    async getSupplierInvoiceTypeList() {
      const res = await this.$api.GetSupplierInvoiceTypeList({ supplierId: this.supplierId })
      const list = kvInvoiceType.filter((item) => {
        return !!res.list.find((it) => it == item.key)
      })
      this.applyStateOptions = list
    },
    // 获取税目对应的可开票金额
    async getTaxCanBillList() {
      this.loading = true
      const res = await this.$api.GetTaxCanBillList()
      this.loading = false
      this.oriList = res.list || []
      // 初始化发票类型值
      this.oriList &&
        this.oriList.length &&
        this.oriList.map((item, i) => {
          item.applyState = (this.applyStateOptions[0] && this.applyStateOptions[0].key) || ''
          item.invoiceAmount = 0
          this.$nextTick(() => {
            this.$refs.vFormApply[i].upData({ applyState: item.applyState, invoiceAmount: item.invoiceAmount, returnAmount: item.returnAmount })
          })
        })
    },
    applyFormLabels(maxCanBillAmount, returnAmount) {
      return [
        {
          cmp: 'select',
          label: '发票类型',
          prop: 'applyState',
          col: 12,
          itemConfig: { rules: [{ required: true, message: '请选择发票类型', trigger: ['blur', 'change'] }], class: 'item-inline' },
          config: { style: { width: '180px' } },
          options: this.applyStateOptions
        },
        {
          cmp: 'number',
          label: '申请金额',
          prop: 'invoiceAmount',
          col: 12,
          itemConfig: { rules: [{ required: true, message: '请输入申请金额', trigger: 'blur' }] },
          config: {
            style: { width: '180px' },
            placeholder: '请输入申请金额',
            precision: 2,
            min: 0,
            max: maxCanBillAmount,
            controls: false,
            unitText: '元'
          }
        },
        {
          cmp: 'number',
          label: '退汇金额',
          prop: 'returnAmount',
          col: 12,
          config: { disabled: true, style: { width: '180px' }, placeholder: '', precision: 2, controls: false, unitText: '元' }
        },
        {
          cmp: 'number',
          label: '开票金额',
          prop: 'invoicingMoney',
          col: 12,
          config: { disabled: true, style: { width: '180px' }, placeholder: '', precision: 2, controls: false, unitText: '元' }
        }
      ]
    },
    // 获取邮寄地址选项列表
    async getAddressInfo() {
      const res = await this.$api.GetAddressInfo()

      if (!res.list.length) return false
      this.addressList = res.list
      this.getDefault('addressId', this.addressList)
      this.$refs.vForm.upData({ addressId: this.baseForm.addressId })
    },
    // 获取发票抬头选项列表
    async getRiseInfo() {
      const res = await this.$api.GetRiseInfo()

      if (!res.list.length) return false
      this.riseList = res.list
      this.getDefault('riseId', this.riseList)
      this.$refs.vForm.upData({ riseId: this.baseForm.riseId })
    },
    // 有默认取默认值，没有默认就取第一个值
    getDefault(name, list) {
      if (list && list.length) {
        const hasDefault = list.some((item) => item.isDefault == '是')

        if (hasDefault) {
          let defaultData = list.filter((item) => item.isDefault == '是')[0]

          this.baseForm[name] = (defaultData && defaultData[name]) || ''
        } else {
          this.baseForm[name] = (list[0] && list[0][name]) || ''
        }
      } else {
        this.baseForm[name] = ''
      }
    },
    // 动态新增新增按钮
    addBtnByVisibleChange(visible, refName) {
      if (visible) {
        const ref = this.$refs[refName]
        let popper = ref.$refs.popper,
          btnName = refName == 'riseRef' ? '新增发票抬头' : '新增邮寄地址'

        if (popper.$el) popper = popper.$el
        if (!Array.from(popper.children).some((v) => v.className === 'el-template-menu__list')) {
          const el = document.createElement('div')

          el.className = 'el-template-menu__list'
          el.style = 'padding: 14px 0; border-top: 1px solid #E5E6EB; color: #30D68C; font-size: 14px'
          el.innerHTML = `<div style="line-height: 20px; text-align: center; cursor: pointer">${btnName}</div>`
          popper.appendChild(el)
          el.onclick = () => {
            this.$emit('addEvent', refName, 'applyByMoney')
          }
        }
      }
    },
    emailChange(v) {
      if (v) {
        this.$refs.vForm.$refs.form.validate('inboxEmail')
      }
    },
    emailValidator(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else if (!regular.email.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    },
    addressValidator(rule, value, callback) {
      if (!value) {
        callback(new Error('请选择邮寄地址'))
      } else {
        callback()
      }
    },
    // change事件
    async onInputChange(val, key, index) {
      this.oriList[index][key] = val

      if (key == 'applyState') {
        const arr = this.oriList.filter((item) => item.applyState).map((item) => item.applyState)

        if (arr && arr.length) {
          if (arr.some((i) => i == 3 || i == 4)) {
            this.inboxEmailRequired = true
          } else {
            this.inboxEmailRequired = false
            this.$refs.vForm.$refs.form.clearValidate('inboxEmail')
          }
          if (arr.every((i) => i == 3 || i == 4)) {
            this.addressIdRequired = false
            this.$refs.vForm.$refs.form.clearValidate('addressId')
          } else {
            this.judgeAddressRequired()
          }
        }
      }
      if (key == 'invoiceAmount') {
        this.getInvoicingMoney()
      }
    },
    // 判断邮箱地址是否必填（全部为电子发票类型时为非必填；部分为电子发票类型时，其全部相对应的开票金额大于0即可非必填）
    judgeAddressRequired() {
      let arrList = this.oriList.filter((item) => item.invoicingMoney && item.invoicingMoney > 0).map((item) => item.applyState)

      if (arrList && arrList.length) {
        if (arrList.every((i) => i == 3 || i == 4)) {
          this.addressIdRequired = false
          this.$refs.vForm.$refs.form.clearValidate('addressId')
        } else {
          this.addressIdRequired = true
        }
      } else {
        this.addressIdRequired = true
      }
    },
    // 获取最大申请金额
    getMaxInvoiceAmount() {
      const arr = this.oriList.filter((item) => item.invoiceAmount).map((item) => item.invoiceAmount)

      if (arr && arr.length) {
        if (new Set(arr).size == arr.length) {
          this.invoiceAmountRepeat = false
        } else {
          this.invoiceAmountRepeat = true
        }
        return arr.sort((a, b) => b - a)[0]
      }
      return 0
    },
    // 开票金额 = 最大申请金额 - 退汇金额
    getInvoicingMoney() {
      const max = this.getMaxInvoiceAmount()

      this.oriList.map((item, i) => {
        const invoicingMoney = item.invoiceAmount - (item.returnAmount || 0)
        item.invoicingMoney = invoicingMoney > 0 ? invoicingMoney : 0
        this.$refs.vFormApply[i].upData({ invoicingMoney: invoicingMoney > 0 ? invoicingMoney : 0 })
      })
      const arr = this.oriList.filter((item) => item.applyState).map((item) => item.applyState)

      if (arr && arr.length) {
        if (arr.every((i) => i == 3 || i == 4)) {
          this.addressIdRequired = false
          this.$refs.vForm.$refs.form.clearValidate('addressId')
        } else {
          this.judgeAddressRequired()
        }
      }
    },
    // 提交
    async submit() {
      if (!(this.oriList && this.oriList.length)) {
        return this.$message.warning('没有申请开票的内容')
      }
      const params = await this.$refs.vForm.getForm(),
        objArr = this.$refs.vFormApply

      let errorArr = [],
        billInvoices = this.oriList

      await objArr.map((item) => {
        item.getForm().catch((res) => errorArr.push(res))
      })
      if (errorArr && errorArr.length) return false

      const arr = this.oriList.filter((item) => item.invoicingMoney).map((item) => item.invoicingMoney)

      if (arr && arr.length) {
        if (arr.every((item) => item == 0)) {
          return this.$message.warning('暂无可开票税目，请确认有税目的开票金额大于0')
        } else {
          billInvoices = this.oriList.filter((item) => item.invoicingMoney > 0)
        }
      } else {
        return this.$message.warning('暂无可开票税目，请确认有税目的开票金额大于0')
      }
      billInvoices.map((item) => {
        item.taxItemId = item.taxItemsId
      })
      this.btnloadSubmit = true
      const res = await this.$api.AddBillInfo(Object.assign({ invoicingSource: 2, billInvoices }, params)).finally(() => {
        this.btnloadSubmit = false
      })
      this.$emit('submit')
      this.show = false
      this.$message.success('发票申请提交成功')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 4px 20px 30px;
}
.content-box {
  .apply-info-box {
    padding: 20px 0 2px;
    margin: 0 0 20px 0;
    background-color: #f7f8fa;
    .apply-info {
      margin: 0 0 12px 0;
    }
    ::v-deep .el-form-item__label {
      font-weight: 400;
    }
    ::v-deep .el-input-number {
      .el-input__inner {
        padding-right: 26px;
      }
    }
    ::v-deep .unit-text {
      color: #1d2129;
      font-size: 14px;
      position: absolute;
      left: 154px;
    }
  }
  ::v-deep .detail-card {
    margin: 0;
    .detail-card-container {
      padding: 24px 0;
      .el-descriptions__body {
        background-color: #f7f8fa;
      }
    }
  }
}
.el-select-dropdown__item {
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
