<template>
  <el-dialog title="开启余额提醒" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <div v-if="show">
      <el-form ref="vForm" :model="form" :rules="rules" :inline="true" hide-required-asterisk>
        <el-form-item prop="warnAmount" label="余额低于"><el-input-number v-model="form.warnAmount" placeholder="请输入"
            :precision="2" :min="0" controls-position="right"></el-input-number></el-form-item>
        <el-form-item prop="mobile1" label="元，进行余额提醒，提醒手机号："><el-input v-model="form.mobile1"
            placeholder="必填手机号" style="width:120px"></el-input></el-form-item>
        <el-form-item prop="mobile2" label="、"><el-input v-model="form.mobile2" placeholder="选填手机号" style="width:120px"></el-input></el-form-item>
        <!-- <el-form-item prop="mobiles" label="元，进行余额提醒，提醒手机号："><el-input v-model="form.mobiles"
            placeholder="多个手机号请用“;”间隔，最多不超过5个"></el-input></el-form-item> -->
      </el-form>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      id: null,
      form: {},
      rules: {
        warnAmount: [{ required: true, message: '请输入提醒余额', trigger: 'blur' }],
        mobile1: [{ required: true, message: '请至少输入第一个手机号', trigger: 'blur' },
        { pattern: /^1\d{10}$/, message: '手机号格式不正确', trigger: 'blur' }],
        mobile2: [{ pattern: /^1\d{10}$/, message: '手机号格式不正确', trigger: 'blur' }],
        mobiles: [{ required: true, message: '请至少输入1个手机号', trigger: 'blur' },
        { pattern: /^(1\d{10})(;1\d{10}){0,4}$/, message: '格式不正确，多个手机号请用“;”间隔，最多不超过5个', trigger: 'blur' }],
      }
    }
  },
  computed: {
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null;
      this.form = {}
    },
    open(obj = {}) {
      this.clear()
      this.form = {
        id: obj.id,
        warnAmount: obj.warnAmount,
        mobile1: (obj.mobile || [])[0] || null,
        mobile2: (obj.mobile || [])[1] || null,
        // mobiles: (obj.mobile || []).join(';')
      }
      console.log(obj)
      this.show = true
    },
    // 提交
    async submit() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          // 判断手机号
          console.log(this.form)

          this.btnloadSubmit = true
          const res = await this.$api.SaveMerchantBalanceWarnSetting({
            id: this.id,
            warnAmount: this.form.warnAmount,
            mobiles: [this.form.mobile1, this.form.mobile2 || ''],
            isWarn: 1
          }).finally(() => {
            this.btnloadSubmit = false
          })
          this.$message.success('开启成功')
          this.$emit('submit', res)
          this.show = false
        }
      })
    }
  }
}
</script>
