<template>
  <span>
    <el-dialog title="更换手机号" :visible.sync="oldShow" :append-to-body="true" :close-on-click-modal="false" width="500px">
      <el-form ref="vFormOld" :model="formOld" :rules="rules" :inline="true" @submit.native.prevent>
        <div style="margin-bottom: 20px; color: #909399">短信验证码将发送至{{ phone }}</div>
        <el-form-item prop="vCode" label="短信验证码： "><el-input v-model="formOld.vCode" placeholder="请输入短信验证码"></el-input></el-form-item>
        <el-form-item
          ><el-button type="primary" @click="sendOldCode" :disabled="vcCountdown != 0" style="width: 120px">{{
            vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
          }}</el-button></el-form-item
        >
      </el-form>
      <span slot="footer">
        <el-button plain @click="oldShow = false">取 消</el-button>
        <el-button type="primary" @click="submitOld" :loading="btnloadSubmit">下一步</el-button>
      </span>
    </el-dialog>
    <el-dialog title="更换手机号" :visible.sync="newShow" :append-to-body="true" :close-on-click-modal="false" width="500px">
      <el-form ref="vFormNew" :model="formNew" :rules="rules" :inline="true" @submit.native.prevent>
        <el-form-item prop="mobile" label="手机号： " label-width="120px"
          ><el-input v-model="formNew.mobile" placeholder="请输入新手机号" style="width: 322px"></el-input
        ></el-form-item>
        <el-form-item prop="vCode" label="短信验证码： " label-width="120px"
          ><el-input v-model="formNew.vCode" placeholder="请输入短信验证码"></el-input
        ></el-form-item>
        <el-form-item
          ><el-button type="primary" @click="sendNewCode" :disabled="vcCountdown != 0" style="width: 120px">{{
            vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
          }}</el-button></el-form-item
        >
      </el-form>
      <span slot="footer">
        <el-button plain @click="newShow = false">取 消</el-button>
        <el-button type="primary" @click="submitNew" :loading="btnloadSubmit">确 认</el-button>
      </span>
    </el-dialog>
  </span>
</template>

<script>
import { regular } from '@/configs/validate'
export default {
  props: {
    id: String,
    phone: String
  },
  data() {
    return {
      btnloadSubmit: false,
      oldShow: false,
      formOld: {},
      formNew: {},
      rules: {
        mobile: [
          { required: true, message: '请输入新手机号', trigger: 'blur' },
          { pattern: regular.mobile, message: regular.mobileMsg, trigger: 'blur' }
        ],
        vCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      },
      newShow: false,
      noSend: true
    }
  },
  computed: {
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    open() {
      this.formOld = {}
      this.formNew = {}
      if (this.$refs.vFormOld) this.$refs.vFormOld.clearValidate()
      if (this.$refs.vFormNew) this.$refs.vFormNew.clearValidate()
      this.oldShow = true
      this.newShow = false
      // 判断验证码
      if (this.vcCountdown) this.noSend = false
      else this.noSend = true
    },
    // 获取old验证吗
    async sendOldCode() {
      const res = await this.$api.ChangeMobileSendSms()
      this.$store.dispatch('app/vcDownStart', true)
      this.noSend = false
    },
    // 获取new验证吗
    sendNewCode() {
      this.$refs.vFormNew.validateField('mobile', async (valid) => {
        if (!valid) {
          const res = await this.$api.SetMobileSendSms({
            mobile: this.formNew.mobile
          })
          this.$store.dispatch('app/vcDownStart', true)
        }
      })
    },
    // 下一步
    submitOld() {
      // 先判断是否有点击过验证码
      if (this.noSend) return this.$message.error('请先获取验证码')
      this.$refs.vFormOld.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          const res = await this.$api.OldMobileVerifySendSms(this.formOld.vCode).finally(() => {
            this.btnloadSubmit = false
          })
          this.oldShow = false
          this.$store.commit('app/clearVcCountdown')
          this.newShow = true
        }
      })
    },
    // 提交
    submitNew() {
      this.$refs.vFormNew.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          const res = await this.$api.SetMobileVerifySendSms(this.formNew).finally(() => {
            this.btnloadSubmit = false
          })
          this.newShow = false
          this.$message.success('手机号更换成功')
          this.$emit('submit', this.formNew.mobile)
        }
      })
    }
  }
}
</script>
