<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <el-card shadow="never" style="margin-bottom: 18px; flex-shrink: 0">
      <div class="title">抵扣金余额</div>
      <div class="center">
        <span class="money" v-format="'#,##0.00'">{{ accountBalance }}</span>
        <span class="text">抵扣金可抵扣服务费，无到期时间，永久有效</span>
      </div>
    </el-card>
    <TableView @get="getInvoiceAmount" ref="vTable" :labels="tableLabels" apiName="getDeductionTardePageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left">
        <div class="center">
          <span style="font-size: 20px; margin-right: 10px" class="money">抵扣金明细</span>
          <!-- <span style="color: black" class="text"
            >查询范围内共转入<span v-format="'#,##0.00'">{{ amountInfo.intoAmount }}</span
            >元，转出<span v-format="'#,##0.00'">{{ amountInfo.turnOutAmount }}</span
            >元，其中<span v-format="'#,##0.00'">{{ amountInfo.blockedAmount }}</span
            >元冻结中</span
          > -->
        </div>
      </el-form-item>
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="类型" width="250" fixed="right">
        <template v-slot="{ row }">
          <span v-if="row.businessType == 1">
            <div>服务费返还</div>
            <div>
              关联服务费返还单号：{{ row.workDetailNo }}
              <i class="copy el-icon-copy-document" :data-clipboard-text="row.workDetailNo" @click="copy"></i></div
          ></span>
          <span v-if="row.businessType == 2">
            <div>赠送</div>
            <div>
              关联业务流水号：{{ row.workDetailNo }}
              <i class="copy el-icon-copy-document" :data-clipboard-text="row.workDetailNo" @click="copy"></i></div
          ></span>
          <div v-if="row.businessType == 3">
            <div>抵扣服务费</div>
            <div>
              关联订单号：{{ row.workDetailNo }} <i class="copy el-icon-copy-document" :data-clipboard-text="row.workDetailNo" @click="copy"></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 操作栏 -->
      <!-- <el-table-column slot="table-item" label="操作" width="100" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('004900')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
        </template>
      </el-table-column> -->
    </TableView>
    <!-- <Detail ref="Detail" /> -->
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import { tableDiscountDetail } from '@/configs/tables'
import { searchDiscountDetail } from '@/configs/searches'
import { downloadExcel } from '@/utils'
// import Detail from './detail'
export default {
  // components: { Detail },
  data() {
    return {
      btnloadDownload: false,
      tableLabels: tableDiscountDetail,
      searchList: searchDiscountDetail,
      amountInfo: {},
      accountBalance: 0
    }
  },
  methods: {
    async getInvoiceAmount(e) {
      let res = await this.$api.getDeductionTardePageListStatistics(e)
      this.amountInfo = res
      const amount = await this.$api.GetMerchantInfo()
      console.log(amount)
      this.accountBalance = amount.deductionAvailableAmount
    },
    // 取消
    async cancelOne(item) {
      await this.$confirm(`确认取消当前开票申请吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.CancelBillInfo({ billId: item.billId })
      this.$message.success('开票申请已取消')
      this.$refs.vTable.refreshTable()
    },
    async download() {
      const params = this.$refs.vTable.getParams()
      this.btnloadDownload = true
      const res = await this.$api.DeductionTardePageListExport(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '抵扣金明细')
    },
    copy(e = { target: 222 }) {
      const clipboard = new Clipboard(e.target)
      clipboard.on('success', (e) => {
        this.$message.success('已复制到剪切板')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message.error('复制失败，请手动复制')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}
.center {
  .money {
    font-size: 30px;
    font-weight: 700;
    margin-right: 30px;
  }
  .text {
    font-size: 14px;
    color: #919191;
  }
}
</style>
