import {
  setToken,
  getToken,
  setFirstLogin,
  getFirstLogin,
  setIsMerchantAdmin,
  getIsMerchantAdmin,
  setUserId,
  getUserId,
  setUsername,
  getUsername,
  setAvatar,
  getAvatar,
  setBusinessType,
  getBusinessType,
  setRoute,
  getRoute,
  Encrypt,
  Decrypt,
  setMainName
} from '@/utils/auth'

import API from '@/api'

export default {
  namespaced: true,
  state: {
    token: getToken(),
    isMerchantAdmin: getIsMerchantAdmin(),
    userId: getUserId(),
    username: getUsername(),
    avatar: getAvatar(),
    businessType: getBusinessType(),
    route: getRoute(),
    menuRouters: [],
    auth: {},
    supplierSignList: []
  },
  mutations: {
    setToken(state, token) {
      state.token = Decrypt(token)
      setToken(token)
    },
    setIsMerchantAdmin(state, isMerchantAdmin) {
      state.isMerchantAdmin = isMerchantAdmin
      setIsMerchantAdmin(isMerchantAdmin)
    },
    setUserId(state, userId) {
      state.userId = userId
      setUserId(userId)
    },
    setUsername(state, username) {
      state.username = username
      setUsername(username)
    },
    setAvatar(state, avataer) {
      state.avataer = avataer
      setAvatar(avataer)
    },
    setBusinessType(state, businessType) {
      state.businessType = businessType
      setBusinessType(businessType)
    },
    setRoute(state, route) {
      state.route = route
      setRoute(route)
    },
    setMenuRouters(state, routers) {
      state.menuRouters = routers
    },
    setAuth(state, obj) {
      const { supplierSignList, ...authObj } = obj
      state.auth = authObj || {}
      state.supplierSignList = supplierSignList
    },
    setMainName(state, mainName) {
      state.mainName = mainName
      setMainName(mainName)
    }
  },
  actions: {
    login({ dispatch, commit }, { username: user, password }) {
      return new Promise((resolve, reject) => {
        const params = {
          code: Encrypt(JSON.stringify({ user, password }))
        }
        API.login(params)
          .then((res) => {
            if (res.token) {
              if(res.isBatchDownReceipt == 1) res.routeList.push('batchDownReceipt')
              // 保存token & username & avatar
              commit('setToken', res.token)
              commit('setIsMerchantAdmin', res.isMerchantAdmin)
              commit('setUserId', res.userId)
              commit('setUsername', res.userName)
              commit('setBusinessType', res.businessType)
              // commit('setAvatar', result.avatar)
              commit('setRoute', res.routeList || [])
              commit('setMainName', res.mainName)
              // dispatch('getAuth')
              if (!getFirstLogin(res.userId)) { // 是否是首次登录该浏览器
                setFirstLogin(1, res.userId) // 首次
              } else {
                setFirstLogin(2, res.userId) // 非首次
              }
              resolve()
            }
            reject()
          })
          .catch((err) => {
            reject()
          })
      })
    },
    loginFulu({ dispatch, commit }, { code, state }) {
      return new Promise((resolve, reject) => {
        const params = {
          code: code,
          state: state,
          redirect_uri: encodeURIComponent(window.location.origin)
        }
        API.loginFulu(params)
          .then((res) => {
            if (res.token) {
              // 保存token & username & avatar
              commit('setToken', res.token)
              commit('setUserId', res.userId)
              commit('setUsername', res.userName)
              commit('setBusinessType', res.businessType)
              // commit('setAvatar', result.avatar)
              commit('setRoute', res.routeList || [])
              // dispatch('getAuth')
              resolve()
            }
            reject()
          })
          .catch((err) => {
            reject()
          })
      })
    },
    logOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setToken', '')
        commit('setIsMerchantAdmin', false)
        commit('setUserId', '')
        commit('setUsername', '')
        commit('setBusinessType', '')
        commit('setAvatar', '')
        commit('setRoute', [])
        commit('setAuth', { supplierSignList: [] })
        resolve()
      })
    },
    clearCache({ commit }) {
      commit('setToken', '')
      commit('setIsMerchantAdmin', false)
      commit('setUserId', '')
      commit('setUsername', '')
      commit('setBusinessType', '')
      commit('setAvatar', '')
      commit('setRoute', [])
      commit('setAuth', {})
    },
    getAuth({ commit }) {
      return new Promise((resolve, reject) => {
        API.GetAuthAndSign()
          .then((res) => {
            commit('setAuth', res)
            resolve(res)
          })
          .catch((err) => {
            reject()
          })
      })
    }
  }
}
